import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerName: "",
  headerImage: "",
  headerImageSrc: "",
  isFullscreen: false,
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderDetails(state, { payload }) {
      return { ...state, ...payload };
    },
    clearHeaderDetails(state) {
      return initialState;
    },
  },
});

export const { setHeaderDetails,clearHeaderDetails } = headerSlice.actions;

export default headerSlice.reducer;

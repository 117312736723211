/* eslint-disable no-dupe-keys */
export const images = {
  logo: require("../assets/images/ElectrothermLogo-sm.png"),
  headerLogo: require("../assets/images/HeaderLogo.png"),
  sliderImage1: require("../assets/images/SliderImg1.png"),
  sliderImage2: require("../assets/images/SliderImg2.jpg"),
  sliderImage3: require("../assets/images/SliderImg3.jpg"),
  sliderImage4: require("../assets/images/SliderImg4.jpg"),
  sliderImage5: require("../assets/images/SliderImg5.jpg"),
  sliderImage6: require("../assets/images/SliderImg6.png"),
  sliderImage7: require("../assets/images/SliderImg7.jpg"),
  communicationImg: require("../assets/images/ComminicationImg.png"),
  viewIcon: require("../assets/images/EyeIcon.png"),
  profileImg: require("../assets/images/ProfileImg.png"),
  expandScreen: require("../assets/images/expandScreen.png"),
  uploadLogo: require("../assets/images/UploadLogo.png"),
  editIcon: require("../assets/images/EditIcon.png"),
  closeIcon: require("../assets/images/close.png"),
  loaderImage: require("../assets/images/LoaderImage.png"),
  energyMeterImg: require("../assets/images/EnergyMeterImg.png"),
  // plants: require("../assets/images/Plants.png"),
  activeImg: require("../assets/images/ActiveImg.png"),
  serviceCall: require("../assets/images/ServiceCall.png"),
  alertImage: require("../assets/images/AlertImage.png"),
  notoficationImage: require("../assets/images/NotificationImage.png"),
  IIOTdeviceImage: require("../assets/images/IIOTdeviceImage.png"),
  activeImage: require("../assets/images/ActiveImage.png"),
  subscriptionManagement: require("../assets/images/SubscriptionManagement.png"),
  expiringSoonImage: require("../assets/images/ExpiringSoonImg.png"),
  expiredImage: require("../assets/images/ExpiredImage.png"),
  NotificationImg: require("../assets/images/NotificationImg.png"),
  userManagement: require("../assets/images/UserMangement.png"),
  deviceManagement: require("../assets/images/DeviceManagement.png"),
  accessManagement: require("../assets/images/AccessManagement.png"),
  reportManagement: require("../assets/images/ReportManagement.png"),
  clientManagement: require("../assets/images/ClientManagement.png"),
  plantsManagement: require("../assets/images/PlanManagement.png"),
  // subscription:require("../assets/images/SubscriptionImg.png"),
  subscriptionbg: require("../assets/images/bg.png"),
  serviceCallImg: require("../assets/images/ServiceCallImg.png"),
  inactiveCallImg: require("../assets/images/InactivecallImg.png"),
  notificationsImg: require("../assets/images/NotificationsImg.png"),
  callImg: require("../assets/images/CallImg.png"),
  alertImg: require("../assets/images/AlertImg.png"),
  managementImg: require("../assets/images/ManagementImg.png"),
  cloudActiveImg: require("../assets/images/CloudactiveImg.png"),
  cloudImg: require("../assets/images/CloudImg.png"),
  editImg: require("../assets/images/EditImg.png"),
  settingImg: require("../assets/images/SettingImg.png"),
  deleteImg: require("../assets/images/DeleteImg.png"),
  activeKey: require("../assets/images/ActiveKeyImg.png"),
  expiresSoonImg: require("../assets/images/ExpiresSoonImg.png"),
  expiresImg: require("../assets/images/ExpireImage.png"),
  excelLogo: require("../assets/images/ExcelLogo.png"),
  scanImage: require("../assets/images/ScanImage.png"),
  addImage: require("../assets/images/AddImage.png"),
  deleteIcon: require("../assets/images/DeleteIcon.png"),
  notificationactiveImg: require("../assets/images/NotificationActiveImg.png"),
  passwordImg: require("../assets/images/PasswordImg.png"),
  subscriptionIcon: require("../assets/images/subscriptionIcon.png"),
  csvImg: require("../assets/images/CsvImg.png"),
  bridgeImg: require("../assets/images/BridgeImg.png"),
  speedometer: require("../assets/images/speedometer.png"),
  pieChart: require("../assets/images/piechart.png"),
  barChart: require("../assets/images/barchart.png"),
  informationIcon: require("../assets/images/informationIcon.png"),
  eyecloseIcon: require("../assets/images/EyecloseIcon.png"),
  addIcon: require("../assets/images/AddIcon.png"),
  activeDeviceimg: require("../assets/images/activeDeviceimg.png"),
  scanImg: require("../assets/images/scanImage.jpg"),
  minimizeScreen: require("../assets/images/Minimize-screen.png"),
  editbtn: require("../assets/images/Edit-btn.png"),
  excel: require("../assets/images/Excel.png"),
  configration: require("../assets/images/dashboard.png"),
  document: require("../assets/images/document.png"),
  right: require("../assets/images/right.png"),
  downIcon: require("../assets/images/DownIcon.png"),
  upArrow: require("../assets/images/UpArrow.png"),
  clientManagementPlantS: require("../assets/images/ClientManagementplant.png"),
  alarmimg: require("../assets/images/alarmImg.png"),
  alarmEmptyImg: require("../assets/images/alarmEmptyImg.png"),
  alarmFullImg: require("../assets/images/alarmFullImg.png"),
  lockOpenImg: require("../assets/images/Lock-open-icon.png"),
  lockImg: require("../assets/images/Lock-icon.png"),
  settingImage: require("../assets/images/SettingImage.png"),
  downIconSmall: require("../assets/images/DowniconSmall.png"),
  deviceManagementUnlockWhite: require("../assets/images/DeviceManagementUnlock.png"),
  deviceManagementLockDark: require("../assets/images/DeviceManagementLock.png"),
  deviceManagementUnlockDark: require("../assets/images/DeviceManagementUnlockDark.png"),
  deviceManagementLockWhite: require("../assets/images/DeviceManagementLockWhite.png"),
  pieChartBgImg: require("../assets/images/piechartBGImg.png"),
  barChartBgImg: require("../assets/images/barchartBGImg.png"),
  userImg: require("../assets/images/UsersImg.png"),
  zoomIn: require("../assets/images/ZoomIn.png"),
  zoomOut: require("../assets/images/ZoomOut.png"),
  spinner: require("../assets/images/spinner.gif"),
  notificationbell: require("../assets/images/notification_bell_alert_icon.png"),
  machine: require("../assets/images/machine.png"),
  information: require("../assets/images/information icon.png"),
  cubeIcon: require("../assets/images/cubeIcon.png"),
  crossIcon: require("../assets/images/crossIcon.png"),
  MinusIcon: require("../assets/images/MinusIcon.png"),
  plusIcon: require("../assets/images/plusIcon.png"),
  criticalEvent: require("../assets/images/criticalEvent.png"),
  workOrder: require("../assets/images/workorder.png"),
  alarmImg: require("../assets/images/alarm.png"),
  graph: require("../assets/images/graph.png"),
  graphparams: require("../assets/images/graphparameter.png"),
  active: require("../assets/images/Active.png"),
  inactive: require("../assets/images/InActive.png"),
  whiteplusIcon: require("../assets/images/whitePlusIcon.png"),
  up: require("../assets/images/upImg.png"),
  down: require("../assets/images/downImg.png"),
  right1: require("../assets/images/RightImg.png"),
  left: require("../assets/images/LeftImg.png"),
  noDataFoundImg: require("../assets/images/notfound.png"),
  userLight: require("../assets/images/user_light.png"),
  userDark: require("../assets/images/user_dark.png"),
  speedometerHikemm: require("../assets/images/speedometerHikemm.png"),
  DownTime: require("../assets/images/DownTime.png"),
  vmsSpeedometer: require("../assets/images/vms-speedometer.png"),
  VMSWorkOrder: require("../assets/images/VMSWorkOrder.png"),
  SmartTaglogo: require("../assets/images/dashboard@2x.png"),
  triandleFillIcon: require("../assets/images/triangle-up-icon.png"),
  smartTagUpArrow: require("../assets/images/smartTag-upArrow.png"),
  compactViewIcon: require("../assets/images/CompactViewIcon.png"),
  detailViewIcon: require("../assets/images/DetailedViewIcon.png"),
  compact: require("../assets/images/compact.png"),
  detail: require("../assets/images/detailfillIcon.png"),
  triangleDownIcon: require("../assets/images/triangle-down-icon.png"),
  smartTagDownArrow: require("../assets/images/smarttag-downArrow.png"),
  pinArrow: require("../assets/images/pin.png"),
  unpinArrow: require("../assets/images/unpin.png"),
  editsmartag: require("../assets/images/edit-smarttag.png"),
  noSub: require("../assets/images/no-subscription.png"),
  notFoundData: require("../assets/images/notfoundData.png"),
  processDashboard: require("../assets/images/process-dashboard.png"),
  filterImg: require("../assets/images/filter-icon.png"),
  upIcon: require("../assets/images/up-arrow.png"),
  downIcon: require("../assets/images/down-arrow.png"),
  gearImg: require("../assets/images/setting.png"),
  plusImg: require("../assets/images/Plus icon.png"),
  redNotification: require("../assets/images/red-notification-bell.png"),
  inActiveImg: require("../assets/images/InactiveIcon.png"),
  solarImg: require("../assets/images/solar.png"),
  windImg: require("../assets/images/wind.png"),
  analyze: require("../assets/images/FileTransfer.png"),
  upArrow: require("../assets/images/bpcl-up-arrow.png"),
  powerImg: require("../assets/images/power.png"),
  utilizationImg: require("../assets/images/utilization.png"),
  costSavings: require("../assets/images/cost-savings.png"),
  ecology: require("../assets/images/ecology.png"),
  downarrow: require("../assets/images/down-red-arrow.png"),
  infoIcon: require("../assets/images/info-icon.png"),
  infoIconBlack: require("../assets/images/infoIcon.png"),
  highEfficient: require("../assets/images/highEfficiency.png"),
  mediumEfficient: require("../assets/images/mediumEfficiency.png"),
  lowEfficient: require("../assets/images/lowEfficiency.png"),
  filterIcon: require("../assets/images/filter.png"),
  whiteSolarIcon: require("../assets/images/white-solarIcon.png"),
  whiteWindIcon: require("../assets/images/white-windImg.png"),
  solarImage: require("../assets/images/solarImg.png"),
  windImage: require("../assets/images/windImg.png"),
  greenSolar: require("../assets/images/green-solarImg.png"),
  redSolar: require("../assets/images/red-solarImg.png"),
  orangeSolar: require("../assets/images/orange-solarImg.png"),
  greenWind: require("../assets/images/green-windImg.png"),
  nextIco: require("../assets/images/next-icon.png"),
  preIco: require("../assets/images/Prev-icon.png"),
  lineIco: require("../assets/images/line-icon.png"),
  reactivePower : require("../assets/images/Reactivepower.png"),
  plantImage : require("../assets/images/plant-image.png"),
  suryaGenerationIcon: require("../assets/images/surya-generationIcon.png"),
  suryaCo2Emission: require("../assets/images/surya-co2-emission.png"),
  suryaTotalCapacity: require("../assets/images/surya-total-capacity.png"),
  suryaTotalSavingIcon: require("../assets/images/surya-total-saving.png"),
  suryaLogo: require("../assets/images/suryaLogo.png"),
  renewalIcon: require("../assets/images/Renewable Energy.jpg"),
  suryaDrashti: require("../assets/images/suryadrshtilogo.png"),
  windPowerImg: require("../assets/images/Wind_Power.png")
}

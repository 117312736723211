import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import dayjs from "dayjs";

HC_more(Highcharts);
exporting(Highcharts);

const AreaChart = ({
  chartData,
  colorCode,
  timeRange,
  startDate,
  endDate,
  cubeData,
  selectedPlantId,
  checkBoxPlantId,
  isOthersChecked
}) => {
  console.log("chartData", chartData);
  console.log("cubeData", cubeData);

  // State to hold filteredData
  const [filteredData, setFilteredData] = useState([]);

  const adjustColor = (color, isSelected) => {
    if (isSelected) return color; // Use the original color for selected plants
    return "#918c8c"; // Gray color for unselected plants
};


  useEffect(() => {
    setFilteredData([]);
  }, [timeRange]);

  useEffect(() => {
    // If startDate and endDate are provided, filter the chartData
    const newFilteredData =
      startDate && endDate
        ? chartData
            .filter((plant) => {
              return plant.tags && plant.tags.length > 0;
            })
            .map((plant) => {
              const tag = plant.tags[0]; // Assuming one tag per plant
              // Filter out duplicate timestamps
              const uniqueData = tag.data.reduce((acc, current) => {
                const found = acc.find(
                  (item) => item.x === current.MIN_KEY.split(" ")[0]
                );
                if (!found) {
                  acc.push({
                    x: current.MIN_KEY.split(" ")[0], // Extract only the date portion
                    y: current.tag_value, // Use the tag_value for the y-axis
                  });
                }
                return acc;
              }, []);
              return {
                name: plant.plant_name,
                data: tag.data.map((d) => ({
                  x: d.MIN_KEY.split(" ")[0], // Extract only the date portion
                  y: d.tag_value, // Use the tag_value for the y-axis
                })),
                tag_unit: tag.tag_unit, // Capture the tag_unit for y-axis title
                plantId: plant.plant_id,
              };
            })
        : cubeData.map((plant, i) => {
            console.log("Plant data:", plant);

            // Loop through each tag in the plant's tags array
            const tagData = plant.tags.map((tag) => {
              console.log("Tag data:", tag);
              // Return the data with x (tagTime) and y (data value)
              return {
                x: tag.tagTime, // Use tagTime for x-axis
                y: parseFloat(parseFloat(tag.data).toFixed(2)), // Convert string data to float for y-axis
              };
            });

            return {
              name: plant.plant_name,
              data: tagData, // Add all the tags' data for the plant
              plantId: plant.plantId,
            };
          });

    setFilteredData(newFilteredData);
  }, [chartData, cubeData, startDate, endDate, timeRange]);

  const options = {
    chart: {
      type: "area", // Set chart type as 'area'
      height: 270, // Set the height of the chart
    },
    title: {
      text: null, // No title for the chart
    },
    xAxis: {
      type: "datetime", // Use datetime for x-axis
      categories: filteredData[0]?.data.map((d) => d.x), // Use the x-axis timestamps
      title: {
        text: null,
      },
      startOnTick: true,
      tickmarkPlacement: "on",
      min: 0, // Ensure the first point starts at the x-axis origin
      scrollbar: {
        enabled: true, // Enable the scrollbar
      },
    },
    yAxis: {
      title: {
        text: filteredData[0]?.tag_unit || "", // Dynamic Y-axis title
      },
      gridLineDashStyle: "Dash",
      gridLineColor: "#e0e0e0",
    },

    tooltip: {
      shared: true,
      formatter: function () {
        // Get the plant data from filteredData, since that's what the chart is using
        const plantData = filteredData.find(
          (plant) => plant.data.some((d) => d.x === this.x) // Find the plant corresponding to the current timestamp
        );

        const MinKey = chartData[0]?.tags[0]?.data.map((d) => d.MIN_KEY);
        const timestamp =
          startDate && endDate
            ? MinKey[this.points[0]?.point.index] || "N/A" // Use MinKey based on the point index
            : this.x; // Default to this.x

        // Start building the tooltip content
        let s = `<strong>${timestamp}</strong><br/>`; // Use the chosen timestamp format

        // let s = `${this.x}<br/>`; // Show the timestamp in tooltip
        // Loop through the points (series) and format each one
        this.points.forEach((point) => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
        });

        return s;
      },
    },

    credits: {
      enabled: false, // Remove Highcharts logo
    },
    legend: {
      enabled: false, // Hide the legend for a clean look
    },
    plotOptions: {
      area: {
        stacking: "normal",
        lineColor: "#ffffff",
        lineWidth: 1,
        fillOpacity: 1,
        marker: {
          enabled: false,
        },
      },
    },
    series: filteredData.map((t, i) => {
      console.log("plantId", t, checkBoxPlantId);
      const isSelected = checkBoxPlantId.includes(t.plantId);
      const plantDataFromCube = cubeData.find((cubePlant) => cubePlant.plantId === t.plantId);
      const color = plantDataFromCube ? adjustColor(plantDataFromCube.color, isSelected) : "#918c8c"; // Fallback to gray

      return {
        name: t.name,
        data: t.data.map((d) => d.y), // Use the y-values for the series
        color:  color, // Use the color from colorCode
      };
    }),
  };

  return !isOthersChecked ?  <HighchartsReact highcharts={Highcharts} options={options} /> : "";
};

export default AreaChart;

import { useEffect, useState } from "react";
import { cubejsApi } from "../../../Cubejs/CubejsApiWrapper";
import { SoftTagFormula } from "../../softTag/SoftTagFormula";
import { useSelector } from "react-redux";

const GraphDataMqtt = ({
  selectTagData,
  setGraphRealTimeData,
  selectTimeRange,
  sliderRealTimeTagData,
  dataArray,
}) => {
  const { selectTimeZoneProfile } = useSelector((state) => state.userState);

  const [data, setData] = useState();
  const [selectData, setSelectData] = useState();

  const getTagTime = () => {
    const timeZone = selectTimeZoneProfile || "UTC"; // Default to UTC if null
    return new Date().toLocaleTimeString("en-US", {
      timeZone,
      hour12: false,
    });
  };
  

  useEffect(() => {
    const findTag = dataArray[data];
    console.log("findTag", findTag);
    setSelectData(findTag);
  }, [dataArray]);

  useEffect(() => {
    if (selectTimeRange == "live") {
      const findDvTag =
        selectTagData.tag_config_data &&
        selectTagData.tag_config_data[0] &&
        selectTagData.tag_config_data[0]?.device_tag !== null
          ? selectTagData.tag_config_data[0]?.device_tag
          : false;
      setData(findDvTag);

      const findSoftTag =
        selectTagData.tag_config_data &&
        selectTagData.tag_config_data[0] &&
        selectTagData.tag_config_data[0]?.is_soft_tag
          ? selectTagData.tag_config_data[0]?.is_soft_tag
          : false;

      const findSoftTagFormula =
        selectTagData.tag_config_data &&
        selectTagData.tag_config_data[0] &&
        selectTagData.tag_config_data[0]?.soft_tag_id__formula
          ? selectTagData.tag_config_data[0]?.soft_tag_id__formula
          : null;

      if (findSoftTag && findSoftTagFormula) {
        const softTagResult = SoftTagFormula(
          selectTagData.tag_config_data[0]["soft_tag_id__formula"],
          dataArray
        );

        console.log("softTagResult", softTagResult);
        if (softTagResult?.softTagNotGetTag) {
          setGraphRealTimeData((prev) => {
            const oldData = [...prev];
            const checkTimeData =
              oldData[oldData.length - 1]?.tagTime == getTagTime();

            const checkValueData = oldData.length
              ? oldData[oldData.length - 1]?.tagValue == softTagResult.value ||
                false
              : false;

            if (checkTimeData) {
              return oldData;
            }

            if (checkValueData) {
              return oldData;
            }

            if (oldData.length >= process.env.REACT_APP_GRAPH_LENGTH) {
              oldData.shift(); // Remove oldest entry if exceeds MAX_LENGTH
            }

            const newData = [
              ...prev,
              { tagValue: softTagResult.value, tagTime: getTagTime() },
            ];
            return newData;
          });
        }
      } else if (findDvTag) {
        console.log("Triggering live data update");
        const findSelectTag = selectData;
        if (findSelectTag) {
          console.log("Setting graph data for live data:", findSelectTag);
          setGraphRealTimeData((prev) => {
            let oldData = [...prev];
            if (oldData.length >= process.env.REACT_APP_GRAPH_LENGTH) {
              oldData.shift();
            }
            const newData = [...oldData, { tagValue: findSelectTag, tagTime: currentTime }];
            console.log("newData:", newData);
            return newData;
          });
        }
      }
      
    }
  }, [dataArray]);
  let currentTime = new Date().toLocaleTimeString("en-US", {
    timeZone: selectTimeZoneProfile,
    hour12: false,
  });

  useEffect(() => {
    if (!selectTagData.tag_config_data || !selectTagData.tag_config_data[0]) {
      return;
    }

    const executeCubeQuery = async () => {
      try {
        const tagId = selectTagData.tag_config_data[0].id.toString();
        let currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });

        if (selectTimeRange === "1min") {
          currentTime = new Date().toLocaleTimeString("en-US", {
            timeZone: selectTimeZoneProfile,
            hour12: false,
          });
        } else if (selectTimeRange === "5min") {
          currentTime = new Date().toLocaleTimeString("en-US", {
            timeZone: selectTimeZoneProfile,
            hour12: false,
          });
        } else if (selectTimeRange === "15min") {
          currentTime = new Date().toLocaleTimeString("en-US", {
            timeZone: selectTimeZoneProfile,
            hour12: false,
          });
        }

        const chartGraph = await cubejsApi.load({
          dimensions: ["DeviceConfigurationTagconfiguration.latestValue"],
          limit: 1,
          filters: [
            {
              member: "DeviceConfigurationTagconfiguration.tagid",
              operator: "equals",
              values: [tagId],
            },
          ],
        });

        const tagValue = parseFloat(
          chartGraph.tablePivot().length
            ? chartGraph.tablePivot()[0][
                "DeviceConfigurationTagconfiguration.latestValue"
              ] || 0
            : 0
        );

        // setGraphRealTimeData((prevData) =>
        //   [...prevData, { tagValue, tagTime: currentTime },
        // ]);
        setGraphRealTimeData((prev) => {
          // Create a shallow copy of the previous state
          const indexCopy = [...prev, { tagValue, tagTime: currentTime }];
          //==========================new add code==============================
          if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
            indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
          }

          return indexCopy;
        });
      } catch (error) {
        console.error("Cube query error:", error);
      }
    };

    if (selectTimeRange !== "live") {
      executeCubeQuery();
    }

    let interval = null;
    if (selectTimeRange === "1min") {
      interval = setInterval(() => {
        executeCubeQuery();
      }, 60000);
    } else if (selectTimeRange === "5min") {
      interval = setInterval(() => {
        executeCubeQuery();
      }, 300000);
    } else if (selectTimeRange === "15min") {
      interval = setInterval(() => {
        executeCubeQuery();
      }, 900000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    selectTagData,
    selectTimeRange,
    setGraphRealTimeData,
    selectTimeZoneProfile,
  ]);

  return null;
};

export default GraphDataMqtt;

import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { images } from "../../../config";
import { Switch } from "@mui/material"; // Import the MUI Switch component

HC_more(Highcharts);
exporting(Highcharts);

export const CostBarChart = React.memo(
  ({ costData, showAverage, costTime, startDate, endDate }) => {
    const { selectUnit } = useSelector(
      (state) => state.dateSliceprocessDashboardState
    );
    const chartRef = useRef(null);
    const param = useParams();

    // State to manage the Average switch

    // Prepare data and labels from costData.data, with a fallback to empty arrays if costData is not loaded
    const dataSeries = [
      {
        name: "Tag Value",
        data: costData?.data?.map((item) => parseFloat(item.tag_value)) || [],
        color: "#9933FF",
      },
    ];

    const labels = costData
      ? startDate != endDate
        ? costData?.data?.map((item) => item.MIN_KEY.split(" ")[0])
        : costTime == 1 ||
          (startDate !== null && endDate !== null && startDate == endDate)
        ? costData?.data?.map((item) => item.MIN_KEY)
        : costData?.data?.map((item) => item.MIN_KEY.split(" ")[0])
      : "";
    const averageValues =
      costData?.data?.map((item) => item.average_value) || [];

    const options = {
      chart: {
        type: "column",
        events: {
          load: function () {
            const xAxis = this.xAxis[0];
            if (labels.length >= 5) {
              const visibleCategories = labels.slice(-5);
              xAxis.setExtremes(
                labels.length - visibleCategories.length,
                labels.length - 1,
                false
              );
            }
            this.redraw();
          },
        },
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: labels,
      },
      yAxis: {
        title: {
          text: costData?.data?.length ? costData.data[0]?.tag_unit : "",
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: "bold",
            color: "gray",
          },
        },
      },
      plotOptions: {
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        ...dataSeries,
        ...(showAverage
          ? [
              {
                name: "Average Value",
                data: averageValues,
                type: "line",
                dashStyle: "Dash",
                color: "green",
                marker: {
                  enabled: false,
                },
              },
            ]
          : []),
      ],
      scrollbar: {
        enabled: true,
      },
      exporting: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          const pointIndex = this.point.index;
          const currentData = costData?.data?.[pointIndex];
          let tooltipContent = `<b>${currentData?.MIN_KEY}</b><br/>Value: ${currentData?.tag_value} `;

          if (showAverage && currentData?.average_value) {
            tooltipContent += `<br/><b>Average Value:</b> ${currentData.average_value}`;
          }

          if (currentData?.recipe_data) {
            tooltipContent += `<br/><b>Recipe Data:</b><br/><table style="width: 100%; border-collapse: collapse;">`;
            currentData.recipe_data.forEach((recipe) => {
              tooltipContent += `<tr><td>${recipe.recipe_name}</td><td>${recipe.tag_value}</td></tr>`;
            });
            tooltipContent += `</table>`;
          }
          return tooltipContent;
        },
      },
    };

    useEffect(() => {
      const chart = chartRef.current?.chart;
      if (chart && labels.length >= 5) {
        chart.options.xAxis.min = labels.length - 5;
        chart.options.xAxis.max = labels.length - 1;
        chart.redraw();
      }
    }, [costData, showAverage]);

    console.log("costData", costData);
    if (!costData || costData.length === 0) {
      // No data found
      return (
        <div style={{ height: "395px" }}>
          <img
            src={images.noDataFoundImg}
            className="notFoundDataImg"
            alt="No Data Found Img"
            style={{ transform: "translate(-50px, 50px)" }}
          />
        </div>
      );
    }

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    );
  }
);

// export function CostBarChart({ costData, showAverage }) {
//   const { selectUnit } = useSelector(
//     (state) => state.dateSliceprocessDashboardState
//   );
//   const chartRef = useRef(null);
//   const param = useParams();

//   // State to manage the Average switch

//   // Prepare data and labels from costData.data, with a fallback to empty arrays if costData is not loaded
//   const dataSeries = [
//     {
//       name: "Tag Value",
//       data: costData?.data?.map((item) => parseFloat(item.tag_value)) || [],
//       color: "#9933FF",
//     },
//   ];
//   const labels = costData?.data?.map((item) => item.MIN_KEY) || [];
//   const averageValues = costData?.data?.map((item) => item.average_value) || [];

//   const options = {
//     chart: {
//       type: "column",
//       events: {
//         load: function () {
//           const xAxis = this.xAxis[0];
//           if (labels.length >= 5) {
//             const visibleCategories = labels.slice(-5);
//             xAxis.setExtremes(
//               labels.length - visibleCategories.length,
//               labels.length - 1,
//               false
//             );
//           }
//           this.redraw();
//         },
//       },
//     },
//     title: {
//       text: null,
//     },
//     legend: {
//       enabled: false,
//     },
//     xAxis: {
//       categories: labels,
//     },
//     yAxis: {
//       title: {
//         text: null,
//       },
//       stackLabels: {
//         enabled: false,
//         style: {
//           fontWeight: "bold",
//           color: "gray",
//         },
//       },
//     },
//     plotOptions: {
//       series: {
//         stacking: "normal",
//         dataLabels: {
//           enabled: false,
//         },
//       },
//     },
//     series: [
//       ...dataSeries,
//       ...(showAverage
//         ? [
//             {
//               name: "Average Value",
//               data: averageValues,
//               type: "line",
//               dashStyle: "Dash",
//               color: "green",
//               marker: {
//                 enabled: false,
//               },
//             },
//           ]
//         : []),
//     ],
//     scrollbar: {
//       enabled: true,
//     },
//     exporting: {
//       enabled: true,
//     },
//     credits: {
//       enabled: false,
//     },
//     tooltip: {
//       useHTML: true,
//       formatter: function () {
//         const pointIndex = this.point.index;
//         const currentData = costData?.data?.[pointIndex];
//         let tooltipContent = `<b>${currentData?.MIN_KEY}</b><br/>Value: ${currentData?.tag_value} `;

//         if (showAverage && currentData?.average_value) {
//           tooltipContent += `<br/><b>Average Value:</b> ${currentData.average_value}`;
//         }

//         if (currentData?.recipe_data) {
//           tooltipContent += `<br/><b>Recipe Data:</b><br/><table style="width: 100%; border-collapse: collapse;">`;
//           currentData.recipe_data.forEach((recipe) => {
//             tooltipContent += `<tr><td>${recipe.recipe_name}</td><td>${recipe.tag_value}</td></tr>`;
//           });
//           tooltipContent += `</table>`;
//         }
//         return tooltipContent;
//       },
//     },
//   };

//   useEffect(() => {
//     const chart = chartRef.current?.chart;
//     if (chart && labels.length >= 5) {
//       chart.options.xAxis.min = labels.length - 5;
//       chart.options.xAxis.max = labels.length - 1;
//       chart.redraw();
//     }
//   }, [costData, showAverage]);

//   return (
//     <>
//       {costData ? (
//         <HighchartsReact
//           highcharts={Highcharts}
//           options={options}
//           ref={chartRef}
//         />
//       ) : (
//         <img
//           src={images.noDataFoundImg}
//           className="notFoundDataImg"
//           alt="No Data Found Img"
//         />
//       )}
//     </>
//   );
// }

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "../../../container/ProcessDashboard/Source/Source.css";
import ConsumptionSlider from "../../../container/ProcessDashboard/Consumption/ConsumptionSlider";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import Switch from "@mui/material/Switch";
import { useMemo, useRef, useState } from "react";
import "../../ProcessDashboard/Consumption/Consumption.css";
import {
  getDeviceSourceAPI,
  getTagID,
} from "../../ProcessDashboard/Source/services";
import {
  getDeviceConf,
  getPlantDeviceTag,
  exportConsumptionReportExcelApi,
  exportSourceReportExcelApi,
} from "../../ProcessDashboard/Consumption/service";
import { useEffect } from "react";
import { LineChart } from "../../ProcessDashboard/Consumption/LineChart";
import HighchartsGraph from "../../ProcessDashboard/Consumption/LineChart2";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";
import { useCubeQuery } from "@cubejs-client/react";
import CubeQueryComponent from "../../ProcessDashboard/Consumption/ConsumptionGraphData";
import CubeQueryComponent1 from "../../ProcessDashboard/Consumption/ConsumptionGraphData1";
import CubeQueryComponent2 from "../../ProcessDashboard/Consumption/ConsumptionGraphData2";
import CubeQueryComponent3 from "../../ProcessDashboard/Consumption/ConsumptionGraphData3";
import ConsumptionThresholdValue from "../../ProcessDashboard/Consumption/ConsumptionThresholdValue";
import { setProcessDashboarData } from "../../ProcessDashboard/Consumption/slice/processDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Every15minutesRemoveGraphdata from "../../ProcessDashboard/Consumption/RemoveGraphData/Every15minutesRemoveGraphdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { images } from "../../../config";
import {
  CompactTagUnit,
  CompactTagValue,
} from "../../../components/CompactValue/CompactValue";
import DeviceListShimmer from "../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";
import { setProcessDashboarDateSet } from "../../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import { getDeviceUnitData } from "../../ProcessDashboard/OverView/services";
import { SoftTagFormula } from "../../SmartTag/softTag/SoftTagFormula";
import { geTagAllTimeMinMax } from "../../VMS/VmsCompanyDashboard/VmsMachine/service";

const BPCLEnergy = () => {
  const [activeClass, setActiveClass] = useState("Generation");
  const [deviceData, setDeviceData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [unitSearchQuery, setUnitSearchQuery] = useState("");
  const [selectTimeRange, setSelectTimeRange] = useState("live");
  const [openEnergyModal, setOpenEnergyModal] = useState(false);
  const [deviceUnitData, setDeviceUnitData] = useState([]);
  const [tagVMSData, setTagVMSData] = useState([]);
  const [mqttTagData, setMqttTagData] = useState();
  const [previousValues, setPreviousValues] = useState({});
  const [selectedPreFixData, setSelectedPrefixData] = useState("");
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);
  const [tagMinMaxValue, setTagMinMaxValue] = useState([]);

  const { deviceIdRe, deviceUnit, deviceColor } = useSelector(
    (state) => state.processDashboardState
  );
  const { user_access } = useSelector((state) => state.userState);
  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  const { selectTimeZoneProfile, is_renewable_energy } = useSelector(
    (state) => state.userState
  );

  useEffect(() => {
    const hasKwUnit = deviceUnitData.some((f) =>
      f.tag_unit.toLowerCase().startsWith("k")
    );
    setSelectedPrefixData(hasKwUnit);
  }, [deviceUnitData]);

  const dispatch = useDispatch();
  // console.log("deviceColor", deviceColor);

  const handleUnitSearchChange = (event) => {
    setUnitSearchQuery(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const [deviceId, setDeviceId] = useState([]);
  const [unitName, setUnitName] = useState([]);
  const [getDevice, setGetDevice] = useState([]);
  const [minValue, setMinValue] = useState([]);
  const [maxValue, setMaxValue] = useState([]);
  const [chartCubeData, setChartCubeData] = useState([]);
  const [average, setAverage] = useState(false);
  const [confData, setConfData] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [chartLineData, setChartLineData] = useState([]);
  const [minMax, setMinMax] = useState([]);
  const [realTimevalue, setRealTimevalue] = useState([]);
  const [chartLineData1, setChartLineData1] = useState([]);
  const [minMax1, setMinMax1] = useState([]);
  const [realTimevalue1, setRealTimevalue1] = useState([]);
  const [chartLineData2, setChartLineData2] = useState([]);
  const [minMax2, setMinMax2] = useState([]);
  const [realTimevalue2, setRealTimevalue2] = useState([]);
  const [chartLineData3, setChartLineData3] = useState([]);
  const [minMax3, setMinMax3] = useState([]);
  const [realTimevalue3, setRealTimevalue3] = useState([]);
  const [combineChartData, setCombineChartData] = useState([[], [], [], []]);
  const [combineRealTimeData, setCombinerealTimeData] = useState([]);
  const [combineMinMax, setCombineminMax] = useState([]);
  const [percentageValue, setPercentageValue] = useState([]);
  const [cubeThresholdValue, setCubeThresoldValue] = useState([]);
  const [cubeTagUnitSTKVA, setCubeTagUnitSTKVA] = useState(0);
  const [thresholdValue, setThresholdValue] = useState(0);
  const [averageValue, setAverageValue] = useState([]);
  const [isShimmerUnitlist, setisShimmerUnitlist] = useState(false);
  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);

  console.log("getDevice", getDevice);

  const location = useLocation();
  const splitUrl = location.pathname.split("/");

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;
  const consumptionAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Consumption"
        )
      : null
    : null;

  const sourceAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Source"
        )
      : null
    : null;
  // Line-1 Chart data
  const handelCubeChartLineData = (value) => {
    setChartLineData((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData((prev) => [...prev, value]);

    setRealTimevalue([value]);
  };

  // Line-2 Chart data
  const handelCubeChartLineData1 = (value) => {
    setChartLineData1((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData1((prev) => [...prev, value]);
    setRealTimevalue1([value]);
    // setMinMax1([minmax]);
  };

  // Line-3 Chart data
  const handelCubeChartLineData2 = (value) => {
    setChartLineData2((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData2((prev) => [...prev, value]);
    setRealTimevalue2([value]);
    // setMinMax2([minmax]);
  };

  // Line-4 Chart data
  const handelCubeChartLineData3 = (value) => {
    setChartLineData3((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData3((prev) => [...prev, value]);
    setRealTimevalue3([value]);
    // setMinMax3([minmax]);
  };

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  useEffect(() => {
    setCombinerealTimeData([
      realTimevalue,
      realTimevalue1,
      realTimevalue2,
      realTimevalue3,
    ]);
    // setCombineminMax([minMax, minMax1, minMax2, minMax3]);
  }, [
    chartLineData,
    chartLineData1,
    chartLineData2,
    chartLineData3,
    realTimevalue,
    realTimevalue1,
    realTimevalue2,
    realTimevalue3,
    minMax,
    minMax1,
    minMax2,
    minMax3,
  ]);

  // device percentage value
  useEffect(() => {
    if (combineChartData.length) {
      const resultArray = combineChartData.map((subArray) => {
        const lastValue = subArray[subArray.length - 1]?.tagValue;
        const secondLastValue = subArray[subArray.length - 2]?.tagValue;

        const diff = lastValue - secondLastValue;
        const percentage = (diff / secondLastValue) * 100;
        return parseFloat(percentage.toExponential(2).replace(/e-6$/, "")); // round to 2 decimal places
      });

      setPercentageValue(resultArray);
    }
  }, [combineChartData]);
  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {
        console.log("dfgfdgfsdsd", row);
        // Handle soft tags
        if (row.is_soft_tag && row.soft_tag_id__formula) {
          // Compute current value using the formula
          const evaluatedCurrentValue = SoftTagFormula(
            row.soft_tag_id__formula,
            mqttDataReduce
          )?.value;
          console.log("Evaluated current value:", evaluatedCurrentValue);

          // Set the evaluated value as the new previous value
          setPreviousValues((prev) => ({
            ...prev,
            [row.soft_tag_id__formula]: evaluatedCurrentValue,
          }));

          // Use the previous value from state
          const evaluatedPreviousValue =
            previousValues[row.soft_tag_id__formula] || 0;
          console.log("Evaluated previous value:", evaluatedPreviousValue);

          // Calculate statusValue ensuring no division by zero
          const statusValue =
            evaluatedPreviousValue !== 0
              ? ((evaluatedCurrentValue - evaluatedPreviousValue) /
                  evaluatedPreviousValue) *
                100
              : 0;

          return {
            dvName: row.dvName,
            statusValue: statusValue,
            formula: row.soft_tag_id__formula,
          };
        } else {
          const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;

          return {
            dvName: row.dvName,
            statusValue: isFinite(avgCal) ? avgCal : 0.0,
          };
        }
      });

      console.log("averagCalTagvalue", averagCalTagvalue);

      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);

  useMemo(() => {
    if (getDevice.length == 1 && chartLineData.length >= 2) {
      const lastValue = parseFloat(
        chartLineData[chartLineData.length - 1].tagValue
      );
      const secondLastValue = parseFloat(
        chartLineData[chartLineData.length - 2].tagValue
      );
      const chartLineDataAverage = (lastValue + secondLastValue) / 2;
      // setAverageValue((prev) => [...prev, chartLineDataAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, chartLineDataAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData]);

  useMemo(() => {
    if (
      getDevice.length == 2 &&
      chartLineData.length == chartLineData1.length
    ) {
      console.log(
        "chartLineDatachartLineData",
        chartLineData.length,
        chartLineData1.length
      );

      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      // const device3 = chartLineData2.length ? [chartLineData2[chartLineData2.length - 1]] : [];
      // const device4 = chartLineData3.length ? [chartLineData3[chartLineData3.length - 1]] : [];

      const nonEmptyArrays = [device1, device2].filter((arr) => arr.length > 0);
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, selectUnit]);

  useMemo(() => {
    if (
      getDevice.length == 3 &&
      chartLineData.length == chartLineData1.length &&
      chartLineData1.length == chartLineData2.length
    ) {
      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      const device3 = chartLineData2.length
        ? [chartLineData2[chartLineData2.length - 1]]
        : [];
      // const device4 = chartLineData3.length ? [chartLineData3[chartLineData3.length - 1]] : [];

      const nonEmptyArrays = [device1, device2, device3].filter(
        (arr) => arr.length > 0
      );
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, chartLineData2, selectUnit]);

  useMemo(() => {
    if (
      getDevice.length == 4 &&
      chartLineData.length == chartLineData1.length &&
      chartLineData1.length == chartLineData2.length &&
      chartLineData2.length == chartLineData3.length
    ) {
      const device1 = chartLineData.length
        ? [chartLineData[chartLineData.length - 1]]
        : [];
      const device2 = chartLineData1.length
        ? [chartLineData1[chartLineData1.length - 1]]
        : [];
      const device3 = chartLineData2.length
        ? [chartLineData2[chartLineData2.length - 1]]
        : [];
      const device4 = chartLineData3.length
        ? [chartLineData3[chartLineData3.length - 1]]
        : [];

      const nonEmptyArrays = [device1, device2, device3, device4].filter(
        (arr) => arr.length > 0
      );
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        return indexCopy;
      });
    }
  }, [chartLineData1, chartLineData2, chartLineData3, selectUnit]);

  // threshold value in st_kva
  useEffect(() => {
    const differentValue = combineRealTimeData
      .filter((arr) => arr.length > 0)
      .map(
        (arr, index) => parseFloat(arr[0].tagValue) - cubeThresholdValue[index]
      );

    // console.log("differentValuedifferentValue", cubeTagUnitSTKVA);
    if (
      differentValue.length &&
      cubeTagUnitSTKVA &&
      differentValue.every(
        (value) => typeof value === "number" && !isNaN(value)
      )
    ) {
      const kvaThreshold =
        (differentValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        ) /
          (cubeTagUnitSTKVA * 24)) *
        100;
      setThresholdValue(kvaThreshold ? kvaThreshold.toFixed(2) : 0);
    } else {
      console.error(
        "Invalid data in combineRealTimeData or cubeThresholdValue"
      );
    }
  }, [cubeThresholdValue]);

  const mergedArray = mqttTagData
    ? [].concat(...Object.values(mqttTagData))
    : [];

  const mqttDataReduce = mergedArray.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  console.log("mqttDataReduce", mqttDataReduce);

  const switchColors = [
    "#FEBB22",
    "#075571",
    "#002B2A",
    "#C99C00",
    "#69927A",
    "#872B81",
    "#F06292",
    "#29445A",
    "#CD4D4A",
    "#0C4562",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#001A30",
    "#0B648D",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#123782",
    "#C60BC8",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#EB9E79",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#7D2264",
    "#0EB520",
    "#0E3504",
    "#1D733F",
    "#6E02B1",
  ];

  const navigate = useNavigate();

  const param = useParams();
  console.log("param", param);

  useEffect(() => {
    // Check if the URL already has a tab parameter
    const searchParams = new URLSearchParams(location.search);
    const currentTab = searchParams.get("tab");

    // If no tab parameter is present, set it to the default tab
    if (!currentTab) {
      navigate(
        `/energy_trends/company/${param.companyId}/plant/${param.id}?tab=Generation`,
        { replace: true }
      );
    } else {
      setActiveClass(currentTab);
    }
  }, [param.companyId, param.id, location.search, navigate]);

  const handleTabClick = (tabName) => {
    setActiveClass(tabName);

    // Update the URL with the selected tab
    navigate(
      `/energy_trends/company/${param.companyId}/plant/${param.id}?tab=${tabName}`
    );
  };

  useEffect(() => {
    if (activeClass == "Consumption") {
      getDeviceData("Consumption");
      handleUnitData();
    } else {
      getDeviceData("Generation");
      handleUnitData();
    }
  }, [is_renewable_energy, activeClass]);

  useEffect(() => {
    handleUnitData();
  }, [deviceIdRe, activeClass]);

  const handleUnitData = async () => {
    const params = {
      device: deviceIdRe
        .filter((f) => f.plantId == param.id && f.type == activeClass)
        .map((e) => e.deviceId),
    };
    try {
      const resp = await getDeviceUnitData(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceUnitData(resp.data.payload);
      } else {
        setDeviceUnitData([]);
      }
    } catch (error) {
      setDeviceUnitData([]);
      console.log("handlesubmit", error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
        const params = {
            tag_id: getDevice.map((row) => row?.id),
            unit: selectUnit
        };

        try {
            const resp = await geTagAllTimeMinMax(params);
            if ((resp.status === 200 || resp.status === 201) && resp.data.payload.length) {
                setCombineminMax(resp.data.payload);
                console.log("resp.data.payload...CombineminMax", resp.data.payload);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Immediately call fetchData
    fetchData();

    const intervalId = setInterval(fetchData, 15000); // Call fetchData every 15 seconds

    return () => {
        clearInterval(intervalId); // Clear interval on component unmount
    };
}, [getDevice, selectUnit]);


  const getDeviceData = async (type) => {
    setisShimmerdevicelist(true);
    setisShimmerUnitlist(true);
    const params = {
      device_type: type,
      plant_id: Number(param.id),
      is_renewable_energy: is_renewable_energy,
    };
    try {
      const resp = await getDeviceSourceAPI(params);
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerUnitlist(false);
        }, 1000);

        setDeviceData(resp.data.payload?.device);
        setTagData(resp.data.payload?.tag);
      }
    } catch (error) {
      setDeviceData([]);
      setTagData([]);

      setisShimmerdevicelist(false);
      setisShimmerUnitlist(false);
      console.log("error", error);
    }
  };
  const handleSearchData = () => {};
  useEffect(() => {
    const fetchData = async (type) => {
      const newConfData = await Promise.all(
        deviceData.map(async (device) => {
          const unit = await getDeviceConfData(device?.device, type);
          return unit;
        })
      );
      setConfData(newConfData);
    };

    if (activeClass == "Consumption") {
      fetchData("Consumption");
    }

    if (activeClass == "Generation") {
      fetchData("Generation");
    }
  }, [deviceData, activeClass]);

  const getDeviceConfData = async (deviceId, type) => {
    try {
      const param1 = {
        plant_id: param.id,
        company_id: param.companyId,
        device_type: type,
        device_id: deviceId,
      };

      const resp = await getDeviceConf(param1);
      if (resp.status === 200 || resp.status === 201) {
        return resp.data.payload.length
          ? resp.data.payload[0].tag_assigned
          : [];
      }
    } catch (error) {
      console.error("Error fetching device configuration:", error);
    }
  };

  const isCheckboxDisabled = (id) => {
    const selectedDevices = deviceIdRe.filter(
      (device) => device.plantId === param.id && device.type == activeClass
    );
    return (
      selectedDevices.length >= 4 &&
      !selectedDevices.some((device) => device.deviceId === id)
    );
  };

  const handleSelectDeviceId = (id, index, event, row) => {
    const isDeviceTurnedOn = event.target.checked;
    const type = activeClass; // Assuming splitUrl is defined elsewhere in your code

    if (isDeviceTurnedOn) {
      // Device is being turned on

      // Handle device color
      const existingColorIndex = deviceColor.findIndex(
        (color) => color.deviceId === row.device && color.type === type
      );

      if (existingColorIndex !== -1) {
        // Remove existing color if found
        const updatedColors = deviceColor.filter(
          (color) => !(color.deviceId === row.device && color.type === type)
        );
        dispatch(
          setProcessDashboarData({
            deviceColor: updatedColors,
          })
        );
      } else {
        // Add new color
        dispatch(
          setProcessDashboarData({
            deviceColor: [
              ...deviceColor,
              {
                deviceId: row.device,
                dvName: row.device__device_name,
                color: event.target.name,
                type: type,
              },
            ],
          })
        );
      }

      // Handle deviceIdRe
      const existingDeviceIndex = deviceIdRe.findIndex(
        (device) => device.deviceId === id && device.type === type
      );

      if (existingDeviceIndex === -1) {
        // Add device to deviceIdRe if not already present
        dispatch(
          setProcessDashboarData({
            deviceIdRe: [
              ...deviceIdRe,
              {
                plantId: param.id,
                deviceId: id,
                type: type,
              },
            ],
          })
        );
      }
    } else {
      // Device is being turned off

      // Remove color
      const updatedColors = deviceColor.filter(
        (color) => !(color.deviceId === row.device && color.type === type)
      );
      dispatch(
        setProcessDashboarData({
          deviceColor: updatedColors,
        })
      );

      // Remove deviceId from deviceIdRe
      const updatedDevices = deviceIdRe.filter(
        (device) => !(device.deviceId === id && device.type === type)
      );
      dispatch(
        setProcessDashboarData({
          deviceIdRe: updatedDevices,
        })
      );

      // Turn off the associated unit switch
      const updatedUnits = deviceUnit.filter(
        (unit) => !(unit.plantId === param.id && unit.type === type)
      );
      dispatch(
        setProcessDashboarData({
          deviceUnit: updatedUnits,
        })
      );
    }
  };

  const handleSelectTagUnit = (id) => {
    const selectedUnit = deviceUnit.find(
      (unit) => unit.plantId === param.id && unit.type === activeClass
    );

    if (!Array.isArray(deviceUnit)) {
      dispatch(setProcessDashboarData({ deviceUnit: [] }));
    }

    if (selectedUnit && selectedUnit.tagUnit === id) {
      // Remove unit if already selected
      dispatch(
        setProcessDashboarData({
          deviceUnit: deviceUnit.filter(
            (unit) => !(unit.plantId === param.id && unit.type === activeClass)
          ),
        })
      );
    } else {
      // Check if a tag is already selected
      const isTagSelected = deviceUnit.some(
        (unit) => unit.plantId === param.id && unit.type === activeClass
      );

      if (isTagSelected) {
        return true;
      } else {
        dispatch(
          setProcessDashboarData({
            deviceUnit: [
              ...deviceUnit,
              { plantId: param.id, tagUnit: id, type: activeClass },
            ],
          })
        );
      }
    }
  };
  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };
  const evaluateSoftTagValue = (formula, tags) => {
    try {
      // Dynamic tag matching using tag pattern extraction
      const tagPattern = /[A-Za-z0-9_]+/g;
      const tagMatches = formula.match(tagPattern) || [];
      let evaluatedFormula = formula;

      // Replace tags in formula with current values from tags list
      tagMatches.forEach((tag) => {
        const foundTag = tags.find((t) => t.dvName === tag);
        const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
        evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
      });

      // Calculate and return the evaluated value
      return eval(evaluatedFormula);
    } catch {
      return 0; // Default to 0 on error
    }
  };
  const getTagIDApi = async () => {
    const findPlantviseDeviceId = deviceIdRe
      .filter((f) => f.plantId == param.id && f.type == activeClass)
      .map((e) => e.deviceId);
    try {
      const params = {
        device_id: findPlantviseDeviceId,
        tag_unit: deviceUnit.filter(
          (f) => f.plantId == param.id && f.type == activeClass
        )[0]["tagUnit"],
      };
      console.log("paramparamparam", params);
      const resp = await getTagID(params);

      if (resp.status === 200 && resp.data.payload.length) {
        const validDevices = findPlantviseDeviceId
          .map((id) => resp.data.payload.find((item) => item?.device == id))
          .filter(Boolean); // Filter out `null` values

        setGetDevice(validDevices);

        console.log("resp.data.payload", resp.data.payload);

        const tagObj = resp.data.payload.map((row) => {
          let curValue = 0;
          let prevValue = 0;

          // Evaluate formula for soft tags if present
          if (row.is_soft_tag && row.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              row.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue; // Adjust if needed for different prevValue logic
          }

          const obj = {
            dvName: row.device_tag || "-", // Fallback to "-" if `device_tag` is null or undefined
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            is_soft_tag: row.is_soft_tag || false,
            soft_tag_id__formula: row.soft_tag_id__formula || "", // Fallback to empty string if no formula
          };

          console.log("obj", obj);
          return obj;
        });

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      setGetDevice([]);
      // Handle the error here
      console.error("An error occurred while fetching tag IDs:", error);
    }
  };

  useEffect(() => {
    if (deviceIdRe.length && deviceUnit.length) {
      setChartLineData([]);
      setChartLineData1([]);
      setChartLineData2([]);
      setChartLineData3([]);
      setMinMax([]);
      setMinMax1([]);
      setMinMax2([]);
      setMinMax3([]);
      setRealTimevalue([]);
      setRealTimevalue1([]);
      setRealTimevalue2([]);
      setRealTimevalue3([]);
      setCombineChartData([[], [], [], []]);
      setCombineminMax([]);
      setCombinerealTimeData([]);
      setGetDevice([]);
      setAverageValue([]);
      setThresholdValue(0);
      getTagIDApi();
    } else {
      setChartLineData([]);
      setChartLineData1([]);
      setChartLineData2([]);
      setChartLineData3([]);
      setMinMax([]);
      setMinMax1([]);
      setMinMax2([]);
      setMinMax3([]);
      setRealTimevalue([]);
      setRealTimevalue1([]);
      setRealTimevalue2([]);
      setRealTimevalue3([]);
      setCombineChartData([[], [], [], []]);
      setCombineminMax([]);
      setCombinerealTimeData([]);
      setGetDevice([]);
      setAverageValue([]);
      setThresholdValue(0);
    }
  }, [deviceIdRe.length, deviceUnit.length, selectTimeRange, activeClass]);

  const handleExportConsumptionReportExcel = async () => {
    if (!deviceIdRe.length && !deviceUnit.length) {
      notifyError("please select device or unit");
      return true;
    }

    const paramReq = {
      company: param.companyId,
      plant: param.id,
      time: combineChartData.length
        ? combineChartData[0].map((e) => e.tagTime)
        : [],
      device: deviceIdRe
        .filter((f) => f.plantId == param.id && f.type == activeClass)
        .map((e) => e.deviceId),
      tag_unit: deviceUnit.filter(
        (f) => f.plantId == param.id && f.type == activeClass
      )[0]["tagUnit"],
      tag_value: combineChartData.length
        ? combineChartData.flatMap((array) => array.map((obj) => obj.tagValue))
        : [],
    };

    if (activeClass == "Consumption") {
      const resp = await exportConsumptionReportExcelApi(paramReq);
      console.log("respresprespresp", resp.data);
      if (resp.data.success == true) {
        notify("Energy data exported successfully.");
      }
    } else {
      const resp = await exportSourceReportExcelApi(paramReq);
      console.log("respresprespresp", resp.data);
      if (resp.data.success == true) {
        notify("Energy data exported successfully.");
      }
    }
  };

  const getTagTime = () => {
    return new Date().toLocaleTimeString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  const executeCubeQuery = async () => {
    try {
      var currentTime;

      if (selectTimeRange == "1min") {
        console.log(
          "selectTimeRange.........................",
          selectTimeRange
        );
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "5min") {
        console.log(
          "selectTimeRange.........................",
          selectTimeRange
        );
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "15min") {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "30min") {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      const chartGraph = await cubejsApi.load({
        dimensions: [
          "DeviceConfigurationTagconfiguration.latestValue",
          "DeviceConfigurationTagconfiguration.tagName",
          "DeviceConfigurationTagconfiguration.deviceId",
        ],
        filters: [
          {
            member: "DeviceConfigurationTagconfiguration.tagName",
            operator: "equals",
            values: getDevice.length
              ? getDevice.map((row) => row.tag_name)
              : [],
          },
        ],
        limit: 4,
      });

      const findPlantviseDeviceId = deviceIdRe
        .filter((f) => f.plantId == param.id && f.type == activeClass)
        .map((e) => e.deviceId);

      const sortingSelectViseDeiveId = findPlantviseDeviceId.map((row) => {
        return chartGraph
          .tablePivot()
          ?.find(
            (id) => id["DeviceConfigurationTagconfiguration.deviceId"] == row
          );
      });

      console.log("sortingSelectViseDeiveId", sortingSelectViseDeiveId);
      setCombineChartData((prev) => {
        const zeroIndx = [...prev];

        // Create a shallow copy of the inner array you want to modify (assuming it's an array)
        let innerArrayCopy = [...zeroIndx[0]];
        let innerArrayCopy1 = [...zeroIndx[1]];
        let innerArrayCopy2 = [...zeroIndx[2]];
        let innerArrayCopy3 = [...zeroIndx[3]];

        // Push the new object into the inner array copy
        if (getDevice[0]) {
          if (
            innerArrayCopy[innerArrayCopy.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy.push({
              tagValue:
                sortingSelectViseDeiveId?.[0]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[0]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (getDevice[1]) {
          if (
            innerArrayCopy1[innerArrayCopy1.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy1.push({
              tagValue:
                sortingSelectViseDeiveId?.[1]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData1(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[1]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (getDevice[2]) {
          if (
            innerArrayCopy2[innerArrayCopy2.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy2.push({
              tagValue:
                sortingSelectViseDeiveId?.[2]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData2(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[2]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (getDevice[3]) {
          if (
            innerArrayCopy3[innerArrayCopy3.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy3.push({
              tagValue:
                sortingSelectViseDeiveId?.[3]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData3(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[3]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        //==========================new add code==============================
        if (innerArrayCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy1.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy1.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy2.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy2.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy3.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy3.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        zeroIndx[0] = innerArrayCopy;
        zeroIndx[1] = innerArrayCopy1;
        zeroIndx[2] = innerArrayCopy2;
        zeroIndx[3] = innerArrayCopy3;

        return zeroIndx;
      });

      console.log("chartGraphchartGraphchartGraph", sortingSelectViseDeiveId);
    } catch (error) {
      // Handle any errors that occur during the cube query
      console.error("Cube query error:", error);
    }
  };

  useEffect(() => {
    if (selectTimeRange != "live" && getDevice.length) {
      console.log("kpkpkpkpkpkpkkpk");
      // Function to execute the cube query

      // Set interval to execute the cube query based on selectTimeRange
      let interval = null;
      switch (selectTimeRange) {
        case "1min":
          interval = setInterval(executeCubeQuery, 60000);
          break;
        case "5min":
          interval = setInterval(executeCubeQuery, 300000);
          break;
        case "15min":
          interval = setInterval(executeCubeQuery, 900000);
          break;
        default:
          clearInterval(interval); // Clear interval if selectTimeRange is not supported
      }

      // Execute the cube query immediately
      executeCubeQuery();

      // Clean up the interval when the component unmounts or when selectTimeRange changes
      return () => {
        clearInterval(interval);
      };
    }
  }, [getDevice, selectTimeRange]);

  const handleOpenEnergyModal = () => {
    setOpenEnergyModal((prev) => {
      const newState = !prev;
      console.log("openEnergy", newState);
      return newState;
    });
  };
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      !event.target.closest(".filter-img-class")
    ) {
      setOpenEnergyModal(false);
    }
  };

  useEffect(() => {
    if (openEnergyModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEnergyModal]);

  return (
    <>
      {getDevice.length ? (
              <CubeQueryComponent
              getDevice={getDevice}
              handelCubeChartLineData={handelCubeChartLineData}
              handelCubeChartLineData1={handelCubeChartLineData1}
              handelCubeChartLineData2={handelCubeChartLineData2}
              handelCubeChartLineData3={handelCubeChartLineData3}
              selectTimeRange={selectTimeRange}
              setCombineChartData={setCombineChartData}
              setCombineminMax={setCombineminMax}
              setCombinerealTimeData={setCombinerealTimeData}
              combineRealTimeData={combineRealTimeData}
              setTagVMSData={setTagVMSData}
              setMqttTagPreviousvalue={setMqttTagPreviousvalue}
              setMqttTagData={setMqttTagData}
              mqttTagData={mqttTagData}
            />
      ) : null}

      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          {activeClass === "Generation" ? (
            <div
              onClick={() => navigate(`/bpcl-processdashboard/company/${param.companyId}/plant/${param.id}`)}
              className="page-back-btn"
            >
              <ArrowBackIcon />
              {/* <span> Generation</span> */}
              <span>Energy Trends</span>
            </div>
          ) : (
            <div
              onClick={() =>
                navigate(
                  `/bpcl-processdashboard/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
            >
              <ArrowBackIcon />
              {/* <span>Consumption</span> */}
              <span>Energy Trends</span>
            </div>
          )}

          <div className="d-flex">
            <button
              className={`${
                activeClass === "Generation"
                  ? "active-tab Analytics-tabs"
                  : "Reports-tabs"
              }`}
              onClick={() => handleTabClick("Generation")}
            >
              Generation
            </button>
            <button
              className={`${
                activeClass === "Consumption"
                  ? "active-tab Analytics-tabs"
                  : "Reports-tabs"
              }`}
              onClick={() => handleTabClick("Consumption")}
            >
              Consumption
            </button>
          </div>
        </div>

        {activeClass == "Generation" ? (
          <>
            <div className="overview-card-header sticky-page">
              <div className="page-header d-flex justify-content-between">
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%", flexWrap: "wrap" }}
                >
                  <div
                    onClick={() =>
                      navigate(
                        `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
                      )
                    }
                    className="page-back-btn"
                    style={{ marginTop: "-19px" }}
                  >
                    <ArrowBackIcon />
                    <span>
                      {" "}
                      {activeClass == "Consumption"
                        ? "Consumption"
                        : "Generation"}
                    </span>
                  </div>
                  <div className="d-flex gap-2 justify-content-end compact-sec">
                    <div>
                      <select
                        className="compact-btn"
                        value={selectUnit}
                        onChange={(e) =>
                          dispatch(
                            setProcessDashboarDateSet({
                              selectUnit: e.target.value,
                            })
                          )
                        }
                      >
                        <option value="k">Kilo</option>
                        <option value="m">Mega</option>
                        <option value="g">Giga</option>
                      </select>
                    </div>
                    <div>
                      <button
                        className="configration-btn confi-btn"
                        onClick={() => {
                          if (activeClass === "Consumption") {
                            navigate(
                              `/consumption-configure/company/${param.companyId}/plant/${param.id}`
                            );
                          } else {
                            navigate(
                              `/generation-conf/source/company/${param.companyId}/plant/${param.id}`
                            );
                          }
                        }}
                      >
                        <img
                          src={images.configration}
                          style={{
                            height: "18px",
                            width: "18px",
                            marginRight: "8px",
                          }}
                          alt=""
                        />
                        Configuration
                      </button>
                    </div>

                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenEnergyModal();
                      }}
                      className="filter-img-class"
                    >
                      <img
                        src={images.filterImg}
                        alt=""
                        style={{ height: "40px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-3">
              <ConsumptionSlider
                deviceData={deviceData}
                confData={confData}
                getDevice={getDevice}
              />
            </div>
            <div className="pt-2 consumption-grid-card consumption-fixHeight">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                  <Box className="source-box consumer-box">
                    <Box className="consumer-text-disc mt-2">
                      <h6 className="Title">Title</h6>
                      <h6
                        className="Title-desc"
                        style={{ marginBottom: "0 !important" }}
                      >
                        Energy Meter Trends - Real Time
                      </h6>
                    </Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Device here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />
                    <div className="furnace-grid">
                      {(consumptionAccess?.is_viewer &&
                        consumptionAccess?.is_editor) ||
                      (!consumptionAccess?.is_viewer &&
                        consumptionAccess?.is_editor) ||
                      (consumptionAccess?.is_viewer &&
                        !consumptionAccess?.is_editor) ||
                      (sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                      (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                      (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            deviceData.length ? (
                              deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handleSelectDeviceId(
                                              e.device,
                                              index,
                                              event,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          disabled={isCheckboxDisabled(
                                            e.device
                                          )}
                                          checked={deviceIdRe.find(
                                            (f) =>
                                              f.deviceId == e.device &&
                                              f.type == activeClass
                                          )}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      ) : (
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            deviceData.length ? (
                              deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          name={switchColors[index]}
                                          disabled={isCheckboxDisabled(
                                            e.device
                                          )}
                                          checked={deviceIdRe.find(
                                            (f) =>
                                              f.deviceId == e.device &&
                                              f.type == activeClass
                                          )}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      )}
                    </div>
                    <Box className="consumer-text-disc mt-2 "></Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Unit here"
                      value={unitSearchQuery}
                      onChange={handleUnitSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />

                    <div className="device-unit" style={{ height: "196px" }}>
                      <Box className="Grid-text1 mt-2">
                        {!isShimmerUnitlist ? (
                          [
                            ...new Set(
                              deviceUnitData
                                .filter(
                                  (e) =>
                                    e.tag_unit &&
                                    e.tag_unit
                                      .toLowerCase()
                                      .includes(unitSearchQuery.toLowerCase())
                                )
                                .map((e) => e.tag_unit)
                            ),
                          ].map((tagUnit) => (
                            <Grid item xs={12} key={tagUnit}>
                              {tagUnit && (
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    className="mt-2"
                                    style={{ padding: "7px 12px" }}
                                  >
                                    <h6>{tagUnit}</h6>
                                  </Grid>
                                  {(consumptionAccess?.is_viewer &&
                                    consumptionAccess?.is_editor) ||
                                  (!consumptionAccess?.is_viewer &&
                                    consumptionAccess?.is_editor) ||
                                  (consumptionAccess?.is_viewer &&
                                    !consumptionAccess?.is_editor) ||
                                  (sourceAccess?.is_viewer &&
                                    sourceAccess?.is_editor) ||
                                  (!sourceAccess?.is_viewer &&
                                    sourceAccess?.is_editor) ||
                                  (sourceAccess?.is_viewer &&
                                    !sourceAccess?.is_editor) ? (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnit.find(
                                            (f) =>
                                              f.tagUnit == tagUnit &&
                                              f.plantId == param.id &&
                                              f.type == activeClass
                                          )
                                            ? true
                                            : false
                                        }
                                        style={{ float: "right" }}
                                        onClick={() =>
                                          handleSelectTagUnit(tagUnit)
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#50C878",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#CEE6E1 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnit.find(
                                            (f) =>
                                              f.tagUnit == tagUnit &&
                                              f.plantId == param.id &&
                                              f.type == activeClass
                                          )
                                            ? true
                                            : false
                                        }
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#50C878",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#CEE6E1 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          ))
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    </div>

                    <div>
                      <button
                        className="export-btn"
                        onClick={handleExportConsumptionReportExcel}
                      >
                        EXPORT ENERGY DATA
                      </button>
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  className="consumption-table-box"
                >
                  <Box className="source-table-box">
                    <div style={{ paddingTop: "20px" }}>
                      <div className="d-flex justify-content-end">
                        {["kwh", "kvah", "kvarh","agg"].includes(
                          deviceUnit.length
                            ? deviceUnit.find(
                                (f) =>
                                  f.plantId == param.id && f.type == activeClass
                              )
                              ? deviceUnit
                                  .find(
                                    (f) =>
                                      f.plantId == param.id &&
                                      f.type == activeClass
                                  )
                                  ["tagUnit"].toLowerCase()
                              : ""
                            : ""
                        ) && (
                          <Box className="source-boxes">
                            <h6 style={{ color: "#50C878" }}>
                              Cum. :{" "}
                              {percentageValue.length
                                ? percentageValue.reduce(
                                    (total, value) => total + value,
                                    0
                                  )
                                  ? percentageValue
                                      .reduce(
                                        (total, value) => total + value,
                                        0
                                      )
                                      .toFixed(2)
                                  : 0
                                : 0}
                              %
                            </h6>
                          </Box>
                        )}

                        <Box
                          className="source-boxes"
                          style={{ marginRight: "22px" }}
                        >
                          <h6>
                            Threshold:<b> {thresholdValue}%</b>
                          </h6>
                        </Box>
                        <div className="d-flex avg-switch">
                          <p className="average-toggle consumption-avg-toggle">
                            Average
                          </p>
                          {(consumptionAccess?.is_viewer &&
                            consumptionAccess?.is_editor) ||
                          (!consumptionAccess?.is_viewer &&
                            consumptionAccess?.is_editor) ||
                          (consumptionAccess?.is_viewer &&
                            !consumptionAccess?.is_editor) ||
                          (sourceAccess?.is_viewer &&
                            sourceAccess?.is_editor) ||
                          (!sourceAccess?.is_viewer &&
                            sourceAccess?.is_editor) ||
                          (sourceAccess?.is_viewer &&
                            !sourceAccess?.is_editor) ? (
                            <Switch
                              checked={average}
                              onChange={(e) => setAverage(e.target.checked)}
                              className="average-toggle-switch"
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#50C878",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#CEE6E1 !important",
                                  },
                                },
                              }}
                            />
                          ) : (
                            <Switch
                              // checked={average}
                              onClick={handleTostMsg}
                              className="average-toggle-switch"
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#50C878",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#CEE6E1 !important",
                                  },
                                },
                              }}
                            />
                          )}
                        </div>

                        <div className="d-flex avg-switch">
                          <h6 className="time-desc">Interval</h6>
                          {(consumptionAccess?.is_viewer &&
                            consumptionAccess?.is_editor) ||
                          (!consumptionAccess?.is_viewer &&
                            consumptionAccess?.is_editor) ||
                          (consumptionAccess?.is_viewer &&
                            !consumptionAccess?.is_editor) ||
                          (sourceAccess?.is_viewer &&
                            sourceAccess?.is_editor) ||
                          (!sourceAccess?.is_viewer &&
                            sourceAccess?.is_editor) ||
                          (sourceAccess?.is_viewer &&
                            !sourceAccess?.is_editor) ? (
                            <Box
                              style={{ float: "right", marginRight: "20px" }}
                            >
                              <select
                                className="form-input-class consumption-interval-toggle"
                                style={{
                                  padding: "6px 8px",
                                  borderRadius: "4px",
                                }}
                                id="selectedClass"
                                // defaultValue="all"
                                value={selectTimeRange}
                                onChange={(event) =>
                                  setSelectTimeRange(event.target.value)
                                }
                                required
                              >
                                <option value="live" selected>
                                  Live
                                </option>
                                <option value="1min">1 Min</option>
                                <option value="5min">5 Min</option>
                                <option value="15min">15 Min</option>
                              </select>
                            </Box>
                          ) : (
                            <Box
                              sx={{ minWidth: 200 }}
                              style={{ float: "right", marginRight: "20px" }}
                            >
                              <select
                                className="form-input-class"
                                style={{
                                  padding: "6px 8px",
                                  borderRadius: "4px",
                                }}
                                id="selectedClass"
                                onChange={handleTostMsg}
                                required
                              >
                                <option value="live" selected>
                                  Live
                                </option>
                                <option value="1min">1 Min</option>
                                <option value="5min">5 Min</option>
                                <option value="15min">15 Min</option>
                              </select>
                            </Box>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "421px",
                        width: "100%",
                        padding: "20px",
                      }}
                    >
                      <HighchartsGraph
                    selectedColors={deviceColor}
                    unitName={CompactTagUnit(
                      selectUnit,
                      deviceUnit.length
                        ? deviceUnit.filter(
                            (f) =>
                              f.plantId == param.id && f.type == splitUrl[1]
                          )[0]?.["tagUnit"] || ""
                        : ""
                    )}
                    chartCubeData={chartCubeData}
                    getDevice={getDevice}
                    average={average}
                    chartLineData={chartLineData}
                    combineChartData={combineChartData}
                    selectTimeRange={selectTimeRange}
                    averageValue={averageValue}
                    selectUnit={selectUnit}
                    // setCombineChartData={setCombineChartData}
                  />
                    </div>
                    <div>
                      <Table className="source-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "600",
                                width: "18%",
                                fontSize: "18px",
                                fontWeight: "800",
                              }}
                            >
                              {CompactTagUnit(
                                selectUnit,
                                deviceUnit.length
                                  ? deviceUnit.filter(
                                      (f) =>
                                        f.plantId == param.id &&
                                        f.type == activeClass
                                    )[0]?.["tagUnit"] || ""
                                  : ""
                              )}
                            </TableCell>

                            {getDevice.length
                          ? getDevice.map((e, index) => {
                              const findColor = deviceColor?.find(
                                (f) => f?.deviceId == e?.device
                              );

                              const findStatus = tagStatus.length
                              ? tagStatus.find(
                                  (f) => f.dvName == e.device_tag
                                )
                              : 0;
                              return (
                                <TableCell>
                                  <div className="d-flex justify-content-between">
                                    <div>{e?.device__device_name}</div>
                                    <div>
                                          {e.is_soft_tag && e.soft_tag_id__formula ? (
                                            tagStatus.find(
                                              (status) =>
                                                status.formula ===
                                                e.soft_tag_id__formula
                                            )?.statusValue < 0 ? (
                                              <SouthIcon
                                                style={{ color: findColor
                                              ? findColor.color
                                              : "" }}
                                              />
                                            ) : (
                                              <NorthIcon
                                                style={{ color: findColor
                                              ? findColor.color
                                              : "" }}
                                              />
                                            )
                                          ) : findStatus ? (
                                            findStatus.statusValue < 0 ? (
                                              <SouthIcon
                                                style={{ color: findColor
                                              ? findColor.color
                                              : "" }}
                                              />
                                            ) : (
                                              <NorthIcon
                                                style={{ color: findColor
                                              ? findColor.color
                                              : "" }}
                                              />
                                            )
                                          ) : (
                                            <NorthIcon
                                              style={{ color: findColor
                                              ? findColor.color
                                              : "" }}
                                            />
                                          )}
                                          {e.is_soft_tag && e.soft_tag_id__formula
                                            ? (
                                                tagStatus.find(
                                                  (status) =>
                                                    status.formula ==
                                                    e.soft_tag_id__formula
                                                )?.statusValue || 0.0
                                              ).toFixed(2)
                                            : findStatus
                                            ? (
                                                findStatus.statusValue || 0.0
                                              ).toFixed(2)
                                            : (0.0).toFixed(2)}
                                          %
                                        </div>
                                  </div>
                                </TableCell>
                              );
                            })
                          : []}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              Real Time
                            </TableCell>
                            {getDevice && getDevice.length
                              ? getDevice.map((row, i) => {
                                  const findTagName = tagVMSData.length
                                    ? tagVMSData.find(
                                        (f) => f.device_tag == row.device_tag
                                      )
                                    : "";
                                  console.log(
                                    "findTagName",
                                    findTagName,
                                    tagVMSData,
                                    row
                                  );
                                  const tagValue =
                                    combineRealTimeData[i]?.[0]?.tagValue;
                                  return (
                                    <TableCell>
                                      {selectedPreFixData
                                        ? row.is_soft_tag &&
                                          row.soft_tag_id__formula
                                          ? SoftTagFormula(
                                              row.soft_tag_id__formula,
                                              mqttDataReduce
                                            )?.softTagNotGetTag
                                            ? CompactTagValue(
                                                selectUnit,
                                                SoftTagFormula(
                                                  row.soft_tag_id__formula,
                                                  mqttDataReduce
                                                )?.value
                                              )
                                            : findTagName?.tagValue
                                            ? CompactTagValue(
                                                selectUnit,
                                                findTagName.tagValue
                                              )
                                            : "-"
                                          : findTagName?.tagValue
                                          ? CompactTagValue(
                                              selectUnit,
                                              findTagName.tagValue
                                            )
                                          : "-"
                                        : row.is_soft_tag &&
                                          row.soft_tag_id__formula
                                        ? SoftTagFormula(
                                            row.soft_tag_id__formula,
                                            mqttDataReduce
                                          )?.softTagNotGetTag
                                          ? SoftTagFormula(
                                              row.soft_tag_id__formula,
                                              mqttDataReduce
                                            )?.value
                                          : findTagName?.tagValue
                                          ? findTagName.tagValue
                                          : "-"
                                        : findTagName?.tagValue
                                        ? findTagName.tagValue
                                        : "-"}
                                    </TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              All Time Min.
                            </TableCell>
                            {getDevice && getDevice.length
                              ? getDevice.map((row, i) => {
                                  const findMinValue = combineMinMax.length
                                    ? combineMinMax.find(
                                        (f) => row.id == f.tag_id
                                      )
                                    : 0;
                                  return (
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_min_value
                                        : "-"}
                                    </TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              All Time Max.
                            </TableCell>
                            {getDevice && getDevice.length
                              ? getDevice.map((row, i) => {
                                  const findMinValue = combineMinMax.length
                                    ? combineMinMax.find(
                                        (f) => row.id == f.tag_id
                                      )
                                    : 0;
                                  return (
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_max_value
                                        : "-"}
                                    </TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table className="source-consumption-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "600",
                                width: "18%",
                                fontSize: "14px",
                                fontWeight: "800",
                              }}
                            >
                              {CompactTagUnit(
                                selectUnit,
                                deviceUnit.length
                                  ? deviceUnit.filter(
                                      (f) =>
                                        f.plantId == param.id &&
                                        f.type == activeClass
                                    )[0]?.["tagUnit"] || ""
                                  : ""
                              )}
                            </TableCell>
                            <TableCell style={{ fontWeight: "700" }}>
                              Real Time
                            </TableCell>
                            <TableCell style={{ fontWeight: "700" }}>
                              All Time Min.
                            </TableCell>
                            <TableCell style={{ fontWeight: "700" }}>
                              All Time Max.
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getDevice && getDevice.length
                            ? getDevice.map((device, index) => {
                                const findColor = deviceColor.find(
                                  (f) => f.deviceId == device?.device
                                );
                                const tagValue =
                                  combineRealTimeData[index]?.[0]?.tagValue;
                                const findMinValue = combineMinMax.find(
                                  (f) => device.id == f.tag_id
                                );

                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <div className="d-flex justify-content-between">
                                        <div>{device?.device__device_name}</div>
                                        <div>
                                          {percentageValue[index] > 0 ? (
                                            <NorthIcon
                                              style={{
                                                color: findColor
                                                  ? findColor.color
                                                  : "",
                                              }}
                                            />
                                          ) : percentageValue[index] == 0 ? (
                                            ""
                                          ) : (
                                            <SouthIcon
                                              style={{
                                                color: findColor
                                                  ? findColor.color
                                                  : "",
                                              }}
                                            />
                                          )}
                                          {percentageValue[index]
                                            ? percentageValue[index].toFixed(2)
                                            : 0.0}
                                          %
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      {tagValue
                                        ? CompactTagValue(selectUnit, tagValue)
                                        : ""}
                                    </TableCell>
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_min_value
                                        : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_max_value
                                        : "-"}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            : []}
                        </TableBody>
                      </Table>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
            {openEnergyModal && (
              <div className="consumerCard" style={{ top: "23%" }}>
                <Box className="source-box consumer-card-box">
                  <Box className="consumer-text-disc mt-2">
                    <h6 className="Title">Title</h6>
                    <h6
                      className="Title-desc"
                      style={{ marginBottom: "0 !important" }}
                    >
                      Energy Meter Trends - Real Time
                    </h6>
                  </Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Device here"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />
                  <div className="furnace-grid">
                    {(consumptionAccess?.is_viewer &&
                      consumptionAccess?.is_editor) ||
                    (!consumptionAccess?.is_viewer &&
                      consumptionAccess?.is_editor) ||
                    (consumptionAccess?.is_viewer &&
                      !consumptionAccess?.is_editor) ||
                    (sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                    (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                    (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
                      <Box className="Grid-text mt-2">
                        {!isShimmerdevicelist ? (
                          deviceData.length ? (
                            deviceData
                              .filter((e) =>
                                e.device__device_name
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.device__device_name}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      style={{
                                        padding: "7px 12px",
                                        borderLeft: `4px solid ${
                                          switchColors[
                                            index % switchColors.length
                                          ]
                                        }`,
                                      }}
                                    >
                                      <h6 className="mt-2">
                                        {e?.device__device_name}
                                      </h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={(event) =>
                                          handleSelectDeviceId(
                                            e.device,
                                            index,
                                            event,
                                            e
                                          )
                                        }
                                        name={switchColors[index]}
                                        disabled={isCheckboxDisabled(e.device)}
                                        checked={deviceIdRe.find(
                                          (f) =>
                                            f.deviceId == e.device &&
                                            f.type == activeClass
                                        )}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))
                          ) : (
                            []
                          )
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    ) : (
                      <Box className="Grid-text mt-2">
                        {!isShimmerdevicelist ? (
                          deviceData.length ? (
                            deviceData
                              .filter((e) =>
                                e?.device__device_name
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e?.device__device_name}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      style={{
                                        padding: "7px 12px",
                                        borderLeft: `4px solid ${
                                          switchColors[
                                            index % switchColors.length
                                          ]
                                        }`,
                                      }}
                                    >
                                      <h6 className="mt-2">
                                        {e?.device__device_name}
                                      </h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        name={switchColors[index]}
                                        disabled={isCheckboxDisabled(e.device)}
                                        checked={deviceIdRe.find(
                                          (f) =>
                                            f.deviceId == e.device &&
                                            f.type == activeClass
                                        )}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))
                          ) : (
                            []
                          )
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    )}
                  </div>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Unit here"
                    value={unitSearchQuery}
                    onChange={handleUnitSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />

                  <div className="device-unit" style={{ height: "196px" }}>
                    <Box className="Grid-text1 mt-2">
                      {!isShimmerUnitlist ? (
                        [
                          ...new Set(
                            deviceUnitData
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e) => e.tag_unit)
                          ),
                        ].map((tagUnit) => (
                          <Grid item xs={12} key={tagUnit}>
                            {tagUnit && (
                              <Grid
                                container
                                alignItems="center"
                                style={{
                                  background: "#efeffd",
                                  width: "100%",
                                  marginBottom: "7px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  className="mt-2"
                                  style={{ padding: "7px 12px" }}
                                >
                                  <h6>{tagUnit}</h6>
                                </Grid>
                                {(consumptionAccess?.is_viewer &&
                                  consumptionAccess?.is_editor) ||
                                (!consumptionAccess?.is_viewer &&
                                  consumptionAccess?.is_editor) ||
                                (consumptionAccess?.is_viewer &&
                                  !consumptionAccess?.is_editor) ||
                                (sourceAccess?.is_viewer &&
                                  sourceAccess?.is_editor) ||
                                (!sourceAccess?.is_viewer &&
                                  sourceAccess?.is_editor) ||
                                (sourceAccess?.is_viewer &&
                                  !sourceAccess?.is_editor) ? (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      checked={
                                        deviceUnit.find(
                                          (f) =>
                                            f.tagUnit == tagUnit &&
                                            f.plantId == param.id &&
                                            f.type == activeClass
                                        )
                                          ? true
                                          : false
                                      }
                                      style={{ float: "right" }}
                                      onClick={() =>
                                        handleSelectTagUnit(tagUnit)
                                      }
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#50C878",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#CEE6E1 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      checked={
                                        deviceUnit.find(
                                          (f) =>
                                            f.tagUnit == tagUnit &&
                                            f.plantId == param.id &&
                                            f.type == activeClass
                                        )
                                          ? true
                                          : false
                                      }
                                      style={{ float: "right" }}
                                      onClick={handleTostMsg}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#50C878",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#CEE6E1 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          </Grid>
                        ))
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  </div>

                  <div>
                    <button
                      className="export-btn"
                      onClick={handleExportConsumptionReportExcel}
                    >
                      EXPORT ENERGY DATA
                    </button>
                  </div>
                </Box>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="overview-card-header sticky-page">
              <div className="page-header d-flex justify-content-between">
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%", flexWrap: "wrap" }}
                >
                  <div
                    onClick={() =>
                      navigate(
                        `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
                      )
                    }
                    className="page-back-btn"
                    style={{ marginTop: "-19px" }}
                  >
                    <ArrowBackIcon />
                    <span>
                      {" "}
                      {activeClass == "Consumption"
                        ? "Consumption"
                        : "Generation"}
                    </span>
                  </div>
                  <div className="d-flex gap-2 justify-content-end compact-sec">
                    <div>
                      <select
                        className="compact-btn"
                        value={selectUnit}
                        onChange={(e) =>
                          dispatch(
                            setProcessDashboarDateSet({
                              selectUnit: e.target.value,
                            })
                          )
                        }
                      >
                        <option value="k">Kilo</option>
                        <option value="m">Mega</option>
                        <option value="g">Giga</option>
                      </select>
                    </div>
                    <div>
                      <button
                        className="configration-btn confi-btn"
                        onClick={() => {
                          if (activeClass === "Consumption") {
                            navigate(
                              `/consumption-configration/company/${param.companyId}/plant/${param.id}`
                            );
                          } else {
                            navigate(
                              `/configration-source/source/company/${param.companyId}/plant/${param.id}`
                            );
                          }
                        }}
                      >
                        <img
                          src={images.configration}
                          style={{
                            height: "18px",
                            width: "18px",
                            marginRight: "8px",
                          }}
                          alt=""
                        />
                        Configuration
                      </button>
                    </div>

                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenEnergyModal();
                      }}
                      className="filter-img-class"
                    >
                      <img
                        src={images.filterImg}
                        alt=""
                        style={{ height: "40px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-3">
              <ConsumptionSlider
                deviceData={deviceData}
                confData={confData}
                getDevice={getDevice}
              />
            </div>
            <div className="pt-2 consumption-grid-card consumption-fixHeight">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                  <Box className="source-box consumer-box">
                    <Box className="consumer-text-disc mt-2">
                      <h6 className="Title">Title</h6>
                      <h6
                        className="Title-desc"
                        style={{ marginBottom: "0 !important" }}
                      >
                        Energy Meter Trends - Real Time
                      </h6>
                    </Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Device here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />
                    <div className="furnace-grid">
                      {(consumptionAccess?.is_viewer &&
                        consumptionAccess?.is_editor) ||
                      (!consumptionAccess?.is_viewer &&
                        consumptionAccess?.is_editor) ||
                      (consumptionAccess?.is_viewer &&
                        !consumptionAccess?.is_editor) ||
                      (sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                      (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                      (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            deviceData.length ? (
                              deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handleSelectDeviceId(
                                              e.device,
                                              index,
                                              event,
                                              e
                                            )
                                          }
                                          name={switchColors[index]}
                                          disabled={isCheckboxDisabled(
                                            e.device
                                          )}
                                          checked={deviceIdRe.find(
                                            (f) =>
                                              f.deviceId == e.device &&
                                              f.type == activeClass
                                          )}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      ) : (
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            deviceData.length ? (
                              deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          name={switchColors[index]}
                                          disabled={isCheckboxDisabled(
                                            e.device
                                          )}
                                          checked={deviceIdRe.find(
                                            (f) =>
                                              f.deviceId == e.device &&
                                              f.type == activeClass
                                          )}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      )}
                    </div>
                    <Box className="consumer-text-disc mt-2 "></Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Unit here"
                      value={unitSearchQuery}
                      onChange={handleUnitSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />

                    <div className="device-unit" style={{ height: "196px" }}>
                      <Box className="Grid-text1 mt-2">
                        {!isShimmerUnitlist ? (
                          [
                            ...new Set(
                              deviceUnitData
                                .filter(
                                  (e) =>
                                    e.tag_unit &&
                                    e.tag_unit
                                      .toLowerCase()
                                      .includes(unitSearchQuery.toLowerCase())
                                )
                                .map((e) => e.tag_unit)
                            ),
                          ].map((tagUnit) => (
                            <Grid item xs={12} key={tagUnit}>
                              {tagUnit && (
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    className="mt-2"
                                    style={{ padding: "7px 12px" }}
                                  >
                                    <h6>{tagUnit}</h6>
                                  </Grid>
                                  {(consumptionAccess?.is_viewer &&
                                    consumptionAccess?.is_editor) ||
                                  (!consumptionAccess?.is_viewer &&
                                    consumptionAccess?.is_editor) ||
                                  (consumptionAccess?.is_viewer &&
                                    !consumptionAccess?.is_editor) ||
                                  (sourceAccess?.is_viewer &&
                                    sourceAccess?.is_editor) ||
                                  (!sourceAccess?.is_viewer &&
                                    sourceAccess?.is_editor) ||
                                  (sourceAccess?.is_viewer &&
                                    !sourceAccess?.is_editor) ? (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnit.find(
                                            (f) =>
                                              f.tagUnit == tagUnit &&
                                              f.plantId == param.id &&
                                              f.type == activeClass
                                          )
                                            ? true
                                            : false
                                        }
                                        style={{ float: "right" }}
                                        onClick={() =>
                                          handleSelectTagUnit(tagUnit)
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#50C878",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#CEE6E1 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnit.find(
                                            (f) =>
                                              f.tagUnit == tagUnit &&
                                              f.plantId == param.id &&
                                              f.type == activeClass
                                          )
                                            ? true
                                            : false
                                        }
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#50C878",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#CEE6E1 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          ))
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    </div>

                    <div>
                      <button
                        className="export-btn"
                        onClick={handleExportConsumptionReportExcel}
                      >
                        EXPORT ENERGY DATA
                      </button>
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  className="consumption-table-box"
                >
                  <Box className="source-table-box">
                    <div style={{ paddingTop: "20px" }}>
                      <div className="d-flex justify-content-end">
                        {["kwh", "kvah", "kvarh","agg"].includes(
                          deviceUnit.length
                            ? deviceUnit.find(
                                (f) =>
                                  f.plantId == param.id && f.type == activeClass
                              )
                              ? deviceUnit
                                  .find(
                                    (f) =>
                                      f.plantId == param.id &&
                                      f.type == activeClass
                                  )
                                  ["tagUnit"].toLowerCase()
                              : ""
                            : ""
                        ) && (
                          <Box className="source-boxes">
                            <h6 style={{ color: "#50C878" }}>
                              Cum. :{" "}
                              {percentageValue.length
                                ? percentageValue.reduce(
                                    (total, value) => total + value,
                                    0
                                  )
                                  ? percentageValue
                                      .reduce(
                                        (total, value) => total + value,
                                        0
                                      )
                                      .toFixed(2)
                                  : 0
                                : 0}
                              %
                            </h6>
                          </Box>
                        )}

                        <Box
                          className="source-boxes"
                          style={{ marginRight: "22px" }}
                        >
                          <h6>
                            Threshold:<b> {thresholdValue}%</b>
                          </h6>
                        </Box>
                        <div className="d-flex avg-switch">
                          <p className="average-toggle consumption-avg-toggle">
                            Average
                          </p>
                          {(consumptionAccess?.is_viewer &&
                            consumptionAccess?.is_editor) ||
                          (!consumptionAccess?.is_viewer &&
                            consumptionAccess?.is_editor) ||
                          (consumptionAccess?.is_viewer &&
                            !consumptionAccess?.is_editor) ||
                          (sourceAccess?.is_viewer &&
                            sourceAccess?.is_editor) ||
                          (!sourceAccess?.is_viewer &&
                            sourceAccess?.is_editor) ||
                          (sourceAccess?.is_viewer &&
                            !sourceAccess?.is_editor) ? (
                            <Switch
                              checked={average}
                              onChange={(e) => setAverage(e.target.checked)}
                              className="average-toggle-switch"
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#50C878",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#CEE6E1 !important",
                                  },
                                },
                              }}
                            />
                          ) : (
                            <Switch
                              // checked={average}
                              onClick={handleTostMsg}
                              className="average-toggle-switch"
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#50C878",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#CEE6E1 !important",
                                  },
                                },
                              }}
                            />
                          )}
                        </div>

                        <div className="d-flex avg-switch">
                          <h6 className="time-desc">Interval</h6>
                          {(consumptionAccess?.is_viewer &&
                            consumptionAccess?.is_editor) ||
                          (!consumptionAccess?.is_viewer &&
                            consumptionAccess?.is_editor) ||
                          (consumptionAccess?.is_viewer &&
                            !consumptionAccess?.is_editor) ||
                          (sourceAccess?.is_viewer &&
                            sourceAccess?.is_editor) ||
                          (!sourceAccess?.is_viewer &&
                            sourceAccess?.is_editor) ||
                          (sourceAccess?.is_viewer &&
                            !sourceAccess?.is_editor) ? (
                            <Box
                              style={{ float: "right", marginRight: "20px" }}
                            >
                              <select
                                className="form-input-class consumption-interval-toggle"
                                style={{
                                  padding: "6px 8px",
                                  borderRadius: "4px",
                                }}
                                id="selectedClass"
                                // defaultValue="all"
                                value={selectTimeRange}
                                onChange={(event) =>
                                  setSelectTimeRange(event.target.value)
                                }
                                required
                              >
                                <option value="live" selected>
                                  Live
                                </option>
                                <option value="1min">1 Min</option>
                                <option value="5min">5 Min</option>
                                <option value="15min">15 Min</option>
                              </select>
                            </Box>
                          ) : (
                            <Box
                              sx={{ minWidth: 200 }}
                              style={{ float: "right", marginRight: "20px" }}
                            >
                              <select
                                className="form-input-class"
                                style={{
                                  padding: "6px 8px",
                                  borderRadius: "4px",
                                }}
                                id="selectedClass"
                                onChange={handleTostMsg}
                                required
                              >
                                <option value="live" selected>
                                  Live
                                </option>
                                <option value="1min">1 Min</option>
                                <option value="5min">5 Min</option>
                                <option value="15min">15 Min</option>
                              </select>
                            </Box>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "421px",
                        width: "100%",
                        padding: "20px",
                      }}
                    >
                      <HighchartsGraph
                        selectedColors={deviceColor}
                        unitName={CompactTagUnit(
                          selectUnit,
                          deviceUnit.length
                            ? deviceUnit.filter(
                                (f) =>
                                  f.plantId == param.id && f.type == activeClass
                              )[0]?.["tagUnit"] || ""
                            : ""
                        )}
                        chartCubeData={chartCubeData}
                        getDevice={getDevice}
                        average={average}
                        chartLineData={chartLineData}
                        combineChartData={combineChartData}
                        selectTimeRange={selectTimeRange}
                        averageValue={averageValue}
                        selectUnit={selectUnit}
                      />
                    </div>
                    <div>
                      <Table className="source-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "600",
                                width: "18%",
                                fontSize: "18px",
                                fontWeight: "800",
                              }}
                            >
                              {CompactTagUnit(
                                selectUnit,
                                deviceUnit.length
                                  ? deviceUnit.filter(
                                      (f) =>
                                        f.plantId == param.id &&
                                        f.type == activeClass
                                    )[0]?.["tagUnit"] || ""
                                  : ""
                              )}
                            </TableCell>

                            {getDevice.length
                              ? getDevice.map((e, index) => {
                                  const findColor = deviceColor?.find(
                                    (f) => f?.deviceId == e?.device
                                  );
                                  return (
                                    <TableCell>
                                      <div className="d-flex justify-content-between">
                                        <div>{e?.device__device_name}</div>
                                        <div>
                                          {percentageValue[index] ? (
                                            percentageValue[index].toFixed(2) <
                                            0 ? (
                                              <SouthIcon
                                                style={{
                                                  color: findColor
                                                    ? findColor.color
                                                    : "",
                                                }}
                                              />
                                            ) : (
                                              <NorthIcon
                                                style={{
                                                  color: findColor
                                                    ? findColor.color
                                                    : "",
                                                }}
                                              />
                                            )
                                          ) : (
                                            <NorthIcon
                                              style={{
                                                color: findColor
                                                  ? findColor.color
                                                  : "",
                                              }}
                                            />
                                          )}
                                          {percentageValue[index]
                                            ? percentageValue[index].toFixed(2)
                                            : 0.0}{" "}
                                          %
                                        </div>
                                      </div>
                                    </TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              Real Time
                            </TableCell>
                            {getDevice && getDevice.length
                              ? getDevice.map((row, i) => {
                                  const tagValue =
                                    combineRealTimeData[i]?.[0]?.tagValue;
                                  return (
                                    <TableCell>
                                      {tagValue
                                        ? CompactTagValue(selectUnit, tagValue)
                                        : 0}
                                    </TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              All Time Min.
                            </TableCell>
                            {getDevice && getDevice.length
                              ? getDevice.map((row, i) => {
                                  const findMinValue = combineMinMax.length
                                    ? combineMinMax.find(
                                        (f) => row.id == f.tag_id
                                      )
                                    : 0;
                                  return (
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_min_value
                                        : "-"}
                                    </TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              All Time Max.
                            </TableCell>
                            {getDevice && getDevice.length
                              ? getDevice.map((row, i) => {
                                  const findMinValue = combineMinMax.length
                                    ? combineMinMax.find(
                                        (f) => row.id == f.tag_id
                                      )
                                    : 0;
                                  return (
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_max_value
                                        : "-"}
                                    </TableCell>
                                  );
                                })
                              : []}
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table className="source-consumption-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "600",
                                width: "18%",
                                fontSize: "14px",
                                fontWeight: "800",
                              }}
                            >
                              {CompactTagUnit(
                                selectUnit,
                                deviceUnit.length
                                  ? deviceUnit.filter(
                                      (f) =>
                                        f.plantId == param.id &&
                                        f.type == activeClass
                                    )[0]?.["tagUnit"] || ""
                                  : ""
                              )}
                            </TableCell>
                            <TableCell style={{ fontWeight: "700" }}>
                              Real Time
                            </TableCell>
                            <TableCell style={{ fontWeight: "700" }}>
                              All Time Min.
                            </TableCell>
                            <TableCell style={{ fontWeight: "700" }}>
                              All Time Max.
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getDevice && getDevice.length
                            ? getDevice.map((device, index) => {
                                const findColor = deviceColor.find(
                                  (f) => f.deviceId == device?.device
                                );
                                const tagValue =
                                  combineRealTimeData[index]?.[0]?.tagValue;
                                const findMinValue = combineMinMax.find(
                                  (f) => device.id == f.tag_id
                                );

                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <div className="d-flex justify-content-between">
                                        <div>{device?.device__device_name}</div>
                                        <div>
                                          {percentageValue[index] > 0 ? (
                                            <NorthIcon
                                              style={{
                                                color: findColor
                                                  ? findColor.color
                                                  : "",
                                              }}
                                            />
                                          ) : percentageValue[index] == 0 ? (
                                            ""
                                          ) : (
                                            <SouthIcon
                                              style={{
                                                color: findColor
                                                  ? findColor.color
                                                  : "",
                                              }}
                                            />
                                          )}
                                          {percentageValue[index]
                                            ? percentageValue[index].toFixed(2)
                                            : 0.0}
                                          %
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      {tagValue
                                        ? CompactTagValue(selectUnit, tagValue)
                                        : ""}
                                    </TableCell>
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_min_value
                                        : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {findMinValue
                                        ? findMinValue.all_time_max_value
                                        : "-"}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            : []}
                        </TableBody>
                      </Table>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
            {openEnergyModal && (
              <div className="consumerCard" style={{ top: "23%" }}>
                <Box className="source-box consumer-card-box">
                  <Box className="consumer-text-disc mt-2">
                    <h6 className="Title">Title</h6>
                    <h6
                      className="Title-desc"
                      style={{ marginBottom: "0 !important" }}
                    >
                      Energy Meter Trends - Real Time
                    </h6>
                  </Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Device here"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />
                  <div className="furnace-grid">
                    {(consumptionAccess?.is_viewer &&
                      consumptionAccess?.is_editor) ||
                    (!consumptionAccess?.is_viewer &&
                      consumptionAccess?.is_editor) ||
                    (consumptionAccess?.is_viewer &&
                      !consumptionAccess?.is_editor) ||
                    (sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                    (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
                    (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
                      <Box className="Grid-text mt-2">
                        {!isShimmerdevicelist ? (
                          deviceData.length ? (
                            deviceData
                              .filter((e) =>
                                e.device__device_name
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.device__device_name}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      style={{
                                        padding: "7px 12px",
                                        borderLeft: `4px solid ${
                                          switchColors[
                                            index % switchColors.length
                                          ]
                                        }`,
                                      }}
                                    >
                                      <h6 className="mt-2">
                                        {e?.device__device_name}
                                      </h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={(event) =>
                                          handleSelectDeviceId(
                                            e.device,
                                            index,
                                            event,
                                            e
                                          )
                                        }
                                        name={switchColors[index]}
                                        disabled={isCheckboxDisabled(e.device)}
                                        checked={deviceIdRe.find(
                                          (f) =>
                                            f.deviceId == e.device &&
                                            f.type == activeClass
                                        )}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))
                          ) : (
                            []
                          )
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    ) : (
                      <Box className="Grid-text mt-2">
                        {!isShimmerdevicelist ? (
                          deviceData.length ? (
                            deviceData
                              .filter((e) =>
                                e?.device__device_name
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e?.device__device_name}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      style={{
                                        padding: "7px 12px",
                                        borderLeft: `4px solid ${
                                          switchColors[
                                            index % switchColors.length
                                          ]
                                        }`,
                                      }}
                                    >
                                      <h6 className="mt-2">
                                        {e?.device__device_name}
                                      </h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        name={switchColors[index]}
                                        disabled={isCheckboxDisabled(e.device)}
                                        checked={deviceIdRe.find(
                                          (f) =>
                                            f.deviceId == e.device &&
                                            f.type == activeClass
                                        )}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))
                          ) : (
                            []
                          )
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    )}
                  </div>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Unit here"
                    value={unitSearchQuery}
                    onChange={handleUnitSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />

                  <div className="device-unit" style={{ height: "196px" }}>
                    <Box className="Grid-text1 mt-2">
                      {!isShimmerUnitlist ? (
                        [
                          ...new Set(
                            deviceUnitData
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e) => e.tag_unit)
                          ),
                        ].map((tagUnit) => (
                          <Grid item xs={12} key={tagUnit}>
                            {tagUnit && (
                              <Grid
                                container
                                alignItems="center"
                                style={{
                                  background: "#efeffd",
                                  width: "100%",
                                  marginBottom: "7px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  className="mt-2"
                                  style={{ padding: "7px 12px" }}
                                >
                                  <h6>{tagUnit}</h6>
                                </Grid>
                                {(consumptionAccess?.is_viewer &&
                                  consumptionAccess?.is_editor) ||
                                (!consumptionAccess?.is_viewer &&
                                  consumptionAccess?.is_editor) ||
                                (consumptionAccess?.is_viewer &&
                                  !consumptionAccess?.is_editor) ||
                                (sourceAccess?.is_viewer &&
                                  sourceAccess?.is_editor) ||
                                (!sourceAccess?.is_viewer &&
                                  sourceAccess?.is_editor) ||
                                (sourceAccess?.is_viewer &&
                                  !sourceAccess?.is_editor) ? (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      checked={
                                        deviceUnit.find(
                                          (f) =>
                                            f.tagUnit == tagUnit &&
                                            f.plantId == param.id &&
                                            f.type == activeClass
                                        )
                                          ? true
                                          : false
                                      }
                                      style={{ float: "right" }}
                                      onClick={() =>
                                        handleSelectTagUnit(tagUnit)
                                      }
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#50C878",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#CEE6E1 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      checked={
                                        deviceUnit.find(
                                          (f) =>
                                            f.tagUnit == tagUnit &&
                                            f.plantId == param.id &&
                                            f.type == activeClass
                                        )
                                          ? true
                                          : false
                                      }
                                      style={{ float: "right" }}
                                      onClick={handleTostMsg}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#50C878",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#CEE6E1 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          </Grid>
                        ))
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  </div>

                  <div>
                    <button
                      className="export-btn"
                      onClick={handleExportConsumptionReportExcel}
                    >
                      EXPORT ENERGY DATA
                    </button>
                  </div>
                </Box>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default BPCLEnergy;

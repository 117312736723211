import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

const HalfCircleGauge = ({
  realTimeData,
  sliderRealTimeTagData,
  containerName,
  color,
}) => {
  const chartRef = useRef(null);

  // console.log("realTimeData",sliderRealTimeTagData)

  useEffect(() => {
    if (!realTimeData || realTimeData.peak_value_today === undefined) {
      console.error("realTimeData or peak_value_today is undefined.");
      return;
    }

    // Extract Real-Time Data
    const RealTimeData = sliderRealTimeTagData.length
      ? sliderRealTimeTagData.find(
          (f) =>
            f.device__uid === realTimeData.device_uid &&
            f.device_tag === realTimeData.device_tag
        )?.["tagValue"] || 0.0
      : 0.0;

      // console.log("RealTimeDataRealTimeData",RealTimeData)

    // Function to get plotBands based on containerName
    const getPlotBands = () => {
      if (color === "green-red") {
        // Green to Red
        return [
          {
            from: Number(realTimeData.low_range),
            to: Number(realTimeData.high_range) * 0.2,
            color: "#55BF3B", // Green
            thickness: 30,
          },
          {
            from: Number(realTimeData.high_range) * 0.2,
            to: Number(realTimeData.high_range) * 0.5,
            color: "#DDDF0D", // Yellow
            thickness: 30,
          },
          {
            from: Number(realTimeData.high_range) * 0.5,
            to: Number(realTimeData.high_range),
            color: "#DF5353", // Red
            thickness: 30,
          },
        ];
      } else {
        // Red to Green (default)
        return [
          {
            from: Number(realTimeData.low_range),
            to: Number(realTimeData.high_range) * 0.2,
            color: "#DF5353", // Red
            thickness: 30,
          },
          {
            from: Number(realTimeData.high_range) * 0.2,
            to: Number(realTimeData.high_range) * 0.5,
            color: "#DDDF0D", // Yellow
            thickness: 30,
          },
          {
            from: Number(realTimeData.high_range) * 0.5,
            to: Number(realTimeData.high_range),
            color: "#55BF3B", // Green
            thickness: 30,
          },
        ];
      }
    };

    const chartOptions = {
      chart: {
        type: "gauge",
        backgroundColor: null,
        plotBackgroundColor: "transparent",
        plotBorderWidth: 0,
        plotShadow: false,
        height: "50%",
      },
      title: { text: null },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ["50%", "75%"],
        size: "100%",
      },
      credits: { enabled: false },
      yAxis: {
        min: Number(realTimeData.low_range),
        max: Number(realTimeData.high_range),
        tickPixelInterval: 72,
        tickPosition: "inside",
        tickLength: 15,
        minorTickInterval: null,
        labels: {
          enabled: true,
          distance: 15,
        },
        plotBands: getPlotBands(), // Dynamically set plotBands
      },
      series: [
        {
          name: "Real-Time Data",
          data: [Number(RealTimeData)],
          tooltip: { valueSuffix: ` ${realTimeData?.tag_unit}` },
          dataLabels: {
            format: `{y} ${realTimeData?.tag_unit}`,
            y: 6,
          },
          dial: {
            backgroundColor: "black",
            baseWidth: 12,
            baseLength: "0%",
            rearLength: "0%",
            radius: "90%",
          },
          pivot: { backgroundColor: "black", radius: 7 },
        },
        {
          name: "Peak value",
          data: realTimeData?.tag_name !== "Plant_Wind_Dirc" && [
            Number(realTimeData.peak_value_today),
          ],
          dataLabels: {
            format: `{y} ${realTimeData?.tag_unit}`,
            y: 50,
            color: "red",
            borderColor: "red",
            style: {
              fontSize: "11px", // Set the desired font size
              fontWeight: "bold", // Makes the font bold
              fontFamily: "Arial, sans-serif", // Set the desired font family
              textOutline: "none", // Removes the default text outline (important for clarity)
            },
          },
          tooltip: { valueSuffix: ` ${realTimeData?.tag_unit}` },
          dial: {
            backgroundColor: "red",
            baseWidth: 12,
            baseLength: "0%",
            rearLength: "0%",
            radius: "90%",
          },
          pivot: { backgroundColor: "red", radius: 7 },
        },
      ],
    };

    if (!chartRef.current) {
      chartRef.current = Highcharts.chart(containerName, chartOptions);
    } else {
      // Update Peak Value needle only if value has changed
      const peakValueData = Number(realTimeData.peak_value_today);
      if (
        chartRef.current.series[1] &&
        chartRef.current.series[1]?.data[0]?.y !== peakValueData
      ) {
        chartRef.current.series[1]?.data[0]?.update(peakValueData);
      }

      // Update Real-Time Data needle only if value has changed
      const realTimeDataValue = Number(RealTimeData);
      if (
        chartRef.current.series[0] &&
        chartRef.current.series[0]?.data[0]?.y !== realTimeDataValue
      ) {
        chartRef.current.series[0]?.data[0]?.update(realTimeDataValue, true, {
          duration: 500,
        });
      }
    }

    // return () => {
    //   if (chartRef.current) {
    //     chartRef.current.destroy();
    //     chartRef.current = null;
    //   }
    // };
  }, [realTimeData, sliderRealTimeTagData, containerName]);

  return <div id={containerName} style={{ height: "100%" }}></div>;
};

export default HalfCircleGauge;

import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  createTheme,
} from "@mui/material";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import { useEffect } from "react";
import {
  customeReportData,
  exportAnalyticsCOCExcel,
  getDeviceReceipe,
  getDeviceUnitData,
  getExportAnalyticsReportExcel,
  getExportPOPExcel,
  getHikEMMDeviceTagUnits,
  getOverViewAnalyticsData,
  getOverViewAnalyticsDataReceipe,
  getOverViewPieChartData,
  getOverviewDeviceData,
  getOverviewReportData,
  getOverviewReportDeviceData,
  getUnitData,
} from "../../ProcessDashboard/OverView/services";
import { Card, Stack, Typography } from "@mui/material";
import { OverViewAnalyticsReportApiData } from "../../ProcessDashboard/OverView/OverViewAnalyticsReportApiData";
import { useDispatch, useSelector } from "react-redux";
import { setOverviewData } from "../../ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import { ParetoChart } from "../../ProcessDashboard/OverView/ParetoChart";
import dayjs, { Dayjs } from "dayjs";
import { images } from "../../../config/images";
import { MultiAxisLineChart } from "../../ProcessDashboard/OverView/MultiAxisReportLineChart";
import { PopDonutChart } from "../../ProcessDashboard/OverView/PopPieChart";
import { PopgroupBarChart } from "../../ProcessDashboard/OverView/PopgroupBarChart";
import { ToastContainer, toast } from "react-toastify";

import COCDatePicker from "../../../components/COCDatepicker/COCDatePicker";
import POPDatePicker from "../../../components/POPDatePicker/POPDatePicker";
import { ConvertToISOFormat } from "../../../components/ConvertToISOFormat/ConvertToISOFormat";
import { CompactTagUnit } from "../../../components/CompactValue/CompactValue";
import DeviceListShimmer from "../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";

import {
  getReportTableData,
  getStorePreviousSelectedData,
  storeSelectedData,
} from "../../VMS/VmsCompanyDashboard/VmsMachine/service";
import ZoomInChart from "../../ProcessDashboard/OverView/ZoomInEnergyBarChart";
import ZoomInPOPChart from "../../ProcessDashboard/OverView/ZoomInEnergyBarChartPOP";
import { getPlant } from "../../CompanyDashboard/services";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import { getTagList } from "../../SmartTag/services";
import { getReportLineChartData } from "../../VMS/VmsCompanyDashboard/VmsMachine/Reports/getReportLineChartData";
import MultiAxiosLineChart from "../../../components/Graph/MultiAxios/MultiAxiosLineChart";
import { DonutChart } from "../../ProcessDashboard/OverView/PieChart";
import { setProcessDashboarDateSet } from "../../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import { exportBPCLReportsExcelReport } from "../services";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      padding: "8px 10px", // set the desired padding here
    },
  },
});

const BPCLReports = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [deviceData, setDeviceData] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [reportOverViewData, setReportOverViewData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deviceUnitData, setDeviceUnitData] = useState([]);
  const [deviceUnitPOPData, setDeviceUnitPOPData] = useState([]);
  const [unitSearchQuery, setUnitSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [analyticsData, setAnalyticsData] = useState([]);
  const [analyticsPOPData, setAnalyticsPOPData] = useState([]);
  const [analyticsPOP1Data, setAnalyticsPOP1Data] = useState([]);
  const [analyticsPieChartData, setAnalyticsPieChartData] = useState([]);
  const [analyticsPopPieChart, setAnalyticsPopPieChart] = useState([]);
  const [analyticsPop1PieChart, setAnalyticsPop1PieChart] = useState([]);
  const [donutPOPChartSum, setDonutPOPChartSum] = useState([]);
  const [donutPOP1ChartSum, setDonut1POPChartSum] = useState([]);
  const [donutPOPUnit, setDonutPOPUnit] = useState([]);
  const [donutChartSum, setDonutChartSum] = useState([]);
  const [donutUnit, setDonutUnit] = useState([]);
  const [cocDeviceId, setCocDeviceId] = useState([]);
  const [selectedPreviousData, setSelectedPreviousData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [searchTag, setSearchTag] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);

  console.log("cocDeviceId", reportOverViewData);
  const param = useParams();
  // Report Overview Analysis
  const [deviceReport, setDeviceReport] = useState([]);
  const [unitReport, setUnitReport] = useState([]);
  const [showTimeReport, setShowTimeReport] = useState(false);
  const [selectAggReport, setSelectAggReport] = useState("15");
  const [selectIntervalReport, setSelectIntervalReport] = useState("1");
  const [selectTagIDReport1, setSelectTagIDReport1] = useState("none");
  const [selectTagIDReport2, setSelectTagIDReport2] = useState("none");
  const [startDateRep, setStartDateRep] = useState(null);
  const [endDateRep, setEndDateRep] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoading1, setLoading1] = useState(false);
  const [isLoadingPop, setLoadingPop] = useState(false);
  const [isLoadingPop1, setLoadingPop1] = useState(false);
  const [isLoadingReport, setLoadingReport] = useState(false);
  const [isShimmerCOCParetoChart, setisShimmerCOCParetoChart] = useState(false);
  const [isShimmerCOCDonutChart, setisShimmerCOCDonutChart] = useState(false);
  const [openEnergyModal, setOpenEnergyModal] = useState(false);
  const [receipeData, setReceipeData] = useState([]);
  const [customizedReportModal, setCustomizedReportModal] = useState(false);
  const [tagListData, setTagListData] = useState([]);
  const [VmsReportReportsSelectTag, setVmsReportReportsSelectTag] = useState(
    []
  );
  // const [receipePOPData, setReceipePOPData] = useState([]);

  console.log("VmsReportReportsSelectTag", VmsReportReportsSelectTag);

  const modalRef = useRef();

  const [isShimmerPopgroupBarChartPOP, setisShimmerPopgroupBarChartPOP] =
    useState(false);
  const [isShimmerPopDonutChartPOP, setisShimmerPopDonutChartPOP] =
    useState(false);

  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);
  const [isShimmerUnitlist, setisShimmerUnitlist] = useState(false);

  const [isShimmerReportdevicelist, setisShimmerReportdevicelist] =
    useState(false);
  const [isShimmerReportUnitlist, setisShimmerReportUnitlist] = useState(false);

  // chartData Report
  const [fisrtLineChartReportData, setFisrtLineChartReportData] = useState([]);
  const [secondeLineChartReportData, setSecondLineChartReportData] = useState(
    []
  );

  const [
    isShimmerMultiAxisLineChartReports,
    setisShimmerMultiAxisLineChartReports,
  ] = useState(false);

  const { is_renewable_energy } = useSelector((state) => state.userState);

  const {
    // deviceIdRedux,
    // deviceUnitRedux,
    // deviceColors,
    // deviceIdReportRedux,
    // deviceUnitReportRedux,
    // deviceColorsReportRedux,
    // unitColorsReportRedux,
    // deviceIdPOPRedux,
    // deviceUnitPOPRedux,
    // devicePOPColors,
    // startDateRe,
    // endDateRe,
    // startDatePopRe,
    // endDatePopRe,
    // startDatePopRe1,
    // endDatePopRe1,
    activeClassConsumer,
    // showTime,
    // showTimePop,
    // selectTimeRange,
    // selectPOPTimeRange,
    // reportStartDate,
    // reportEndDate,
    // reportSelectUnit1,
    // reportSelectUnit2,
    // reportAgg,
    // reportInterval,
    // reportShowTime,
    // overviewCOCswitch,
    // overviewPOPswitch,
  } = useSelector((state) => state.overViewState);

  const [overviewCOCswitch, setOverViewCOCswitch] = useState(false);
  const [overviewPOPswitch, setOverViewPOPswitch] = useState(false);
  const [overViewReportSwitch, setOverViewReportSwitch] = useState(false);
  const [reportStartDate, setReportStartDate] = useState(
    overViewReportSwitch ? dayjs().subtract(24, "hour") : null
  );
  const [reportEndDate, setReportEndDate] = useState(
    overViewReportSwitch ? dayjs() : null
  );
  const [reportSelectUnit1, setReportSelectUnit1] = useState("none");
  const [reportSelectUnit2, setReportSelectUnit2] = useState("none");
  const [reportAgg, setReportAgg] = useState("15");
  const [reportInterval, setReportInterval] = useState("1");
  const [reportShowTime, setReportShowTime] = useState(false);
  const [startDateRe, setStartDateRe] = useState(
    overviewCOCswitch ? dayjs().subtract(24, "hour") : null
  );
  const [endDateRe, setEndDateRe] = useState(
    overviewCOCswitch ? dayjs() : null
  );
  const [startDatePopRe, setStartDatePopRe] = useState(
    overviewPOPswitch ? dayjs().subtract(24, "hour") : null
  );
  const [endDatePopRe, setEndDatePopRe] = useState(
    overviewPOPswitch ? dayjs() : null
  );
  const [startDatePopRe1, setStartDatePopRe1] = useState(
    overviewPOPswitch ? dayjs().subtract(24, "hour") : null
  );
  const [endDatePopRe1, setEndDatePopRe1] = useState(
    overviewPOPswitch ? dayjs() : null
  );
  const [deviceIdReportRedux, setDeviceIdReportRedux] = useState([]);
  const [deviceUnitReportRedux, setDeviceUnitReportRedux] = useState([]);
  const [deviceColorsReportRedux, setDeviceColorsReportRedux] = useState([]);
  const [unitColorsReportRedux, setUnitColorsReportRedux] = useState([]);
  const [deviceColors, setDeviceColors] = useState([]);
  const [deviceIdRedux, setDeviceIdRedux] = useState([]);
  const [deviceUnitRedux, setDeviceUnitRedux] = useState([]);
  const [deviceIdPOPRedux, setDeviceIdPOPRedux] = useState([]);
  const [devicePOPColors, setDevicePOPColors] = useState([]);
  const [deviceUnitPOPRedux, setDeviceUnitPOPRedux] = useState([]);
  const [selectTimeRange, setSelectTimeRange] = useState("1");
  const [selectPOPTimeRange, setSelectPOPTimeRange] = useState("1");
  const [showTime, setShowTime] = useState(false);
  const [showTimePop, setShowTimePop] = useState(false);
  const [showZoomChart, setShowZoomChart] = useState(false);
  const [showZoomPOPChart, setShowZoomPOPChart] = useState(false);
  const [showOverViewChart, setShowOverviewchart] = useState(true);
  console.log(
    "deviceIdReportRedux",
    deviceIdReportRedux,
    deviceUnitReportRedux,
    reportInterval,
    reportAgg,
    reportSelectUnit1,
    reportSelectUnit2
  );

  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedPOPItems, setCheckedPOPItems] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [allPOPChecked, setAllPOPChecked] = useState(false);
  const options = ["All", "Receipt - 1", "Receipt - 2", "Receipt - 3"];

  const handleSelectTag = (row, color) => {
    const existingIndex = VmsReportReportsSelectTag.findIndex(
      (color) => color.tagId === row.id && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportReportsSelectTag];
      updateMachineId.splice(existingIndex, 1);
      setVmsReportReportsSelectTag(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectTag: updateMachineId,
      //   })
      // );
    } else {
      const newDevice = {
        tagId: row.id,
        tagName: row.tag_name,
        tagUnit: row.tag_unit,
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.id,
        deviceId: param.deviceId,
        color: color,
      };
      // Add the object to the state array if it doesn't exist
      setVmsReportReportsSelectTag((prevState) => [...prevState, newDevice]);

      // Add the object to the state array if it doesn't exist
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectTag: [
      //       ...VmsReportReportsSelectTag,
      //       {
      //         tagId: row.id,
      //         tagName: row.tag_name,
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //         color: color,
      //       },
      //     ],
      //   })
      // );
    }

    setReportSelectUnit1("none");
    setReportSelectUnit2("none");
  };

  const VmsReportSelectTag = VmsReportReportsSelectTag.length
    ? VmsReportReportsSelectTag.filter((f) => f.deviceId == param.deviceId)
    : [];

  const requiredUnits = ["kVAH", "kVARH", "kWH", "agg"];

  const hasRequiredUnits = deviceUnitReportRedux.some((unit) =>
    requiredUnits.some((requiredUnit) =>
      unit.tagUnit.toLowerCase().includes(requiredUnit.toLowerCase())
    )
  );

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setAnchorEl2(null);
  };

  const handleClickUnit = (event) => {
    event.stopPropagation();
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
    setAnchorEl(null);
  };

  const handleClosePaper = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const handlePropagation = (event) => {
    event.stopPropagation();
  };

  const openMachine = Boolean(anchorEl);
  const openUnit = Boolean(anchorEl2);

  useEffect(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, [activeClass]);

  console.log(
    "deviceIdReportRedux",
    deviceIdReportRedux,
    deviceUnitReportRedux
  );
  useEffect(() => {
    const findPlantByReportsSelectmachine = deviceIdReportRedux.length
      ? deviceIdReportRedux
          .filter((f) => f.plantId == param.id)
          .map((row) => row.deviceID)
      : [];

    const findPlantByReportsSelectTagUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id).length
        ? deviceUnitReportRedux
            .filter((f) => f.plantId == param.id)
            ?.map((row) => row.tagUnit) || ""
        : ""
      : "";
    if (
      findPlantByReportsSelectmachine.length &&
      findPlantByReportsSelectTagUnit
    ) {
      getDeviceByTagID(
        findPlantByReportsSelectmachine,
        findPlantByReportsSelectTagUnit
      );
    }
  }, [deviceIdReportRedux, deviceUnitReportRedux]);

  useEffect(() => {
    if (overViewReportSwitch) {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;

      setReportStartDate(startDate);
      setReportEndDate(endDate);
    } else {
      // When the switch is off, clear the start and end dates
      setReportStartDate(null);
      setReportEndDate(null);
    }
  }, [overViewReportSwitch, activeClass]);

  const getDeviceByTagID = async (deviceId, tagUnit) => {
    setisShimmerdevicelist(true);
    try {
      const paramReq = {
        device_id: deviceId,
        tag_unit: tagUnit,
      };
      const resp = await getTagList(paramReq);
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);

        setTagListData(resp.data.payload);
      }
    } catch (e) {
      setisShimmerdevicelist(false);

      console.log("error from getDeviceByTagID", e);
    }
  };
  useEffect(() => {
    setAllChecked(checkedItems.length === receipeData.length);
  }, [checkedItems, receipeData.length]);

  useEffect(() => {
    setAllPOPChecked(checkedPOPItems.length === receipeData.length);
  }, [checkedPOPItems, receipeData.length]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (option) => {
    setCheckedItems((prevChecked) =>
      prevChecked.includes(option)
        ? prevChecked.filter((item) => item !== option)
        : [...prevChecked, option]
    );
  };

  const handleAllToggle = () => {
    if (allChecked) {
      setCheckedItems([]);
    } else {
      setCheckedItems(receipeData);
    }
    setAllChecked(!allChecked);
  };

  const handleAllPOPToggle = () => {
    if (allPOPChecked) {
      setCheckedPOPItems([]);
    } else {
      setCheckedPOPItems(receipeData);
    }
    setAllPOPChecked(!allPOPChecked);
  };

  const handleTogglePOP = (option) => {
    setCheckedPOPItems((prevChecked) =>
      prevChecked.includes(option)
        ? prevChecked.filter((item) => item !== option)
        : [...prevChecked, option]
    );
  };
  const { user_access } = useSelector((state) => state.userState);

  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const startDateReFormat = startDateRe ? dayjs(startDateRe) : null;
  const endDateReFormat = endDateRe ? dayjs(endDateRe) : null;

  const startDatePOPReFormat = startDatePopRe ? dayjs(startDatePopRe) : null;
  const endDatePOPReFormat = endDatePopRe ? dayjs(endDatePopRe) : null;

  const startDatePOP1ReFormat = startDatePopRe1 ? dayjs(startDatePopRe1) : null;
  const endDatePOP1ReFormat = endDatePopRe1 ? dayjs(endDatePopRe1) : null;

  const startDateReportFormat = reportStartDate ? dayjs(reportStartDate) : null;
  const endDateReportFormat = reportEndDate ? dayjs(reportEndDate) : null;

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";

  const selectDateTime = selectedTime
    ? dayjs(selectedTime).format(timeFormat)
    : "";

  const startTimeFormat = startDateRe
    ? dayjs(startDateRe).format(timeFormat)
    : "";

  const endTimeFormat = endDateRe ? dayjs(endDateRe).format(timeFormat) : "";
  const startPOPTimeFormat = startDatePopRe
    ? dayjs(startDatePopRe).format(timeFormat)
    : "";
  const endPOPTimeFormat = endDatePopRe
    ? dayjs(endDatePopRe).format(timeFormat)
    : "";
  const startPOP1TimeFormat = startDatePopRe1
    ? dayjs(startDatePopRe1).format(timeFormat)
    : "";
  const endPOP1TimeFormat = endDatePopRe1
    ? dayjs(endDatePopRe1).format(timeFormat)
    : "";
  const formatStartDate = startDateRe
    ? dayjs(startDateRe).format(dateFormat)
    : "";
  const formatEndDate = endDateRe ? dayjs(endDateRe).format(dateFormat) : "";

  const formatPOPStartDate = startDatePopRe
    ? dayjs(startDatePopRe).format(dateFormat)
    : "";
  const formatPOPEndDate = endDatePopRe
    ? dayjs(endDatePopRe).format(dateFormat)
    : "";
  const formatPOP1StartDate = startDatePopRe1
    ? dayjs(startDatePopRe1).format(dateFormat)
    : "";
  const formatPOP1EndDate = endDatePopRe1
    ? dayjs(endDatePopRe1).format(dateFormat)
    : "";

  const startTimeFormatRep = reportStartDate
    ? dayjs(reportStartDate).format(timeFormat)
    : "";
  const endTimeFormatRep = reportEndDate
    ? dayjs(reportEndDate).format(timeFormat)
    : "";

  const formatStartDateRep = reportStartDate
    ? dayjs(reportStartDate).format(dateFormat)
    : "";
  const formatEndDateRep = reportEndDate
    ? dayjs(reportEndDate).format(dateFormat)
    : "";

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleClickChart = () => {
    setShowZoomPOPChart(true);
    setShowOverviewchart(false);
  };

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  const handleToggleTime = () => {
    setShowTime(!showTime);
    // dispatch(setOverviewData({ showTime: !showTime }));
  };
  const handleToggleTimePop = () => {
    setShowTimePop(!showTimePop);
    // dispatch(setOverviewData({ showTimePop: !showTimePop }));
  };

  useEffect(() => {
    getPlantByCompanyData();
  }, []);

  console.log("plantDta", plantData);

  const getPlantByCompanyData = async () => {
    try {
      const params = {
        dashboard: 1,
        company: param.companyId,
      };
      const resp = await getPlant(params);
      if (resp.status == 200) {
        setPlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };

  const handleStoreSeletedDataReport = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      dashboard: 1,
      module_display_name: "Reports",
      report_device: deviceIdReportRedux.length
        ? deviceIdReportRedux.filter((f) => f.plantId == param.id).length
          ? deviceIdReportRedux
              .filter((f) => f.plantId == param.id)
              .map((row) => row.deviceID)
          : []
        : [],
      // report_tag_unit: deviceUnitReportRedux
      //   .filter((f) => f.plantId == param.id)
      //   ?.map((row) => ({
      //     tag_unit: row.tagUnit,
      //     color: row.color,
      //   })),
      tag_unit: deviceUnitReportRedux
        .filter((f) => f.plantId == param.id)
        ?.map((row) => row.tagUnit),
      tag: VmsReportReportsSelectTag.length
        ? VmsReportReportsSelectTag.filter((f) => f.plantId == param.id).length
          ? VmsReportReportsSelectTag.filter((f) => f.plantId == param.id).map(
              (row) => ({
                tag_id: row.tagId,
                color: row.color,
              })
            )
          : []
        : [],

      interval: reportInterval,
      is_store: overViewReportSwitch,
      left_tag_unit: reportSelectUnit1,
      right_tag_unit: reportSelectUnit2,
      // tag_unit: [],
      recipe_name: [],
    };
    if (hasRequiredUnits) {
      params["aggrigation"] = Number(reportAgg);
    }
    try {
      const resp = await storeSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (reportStartDate && reportEndDate) {
      handleStoreSeletedDataReport();
    } else {
      setFisrtLineChartReportData([]);
      setSecondLineChartReportData([]);
    }
  }, [
    overViewReportSwitch,
    deviceColorsReportRedux,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    reportStartDate,
    reportEndDate,
    reportSelectUnit1,
    reportSelectUnit2,
    reportAgg,
    reportInterval,
    activeClass,
    VmsReportReportsSelectTag,
  ]);

  useEffect(() => {
    if (!overViewReportSwitch) {
      setDeviceIdReportRedux([]);
      setDeviceUnitReportRedux([]);
      setDeviceColorsReportRedux([]);
      setUnitColorsReportRedux([]);
      setReportInterval("1");
      setReportAgg("15");
      setReportStartDate(null);
      setReportEndDate(null);
      setReportShowTime(false);
      setReportSelectUnit1("none");
      setReportSelectUnit2("none");
      setVmsReportReportsSelectTag([]);
      setFisrtLineChartReportData([]);
      setSecondLineChartReportData([]);

      // setVmsReportPOPStartDate(null)
    }
  }, [overViewReportSwitch, activeClass]);

  useEffect(() => {
    handleGetStorePreviousSelectedDataReport();
    if (!overViewReportSwitch) {
      setDeviceIdReportRedux([]);
      setDeviceUnitReportRedux([]);
      setDeviceColorsReportRedux([]);
      setUnitColorsReportRedux([]);
      setReportInterval("1");
      setReportAgg("15");
      setReportStartDate(null);
      setReportEndDate(null);
      setReportShowTime(false);
      setReportSelectUnit1("none");
      setReportSelectUnit2("none");
      setVmsReportReportsSelectTag([]);
      setFisrtLineChartReportData([]);
      setSecondLineChartReportData([]);
    }
  }, [activeClass]);

  const handleGetStorePreviousSelectedDataReport = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      dashboard: 1,
      module_display_name: "Reports",
      report_device: [],
      report_tag_unit: [],
      tag: [],
      recipe_name: [],
      tag_unit: [],
    };

    try {
      const resp = await getStorePreviousSelectedData(params);
      if (resp.status === 200 || resp.status === 201) {
        setSelectedPreviousData(resp.data.payload);

        const cocDeviceData = resp.data.payload?.device?.map((row) => {
          return {
            deviceID: row.id,
            plantId: param.id,
          };
        });
        setDeviceIdReportRedux(cocDeviceData);

        // Tag Unit Data
        const cocTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
          return {
            tagUnit: row,
            plantId: param.id,
          };
        });
        setDeviceUnitReportRedux(cocTagUnitData);

        // Tag Data
        const cocTagDataData = resp.data.payload?.tag?.map((row) => {
          return {
            tagId: row.id,
            tagUnit: row.tag_unit,
            tagName: row.tag_name,
            machineId: row.device_id,
            plantId: param.id,
            color: row.color,
          };
        });
        setVmsReportReportsSelectTag(cocTagDataData);

        const IntervalData = resp.data.payload.interval;
        setReportInterval(IntervalData);

        const switchData = resp.data.payload?.is_store;
        setOverViewReportSwitch(switchData);

        const agg = resp.data.payload?.aggrigation;
        setReportAgg(agg);

        setReportSelectUnit1(resp.data.payload.left_tag_unit || []);
        setReportSelectUnit2(resp.data.payload.right_tag_unit || []);
      }
    } catch (error) {
      setSelectedPreviousData([]);
      console.error("handlesubmit error:", error);
    }
  };

  const handleGetStorePreviousSelectedData = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      dashboard: 1,
      module_display_name: activeClassConsumer,
      device: [],
      tag_unit: [],
      tag: [],
      recipe_name: [],
    };
    try {
      const resp = await getStorePreviousSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        setSelectedPreviousData(resp.data.payload);

        //store data COC
        if (activeClassConsumer == "Consumer") {
          //machine data
          const cocDeviceData = resp.data.payload?.device?.map((row) => {
            return {
              // machineId: row.id,
              // dvName: row.device_name,
              // plantId: param.plantId,
              // deviceId: param.deviceId,
              // deviceId: row.id,
              // dvName: row.device_name,
              // color: row.color,
              // plantId: param.id,
              deviceID: row.id,
              dvName: row.device_name,
              color: row.color,
              plantId: param.id,
            };
          });

          setDeviceIdRedux(cocDeviceData);

          const cocTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
            return {
              tagUnit: row,
              plantId: param.id,
              color: "",
            };
          });
          setDeviceUnitRedux(cocTagUnitData);

          const agg = resp.data.payload.aggrigation;
          setSelectTimeRange(agg);

          const switchData = resp.data.payload?.is_store;
          setOverViewCOCswitch(switchData);

          const recepieData =
            resp.data.payload?.recipe_name?.map((row) => row) || [];
          setCheckedItems(recepieData);
        } else if (activeClassConsumer == "Period") {
          const POPDeviceData = resp.data.payload?.device?.map((row) => {
            return {
              deviceID: row.id,
              dvName: row.device_name,
              color: row.color,
              plantId: param.id,
              // deviceId: row.id,
              // dvName: row.device_name,
              // color: row.color,
              // plantId: param.id,
            };
          });

          setDeviceIdPOPRedux(POPDeviceData);

          const POPTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
            return {
              plantId: param.id,
              tagUnit: row,
            };
          });
          setDeviceUnitPOPRedux(POPTagUnitData);
        }
        const agg = resp.data.payload.aggrigation;
        setSelectPOPTimeRange(agg);

        const switchData = resp.data.payload?.is_store;
        setOverViewPOPswitch(switchData);

        const recepieData =
          resp.data.payload?.recipe_name?.map((row) => row) || [];
        setCheckedPOPItems(recepieData);
      }
    } catch (error) {
      setSelectedPreviousData([]);
      console.log("handlesubmit", error);
    }
  };

  const handleChangeData = () => {
    setShowOverviewchart(false);
    setShowZoomChart(true);
  };

  const handlePOPExcel = async () => {
    const findPlantDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantDevice.length &&
      findPlantUnit.length &&
      formatPOPStartDate &&
      formatPOPEndDate &&
      selectPOPTimeRange
    ) {
      const params = {
        company: param.companyId,
        plant: param.id,
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatPOPStartDate} 00:00:00`,
        end_date: `${formatPOPEndDate} 23:59:59`,
        start_date_1: `${formatPOP1StartDate} 00:00:00`,
        end_date_1: `${formatPOP1EndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
      };
      if (checkedPOPItems) {
        params["recipe_names"] = checkedPOPItems;
      }

      if (showTime) {
        params["start_date"] = `${formatPOPStartDate} ${startPOPTimeFormat}`;
        params["end_date"] = `${formatPOPEndDate} ${endPOPTimeFormat}`;
        params[
          "start_date_1"
        ] = `${formatPOP1StartDate} ${startPOP1TimeFormat}`;
        params["end_date_1"] = `${formatPOP1EndDate} ${endPOP1TimeFormat}`;
      }
      try {
        const resp = await getExportPOPExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Analytics Period Over Period Report Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    } else {
      console.log("error");
    }
  };

  const handleExportExcelClick = async () => {
    const findPlantDevice = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      formatStartDateRep &&
      formatEndDateRep &&
      reportAgg &&
      reportInterval &&
      findPlantDevice.length &&
      findPlantUnit.length
    ) {
      const params = {
        company: param.companyId,
        plant: param.id,
        tag: VmsReportReportsSelectTag.length
          ? VmsReportReportsSelectTag.filter((f) => f.plantId == param.id).map(
              (e) => e.tagId
            )
          : [],
        start_date: `${formatStartDateRep} 00:00:00`,
        end_date: `${formatEndDateRep} 23:59:59`,
        aggregate: Number(reportAgg),
        intervals: Number(reportInterval),
      };
      if (reportShowTime) {
        params["start_date"] = `${formatStartDateRep} ${startTimeFormatRep}`;
        params["end_date"] = `${formatEndDateRep} ${endTimeFormatRep}`;
      }

      try {
        const resp = await exportBPCLReportsExcelReport(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Overview Report Data Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    }
  };
  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShimmer, setShimmer] = useState(false);

  const getReportLineChartAPIReports = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    timeActive,
    intervalTime,
    activeClass
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.id,
      tag_id: tagId,
      interval: Number(reportInterval),
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }

    if (hasRequiredUnits) {
      paramReq["aggregate"] = Number(reportAgg);
    }

    try {
      getReportLineChartData(paramReq, setState, setShimmer);
    } catch (error) {
      console.log("errror", error);
    }
  };
  // Report Line Chart
  useEffect(() => {
    if (reportSelectUnit1 != "none" && formatStartDateRep && formatEndDateRep) {
      getReportLineChartAPIReports(
        [reportSelectUnit1],
        formatStartDateRep,
        formatEndDateRep,
        startTimeFormatRep,
        endTimeFormatRep,
        setFisrtLineChartReportData,
        reportShowTime
      );
    } else {
      setFisrtLineChartReportData([]);
    }
  }, [
    reportSelectUnit1,
    formatStartDateRep,
    formatEndDateRep,
    startTimeFormatRep,
    endTimeFormatRep,
    reportShowTime,
    reportInterval,
    reportAgg,
  ]);

  useEffect(() => {
    if (reportSelectUnit2 != "none" && formatStartDateRep && formatEndDateRep) {
      getReportLineChartAPIReports(
        [reportSelectUnit2],
        formatStartDateRep,
        formatEndDateRep,
        startTimeFormatRep,
        endTimeFormatRep,
        setSecondLineChartReportData,
        reportShowTime
      );
    } else {
      setSecondLineChartReportData([]);
    }
  }, [
    reportSelectUnit2,
    formatStartDateRep,
    formatEndDateRep,
    startTimeFormatRep,
    endTimeFormatRep,
    reportShowTime,
    reportInterval,
    reportAgg,
  ]);

  useEffect(() => {
    handlegetDevice();
  }, []);

  const handlegetDevice = async () => {
    setisShimmerdevicelist(true);
    setisShimmerUnitlist(true);
    const params = {
      plant_id: param.id,
      is_renewable_energy: is_renewable_energy,
      // company_id: param.companyId,
    };
    try {
      const resp = await getOverviewDeviceData(params);
      if (resp.data.success == true) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerUnitlist(false);
        }, 1000);
        setDeviceData(resp.data.payload.device);
        // setDeviceUnitData(resp.data.payload.tag);
        // if (resp.data.payload.length) {
        //   setDeviceId(resp.data.payload[0].device);
        // }
      }
    } catch (error) {
      setisShimmerdevicelist(false);
      setisShimmerUnitlist(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    getUnitData1();
  }, [deviceIdRedux]);

  const getUnitData1 = async () => {
    const params = {
      device: deviceIdRedux
        .filter((e) => e.plantId == param.id && e.deviceID)
        .map((row) => row.deviceID),
    };
    try {
      const resp = await getUnitData(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceUnitData(resp.data.payload);
      }
    } catch (error) {
      setDeviceUnitData([]);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    getUnitPOPData();
  }, [deviceIdPOPRedux]);

  const getUnitPOPData = async () => {
    const params = {
      device: deviceIdPOPRedux
        .filter((e) => e.plantId == param.id && e.deviceID)
        .map((row) => row.deviceID),
    };
    try {
      const resp = await getUnitData(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceUnitPOPData(resp.data.payload);
      }
    } catch (error) {
      setDeviceUnitPOPData([]);
      console.log("handlesubmit", error);
    }
  };

  // get device In Report
  const handlegetDeviceReport = async () => {
    setisShimmerReportdevicelist(true);
    setisShimmerReportUnitlist(true);
    const params = {
      plant_id: param.id,
      dashboard: 1,
      is_renewable_energy: is_renewable_energy,
    };
    try {
      const resp = await getOverviewReportDeviceData(params);
      if (resp.data.success == true) {
        setDeviceReport(resp.data.payload.device);
        // setUnitReport(resp.data.payload.tag);
        setTimeout(() => {
          setisShimmerReportdevicelist(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerReportUnitlist(false);
        }, 1000);
        // if (resp.data.payload.length) {
        //   setDeviceId(resp.data.payload[0].device);
        // }
      }
    } catch (error) {
      setisShimmerReportdevicelist(false);
      setisShimmerReportUnitlist(false);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handlegetDeviceReport();
  }, []);

  useEffect(() => {
    getReportUnitData();
  }, [deviceIdReportRedux]);

  const getReportUnitData = async () => {
    const params = {
      device: deviceIdReportRedux
        .filter((e) => e.plantId == param.id && e.deviceID)
        .map((row) => row.deviceID),
    };
    try {
      const resp = await getDeviceUnitData(params);
      if (resp.status == 200 || resp.status == 201) {
        setUnitReport(resp.data.payload);
      } else {
        setUnitReport([]);
      }
    } catch (error) {
      setUnitReport([]);
      console.log("handlesubmit", error);
    }
  };

  const handleSelectDeviceId = (id, index, row, color) => {
    console.log("color", color);
    setCocDeviceId(id);
    const existingIndex = deviceIdRedux.findIndex(
      (f) => f.deviceID === id && f.plantId === param.id
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...deviceIdRedux];
      updateMachineId.splice(existingIndex, 1);
      setDeviceIdRedux(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectmachine: updateMachineId,
      //   })
      // );

      const findSelectedTagUnit = deviceUnitRedux.length
        ? deviceUnitRedux.filter((f) => f.plantId != param.id)
        : [];

      setDeviceUnitRedux(findSelectedTagUnit);
    } else {
      // Add the object to the state array if it doesn't exist
      const newDevice = {
        deviceID: id,
        dvName: row.device__device_name,
        color: color,
        plantId: param.id,
      };
      // Add the object to the state array if it doesn't exist
      setDeviceIdRedux((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectmachine: [
      //       ...VmsReportPOPSelectmachine,
      //       {
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
  };
  console.log("deviceIdPOPRedux", deviceIdPOPRedux);
  const handlePOPSelectDeviceId = (id, index, row, color) => {
    // Check if the switch for the device is already on
    const existingIndex = deviceIdPOPRedux.findIndex(
      (f) => f.deviceID === id && f.plantId === param.id
    );

    if (existingIndex !== -1) {
      // If the switch is already on, toggle it off
      const updateMachineId = [...deviceIdPOPRedux];
      updateMachineId.splice(existingIndex, 1);
      setDeviceIdPOPRedux(updateMachineId);

      const findSelectedTagUnit = deviceUnitPOPRedux.length
        ? deviceUnitPOPRedux.filter((f) => f.plantId !== param.id)
        : [];

      setDeviceUnitPOPRedux(findSelectedTagUnit);
    } else {
      // Ensure all other switches are turned off before turning on the current one
      setDeviceIdPOPRedux((prevState) => {
        const updatedState = prevState.filter(
          (item) => item.plantId !== param.id
        );
        // Add the new device to the state array
        return [
          ...updatedState,
          {
            deviceID: id,
            dvName: row.device__device_name,
            color: color,
            plantId: param.id,
          },
        ];
      });

      const findSelectedTagUnit = deviceUnitPOPRedux.length
        ? deviceUnitPOPRedux.filter((f) => f.plantId !== param.id)
        : [];

      setDeviceUnitPOPRedux(findSelectedTagUnit);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUnitSearchChange = (event) => {
    setUnitSearchQuery(event.target.value);
  };

  const handleSelectTagUnit = (id) => {
    const selectedUnit = deviceUnitRedux.find(
      (unit) => unit.plantId === param.id
    );
    if (!Array.isArray(deviceUnitRedux)) {
      setDeviceUnitRedux([]);
      // dispatch(setOverviewData({ deviceUnitRedux: [] }));
    }

    if (selectedUnit && selectedUnit.tagUnit == id) {
      setDeviceUnitRedux(
        deviceUnitRedux.filter((unit) => unit.plantId !== param.id)
      );
      // dispatch(
      //   setOverviewData({
      //     deviceUnitRedux: deviceUnitRedux.filter(
      //       (unit) => unit.plantId !== param.id
      //     ),
      //   })
      // );
    } else {
      const selectTag = deviceUnitRedux.length
        ? deviceUnitRedux.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;
      if (selectTag) {
        return true;
      } else {
        const newUnit = {
          plantId: param.id,
          tagUnit: id,
        };
        setDeviceUnitRedux((prevState) => [...prevState, newUnit]);
        // dispatch(
        //   setOverviewData({
        //     deviceUnitRedux: [
        //       ...deviceUnitRedux,
        //       { plantId: param.id, tagUnit: id },
        //     ],
        //   })
        // );
      }
    }
  };

  const handlePOPSelectTagUnit = (id) => {
    const selectedUnit = deviceUnitPOPRedux.find(
      (unit) => unit.plantId === param.id
    );
    if (!Array.isArray(deviceUnitPOPRedux)) {
      setDeviceUnitPOPRedux([]);
      // dispatch(setOverviewData({ deviceUnitPOPRedux: [] }));
    }

    if (selectedUnit && selectedUnit.tagUnit == id) {
      setDeviceUnitPOPRedux(
        deviceUnitPOPRedux.filter((unit) => unit.plantId !== param.id)
      );
      // dispatch(
      //   setOverviewData({
      //     deviceUnitPOPRedux: deviceUnitPOPRedux.filter(
      //       (unit) => unit.plantId !== param.id
      //     ),
      //   })
      // );
    } else {
      const selectTag = deviceUnitPOPRedux.length
        ? deviceUnitPOPRedux.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;
      if (selectTag) {
        return true;
      } else {
        const Unit = {
          plantId: param.id,
          tagUnit: id,
        };
        setDeviceUnitPOPRedux((prevState) => [...prevState, Unit]);

        // dispatch(
        //   setOverviewData({
        //     deviceUnitPOPRedux: [
        //       ...deviceUnitPOPRedux,
        //       { plantId: param.id, tagUnit: id },
        //     ],
        //   })
        // );
      }
    }
  };

  const handleReportInterval = (e) => {
    setReportInterval(e.target.value);
    // dispatch(setOverviewData({ reportInterval: e.target.value }));
  };

  const handleAggReport = (e) => {
    setReportAgg(e.target.value);
    // dispatch(setOverviewData({ reportAgg: e.target.value }));
  };

  const handleReportSelectUnit11 = (e) => {
    setReportSelectUnit1(e.target.value);
    // dispatch(setOverviewData({ reportSelectUnit1: e.target.value }));
  };
  const handleReportSelectUnit12 = (e) => {
    setReportSelectUnit2(e.target.value);
    // dispatch(setOverviewData({ reportSelectUnit2: e.target.value }));
  };

  const handleShowReportToggle = () => {
    setReportShowTime(!reportShowTime);
    // dispatch(setOverviewData({ reportShowTime: !reportShowTime }));
  };
  // const handleSelectDeviceIdReport = (id, index, event, row) => {
  //   const isDeviceTurnedOn = event.target.checked;

  //   if (isDeviceTurnedOn) {
  //     // Add the device to deviceColorsReportRedux if not already present
  //     const existingIndex = deviceColorsReportRedux.findIndex(
  //       (color) => color.deviceId === row.device && color.plantId === param.id
  //     );

  //     if (existingIndex === -1) {
  //       const newDevice = {
  //         deviceId: row.device,
  //         dvName: row.device__device_name,
  //         color: event.target.name,
  //         plantId: param.id,
  //       };
  //       setDeviceColorsReportRedux((prevState) => [...prevState, newDevice]);
  //     }

  //     // Add the device to deviceIdReportRedux if not already present
  //     const matchingIndex = deviceIdReportRedux.findIndex(
  //       (f) => f.deviceID === id && f.plantId === param.id
  //     );

  //     if (matchingIndex === -1) {
  //       setDeviceIdReportRedux((prevState) => [
  //         ...prevState,
  //         { deviceID: id, plantId: param.id },
  //       ]);
  //     }
  //   } else {
  //     // Remove the device from deviceColorsReportRedux
  //     const updatedColors = unitColorsReportRedux.filter(
  //       (color) => color.deviceId !== row.device || color.plantId !== param.id
  //     );
  //     setUnitColorsReportRedux(updatedColors);

  //     // Remove the device from deviceIdReportRedux
  //     const updatedDevices = deviceIdReportRedux.filter(
  //       (f) => f.deviceID !== id || f.plantId !== param.id
  //     );
  //     setDeviceIdReportRedux(updatedDevices);

  //     // Optionally handle unit-related state
  //     const updatedUnits = unitColorsReportRedux.filter(
  //       (color) => color.deviceId !== row.device || color.plantId !== param.id
  //     );
  //     setUnitColorsReportRedux(updatedUnits);
  //   }
  // };
  const handleSelectDeviceIdReport = (row) => {
    const existingIndex = deviceIdReportRedux.findIndex(
      (color) => color.deviceID === row.device && color.plantId == param.id
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...deviceIdReportRedux];
      updateMachineId.splice(existingIndex, 1);
      setDeviceIdReportRedux(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     deviceIdReportRedux: updateMachineId,
      //   })
      // );

      const finfSelectedTagUnit = deviceUnitReportRedux.length
        ? deviceUnitReportRedux.filter((f) => f.plantId != param.id)
        : [];
      setDeviceUnitReportRedux(finfSelectedTagUnit);
      // dispatch(
      //   setVmsReportData({
      //     deviceUnitReportRedux: finfSelectedTagUnit,
      //   })
      // );

      const findSelectedTeg = VmsReportReportsSelectTag.length
        ? VmsReportReportsSelectTag.filter((f) => f.plantId != param.id)
        : [];
      setVmsReportReportsSelectTag(findSelectedTeg);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectTag: findSelectedTeg,
      //   })
      // );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      const newDevice = {
        deviceID: row.device,
        dvName: row.device__device_name,
        plantId: param.id,
      };
      // Add the object to the state array if it doesn't exist
      setDeviceIdReportRedux((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setVmsReportData({
      //     deviceIdReportRedux: [
      //       ...deviceIdReportRedux,
      //       {
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
  };

  // const handleSelectTagUnitReport = (id, event, row, color) => {
  //   const existingIndex = unitColorsReportRedux.findIndex(
  //     (color) => color.tagUnit === row.tag_unit && color.plantId == param.id
  //   );

  //   if (existingIndex !== -1) {
  //     const updatedColors = [...unitColorsReportRedux];
  //     updatedColors.splice(existingIndex, 1);
  //     setUnitColorsReportRedux(updatedColors);
  //     // dispatch(
  //     //   setOverviewData({
  //     //     unitColorsReportRedux: updatedColors,
  //     //   })
  //     // );
  //   } else {
  //     // Add the object to the state array if it doesn't exist
  //     const newDevice = {
  //       tagUnit: row.tag_unit,
  //       color: color,
  //       plantId: param.id,
  //     };
  //     // Add the object to the state array if it doesn't exist
  //     setUnitColorsReportRedux((prevState) => [...prevState, newDevice]);
  //     // dispatch(
  //     //   setOverviewData({
  //     //     unitColorsReportRedux: [
  //     //       ...unitColorsReportRedux,
  //     //       {
  //     //         tagUnit: row.tag_unit,
  //     //         color: event.target.name,
  //     //         plantId: param.id,
  //     //       },
  //     //     ],
  //     //   })
  //     // );
  //   }

  //   if (!Array.isArray(deviceUnitReportRedux)) {
  //     setDeviceId([]);
  //     setDeviceUnitReportRedux([]);
  //     // dispatch(setOverviewData({ deviceUnitReportRedux: [] }));
  //   }
  //   const matchingIndex = deviceUnitReportRedux.findIndex(
  //     (f) => f.tagUnit === id && f.plantId === param.id
  //   );

  //   if (matchingIndex !== -1) {
  //     // Matching element found, remove it
  //     console.log("Match found:", deviceUnitReportRedux[matchingIndex]);

  //     const updatedArray = [...deviceUnitReportRedux];
  //     updatedArray.splice(matchingIndex, 1); // Remove the matching element
  //     setDeviceUnitReportRedux(updatedArray);
  //     // dispatch(
  //     //   setOverviewData({
  //     //     deviceUnitReportRedux: updatedArray,
  //     //   })
  //     // );
  //   } else {
  //     // No match found, add the new object
  //     console.log("No match found");
  //     const newDevice = {
  //       tagUnit: id,
  //       plantId: param.id,
  //       color: color,
  //     };

  //     console.log("newDevicenewDevice", newDevice);
  //     // Add the object to the state array if it doesn't exist
  //     setDeviceUnitReportRedux((prevState) => [...prevState, newDevice]);

  //     // dispatch(
  //     //   setOverviewData({
  //     //     deviceUnitReportRedux: [
  //     //       ...deviceUnitReportRedux,
  //     //       { tagUnit: id, plantId: param.id, color: event.target.name },
  //     //     ],
  //     //   })
  //     // );
  //   }

  //   // dispatch(setOverviewData({ reportSelectUnit1: "none" }));
  //   // dispatch(setOverviewData({ reportSelectUnit2: "none" }));
  // };

  const handleSelectTagUnitReport = (row) => {
    const existingIndex = deviceUnitReportRedux.findIndex(
      (color) => color.tagUnit === row.tag_unit && color.plantId == param.id
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...deviceUnitReportRedux];
      updateMachineId.splice(existingIndex, 1);
      setDeviceUnitReportRedux(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     deviceUnitReportRedux: updateMachineId,
      //   })
      // );

      const findSelectedTeg = VmsReportReportsSelectTag.length
        ? VmsReportReportsSelectTag.filter((f) => f.plantId != param.id)
        : [];
      setVmsReportReportsSelectTag(findSelectedTeg);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectTag: findSelectedTeg,
      //   })
      // );
      setTagListData([]);
    } else {
      const newDevice = {
        tagUnit: row.tag_unit,
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.id,
      };
      // Add the object to the state array if it doesn't exist
      setDeviceUnitReportRedux((prevState) => [...prevState, newDevice]);

      // Add the object to the state array if it doesn't exist
      // dispatch(
      //   setVmsReportData({
      //     deviceUnitReportRedux: [
      //       ...deviceUnitReportRedux,
      //       {
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
  };

  console.log("reportSelectUnit1", reportSelectUnit1);

  useEffect(() => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantDevice.length && findPlantUnit.length) {
      handleAnalyticsData();
    } else {
      setAnalyticsData([]);
    }
  }, [
    deviceIdRedux,
    deviceUnitRedux,
    formatStartDate,
    formatEndDate,
    selectTimeRange,
    startTimeFormat,
    endTimeFormat,
    showTime,
    selectUnit,
    checkedItems,
  ]);

  useEffect(() => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantDevice.length && findPlantUnit.length) {
      handleAnalyticsPieChartData();
    } else {
      setAnalyticsPieChartData([]);
    }
  }, [
    deviceIdRedux,
    deviceUnitRedux,
    formatStartDate,
    formatEndDate,
    showTime,
    startTimeFormat,
    endTimeFormat,
    selectUnit,
  ]);

  const handleAnalyticsData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantDevice.length &&
      findPlantUnit[0] &&
      formatStartDate &&
      formatEndDate &&
      selectTimeRange
    ) {
      setisShimmerCOCParetoChart(true);
      const params = {
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatStartDate} 00:00:00`,
        end_date: `${formatEndDate} 23:59:59`,
        // start_time: startTimeFormat !== "00:00:00" ? startTimeFormat : "",
        // end_time: endTimeFormat !== "00:00:00" ? endTimeFormat : "",
        aggregate: Number(selectTimeRange),
        unit: selectUnit,
        recipe_names: checkedItems,
      };

      if (showTime) {
        params["start_date"] = `${formatStartDate} ${startTimeFormat}`;
        params["end_date"] = `${formatEndDate} ${endTimeFormat}`;
      }
      try {
        setLoading(true);

        const resp = await getOverViewAnalyticsDataReceipe(params);
        if (resp.data.success === true) {
          setLoading(false);
          setTimeout(() => {
            setisShimmerCOCParetoChart(false);
          }, 2000);

          setAnalyticsData(resp.data.payload);
        }
      } catch (error) {
        setisShimmerCOCParetoChart(false);
        setLoading(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && findPlantByUnit.length) {
      handleAnalyticsPOPBarChart();
      console.log("firstAPICall");
    } else {
      setAnalyticsPOPData([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOPStartDate,
    formatPOPEndDate,
    startPOPTimeFormat,
    endPOPTimeFormat,
    selectPOPTimeRange,
    showTimePop,
    selectUnit,
    checkedPOPItems,
  ]);

  const handleAnalyticsPOPBarChart = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      findPlantByUnit &&
      formatPOPStartDate &&
      formatPOPEndDate &&
      selectPOPTimeRange
    ) {
      setisShimmerPopgroupBarChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantByUnit[0]["tagUnit"],
        start_date: `${formatPOPStartDate} 00:00:00`,
        end_date: `${formatPOPEndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
        unit: selectUnit,
        recipe_names: checkedPOPItems,
      };

      if (showTimePop) {
        params["start_date"] = `${formatPOPStartDate} ${startPOPTimeFormat}`;
        params["end_date"] = `${formatPOPEndDate} ${endPOPTimeFormat}`;
      }
      try {
        setLoadingPop(true);
        const resp = await getOverViewAnalyticsDataReceipe(params);
        if (resp.data.success === true) {
          setTimeout(() => setisShimmerPopgroupBarChartPOP(false), 2000);

          setLoadingPop(false);
          setAnalyticsPOPData(resp.data.payload);
        }
      } catch (error) {
        setisShimmerPopgroupBarChartPOP(false);
        setLoadingPop(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };
  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && findPlantByUnit.length) {
      handleAnalyticsPOPBarChart2();
    } else {
      setAnalyticsPOP1Data([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOP1StartDate,
    formatPOP1EndDate,
    selectPOPTimeRange,
    showTimePop,
    selectUnit,
    checkedPOPItems,
  ]);

  const handleAnalyticsPOPBarChart2 = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantByUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantByDevice &&
      deviceUnitPOPRedux &&
      formatPOP1StartDate &&
      formatPOP1EndDate &&
      selectPOPTimeRange
    ) {
      setisShimmerPopgroupBarChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantByUnit[0]["tagUnit"],
        start_date: `${formatPOP1StartDate} 00:00:00`,
        end_date: `${formatPOP1EndDate} 23:59:59`,
        aggregate: Number(selectPOPTimeRange),
        unit: selectUnit,
        recipe_names: checkedPOPItems,
      };

      console.log("params..", params);
      if (showTimePop) {
        params["start_date"] = `${formatPOP1StartDate} ${startPOP1TimeFormat}`;
        params["end_date"] = `${formatPOP1EndDate} ${endPOP1TimeFormat}`;
      }
      try {
        const resp = await getOverViewAnalyticsDataReceipe(params);
        if (resp.data.success === true) {
          setTimeout(() => {
            setisShimmerPopgroupBarChartPOP(false);
          }, 2000);
          setAnalyticsPOP1Data(resp.data.payload);
        }
      } catch (error) {
        setisShimmerPopgroupBarChartPOP(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };
  const handleAnalyticsPieChartData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantDevice.length &&
      findPlantUnit[0] &&
      formatStartDate &&
      formatEndDate
    ) {
      setisShimmerCOCDonutChart(true);
      const params = {
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatStartDate,
        end_date: formatEndDate,
        unit: selectUnit,
      };
      try {
        setLoading1(true);
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(() => {
            setisShimmerCOCDonutChart(false);
          }, 2000);

          setLoading1(false);
          setAnalyticsPieChartData(resp.data.payload);
          setDonutChartSum(resp.data.tag_value_sum);
          setDonutUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setTimeout(() => {
          setisShimmerCOCDonutChart(false);
        }, 2000);
        setLoading1(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && findPlantUnit.length) {
      handleAnalyticsPOPpieChartData();
    } else {
      setAnalyticsPopPieChart([]);
    }
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOPStartDate,
    formatPOPEndDate,
    showTimePop,
    selectUnit,
  ]);

  const handleAnalyticsPOPpieChartData = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      deviceUnitPOPRedux[0] &&
      formatPOPStartDate &&
      formatPOPEndDate
    ) {
      setisShimmerPopDonutChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatPOPStartDate,
        end_date: formatPOPEndDate,
        unit: selectUnit,
      };
      try {
        setLoadingPop1(true);
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(setisShimmerPopDonutChartPOP(false), 3000);

          setLoadingPop1(false);
          setAnalyticsPopPieChart(resp.data.payload);
          setDonutPOPChartSum(resp.data.tag_value_sum);
          setDonutPOPUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setisShimmerPopDonutChartPOP(false);
        setLoadingPop1(false);
        console.log("handleSubmit", error);
      }
    }
  };

  useEffect(() => {
    handleAnalyticsPOP1pieChartData();
  }, [
    deviceIdPOPRedux,
    deviceUnitPOPRedux,
    formatPOP1StartDate,
    formatPOP1EndDate,
    showTimePop,
    selectUnit,
  ]);

  const handleAnalyticsPOP1pieChartData = async () => {
    const findPlantByDevice = deviceIdPOPRedux.length
      ? deviceIdPOPRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitPOPRedux.length
      ? deviceUnitPOPRedux.filter((f) => f.plantId == param.id)
      : [];

    if (
      findPlantByDevice.length &&
      findPlantUnit[0] &&
      formatPOP1StartDate &&
      formatPOP1EndDate
    ) {
      setisShimmerPopDonutChartPOP(true);
      const params = {
        device_id: findPlantByDevice.map((row) => row.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: formatPOP1StartDate,
        end_date: formatPOP1EndDate,
        unit: selectUnit,
      };
      try {
        const resp = await getOverViewPieChartData(params);
        if (resp.data.success == true) {
          setTimeout(setisShimmerPopDonutChartPOP(false), 3000);

          setAnalyticsPop1PieChart(resp.data.payload);
          setDonut1POPChartSum(resp.data.tag_value_sum);
          setDonutUnit(resp.data.payload[0].tag_unit);
        }
      } catch (error) {
        setisShimmerPopDonutChartPOP(false);
        console.log("handleSubmit", error);
      }
    }
  };
  useEffect(() => {
    getOverviewReportApi();
  }, [
    formatStartDateRep,
    formatEndDateRep,
    deviceIdReportRedux,
    deviceUnitReportRedux,
    selectUnit,
    startTimeFormatRep,
    endTimeFormatRep,
    VmsReportReportsSelectTag
  ]);

  // report Table data
  const getOverviewReportApi = async () => {
    if (formatStartDateRep && formatEndDateRep) {
      const paramRequset = {
        start_date: `${formatStartDateRep} 00:00:00`,
        end_date: `${formatEndDateRep} 23:59:59`,
        tag_id: VmsReportReportsSelectTag.filter(
          (f) => f.plantId == param.id
        ).map((e) => e.tagId),
      };
      if (reportShowTime) {
        paramRequset[
          "start_date"
        ] = `${formatStartDateRep} ${startTimeFormatRep}`;
        paramRequset["end_date"] = `${formatEndDateRep} ${endTimeFormatRep}`;
      }

      try {
        const resp = await getReportTableData(paramRequset);

        if (resp.data.success == true) {
          setReportOverViewData(resp.data.payload);
        }
      } catch (error) {
        setReportOverViewData([])
        console.log("error", error);
      }
    }
  };

  const handleExportEnergyData = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];
    if (
      findPlantDevice.length &&
      findPlantUnit.length &&
      formatStartDate &&
      formatEndDate &&
      selectTimeRange
    ) {
      const params = {
        company: param.companyId,
        plant: param.id,
        device_id: findPlantDevice.map((id) => id.deviceID),
        tag_unit: findPlantUnit[0]["tagUnit"],
        start_date: `${formatStartDate} 00:00:00`,
        end_date: `${formatEndDate} 23:59:59`,
        aggregate: Number(selectTimeRange),
      };
      if (checkedItems) {
        params["recipe_names"] = checkedItems;
      }

      if (showTime) {
        params["start_date"] = `${formatStartDate} ${startTimeFormat}`;
        params["end_date"] = `${formatEndDate} ${endTimeFormat}`;
      }
      try {
        const resp = await exportAnalyticsCOCExcel(params);
        if (resp.status == 200 || resp.status == 201) {
          notify("Export Analytics Consumer Over Consumer Report Successfully");
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    const findPlantDevice = deviceIdReportRedux.length
      ? deviceIdReportRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitReportRedux.length
      ? deviceUnitReportRedux.filter((f) => f.plantId == param.id)
      : [];
    if (!findPlantDevice.length || !findPlantUnit.length) {
      setReportOverViewData([]);
      setFisrtLineChartReportData([]);
      setSecondLineChartReportData([]);
    }
  }, [deviceIdReportRedux, deviceUnitReportRedux]);

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setStartDateRe(null);
      // dispatch(setOverviewData({ startDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      setStartDateRe(newValue);
      // dispatch(setOverviewData({ startDateRe: newValue }));
    } else if (!endDateReFormat) {
      setStartDateRe(newValue);
      // dispatch(setOverviewData({ startDateRe: newValue }));
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        showTime &&
        (newValue.hour() > endDateReFormat.hour() ||
          (newValue.hour() === endDateReFormat.hour() &&
            newValue.minute() >= endDateReFormat.minute()))
      ) {
        setStartDateRe(null);
        // dispatch(setOverviewData({ startDateRe: null }));
        notifyError("Start time must be before end time");
      } else {
        setStartDateRe(newValue);
        // dispatch(setOverviewData({ startDateRe: newValue }));
      }
    } else {
      setStartDateRe(null);
      // dispatch(setOverviewData({ startDateRe: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setEndDateRe(null);
      // dispatch(setOverviewData({ endDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      setEndDateRe(newValue);
      // dispatch(setOverviewData({ endDateRe: newValue }));
    } else if (!startDateReFormat) {
      setEndDateRe(newValue);
      // dispatch(setOverviewData({ endDateRe: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        showTime &&
        (newValue.hour() < startDateReFormat.hour() ||
          (newValue.hour() === startDateReFormat.hour() &&
            newValue.minute() <= startDateReFormat.minute()))
      ) {
        setEndDateRe(null);
        // dispatch(setOverviewData({ endDateRe: null }));
        notifyError("End time must be after start time");
      } else {
        setEndDateRe(newValue);
        // dispatch(setOverviewData({ endDateRe: newValue }));
      }
    } else {
      setEndDateRe(null);
      // dispatch(setOverviewData({ endDateRe: null }));
      notifyError("Start date must be less than end date");
    }
  };

  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOPReFormat
      ? endDatePOPReFormat.diff(newValue, "days")
        ? endDatePOPReFormat.diff(newValue, "days") > 0
          ? endDatePOPReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setStartDatePopRe(null);
      // dispatch(setOverviewData({ startDatePopRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOPReFormat && newValue.isBefore(endDatePOPReFormat)) {
      setStartDatePopRe(newValue);
      // dispatch(setOverviewData({ startDatePopRe: newValue }));
    } else if (!endDatePOPReFormat) {
      setStartDatePopRe(newValue);
      // dispatch(setOverviewData({ startDatePopRe: newValue }));
    } else if (
      endDatePOPReFormat &&
      newValue.isSame(endDatePOPReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() > endDatePOPReFormat.hour() ||
          (newValue.hour() === endDatePOPReFormat.hour() &&
            newValue.minute() >= endDatePOPReFormat.minute()))
      ) {
        setStartDatePopRe(null);
        // dispatch(setOverviewData({ startDatePopRe: null }));
        notifyError("Start time must be before end time");
      } else {
        setStartDatePopRe(newValue);
        // dispatch(setOverviewData({ startDatePopRe: newValue }));
      }
    } else {
      setStartDatePopRe(null);
      // dispatch(setOverviewData({ startDatePopRe: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setOverviewData({ startDatePopRe: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDatePOPReFormat
      ? startDatePOPReFormat.diff(newValue, "days")
        ? startDatePOPReFormat.diff(newValue, "days") > 0
          ? startDatePOPReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setEndDatePopRe(null);
      // dispatch(setOverviewData({ endDatePopRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOPReFormat && newValue.isAfter(startDatePOPReFormat)) {
      setEndDatePopRe(newValue);
      // dispatch(setOverviewData({ endDatePopRe: newValue }));
    } else if (!startDatePOPReFormat) {
      setEndDatePopRe(newValue);
      // dispatch(setOverviewData({ endDatePopRe: newValue }));
    } else if (
      startDatePOPReFormat &&
      newValue.isSame(startDatePOPReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() < startDatePOPReFormat.hour() ||
          (newValue.hour() === startDatePOPReFormat.hour() &&
            newValue.minute() <= startDatePOPReFormat.minute()))
      ) {
        setEndDatePopRe(null);
        // dispatch(setOverviewData({ endDatePopRe: null }));
        notifyError("End time must be after start time");
      } else {
        setEndDatePopRe(newValue);
        // dispatch(setOverviewData({ endDatePopRe: newValue }));
      }
    } else {
      setEndDatePopRe(null);
      // dispatch(setOverviewData({ endDatePopRe: null }));
      notifyError("Start date must be less than end date");
    }
  };
  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP1ReFormat
      ? endDatePOP1ReFormat.diff(newValue, "days")
        ? endDatePOP1ReFormat.diff(newValue, "days") > 0
          ? endDatePOP1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setStartDatePopRe1(null);
      // dispatch(setOverviewData({ startDatePopRe1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP1ReFormat && newValue.isBefore(endDatePOP1ReFormat)) {
      setStartDatePopRe1(newValue);
      // dispatch(setOverviewData({ startDatePopRe1: newValue }));
    } else if (!endDatePOP1ReFormat) {
      setStartDatePopRe1(newValue);
      // dispatch(setOverviewData({ startDatePopRe1: newValue }));
    } else if (
      endDatePOP1ReFormat &&
      newValue.isSame(endDatePOP1ReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() > endDatePOP1ReFormat.hour() ||
          (newValue.hour() === endDatePOP1ReFormat.hour() &&
            newValue.minute() >= endDatePOP1ReFormat.minute()))
      ) {
        setStartDatePopRe1(null);
        // dispatch(setOverviewData({ startDatePopRe1: null }));
        notifyError("Start time must be before end time");
      } else {
        setStartDatePopRe1(newValue);
        // dispatch(setOverviewData({ startDatePopRe1: newValue }));
      }
    } else {
      setStartDatePopRe1(null);
      // dispatch(setOverviewData({ startDatePopRe1: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP1ReFormat
      ? startDatePOP1ReFormat.diff(newValue, "days")
        ? startDatePOP1ReFormat.diff(newValue, "days") > 0
          ? startDatePOP1ReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setEndDatePopRe1(null);
      // dispatch(setOverviewData({ endDatePopRe1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP1ReFormat && newValue.isAfter(startDatePOP1ReFormat)) {
      setEndDatePopRe1(newValue);
      // dispatch(setOverviewData({ endDatePopRe1: newValue }));
    } else if (!startDatePOP1ReFormat) {
      setEndDatePopRe1(newValue);
      // dispatch(setOverviewData({ endDatePopRe1: newValue }));
    } else if (
      startDatePOP1ReFormat &&
      newValue.isSame(startDatePOP1ReFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        showTimePop &&
        (newValue.hour() < startDatePOP1ReFormat.hour() ||
          (newValue.hour() === startDatePOP1ReFormat.hour() &&
            newValue.minute() <= startDatePOP1ReFormat.minute()))
      ) {
        setEndDatePopRe1(null);
        // dispatch(setOverviewData({ endDatePopRe1: null }));
        notifyError("End time must be after start time");
      } else {
        setEndDatePopRe1(newValue);
        // dispatch(setOverviewData({ endDatePopRe1: newValue }));
      }
    } else {
      setEndDatePopRe1(null);
      // dispatch(setOverviewData({ endDatePopRe1: null }));
      notifyError("Start date must be less than end date");
    }
  };

  const handleReportsReportStartDate = (newValue) => {
    const durationInDays = endDateReportFormat
      ? endDateReportFormat.diff(newValue, "days")
        ? endDateReportFormat.diff(newValue, "days") > 0
          ? endDateReportFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setReportStartDate(null);
      // dispatch(setOverviewData({ reportStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReportFormat && newValue.isBefore(endDateReportFormat)) {
      setReportStartDate(newValue);
      // dispatch(setOverviewData({ reportStartDate: newValue }));
    } else if (!endDateReportFormat) {
      setReportStartDate(newValue);
      // dispatch(setOverviewData({ reportStartDate: newValue }));
    } else if (
      endDateReportFormat &&
      newValue.isSame(endDateReportFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        reportShowTime &&
        (newValue.hour() > endDateReportFormat.hour() ||
          (newValue.hour() === endDateReportFormat.hour() &&
            newValue.minute() >= endDateReportFormat.minute()))
      ) {
        setReportStartDate(null);
        // dispatch(setOverviewData({ reportStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        setReportStartDate(newValue);
        // dispatch(setOverviewData({ reportStartDate: newValue }));
      }
    } else {
      setReportStartDate(null);
      // dispatch(setOverviewData({ reportStartDate: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handleReportsReportEndDate = (newValue) => {
    const durationInDays = startDateReportFormat
      ? startDateReportFormat.diff(newValue, "days")
        ? startDateReportFormat.diff(newValue, "days") > 0
          ? startDateReportFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setOverviewData({ endDateReportFormat: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReportFormat && newValue.isAfter(startDateReportFormat)) {
      setReportEndDate(newValue);
      // dispatch(setOverviewData({ reportEndDate: newValue }));
    } else if (!startDateReportFormat) {
      setReportEndDate(newValue);
      // dispatch(setOverviewData({ reportEndDate: newValue }));
    } else if (
      startDateReportFormat &&
      newValue.isSame(startDateReportFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        reportShowTime &&
        (newValue.hour() < startDateReportFormat.hour() ||
          (newValue.hour() === startDateReportFormat.hour() &&
            newValue.minute() <= startDateReportFormat.minute()))
      ) {
        setReportEndDate(null);
        // dispatch(setOverviewData({ reportEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        setReportEndDate(newValue);
        // dispatch(setOverviewData({ reportEndDate: newValue }));
      }
    } else {
      setReportEndDate(null);
      // dispatch(setOverviewData({ reportEndDate: null }));
      notifyError("Start date must be less than end date");
    }
  };
  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Analytics"
        )
      : null
    : null;

  const ReportAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Reports"
        )
      : null
    : null;

  const handleOpenEnergyModal = () => {
    setOpenEnergyModal((prev) => {
      const newState = !prev;
      console.log("openEnergy", newState);
      return newState;
    });
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      !event.target.closest(".filter-img-class")
    ) {
      setOpenEnergyModal(false);
    }
  };

  useEffect(() => {
    if (openEnergyModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEnergyModal]);

  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
  };

  useEffect(() => {
    handleReceipeClick();
  }, [deviceIdRedux, deviceIdPOPRedux, activeClassConsumer]);

  const handleReceipeClick = async () => {
    const params = {
      device:
        activeClassConsumer == "Consumer"
          ? deviceIdRedux
              .filter((e) => e.plantId == param.id && e.deviceID)
              .map((row) => row.deviceID)
          : deviceIdPOPRedux
              .filter((e) => e.plantId == param.id && e.deviceID)
              .map((row) => row.deviceID),
    };
    try {
      const resp = await getDeviceReceipe(params);
      setReceipeData(resp.data.payload);
    } catch (error) {
      setReceipeData([]);
      console.log("handleSubmit", error);
    }
  };

  console.log("receipe", receipeData);

  const handleCustomizedModal = () => {
    setCustomizedReportModal(!customizedReportModal);
  };

  const customeReportAPI = async () => {
    const params = {
      report_type: selectedPlant,
      time: selectDateTime,
    };
    try {
      const resp = await customeReportData(params);
      if (resp.status == 200 || resp.status == 201) {
        setCustomData(resp.data.payload);
        notify(resp.data.message);
        setCustomizedReportModal(false);
        setSelectedTime(null);
        setSelectedPlant(null);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      setCustomizedReportModal(false);
      setSelectedTime(null);
      setSelectedPlant(null);
      console.log("handlesubmit", error);
    }
  };

  const handleCheckboxChange = (plantId) => {
    setSelectedPlant(plantId === selectedPlant ? null : plantId);
  };
  return (
    <>
      {/* <OverViewAnalyticsReportApiData
        showTimeReport={reportShowTime}
        setFisrtLineChartReportData={setFisrtLineChartReportData}
        setSecondLineChartReportData={setSecondLineChartReportData}
        selectFirstUnit={reportSelectUnit1}
        selectAggReport={reportAgg}
        selectIntervalReport={reportInterval}
        deviceIdReportRedux={deviceIdReportRedux}
        deviceUnitReportRedux={deviceUnitReportRedux}
        selectSecondUnit={reportSelectUnit2}
        startTimeFormatRep={startTimeFormatRep}
        endTimeFormatRep={endTimeFormatRep}
        formatStartDateRep={formatStartDateRep}
        formatEndDateRep={formatEndDateRep}
        setLoadingReport={setLoadingReport}
        setisShimmerMultiAxisLineChartReports={
          setisShimmerMultiAxisLineChartReports
        }
      /> */}
      {showOverViewChart ? (
        <>
          <Container maxWidth="xxl" className="pt-4">
            {/* <div className="page-wraper"> */}
            <div className="page-header page-header-analytics">
              {activeClass == "Analytics" ? (
                <div
                  onClick={() =>
                    navigate(
                      `/bpcl-processdashboard/company/${param.companyId}/plant/${param.id}`
                    )
                  }
                  className="page-back-btn"
                >
                  <ArrowBackIcon />
                  <span>Reports</span>
                </div>
              ) : (
                <div
                  onClick={() =>
                    navigate(
                      `/bpcl-processdashboard/company/${param.companyId}/plant/${param.id}`
                    )
                  }
                  className="page-back-btn"
                >
                  <ArrowBackIcon />
                  <span>Report</span>
                </div>
              )}
            </div>

            <>
              <div className="d-flex justify-content-end pt-3">
                <div className="analyticsAgg-datePicker-overview">
                  <div
                    class="overview-report-wrap"
                    style={{ flexWrap: "wrap" }}
                  >
                    <div>
                      <select
                        className="compact-btn mb-0"
                        value={selectUnit}
                        onChange={(e) =>
                          dispatch(
                            setProcessDashboarDateSet({
                              selectUnit: e.target.value,
                            })
                          )
                        }
                      >
                        <option value="k">Kilo</option>
                        <option value="m">Mega</option>
                        <option value="g">Giga</option>
                      </select>
                    </div>
                    <div className="overview-report-time">
                      <h6 className="AGG-dropdown">Interval</h6>
                      {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                      (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                      (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                        <>
                          <Box style={{ float: "right" }}>
                            <select
                              className="form-input-class"
                              style={{
                                padding: "8px",
                                fontSize: "14px",
                                borderRadius: "4px",
                              }}
                              id="selectedClass"
                              value={reportInterval}
                              onChange={handleReportInterval}
                              required
                            >
                              <option value="1" selected disabled hidden>
                                1 min
                              </option>
                              <option value="1">1 Min</option>
                              <option value="15">15 Min</option>
                              <option value="30">30 Min</option>
                              <option value="60">Hourly</option>
                              <option value="24">Day</option>
                              <option value="7">Weekly</option>
                            </select>
                          </Box>
                        </>
                      ) : (
                        <Box style={{ float: "right" }}>
                          <select
                            className="form-input-class"
                            style={{
                              padding: "8px",
                              fontSize: "14px",
                              borderRadius: "4px",
                            }}
                            id="selectedClass"
                            // value={selectIntervalReport}
                            onChange={handleTostMsg}
                            required
                          >
                            <option value="1" selected disabled hidden>
                              1 min
                            </option>
                            <option value="1">1 Min</option>
                            <option value="15">15 Min</option>
                            <option value="30">30 Min</option>
                            <option value="60">Hourly</option>
                            <option value="24">Day</option>
                            <option value="7">Weekly</option>
                          </select>
                        </Box>
                      )}
                    </div>
                    {hasRequiredUnits ? (
                      <div className="overview-report-agg">
                        <h6 className="AGG-dropdown">AGG.</h6>
                        {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (ReportAccess?.is_viewer &&
                          !ReportAccess?.is_editor) ? (
                          <Box
                            sx={{ minWidth: 200 }}
                            style={{ float: "right" }}
                          >
                            <select
                              className="form-input-class"
                              style={{
                                padding: "8px",
                                fontSize: "14px",
                                borderRadius: "4px",
                              }}
                              id="selectedClass"
                              value={reportAgg}
                              onChange={handleAggReport}
                              // onChange={(e) => ["kwh", "kvah", "kvarh"].includes(selectFirstUnit.toLocaleLowerCase()) || ["kwh", "kvah", "kvarh"].includes(selectSecondUnit.toLocaleLowerCase()) ? null : setSelectAggReport(e.target.value) }
                              required
                            >
                              <option value="15" selected disabled hidden>
                                15 Min
                              </option>
                              <option value="15">15 Min</option>
                              <option value="30">30 Min</option>
                              <option value="60">Hourly</option>
                              <option value="1">Day</option>
                              <option value="7">Weekly</option>
                            </select>
                          </Box>
                        ) : (
                          <Box
                            sx={{ minWidth: 200 }}
                            style={{ float: "right" }}
                          >
                            <select
                              className="form-input-class"
                              style={{
                                padding: "8px",
                                fontSize: "14px",
                                borderRadius: "4px",
                              }}
                              id="selectedClass"
                              // value={selectAggReport}
                              onChange={handleTostMsg}
                              // onChange={(e) => ["kwh", "kvah", "kvarh"].includes(selectFirstUnit.toLocaleLowerCase()) || ["kwh", "kvah", "kvarh"].includes(selectSecondUnit.toLocaleLowerCase()) ? null : setSelectAggReport(e.target.value) }
                              required
                            >
                              <option value="15" selected disabled hidden>
                                15 Min
                              </option>
                              <option value="15">15 Min</option>
                              <option value="30">30 Min</option>
                              <option value="60">Hourly</option>
                              <option value="1">Day</option>
                              <option value="7">Weekly</option>
                            </select>
                          </Box>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="overview-time-wrap">
                    <div>
                      {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                      (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                      (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Switch
                                color="primary"
                                checked={reportShowTime}
                              />
                            }
                            label="Time"
                            labelPlacement="top"
                            onChange={handleShowReportToggle}
                            className="overview-switch"
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={<Switch color="primary" />}
                            label="Time"
                            labelPlacement="top"
                            onChange={handleTostMsg}
                            className="overview-switch"
                          />
                        </FormGroup>
                      )}
                    </div>
                    <div className="overview-date-picker-wrap">
                      {/* <div> */}
                      {reportShowTime ? (
                        <>
                          {(ReportAccess?.is_viewer &&
                            ReportAccess?.is_editor) ||
                          (!ReportAccess?.is_viewer &&
                            ReportAccess?.is_editor) ||
                          (ReportAccess?.is_viewer &&
                            !ReportAccess?.is_editor) ? (
                            <>
                              <Box className="">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    label="Start Date"
                                    value={startDateReportFormat}
                                    onChange={(newValue) =>
                                      handleReportsReportStartDate(newValue)
                                    }
                                    disableFuture
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    className="custom-datepicker"
                                  />
                                </LocalizationProvider>
                              </Box>
                              <Box className="">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    label="End Date"
                                    value={endDateReportFormat}
                                    disableFuture
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    onChange={(newValue) =>
                                      handleReportsReportEndDate(newValue)
                                    }
                                    className="custom-datepicker"
                                  />
                                </LocalizationProvider>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box className="">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    label="Start Date"
                                    // value={startDateRep}
                                    onChange={handleTostMsg}
                                    disableFuture
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    className="custom-datepicker"
                                  />
                                </LocalizationProvider>
                              </Box>
                              <Box className="">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    label="End Date"
                                    // value={endDateRep}
                                    disableFuture
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    onChange={handleTostMsg}
                                    className="custom-datepicker"
                                  />
                                </LocalizationProvider>
                              </Box>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {(ReportAccess?.is_viewer &&
                            ReportAccess?.is_editor) ||
                          (!ReportAccess?.is_viewer &&
                            ReportAccess?.is_editor) ||
                          (ReportAccess?.is_viewer &&
                            !ReportAccess?.is_editor) ? (
                            <>
                              <Box className="">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    className="custom-datepicker"
                                    label="Start Date"
                                    value={startDateReportFormat}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                      handleReportsReportStartDate(newValue);
                                    }}
                                    disableFuture
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{ background: "#ffff" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <Box
                                className=""
                                // style={{ width: "30%" }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    className="custom-datepicker"
                                    label="End Date"
                                    value={endDateReportFormat}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                      handleReportsReportEndDate(newValue);
                                    }}
                                    disableFuture
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{ background: "#ffff" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box className="">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    className="custom-datepicker"
                                    label="Start Date"
                                    // value={startDateRep}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={handleTostMsg}
                                    disableFuture
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{ background: "#ffff" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <Box
                                className=""
                                // style={{ width: "30%" }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    className="custom-datepicker"
                                    label="End Date"
                                    // value={endDateRep}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={handleTostMsg}
                                    disableFuture
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{ background: "#ffff" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </>
                          )}
                        </>
                      )}
                      {/* </div> */}
                    </div>
                    {param.id == 40 || param.id == 44 || param.id == 45 ? (
                      <div onClick={handleCustomizedModal}>
                        <img
                          src={images.excelLogo}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the event from propagating to the document
                        handleOpenEnergyModal();
                      }}
                      className="filter-img-class"
                    >
                      <img
                        src={images.filterImg}
                        alt=""
                        style={{ height: "40px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-2 consumption-grid-card">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3}>
                    <Box
                      className="consumerData-box consumer-box"
                      style={{ minHeight: "757px" }}
                    >
                      <Box className="consumer-text-disc">
                        <div className="d-flex justify-content-between">
                          {" "}
                          <div>
                            {" "}
                            <h6 className="Title">Title</h6>{" "}
                            <h6>Energy Meter Charts</h6>{" "}
                          </div>{" "}
                          <div>
                            {" "}
                            <Switch
                              checked={overViewReportSwitch}
                              onChange={() =>
                                setOverViewReportSwitch(!overViewReportSwitch)
                              }
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                      </Box>

                      <Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "13px 8px",
                            borderRadius: "4px",
                            width: "100%",
                            background: "#f5f5f5",
                            cursor: "pointer",
                            marginTop: "4px",
                          }}
                          onClick={handleClick}
                        >
                          Select Device <ArrowDropDownIcon />
                        </div>
                      </Box>

                      <Popper
                        open={openMachine}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        style={{
                          width: "23%",
                          maxHeight: "300px",
                          overflow: "auto",
                        }}
                        className="machine-dropdown"
                      >
                        {/* <Paper>
              {deviceData.length ? deviceData.map((row) => <MenuItem className="vms-coc-menuItem" onClick={handlePropagation}> {row.device__device_name} <Switch style={{ float: "right" }} checked={VmsReportSelectmachine ? VmsReportSelectmachine.find((f) => f.machineId == row.device) ? true : false : false} disabled={isCheckboxMachineDisabled(row.device)} onClick={() => handleSelectMachine(row)} /></MenuItem>) : <MenuItem className="vms-coc-menuItem" onClick={handlePropagation}>No Machine Found</MenuItem>}
            </Paper> */}
                        <Paper>
                          {deviceReport.length ? (
                            deviceReport.map((e, index) => (
                              <MenuItem
                                className="vms-coc-menuItem"
                                onClick={handlePropagation}
                              >
                                {" "}
                                {e.device__device_name}{" "}
                                {(ReportAccess?.is_viewer &&
                                  ReportAccess?.is_editor) ||
                                (!ReportAccess?.is_viewer &&
                                  ReportAccess?.is_editor) ||
                                (ReportAccess?.is_viewer &&
                                  !ReportAccess?.is_editor) ? (
                                  // <Switch
                                  //   style={{ float: "right" }}
                                  //   checked={
                                  //     deviceIdReportRedux.length
                                  //       ? deviceIdReportRedux.find(
                                  //           (f) =>
                                  //             f.deviceID == e.device &&
                                  //             f.plantId == param.id
                                  //         )
                                  //         ? true
                                  //         : false
                                  //       : false
                                  //   }
                                  //   onClick={(event) =>
                                  //     handleSelectDeviceIdReport(
                                  //       e.device,
                                  //       index,
                                  //       event,
                                  //       e
                                  //     )
                                  //   }
                                  //   sx={{
                                  //     "& .MuiSwitch-thumb": {
                                  //       bgcolor: "#828282",
                                  //     },
                                  //     "& .MuiSwitch-track": {
                                  //       bgcolor: "#D9D9E5 !important",
                                  //     },
                                  //     "& .Mui-checked": {
                                  //       "& .MuiSwitch-thumb": {
                                  //         bgcolor:
                                  //           switchColors[
                                  //             index % switchColors.length
                                  //           ],
                                  //       },
                                  //       "& .MuiSwitch-track": {
                                  //         bgcolor: "#E2D9F2 !important",
                                  //       },
                                  //     },
                                  //   }}
                                  // />
                                  <Switch
                                    style={{ float: "right" }}
                                    checked={
                                      deviceIdReportRedux
                                        ? deviceIdReportRedux.find(
                                            (f) => f.deviceID == e.device
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    onClick={() =>
                                      handleSelectDeviceIdReport(e)
                                    }
                                  />
                                ) : (
                                  <Switch
                                    style={{ float: "right" }}
                                    onClick={handleTostMsg}
                                    name={switchColors[index]}
                                    // checked={deviceIdReportRedux.includes(
                                    //   e.device
                                    // )}
                                    checked={
                                      deviceIdReportRedux.length
                                        ? deviceIdReportRedux.find(
                                            (f) =>
                                              f.deviceID == e.device &&
                                              f.plantId == param.id
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor:
                                            switchColors[
                                              index % switchColors.length
                                            ],
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#E2D9F2 !important",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              className="vms-coc-menuItem"
                              onClick={handlePropagation}
                            >
                              No Machine Found
                            </MenuItem>
                          )}
                        </Paper>
                      </Popper>

                      <Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "13px 8px",
                            borderRadius: "4px",
                            width: "100%",
                            background: "#f5f5f5",
                            cursor: "pointer",
                            marginTop: "4px",
                          }}
                          onClick={handleClickUnit}
                        >
                          Select Unit <ArrowDropDownIcon />
                        </div>

                        <Popper
                          open={openUnit}
                          anchorEl={anchorEl2}
                          placement="bottom-start"
                          style={{
                            width: "23%",
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          {/* <Paper>
                {deviceUnitData.length ? deviceUnitData.map((row) => <MenuItem className="vms-coc-menuItem" onClick={handlePropagation}>{row.tag_unit} <Switch style={{ float: "right" }} checked={VmsReportSelectTagUnit ? VmsReportSelectTagUnit.find((f) => f.tagUnit == row.tag_unit) ? true : false : false} disabled={isCheckboxTagUnitDisabled(row.tag_unit)} onClick={() => handleSelectTagUnit(row)}/></MenuItem>) : <MenuItem className="vms-coc-menuItem" onClick={handlePropagation}>No Unit Found</MenuItem>}
              </Paper> */}
                          <Paper>
                            {unitReport.length ? (
                              unitReport.map((e, index) => (
                                <MenuItem
                                  className="vms-coc-menuItem"
                                  onClick={handlePropagation}
                                >
                                  {e.tag_unit}{" "}
                                  {(ReportAccess?.is_viewer &&
                                    ReportAccess?.is_editor) ||
                                  (!ReportAccess?.is_viewer &&
                                    ReportAccess?.is_editor) ||
                                  (ReportAccess?.is_viewer &&
                                    !ReportAccess?.is_editor) ? (
                                    <Switch
                                      checked={deviceUnitReportRedux.some(
                                        (f) =>
                                          f.tagUnit === e.tag_unit &&
                                          f.plantId === param.id
                                      )}
                                      // name={switchColors[index]}
                                      style={{ float: "right" }}
                                      onClick={() =>
                                        handleSelectTagUnitReport(e)
                                      }
                                      // sx={{
                                      //   "& .MuiSwitch-thumb": {
                                      //     bgcolor: "#828282",
                                      //   },
                                      //   "& .MuiSwitch-track": {
                                      //     bgcolor: "#D9D9E5 !important",
                                      //   },
                                      //   "& .Mui-checked": {
                                      //     "& .MuiSwitch-thumb": {
                                      //       bgcolor:
                                      //         switchColors[
                                      //           index % switchColors.length
                                      //         ],
                                      //     },
                                      //     "& .MuiSwitch-track": {
                                      //       bgcolor: "#E2D9F2 !important",
                                      //     },
                                      //   },
                                      // }}
                                    />
                                  ) : (
                                    <Switch
                                      checked={
                                        deviceUnitReportRedux.length
                                          ? deviceUnitReportRedux.find(
                                              (f) =>
                                                f.tagUnit == e.tag_unit &&
                                                f.plantId == param.id
                                            )
                                            ? true
                                            : false
                                          : false
                                      }
                                      name={switchColors[index]}
                                      style={{ float: "right" }}
                                      onClick={handleTostMsg}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                                index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem
                                className="vms-coc-menuItem"
                                onClick={handlePropagation}
                              >
                                No Unit Found
                              </MenuItem>
                            )}
                          </Paper>
                        </Popper>
                      </Box>

                      <div style={{ marginTop: "14px", fontWeight: "bold" }}>
                        Tag List
                      </div>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Unit here"
                        value={searchTag}
                        onChange={(e) => setSearchTag(e.target.value)}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />

                      <div
                        className="vms-report-taglistUnit"
                        style={{ height: "395px" }}
                      >
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerdevicelist ? (
                            tagListData
                              .filter(
                                (e) =>
                                  e.tag_name &&
                                  e.tag_name
                                    .toLowerCase()
                                    .includes(searchTag.toLowerCase())
                              )
                              .map((row, index) => (
                                <Grid item xs={12}>
                                  {row.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6>{row.tag_name}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            VmsReportSelectTag
                                              ? VmsReportSelectTag.find(
                                                  (f) => f.tagId == row.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          style={{ float: "right" }}
                                          name={switchColors[index]}
                                          // disabled={isCheckBoxTagDisabled(activeClass, activeTabClass, row.id)}
                                          onClick={(e) =>
                                            handleSelectTag(row, e.target.name)
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#CEE6E1 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={7} />
                          )}
                        </Box>
                      </div>

                      <div>
                        {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                        (ReportAccess?.is_viewer &&
                          !ReportAccess?.is_editor) ? (
                          <button
                            className="export-btn "
                            style={{ marginTop: "9px" }}
                            onClick={handleExportExcelClick}
                          >
                            Export ENERGY Data
                          </button>
                        ) : (
                          <button
                            className="export-btn"
                            style={{ marginTop: "46px" }}
                            onClick={handleTostMsg}
                          >
                            Export ENERGY Data
                          </button>
                        )}
                      </div>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    className="consumption-table-box"
                  >
                    <Box
                      className="consumerData-box"
                      style={{ padding: "0px" }}
                    >
                      <div style={{ minHeight: "400px" }}>
                        {/* <MultiAxisLineChart
                            fisrtLineChartReportData={fisrtLineChartReportData}
                            secondeLineChartReportData={
                              secondeLineChartReportData
                            }
                            selectFirstUnit={reportSelectUnit1}
                            selectSecondUnit={reportSelectUnit2}
                            Shimmer={isShimmerMultiAxisLineChartReports}
                            selectFirst={CompactTagUnit(
                              selectUnit,
                              reportSelectUnit1
                            )}
                            selectSecond={CompactTagUnit(
                              selectUnit,
                              reportSelectUnit2
                            )}
                            selectAggReport={reportAgg}
                            selectIntervalReport={reportInterval}
                            unitColorsReportRedux={unitColorsReportRedux}
                          /> */}

                        <MultiAxiosLineChart
                          LineChartData1={fisrtLineChartReportData}
                          LineChartData2={secondeLineChartReportData}
                          selectedMachine={
                            VmsReportReportsSelectTag
                              ? VmsReportReportsSelectTag.filter(
                                  (f) => f.plantId == param.id
                                )
                              : []
                          }
                          shimmer={isShimmerMultiAxisLineChartReports}
                          selectAggReport={reportAgg} // aggreagate
                          selectIntervalReport={reportInterval} // interval
                          hasRequiredUnits={hasRequiredUnits}
                          selectFirstUnit={reportSelectUnit1}
                          selectSecondUnit={reportSelectUnit2}
                          selectTag={VmsReportReportsSelectTag}
                        />
                      </div>
                      <div className="d-flex justify-content-between m-3">
                        <select
                          className="form-input-class"
                          style={{
                            padding: "0px 14px",
                            borderRadius: "4px",
                            height: "40px",
                          }}
                          id="selectedClass"
                          value={reportSelectUnit1}
                          onChange={handleReportSelectUnit11}
                          required
                        >
                          <option value="none" selected hidden>
                            Select tag
                          </option>
                          {VmsReportReportsSelectTag
                            ? VmsReportReportsSelectTag.filter(
                                (f) => f.plantId == param.id
                              ).map((e) => (
                                <option value={e.tagId}>{e.tagName}</option>
                              ))
                            : []}
                        </select>

                        <select
                          className="form-input-class"
                          style={{
                            padding: "0px 14px",
                            borderRadius: "4px",
                            height: "40px",
                          }}
                          id="selectedClass"
                          value={reportSelectUnit2}
                          onChange={handleReportSelectUnit12}
                          required
                        >
                          <option value="none" selected hidden>
                            Select tag
                          </option>
                          {VmsReportReportsSelectTag
                            ? VmsReportReportsSelectTag.filter(
                                (f) => f.plantId == param.id
                              ).map((e) => (
                                <option value={e.tagId}>{e.tagName}</option>
                              ))
                            : []}
                        </select>
                      </div>
                      <div className="report-overview-table">
                        <Table className="source-table">
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>

                              {reportOverViewData.length
                                ? reportOverViewData.map((row) => {
                                    const formattedValue = row.tag_name;
                                    return (
                                      <TableCell>{formattedValue}</TableCell>
                                    );
                                  })
                                : []}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Min</TableCell>
                              {reportOverViewData.length
                                ? reportOverViewData.map((row) => {
                                    const formattedValue1 = row.min_value
                                      ? parseFloat(row.min_value).toFixed(2)
                                      : 0.0;
                                    return (
                                      <TableCell>{formattedValue1}</TableCell>
                                    );
                                  })
                                : []}
                            </TableRow>
                            <TableRow>
                              <TableCell>Max</TableCell>
                              {reportOverViewData.length
                                ? reportOverViewData.map((row) => {
                                    const formattedValue2 = row.max_value
                                      ? parseFloat(row.max_value).toFixed(2)
                                      : 0.0;
                                    return (
                                      <TableCell>{formattedValue2}</TableCell>
                                    );
                                  })
                                : []}
                            </TableRow>
                            <TableRow>
                              <TableCell>Mean</TableCell>
                              {reportOverViewData.length
                                ? reportOverViewData.map((row) => {
                                    const formattedValue3 = row.mean_value
                                      ? parseFloat(row.mean_value).toFixed(2)
                                      : 0.0;
                                    return (
                                      <TableCell>{formattedValue3}</TableCell>
                                    );
                                  })
                                : []}
                            </TableRow>
                            <TableRow>
                              <TableCell>Std. Dev</TableCell>
                              {reportOverViewData.length
                                ? reportOverViewData.map((row) => {
                                    const formattedValue4 = row.std_dev_value
                                      ? parseFloat(row.std_dev_value).toFixed(2)
                                      : 0.0;
                                    return (
                                      <TableCell>{formattedValue4}</TableCell>
                                    );
                                  })
                                : []}
                            </TableRow>
                            <TableRow>
                              <TableCell>All Time Min</TableCell>
                              {reportOverViewData.length
                                ? reportOverViewData.map((row) => {
                                    const formattedValue5 =
                                      row.all_time_min_value
                                        ? parseFloat(
                                            row.all_time_min_value
                                          ).toFixed(2)
                                        : 0.0;
                                    return (
                                      <TableCell>{formattedValue5}</TableCell>
                                    );
                                  })
                                : []}
                            </TableRow>
                            <TableRow>
                              <TableCell>All Time Max</TableCell>
                              {reportOverViewData.length
                                ? reportOverViewData.map((row) => {
                                    const formattedValue6 =
                                      row.all_time_max_value
                                        ? parseFloat(
                                            row.all_time_max_value
                                          ).toFixed(2)
                                        : 0.0;
                                    return (
                                      <TableCell>{formattedValue6}</TableCell>
                                    );
                                  })
                                : []}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>{" "}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>

            {openEnergyModal &&
              (activeClassConsumer == "Consumer" ? (
                <>
                  <div className="consumerCard" ref={modalRef}>
                    <Box className="consumerData-box consumer-card-box">
                      <Box className="consumer-text-disc">
                        <h6 className="Title">Title</h6>
                        <h6>Energy Meter Charts</h6>
                      </Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Device here"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div className="furnace-grid">
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            deviceData.length ? (
                              deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    ?.toLowerCase()
                                    ?.includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6
                                          className="mt-2"
                                          style={{ fontSize: "16px" }}
                                        >
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      {(analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (!analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (analyticsAccess?.is_viewer &&
                                        !analyticsAccess?.is_editor) ? (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={(event) =>
                                              handleSelectDeviceId(
                                                e.device,
                                                index,
                                                e,
                                                e.target.name
                                              )
                                            }
                                            name={switchColors[index]}
                                            checked={
                                              deviceIdRedux.find(
                                                (f) =>
                                                  f.deviceID == e.device &&
                                                  f.plantId == param.id
                                              )
                                                ? true
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={handleTostMsg}
                                            name={switchColors[index]}
                                            checked={
                                              deviceIdRedux.find(
                                                (f) =>
                                                  f.deviceID == e.device &&
                                                  f.plantId == param.id
                                              )
                                                ? true
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      </div>
                      <Box className="consumer-text-disc mt-2"></Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Unit here"
                        value={unitSearchQuery}
                        onChange={handleUnitSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />

                      <div className="device-unit">
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerUnitlist ? (
                            deviceUnitData
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      {(analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (!analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (analyticsAccess?.is_viewer &&
                                        !analyticsAccess?.is_editor) ? (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            checked={
                                              deviceUnitRedux.length
                                                ? deviceUnitRedux.find(
                                                    (f) =>
                                                      f.tagUnit == e.tag_unit &&
                                                      f.plantId == param.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            style={{ float: "right" }}
                                            onClick={() =>
                                              handleSelectTagUnit(e.tag_unit)
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#50C878",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#CEE6E1 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            checked={
                                              deviceUnitRedux.length
                                                ? deviceUnitRedux.find(
                                                    (f) =>
                                                      f.tagUnit == e.tag_unit &&
                                                      f.plantId == param.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            style={{ float: "right" }}
                                            onClick={() => handleTostMsg}
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor: "#50C878",
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#CEE6E1 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          style={{ marginTop: "46px" }}
                          onClick={handleExportEnergyData}
                        >
                          EXPORT ENERGY DATA
                        </button>
                      </div>
                    </Box>
                  </div>
                </>
              ) : (
                <div className="consumerCard" ref={modalRef}>
                  <Box className="consumerData-box consumer-card-box">
                    <Box className="consumer-text-disc">
                      <h6 className="Title">Title</h6>
                      <h6>Energy Meter Charts</h6>
                    </Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Device here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />
                    <div className="furnace-grid">
                      <Box className="Grid-text mt-2">
                        {!isShimmerdevicelist ? (
                          deviceData.length ? (
                            deviceData
                              .filter((e) =>
                                e.device__device_name
                                  ?.toLowerCase()
                                  ?.includes(searchQuery.toLowerCase())
                              )
                              .map((e, index) => (
                                <Grid item xs={12} key={e.device__device_name}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      style={{
                                        padding: "7px 12px",
                                        borderLeft: `4px solid ${
                                          switchColors[
                                            index % switchColors.length
                                          ]
                                        }`,
                                      }}
                                    >
                                      <h6
                                        className="mt-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        {e.device__device_name}
                                      </h6>
                                    </Grid>
                                    {(analyticsAccess?.is_viewer &&
                                      analyticsAccess?.is_editor) ||
                                    (!analyticsAccess?.is_viewer &&
                                      analyticsAccess?.is_editor) ||
                                    (analyticsAccess?.is_viewer &&
                                      !analyticsAccess?.is_editor) ? (
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={(event) =>
                                            handlePOPSelectDeviceId(
                                              e.device,
                                              index,
                                              e,
                                              event.target.name
                                            )
                                          }
                                          name={switchColors[index]}
                                          checked={
                                            deviceIdPOPRedux.length
                                              ? deviceIdPOPRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          name={switchColors[index]}
                                          checked={
                                            deviceIdPOPRedux.length
                                              ? deviceIdPOPRedux.find(
                                                  (f) =>
                                                    f.deviceID == e.device &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor:
                                                  switchColors[
                                                    index % switchColors.length
                                                  ],
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#E2D9F2 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              ))
                          ) : (
                            []
                          )
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    </div>
                    <Box className="consumer-text-disc mt-2"></Box>

                    <TextField
                      variant="outlined"
                      className="search-textfield"
                      placeholder="Search Unit here"
                      value={unitSearchQuery}
                      onChange={handleUnitSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        inputProps: {
                          style: { padding: "9px" },
                        },
                      }}
                    />

                    <div className="device-unit">
                      {(analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (!analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (analyticsAccess?.is_viewer &&
                        !analyticsAccess?.is_editor) ? (
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerUnitlist ? (
                            deviceUnitData
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            deviceUnitPOPRedux.length
                                              ? deviceUnitPOPRedux.find(
                                                  (f) =>
                                                    f.tagUnit == e.tag_unit &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          style={{ float: "right" }}
                                          onClick={() =>
                                            handlePOPSelectTagUnit(e.tag_unit)
                                          }
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#50C878",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#CEE6E1 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      ) : (
                        <Box className="Grid-text1 mt-2">
                          {!isShimmerUnitlist ? (
                            deviceUnitData
                              .filter(
                                (e) =>
                                  e.tag_unit &&
                                  e.tag_unit
                                    .toLowerCase()
                                    .includes(unitSearchQuery.toLowerCase())
                              )
                              .map((e) => (
                                <Grid item xs={12} key={e.tag_unit}>
                                  {e.tag_unit && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        className="mt-2"
                                        style={{ padding: "7px 12px" }}
                                      >
                                        <h6>{e.tag_unit}</h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        textAlign="right"
                                        className="grid-switch-unit"
                                      >
                                        <Switch
                                          checked={
                                            deviceUnitPOPRedux.length
                                              ? deviceUnitPOPRedux.find(
                                                  (f) =>
                                                    f.tagUnit == e.tag_unit &&
                                                    f.plantId == param.id
                                                )
                                                ? true
                                                : false
                                              : false
                                          }
                                          style={{ float: "right" }}
                                          onClick={handleTostMsg}
                                          sx={{
                                            "& .MuiSwitch-thumb": {
                                              bgcolor: "#828282",
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#D9D9E5 !important",
                                            },
                                            "& .Mui-checked": {
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#50C878",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#CEE6E1 !important",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      )}
                    </div>

                    <div className="mt-3">
                      <button
                        className="export-btn"
                        style={{ marginTop: "46px" }}
                        onClick={handlePOPExcel}
                      >
                        EXPORT ENERGY DATA
                      </button>
                    </div>
                  </Box>
                </div>
              ))}

            {openEnergyModal && activeClass == "Reports" && (
              <div className="consumerCard" ref={modalRef}>
                <Box
                  className="consumerData-box consumer-card-box"
                  style={{ minHeight: "757px" }}
                >
                  <Box className="consumer-text-disc">
                    <h6 className="Title">Title</h6>
                    <h6>Energy Meter Charts</h6>
                  </Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Device here"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />
                  <div className="furnace-grid">
                    <Box className="Grid-text mt-2">
                      {!isShimmerReportdevicelist ? (
                        deviceReport.length ? (
                          deviceReport
                            .filter((e) =>
                              e.device__device_name
                                ?.toLowerCase()
                                ?.includes(searchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.device__device_name}>
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    background: "#efeffd",
                                    width: "100%",
                                    marginBottom: "7px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    style={{
                                      padding: "7px 12px",
                                      borderLeft: `4px solid ${
                                        switchColors[
                                          index % switchColors.length
                                        ]
                                      }`,
                                    }}
                                  >
                                    <h6
                                      className="mt-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {e.device__device_name}
                                    </h6>
                                  </Grid>

                                  {(ReportAccess?.is_viewer &&
                                    ReportAccess?.is_editor) ||
                                  (!ReportAccess?.is_viewer &&
                                    ReportAccess?.is_editor) ||
                                  (ReportAccess?.is_viewer &&
                                    !ReportAccess?.is_editor) ? (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={(event) =>
                                          handleSelectDeviceIdReport(
                                            e.device,
                                            index,
                                            event,
                                            e
                                          )
                                        }
                                        name={switchColors[index]}
                                        // checked={deviceIdReportRedux.includes(
                                        //   e.device
                                        // )}
                                        checked={
                                          deviceIdReportRedux.length
                                            ? deviceIdReportRedux.find(
                                                (f) =>
                                                  f.deviceID == e.device &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        name={switchColors[index]}
                                        // checked={deviceIdReportRedux.includes(
                                        //   e.device
                                        // )}
                                        checked={
                                          deviceIdReportRedux.length
                                            ? deviceIdReportRedux.find(
                                                (f) =>
                                                  f.deviceID == e.device &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            ))
                        ) : (
                          []
                        )
                      ) : (
                        <DeviceListShimmer itemCount={3} />
                      )}
                    </Box>
                  </div>
                  <Box className="consumer-text-disc mt-2">
                    {/* <h6 className="Title">Description</h6>
                    <h6>Units</h6> */}
                  </Box>

                  <TextField
                    variant="outlined"
                    className="search-textfield"
                    placeholder="Search Unit here"
                    value={unitSearchQuery}
                    onChange={handleUnitSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      inputProps: {
                        style: { padding: "9px" },
                      },
                    }}
                  />

                  <div className="device-unit">
                    {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                    (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                      <Box className="Grid-text1 mt-2">
                        {!isShimmerReportUnitlist ? (
                          unitReport
                            .filter(
                              (e) =>
                                e.tag_unit &&
                                e.tag_unit
                                  .toLowerCase()
                                  .includes(unitSearchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.tag_unit}>
                                {e.tag_unit && (
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      className="mt-2"
                                      style={{ padding: "7px 12px" }}
                                    >
                                      <h6>{e.tag_unit}</h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnitReportRedux.length
                                            ? deviceUnitReportRedux.find(
                                                (f) =>
                                                  f.tagUnit == e.tag_unit &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        name={switchColors[index]}
                                        style={{ float: "right" }}
                                        onClick={(event) =>
                                          handleSelectTagUnitReport(e)
                                        }
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            ))
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    ) : (
                      <Box className="Grid-text1 mt-2">
                        {!isShimmerReportUnitlist ? (
                          unitReport
                            .filter(
                              (e) =>
                                e.tag_unit &&
                                e.tag_unit
                                  .toLowerCase()
                                  .includes(unitSearchQuery.toLowerCase())
                            )
                            .map((e, index) => (
                              <Grid item xs={12} key={e.tag_unit}>
                                {e.tag_unit && (
                                  <Grid
                                    container
                                    alignItems="center"
                                    style={{
                                      background: "#efeffd",
                                      width: "100%",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={8}
                                      className="mt-2"
                                      style={{ padding: "7px 12px" }}
                                    >
                                      <h6>{e.tag_unit}</h6>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      textAlign="right"
                                      className="grid-switch-unit"
                                    >
                                      <Switch
                                        checked={
                                          deviceUnitReportRedux.length
                                            ? deviceUnitReportRedux.find(
                                                (f) =>
                                                  f.tagUnit == e.tag_unit &&
                                                  f.plantId == param.id
                                              )
                                              ? true
                                              : false
                                            : false
                                        }
                                        name={switchColors[index]}
                                        style={{ float: "right" }}
                                        onClick={handleTostMsg}
                                        sx={{
                                          "& .MuiSwitch-thumb": {
                                            bgcolor: "#828282",
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#D9D9E5 !important",
                                          },
                                          "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                              bgcolor:
                                                switchColors[
                                                  index % switchColors.length
                                                ],
                                            },
                                            "& .MuiSwitch-track": {
                                              bgcolor: "#E2D9F2 !important",
                                            },
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            ))
                        ) : (
                          <DeviceListShimmer itemCount={3} />
                        )}
                      </Box>
                    )}
                  </div>

                  <div className="mt-3">
                    <button
                      className="export-btn"
                      style={{ marginTop: "117px" }}
                      onClick={() => handleExportExcelClick()}
                    >
                      EXPORT ENERGY DATA
                    </button>
                  </div>
                </Box>
              </div>
            )}
          </Container>
        </>
      ) : (
        <>
          {showZoomChart ? (
            <ZoomInChart
              setShowOverviewchart={setShowOverviewchart}
              setShowZoomChart={setShowZoomChart}
              startDate={formatStartDate}
              endDate={formatEndDate}
              deviceIdRedux={deviceIdRedux}
              deviceUnitRedux={deviceUnitRedux}
              showTime={showTime}
              startTime={startTimeFormat}
              endTime={endTimeFormat}
              deviceColors={deviceColors}
              startDateReFormat={startDateReFormat}
              endDateReFormat={endDateReFormat}
            />
          ) : null}

          {showZoomPOPChart ? (
            <ZoomInPOPChart
              setShowOverviewchart={setShowOverviewchart}
              setShowZoomPOPChart={setShowZoomPOPChart}
              startDate={formatPOPStartDate}
              endDate={formatPOPEndDate}
              deviceIdPOPRedux={deviceIdPOPRedux}
              deviceUnitPOPRedux={deviceUnitPOPRedux}
              showTimePop={showTimePop}
              startTime={startPOPTimeFormat}
              endTime={endPOPTimeFormat}
              devicePOPColors={devicePOPColors}
              startDatePOPReFormat={startDatePOPReFormat}
              endDatePOPReFormat={endDatePOPReFormat}
              startDate1={formatPOP1StartDate}
              endDate1={formatPOP1EndDate}
              startDatePOP1ReFormat={startDatePOP1ReFormat}
              endDatePOP1ReFormat={endDatePOP1ReFormat}
              startTime1={startPOP1TimeFormat}
              endTime1={endPOP1TimeFormat}
            />
          ) : null}
        </>
      )}
      {/* </div> */}
      {customizedReportModal && (
        <Card className="customized-report-modal">
          <Box className="addDeviceModal-titleWrap">
            <Typography
              variant=""
              gutterBottom
              className="customizedModal-title"
            >
              On Demand Customized Report
            </Typography>
            <img
              src={images.closeIcon}
              alt=""
              className="customized-report-closeImg"
              onClick={() => handleCustomizedModal()}
            />
          </Box>
          <div className="customizedModal-data">
            <div className="customizedModal-border">
              <div className="customizedModal-text">Customized Report</div>
              <div>
                <FormGroup>
                  {plantData.length
                    ? plantData.map((e) => {
                        return (
                          <>
                            <FormControlLabel
                              key={e.id}
                              control={
                                <Checkbox
                                  checked={selectedPlant === e.id}
                                  onChange={() => handleCheckboxChange(e.id)}
                                  disabled={
                                    selectedPlant && selectedPlant !== e.id
                                  }
                                />
                              }
                              label={e.plant_name}
                            />
                          </>
                        );
                      })
                    : []}
                </FormGroup>
              </div>
            </div>
            <div
              className="customizedModal-timepicker"
              style={{ marginLeft: "20px" }}
            >
              <div className="customizedModal-text">Time</div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label="With Time Clock"
                      value={selectedTime}
                      className="custom-datepicker"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      onChange={(newTime) => setSelectedTime(newTime)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <button className="customizedModal-btn" onClick={customeReportAPI}>
            Submit
          </button>
        </Card>
      )}
    </>
  );
};
export default BPCLReports;

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const GuageChart = ({ chartData, percentage, colorCode, chart, selectedPlantId }) => {


 const adjustColorOpacity = (color, isSelected) => {
        if (isSelected) return color; // Keep normal color for selected plants
        const opacity = 0.2; // Adjust opacity for greyed-out effect
        return Highcharts.color(color).setOpacity(opacity).get();
    };

    // Map through chartData to create the data for the chart
    const data = chartData.map((item, index) => {
        const isSelected = selectedPlantId.includes(item.plant_id); // Check if plant_id is in selectedPlantId
        const color = isSelected
        ? colorCode[index] // Use the regular color
        : "#918c8c"; // Use greyed-out color for unchecked plants
         
        return {
            label: item.plant_name,
            value: chart === "utilization"
                ? Math.round((item.utilization_value || item.utilization_value) * 100) / 100 // Use utilization_value for utilization chart
                : Math.round((item.co2_green_value || item.co2_green_value) * 100) / 100, // Use co2_green_value for other charts
            color: color,
        };
    });

    const centerValue = `${(typeof percentage === 'number' && !isNaN(percentage) ? Math.round(percentage) : 0)}%`;

    const series = [
        {
            name: 'Value',
            data: data.map((item) => ({
                name: item.label,
                y: item.value,
                color: item.color,
            })),
        },
    ];

    const options = {
        chart: {
            type: 'pie',
            height: 250,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            itemStyle: {
                fontWeight: 'bold',
                fontSize: '12px',
            },
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                dataLabels: {
                  enabled: false,
                }
              }
        },
        series: series,
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '230px' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
            {data.length > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: '55%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '28px',
                        fontWeight: 'bold',
                        color: '#000',
                        textAlign: 'center',
                    }}
                >
                    {centerValue}
                </div>
            )}
        </div>
    );
};

export default GuageChart;

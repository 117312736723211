import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../config/images";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import {
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Paper,
  Popper,
} from "@material-ui/core";
import {
  getcompanyListPlantManegement,
  getPlantByCompany,
  addPlantData,
  editPlant,
  deletePlant,
  getDevicePlant,
  assignDeviceApi,
  DeviceByPlantApi,
  getAccessmanagement,
  getDeviceByCompany,
  getDeviceAssign,
  getGatewayAssign,
  getMachineData,
  getDevice,
  getDeviceData,
} from "../service";
import "./PlantManagement.css";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";
import { RedirectAdminDashboard } from "../../../components/CommonFunction/RedirectAdminDashboard";
import { ShimmerTable, ShimmerText } from "react-shimmer-effects";
import { SelectPlantShimmer } from "../../../components/Shimmer/SelectPlantShimmer/SelectPlantShimmer";
import ErrorIcon from "@mui/icons-material/Error";
import { SelectAssignDevice } from "../../../components/Shimmer/SelectPlantShimmer/SelectAssignDevice";

function PlantManagement() {
  const [isLoading, setLoading] = useState(false);
  const [selectedMachineId, setSelectedMachineId] = useState(null);
  const [selectCompany, setSelectCompany] = React.useState("");
  const [plantId, setPlantId] = useState("");
  const [openAddplant, setOpenAddplant] = useState(false);
  const [openAssignDeviceModal, setOpenAssignDeviceModal] = useState(false);
  const [openEditplant, setOpenEditplant] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [deviceLastSeenModal, setDeviceLastSeenModal] = useState({});
  const [searchDevice, setSearcDevice] = useState("");
  const [addPlant, setAddPlant] = useState({
    plant_name: "",
    plant_description: "",
    company: "",
    plant_location: "0,0",
    plant_capacity: "0",
  });
  const [selectedEnergyData, setSelectedEnergyData] = useState({
    is_renewable_energy: false,
    is_solar_energy: false,
    is_wind_energy: false,
  });

  console.log("selectedEnergyData", selectedEnergyData);
  const [editPlantData, setEditPlantData] = useState({
    plant_name: "",
    plant_description: "",
    company: "",
    plant_location: "",
    plant_capacity: "",
  });
  const [deviceList, setDeviceList] = useState([]);
  const [assignDeviceIdArray, setAssignDeviceIdArray] = useState([]);
  const [selectDeviceListArray, setSelectDeviceListArray] = useState([]);
  const [assignDevice, setAssignDevice] = useState([]);
  const [isEmptyDevice, setEmpatyDevice] = useState(false);
  const [assignGateway, setAssignGateway] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [machineData, setMachineData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [machineName, setMachineName] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [isShimmerAssignDevice, setisShimmerAssignDevice] = useState(false);
  const [isShimmerPlantList, setisShimmerPlantList] = useState(false);
  const [isRenewableSelected, setIsRenewableSelected] = useState(false);
  const [isNonRenewableSelected, setIsNonRenewableSelected] = useState(false);
  const [isRenewableSolar, setIsRenewableSolar] = useState(false);
  const [isRenewableWind, setIsNonRenewableWind] = useState(false);

  console.log("deviceData", isRenewableSolar,isRenewableWind);

  useEffect(() => {
    setAnchorEl(null);
  }, []);

  const openMachine = Boolean(anchorEl);
  const handleOpenModal = (i) => {
    setDeviceLastSeenModal({ [i]: true });
  };

  const handleCloseModal = (i) => {
    setDeviceLastSeenModal({ [i]: false });
  };

  const {
    companyId,
    companyName,
    companyDescription,
    is_super_admin,
    is_created_by_super_admin_user_company,
    user_access,
  } = useSelector((state) => state.userState);
  // useEffect(() => {
  //   if (selectCompany) {
  //     getDeviceByCompanyApi();
  //   }
  // }, [selectCompany]);

  useEffect(() => {
    // Find the specific plant that matches the plantId
    const matchedPlant = plantList.find((e) => e.id === plantId);

    if (matchedPlant) {
      // If the matchedPlant exists, check if its device array is empty
      setEmpatyDevice(matchedPlant.device.length === 0);
      setAssignDeviceIdArray(
        matchedPlant.device.length ? matchedPlant.device.map((e) => e.id) : []
      );
    }
  }, [plantList, plantId]);

  const handleClick = async (id, event) => {
    const params = {
      gateway: id,
    };
    try {
      const resp = await getMachineData(params);
      if (resp.status == 200 || resp.status == 201) {
        setMachineData(resp.data.payload);
        setSelectedGateway((prevState) => (prevState === id ? null : id));
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  // const getDeviceByCompanyApi = async () => {
  //   const param = {
  //     company: selectCompany,
  //   };
  //   const resp = await getDeviceAssign(param);
  //   if (resp.status == 200) {
  //     setAssignDevice(resp.data.payload);
  //   }
  // };

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessManagementPlant = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Plant Management"
        )
      : null
    : null;

  const selectCompanyName = companyList.find((f) => f.id == selectCompany);

  const navigate = useNavigate();

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleChangeCompany = (e) => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setSelectCompany(e.target.value);
    setDeviceData([]);
    setMachineData([]);
    setMachineName("");
    setSelectAll(false);
  };
  const handleTabValue = (e) => {
    console.log(e.target.id);
  };
  //Add plant modal
  const handleOpenaddPlantMdal = () => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setOpenDeleteModal(false);
    setOpenDeleteDeviceModal(false);
    setOpenAssignDeviceModal(false);
    setOpenEditplant(false);
    setOpenAddplant(!openAddplant);
  };

  const handleOpenaddPlantCloseMdal = () => {
    setOpenAddplant(!openAddplant);
  };
  //Assign Device modal
  const handleOPenAssignDeviceModal = () => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setOpenAddplant(false);
    if (!plantList.length) {
      notify("Please Add Plant.");
      return true;
    }

    setOpenAssignDeviceModal(!openAssignDeviceModal);
    setOpenDeleteModal(false);
    setOpenDeleteDeviceModal(false);
    setOpenEditplant(false);
    // getDeviceByCompanyApi();
    // getDeviceListApi();
  };

  const handleOPenAssignDeviceCloseModal = () => {
    setOpenAssignDeviceModal(!openAssignDeviceModal);
  };
  //Edit plant modal
  const handleEditModal = (e) => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setOpenEditplant(!openEditplant);
    setOpenDeleteModal(false);
    setOpenAssignDeviceModal(false);
    setOpenDeleteDeviceModal(false);
    setEditPlantData({
      plant_name: e.plant_name,
      plant_description: e.plant_description,
      plant_capacity: e.plant_capacity,
      plant_location: e.plant_location,
      company: "",
    });
    setPlantId(e.id);
  };

  const closePlantEditModel = () => {
    setOpenEditplant(!openEditplant);
  };

  //Delete plant modal
  const handleConfirmationModal = () => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setOpenEditplant(false);
    setOpenDeleteModal(!openDeleteModal);
    setOpenAssignDeviceModal(false);
    setOpenDeleteDeviceModal(false);
  };

  const handleConfirmationCloseModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  //Delete device modal
  const handleConfirmationDeleteDeviceModal = () => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    if (!isEmptyDevice) {
      setOpenDeleteDeviceModal(!openDeleteDeviceModal);
      setOpenEditplant(false);
      setOpenAssignDeviceModal(false);
      setOpenDeleteModal(false);
    }
  };

  const handleConfirmationDeleteCloseDeviceModal = () => {
    setOpenDeleteDeviceModal(!openDeleteDeviceModal);
  };

  // Add plant API
  // const handleChangePlantDetails = (e) => {
  //   setAddPlant({ ...addPlant, [e.target.name]: e.target.value });
  // };
  const handleChangePlantDetails = (e) => {
    const { name, value } = e.target;
    setAddPlant({ ...addPlant, [name]: value });
    // Regular expression to match valid latitude and longitude pattern
    const latLongRegex =
      /^-?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*-?((1[0-7]\d(\.\d+)?|180(\.0+)?)|(\d{1,2}(\.\d+)?))$/;

    // Check if the input matches latitude and longitude pattern
    if (latLongRegex.test(value)) {
      setAddPlant({ ...addPlant, [name]: value });
    } else {
      console.log("Invalid input, please enter valid latitude and longitude.");
    }
  };
  // select DeviceIdArray
  const handleSelectAssingDeviceId = (id) => {
    if (!Array.isArray(assignDeviceIdArray)) {
      setAssignDeviceIdArray([]);
    }
    if (assignDeviceIdArray.includes(id)) {
      setAssignDeviceIdArray(
        assignDeviceIdArray.filter((element) => element !== id)
      );
    } else {
      setAssignDeviceIdArray([...assignDeviceIdArray, id]);
    }
  };

  const handleSelectDeviceId = (id) => {
    if (!Array.isArray(selectDeviceListArray)) {
      setSelectDeviceListArray([]);
    }
    if (selectDeviceListArray.includes(id)) {
      setSelectDeviceListArray(
        selectDeviceListArray.filter((element) => element !== id)
      );
    } else {
      setSelectDeviceListArray([...selectDeviceListArray, id]);
    }
  };

  const submitAddPlant = async () => {
    if (addPlant.plant_name === "") {
      notify("Please Enter Plant Name.");
      return;
    }
    if (addPlant.plant_description === "") {
      notify("Please Enter Plant Description.");
      return;
    }
    const latLongRegex =
      /^-?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*-?((1[0-7]\d(\.\d+)?|180(\.0+)?)|(\d{1,2}(\.\d+)?))$/;

    // Validate plant_location (latitude and longitude)
    if (!latLongRegex.test(addPlant.plant_location)) {
      notify("Please Enter a valid Plant Location (Latitude, Longitude).");
      return;
    }

    if (!/^(-?\d+(\.\d+)?)?$/.test(addPlant.plant_capacity)) {
      notify("Please Enter a valid Plant Capacity (number only).");
      return;
    }
    const param = {
      plant_name: addPlant.plant_name,
      plant_description: addPlant.plant_description,
      plant_location: addPlant.plant_location,
      plant_capacity: addPlant.plant_capacity,
      company: selectCompany,
    };
    try {
      setLoading(true);
      const response = await addPlantData(param);
      if (response.data.success == true) {
        notifySuccess(response.data.message);
        setLoading(false);
        setOpenAddplant(false);
        // getcompanyListPlantManegementApi();
        setAddPlant({
          plant_name: "",
          plant_description: "",
          company: "",
          plant_location: "0,0",
          plant_capacity: "0",
        });
        getPlatListByComapnyAPI();
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };

  const getcompanyListPlantManegementApi = async () => {
    setisShimmerPlantList(true);

    try {
      const resp = await getcompanyListPlantManegement();
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerPlantList(false);
        }, 1000);

        if (!selectCompany) {
          //temp change by ut
          setSelectCompany(companyId);
        }
        setCompanyList(resp.data.payload);
      }
    } catch (error) {
      if (!selectCompany) {
        //temp change by ut
        setSelectCompany(companyId);
      }
      setisShimmerPlantList(false);

      console.log("handleSubmit", error);
    }
  };

  // update plant
  const handleChangePlantData = (e) => {
    setEditPlantData({ ...editPlantData, [e.target.name]: e.target.value });
  };

  // Update plant api
  const handleUpdatePlant = async () => {
    if (editPlantData.plant_name === "") {
      notify("Please Enter Plant Name.");
      return;
    }
    if (editPlantData.plant_description === "") {
      notify("Please Enter Plant Description.");
      return;
    }
    const latLongRegex =
      /^-?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*-?((1[0-7]\d(\.\d+)?|180(\.0+)?)|(\d{1,2}(\.\d+)?))$/;

    // Validate plant_location (latitude and longitude)
    if (!latLongRegex.test(editPlantData.plant_location)) {
      notify("Please Enter a valid Plant Location (Latitude, Longitude).");
      return;
    }

    if (!/^(-?\d+(\.\d+)?)?$/.test(editPlantData.plant_capacity)) {
      notify("Please Enter a valid Plant Capacity (number only).");
      return;
    }
    const params = {
      id: plantId,
      plant_name: editPlantData.plant_name,
      plant_description: editPlantData.plant_description,
      plant_location: editPlantData.plant_location,
      plant_capacity: editPlantData.plant_capacity,
    };
    try {
      setLoading(true);
      const response = await editPlant(plantId, params);
      if (response.status == 200 || response.status == 201) {
        notifySuccess(response.data.message);
        getPlatListByComapnyAPI();
        setOpenEditplant(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };

  // Delete plant
  const handleDeletePlant = async () => {
    const response = await deletePlant(plantId);
    if (response.status == 200 || response.status == 201) {
      // getcompanyListPlantManegementApi();
      notifySuccess(response.data.message);
      getPlatListByComapnyAPI();
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    getcompanyListPlantManegementApi();
  }, []);

  useEffect(() => {
    if (selectCompany) {
      getPlatListByComapnyAPIChnageCountry();
    }
  }, [selectCompany]);

  const getPlatListByComapnyAPI = async () => {
    setisShimmerPlantList(true);
    const param = {
      company: selectCompany,
    };
    try {
      // setLoading(true)
      const resp = await getPlantByCompany(param);
      // console.log("kgkfdg", resp);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setPlantList(resp.data.payload);
        setTimeout(() => {
          setisShimmerPlantList(false);
        }, 1000);

        if (plantId) {
          setPlantId(plantId);
        } else {
          setPlantId(resp.data.payload[0].id);
        }
      }
    } catch (error) {
      setisShimmerPlantList(false);
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  const getPlatListByComapnyAPIChnageCountry = async () => {
    setisShimmerAssignDevice(true);
    setisShimmerPlantList(true);
    const param = {
      company: selectCompany,
    };
    try {
      // setLoading(true)
      const resp = await getPlantByCompany(param);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setTimeout(() => {
          setisShimmerAssignDevice(false);
        }, 1000);
        setTimeout(() => {
          setisShimmerPlantList(false);
        }, 1000);

        setPlantList(resp.data.payload);
        if (resp.data.payload.length) {
          setPlantId(resp.data.payload[0].id);
        }
      }
    } catch (error) {
      setLoading(false);
      setisShimmerAssignDevice(false);
      setisShimmerPlantList(false);

      console.log("handleSubmit", error);
    }
  };

  // // getDeviceList
  // const getDeviceListApi = async () => {
  //   const resp = await getAccessmanagement(searchDevice);
  //   if (resp.status == 200) {
  //     setDeviceList(resp.data.payload);
  //   }
  // };

  // useEffect(() => {
  //   getDeviceListApi();
  // }, [searchDevice]);

  const deleteDeviceByPlant = async () => {
    const param = {
      plant: plantId,
      device: selectDeviceListArray,
    };

    if (!selectDeviceListArray.length) {
      notify("Please Select Device.");
      return true;
    }
    try {
      setLoading(true);
      const resp = await DeviceByPlantApi(param);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setOpenDeleteDeviceModal(false);
        getPlatListByComapnyAPI();
        setSelectDeviceListArray([]);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  const submitAssignDevice = async () => {
    // if (!assignDevice.length) {
    //   notify("device not found.");
    //   return true;
    // }
    const param = {
      plant: plantId,
      device: assignDeviceIdArray,
    };
    if (!assignDeviceIdArray.length) {
      notify("Please Select Device.");
      return true;
    }

    try {
      setLoading(true);
      const resp = await assignDeviceApi(param);

      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        getPlatListByComapnyAPI();
        setAssignDeviceIdArray([]);
        setOpenAssignDeviceModal(!openAssignDeviceModal);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };
  const handleTostMsg = () => {
    notify("You don't have access.");
  };

  useEffect(() => {
    handlegetGatewayAssign();
  }, [selectCompany]);

  const handlegetGatewayAssign = async () => {
    const params = {
      company: selectCompany,
    };
    try {
      const resp = await getGatewayAssign(params);
      if (resp.status == 200 || resp.status == 201) {
        setAssignGateway(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleMachineClick = async (id, e) => {
    setMachineName(e.machine_name);
    setSelectedMachineId(id);
    const params = {
      machine: id,
    };
    try {
      const resp = await getDeviceData(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceData(resp.data.payload);
        const getDeviceId = resp.data.payload.map((e) => e.id);

        if (getDeviceId.every((value) => assignDeviceIdArray.includes(value))) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
          // console.log("selected",selected);
        }
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  // const handleCheckBox = (id) => {
  //   if (assignDeviceIdArray.includes(id)) {
  //     setAssignDeviceIdArray(assignDeviceIdArray.filter((d) => d !== id));
  //   } else {
  //     setAssignDeviceIdArray([...assignDeviceIdArray, id]);
  //   }
  // };

  // const handleSelectAll = () => {
  //   if (selectAll) {
  //     // setAssignDeviceIdArray([]);
  //     setAssignDeviceIdArray(
  //       assignDeviceIdArray.filter((d) => !deviceData.map((e) => e.id).includes(d))
  //     );
  //   } else {
  //     const allDeviceIds = deviceData.map((e) => e.id);
  //     setAssignDeviceIdArray([...assignDeviceIdArray].concat(allDeviceIds));
  //   }
  //   setSelectAll(!selectAll);
  // };

  const handleCheckBox = (id, isRenewable,e) => {
 
    if(e.product_name === "HikEMM"){
      let updatedAssignDeviceIdArray;
  
      if (assignDeviceIdArray.includes(id)) {
        updatedAssignDeviceIdArray = assignDeviceIdArray.filter((d) => d !== id);
      } else {
        updatedAssignDeviceIdArray = [...assignDeviceIdArray, id];
      }
    
      setAssignDeviceIdArray(updatedAssignDeviceIdArray);
    
      if (updatedAssignDeviceIdArray.length === 0) {
        // Reset all states when no devices are selected
        setIsRenewableSelected(false);
        setIsNonRenewableSelected(false);
        setIsRenewableSolar(false);
        setIsNonRenewableWind(false);
      } else {
        const selectedDevices = deviceData.filter((device) =>
          updatedAssignDeviceIdArray.includes(device.id)
        );
    
        // Check if renewable energy is selected (solar or wind) for "HikEMM" product
        const hasRenewable = selectedDevices.some(
          (device) =>
            device.product_name === "HikEMM" &&
            device.is_renewable_energy &&
            (device.is_solar_energy || device.is_wind_energy)
        );
    
        // Check if non-renewable energy is selected for "HikEMM"
        const hasNonRenewable = selectedDevices.some(
          (device) =>
            device.product_name === "HikEMM" &&
            !device.is_renewable_energy
        );
    
        // Check for solar and wind separately
        const isSolar = selectedDevices.some(
          (device) => device.is_solar_energy && device.product_name === "HikEMM"
        );
        const isWind = selectedDevices.some(
          (device) => device.is_wind_energy && device.product_name === "HikEMM"
        );
    console.log("hasRenewable",hasRenewable)
        setIsRenewableSolar(isSolar);
        setIsNonRenewableWind(isWind);
        setIsRenewableSelected(hasRenewable);
        setIsNonRenewableSelected(hasNonRenewable);
      }
    }
else{
  let updatedAssignDeviceIdArray;
    if (assignDeviceIdArray.includes(id)) {
      updatedAssignDeviceIdArray = assignDeviceIdArray.filter((d) => d !== id);
    } else {
      updatedAssignDeviceIdArray = [...assignDeviceIdArray, id];
    }

    // Check if all devices are selected
    const allDevicesSelected = deviceData.every((device) =>
      updatedAssignDeviceIdArray.includes(device.id)
    );

    setAssignDeviceIdArray(updatedAssignDeviceIdArray);
    setSelectAll(allDevicesSelected);
  };

  };
  

  const handleSelectAll = () => {
    if (selectAll) {
      // setAssignDeviceIdArray([]);
      setAssignDeviceIdArray(
        assignDeviceIdArray.filter(
          (d) => !deviceData.map((e) => e.id).includes(d)
        )
      );
    } else {
      const allDeviceIds = deviceData.map((e) => e.id);
      setAssignDeviceIdArray([...assignDeviceIdArray].concat(allDeviceIds));
    }
    setSelectAll(!selectAll);
  };

  return (
    <>
      <div>{/*  */}</div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => {
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#222D39",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
              >
                Plant Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              {(accessManagementPlant?.is_viewer &&
                accessManagementPlant?.is_editor) ||
              (!accessManagementPlant?.is_viewer &&
                accessManagementPlant?.is_editor) ? (
                <button
                  className="addDevice-button plant"
                  onClick={handleOpenaddPlantMdal}
                >
                  Add Plant
                </button>
              ) : (
                <button
                  className="addDevice-button plant"
                  onClick={handleTostMsg}
                >
                  Add Plant
                </button>
              )}
            </div>
          </div>
          {/* =============== Add Plant Modal Start =============== */}
          <ValidatorForm onSubmit={submitAddPlant}>
            {openAddplant && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Add Plant
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleOpenaddPlantCloseMdal}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      label="Plant Name"
                      name="plant_name"
                      variant="filled"
                      className="addplantInputfield"
                      InputProps={{}}
                      onChange={handleChangePlantDetails}
                    />
                    <TextField
                      id="filled-basic"
                      label="Plant Description"
                      name="plant_description"
                      variant="filled"
                      className="addplantInputfield"
                      InputProps={{}}
                      onChange={handleChangePlantDetails}
                    />
                    <TextField
                      id="filled-basic"
                      label="Plant Location"
                      name="plant_location"
                      variant="filled"
                      className="addplantInputfield"
                      InputProps={{}}
                      value={addPlant.plant_location}
                      onChange={handleChangePlantDetails}
                    />
                    <TextField
                      id="filled-basic"
                      label="Capacity"
                      name="plant_capacity"
                      variant="filled"
                      className="addplantInputfield"
                      InputProps={{}}
                      onChange={handleChangePlantDetails}
                      value={addPlant.plant_capacity}
                    />
                    {/* <TextField id="filled-basic" label="Iron and Steel Industry" variant="filled" className='addplantInputfield' InputProps={{
                                }} /> */}
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button className="addPlantBtn">Add</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
          {/* =============== Add Plant Modal End =============== */}
          {/* =============== Edit Plant Modal Start =============== */}
          <ValidatorForm onSubmit={handleUpdatePlant}>
            {openEditplant && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Edit Plant
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={closePlantEditModel}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="plant_name"
                      label="Plant Name"
                      variant="filled"
                      className="addplantInputfield"
                      value={editPlantData.plant_name}
                      onChange={handleChangePlantData}
                      InputProps={{}}
                    />
                    <TextField
                      id="filled-basic"
                      name="plant_description"
                      label="Plant Description"
                      variant="filled"
                      className="addplantInputfield"
                      value={editPlantData.plant_description}
                      onChange={handleChangePlantData}
                      InputProps={{}}
                    />
                    <TextField
                      id="filled-basic"
                      name="plant_location"
                      label="Plant Location"
                      variant="filled"
                      className="addplantInputfield"
                      value={editPlantData.plant_location}
                      onChange={handleChangePlantData}
                      InputProps={{}}
                    />
                    <TextField
                      id="filled-basic"
                      name="plant_capacity"
                      label="Plant Capacity"
                      variant="filled"
                      className="addplantInputfield"
                      value={editPlantData.plant_capacity}
                      onChange={handleChangePlantData}
                      InputProps={{}}
                    />
                    {/* <TextField id="filled-basic" label="Iron and Steel Industry" variant="filled" className='addplantInputfield' InputProps={{
                                }} /> */}
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button className="addPlantBtn">Update</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
          {/* =============== Edit Plant Modal  =============== */}
          {/* =============== Delete Plant Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleDeletePlant}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationCloseModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {openDeleteDeviceModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={deleteDeviceByPlant}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationDeleteCloseDeviceModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Plant Modal End =============== */}
          {/* =============== Assign Device Modal Start =============== */}
          <ValidatorForm onSubmit={submitAssignDevice}>
            {openAssignDeviceModal && (
              <>
                <div className="select-gateway-popup">
                  {/* <div className="d-flex devices-text"> */}
                  <div style={{ display: "flex" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={5}>
                        <h5 style={{ color: "#1C407B" }}>
                          <b>Assign Device</b>
                        </h5>
                      </Grid>
                      <Grid item xs={12} sm={7}></Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={2} className="pt-2">
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box className="devicemodal-box">
                        <h6 style={{ fontWeight: 600 }}>Select Gateway</h6>
                        <div
                          style={{
                            height: "420px",
                            overflow: "auto",
                            position: "relative",
                          }}
                        >
                          {assignGateway.length ? (
                            assignGateway.map((gateway, index) => (
                              <div key={gateway.id}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "13px 8px",
                                    background: "white",
                                    cursor: "pointer",
                                    marginTop: index > 0 ? "4px" : "0", // Add margin top for all but the first gateway
                                  }}
                                  onClick={() => handleClick(gateway.id)}
                                >
                                  {gateway.gateway_name} <ArrowDropDownIcon />
                                </div>
                                {selectedGateway === gateway.id && (
                                  <div
                                    style={{
                                      maxHeight: "300px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {machineData.map((machine) => (
                                      <div
                                        key={machine.id}
                                        style={{
                                          position: "relative",
                                          padding: "7px 25px",
                                          cursor: "pointer",
                                          boxShadow: "0px 6px 20px #6D6D6D1A",
                                          background: "#ffff",
                                          borderRight:
                                            selectedMachineId === machine.id
                                              ? "3px solid #1c407b"
                                              : "none",
                                        }}
                                        onClick={() =>
                                          handleMachineClick(
                                            machine.id,
                                            machine
                                          )
                                        }
                                      >
                                        <h6 className="machine-title">
                                          {machine.machine_name}
                                        </h6>
                                        <p className="machine-description">
                                          {machine.uid}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className="gateway-error-div">
                              <ErrorIcon className="gateway-error-icon" />
                              <h5 className="mt-2">No data found.</h5>
                            </div>
                          )}
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box className="devicemodal-box">
                        <div className="d-flex justify-content-between">
                          {machineName ? (
                            <h6 style={{ fontWeight: 600 }}>
                              {machineName} - Device List
                            </h6>
                          ) : (
                            <h6></h6>
                          )}
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{
                                    color: "#1C407B",
                                    padding: "0px",
                                    marginRight: "3px",
                                  }}
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              }
                              label={
                                <Typography style={{ fontSize: "15px" }}>
                                  All
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </div>

                        <div
                          className="pt-2 mb-2"
                          style={{
                            height: "420px",
                            overflow: "auto",
                          }}
                        >
                          {deviceData.length ? (
                            deviceData.map((e) => {
                              const isChecked = assignDeviceIdArray.includes(
                                e.id
                              );
                              const isRenewable = e.is_renewable_energy;

                        
                            const isDisabled =
  e.is_assigned || // Disable if already assigned
  (e.product_name === "HikEMM" &&
    (
      (isRenewableSolar && !e.is_solar_energy) || // Disable non-solar if solar is selected
      (isRenewableWind && !e.is_wind_energy) ||   // Disable non-wind if wind is selected
      (isRenewableSelected && !isRenewable) ||    // Disable non-renewable if renewable is selected
      (isNonRenewableSelected && isRenewable)     // Disable renewable if non-renewable is selected
    )
  );

                              return (
                                <div
                                  className="assignDeviceModalWrap mb-2"
                                  style={{ background: "white" }}
                                >
                                  <div className="assignDeviceDetails">
                                    <div className="assignDeviceDetailsWrap">
                                      <div>
                                        <Checkbox
                                          style={{
                                            color: "#1C407B",
                                          }}
                                          {...label}
                                          checked={isChecked}
                                          disabled={e.is_assigned && isDisabled}
                                          onClick={() =>
                                            handleCheckBox(e.id, isRenewable,e)
                                          }
                                        />
                                      </div>
                                      <div>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          className="assignDeviceDetailsTitle"
                                        >
                                          {e.device_name}
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          gutterBottom
                                          className="assignDeviceDetailsDisc"
                                        >
                                          {e.device_description}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="gateway-error-div">
                              <ErrorIcon className="gateway-error-icon" />
                              <h5 className="mt-2">No data found.</h5>
                            </div>
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>

                  <div className="text-center pt-5 confi-btn-data">
                    <button
                      className="canclebtn"
                      onClick={() => setOpenAssignDeviceModal(false)}
                      // onClick={(e) => handleCloseConfigrationClick(e)}
                    >
                      Cancel
                    </button>
                    <button
                      className="submitbtn"
                      // onClick={() => submitAssignDevice()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
          </ValidatorForm>
          {/* =============== Assign Device Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Company</b>

                      {(accessManagementPlant?.is_viewer &&
                        accessManagementPlant?.is_editor) ||
                      (!accessManagementPlant?.is_viewer &&
                        accessManagementPlant?.is_editor) ? (
                        <FormControl className="selectCompany-dropDown">
                          <Select
                            value={selectCompany}
                            onChange={handleChangeCompany}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={companyId}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span> {companyName}</span>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  style={{ marginBottom: "0px" }}
                                >
                                  {companyDescription}
                                </Typography>
                              </div>
                            </MenuItem>
                            {is_super_admin ||
                            (is_created_by_super_admin_user_company &&
                              companyList.length)
                              ? companyList.map((e) => {
                                  return (
                                    <MenuItem value={e.id} key={e.id}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span>{e.company_name}</span>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          style={{ marginBottom: "0px" }}
                                        >
                                          {e.company_description}
                                        </Typography>
                                      </div>
                                    </MenuItem>
                                  );
                                })
                              : []}
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl className="selectCompany-dropDown">
                          <Select
                            value={selectCompany}
                            onChange={handleTostMsg}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={companyId}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span> {companyName}</span>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  style={{ marginBottom: "0px" }}
                                >
                                  {companyDescription}
                                </Typography>
                              </div>
                            </MenuItem>
                            {is_super_admin ||
                            (is_created_by_super_admin_user_company &&
                              companyList.length)
                              ? companyList.map((e) => {
                                  return (
                                    <MenuItem value={e.id} key={e.id}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span>{e.company_name}</span>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          style={{ marginBottom: "0px" }}
                                        >
                                          {e.company_description}
                                        </Typography>
                                      </div>
                                    </MenuItem>
                                  );
                                })
                              : []}
                          </Select>
                        </FormControl>
                      )}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Plant</b>
                    </Typography>

                    <div
                      className="manageDepartment-wrap"
                      style={{ height: "538px", overflow: "auto" }}
                    >
                      {!isShimmerPlantList ? (
                        plantList.length ? (
                          plantList.map((e, i) => {
                            return (
                              <div>
                                {(accessManagementPlant?.is_viewer &&
                                  accessManagementPlant?.is_editor) ||
                                (!accessManagementPlant?.is_viewer &&
                                  accessManagementPlant?.is_editor) ? (
                                  <>
                                    <div
                                      className="plantmanagementDepartment-fieldWrap"
                                      onClick={() => {
                                        if (
                                          openAddplant ||
                                          openAssignDeviceModal ||
                                          openEditplant ||
                                          openDeleteModal ||
                                          openDeleteDeviceModal
                                        ) {
                                          return true;
                                        }
                                        setPlantId(e.id);
                                        setSelectedEnergyData({
                                          is_renewable_energy:
                                            e.is_renewable_energy,
                                          is_solar_energy: e.is_solar_energy,
                                          is_wind_energy: e.is_wind_energy,
                                        });
                                      }}
                                    >
                                      {plantId == e.id && (
                                        <img
                                          src={images.activeDeviceimg}
                                          alt=""
                                          className="activeDeviceimg"
                                        />
                                      )}
                                      <div
                                        className="manageDepartment-field"
                                        id="1"
                                        onClick={(e) => handleTabValue(e)}
                                      >
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          style={{
                                            margin: "0",
                                            color: "#001323",
                                          }}
                                          id="1"
                                          onClick={(e) => handleTabValue(e)}
                                        >
                                          <b
                                            id="1"
                                            onClick={(e) => handleTabValue(e)}
                                          >
                                            {e.plant_name}
                                          </b>
                                          <Typography
                                            variant="body2"
                                            gutterBottom
                                            id="1"
                                            onClick={(e) => handleTabValue(e)}
                                          >
                                            {e.plant_description
                                              ? e.plant_description
                                              : "-"}
                                          </Typography>
                                        </Typography>
                                        <div className="manageDepartment-fieldIcon">
                                          <img
                                            src={images.editImg}
                                            alt=""
                                            style={{
                                              width: "25x",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => handleEditModal(e)}
                                          />
                                          <img
                                            src={images.deleteImg}
                                            alt=""
                                            style={{
                                              width: "25px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handleConfirmationModal}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="plantmanagementDepartment-fieldWrap"
                                      onClick={() => {
                                        if (
                                          openAddplant ||
                                          openAssignDeviceModal ||
                                          openEditplant ||
                                          openDeleteModal ||
                                          openDeleteDeviceModal
                                        ) {
                                          return true;
                                        }
                                        setPlantId(e.id);
                                      }}
                                    >
                                      {plantId == e.id && (
                                        <img
                                          src={images.activeDeviceimg}
                                          alt=""
                                          className="activeDeviceimg"
                                        />
                                      )}
                                      <div
                                        className="manageDepartment-field"
                                        id="1"
                                        onClick={(e) => handleTabValue(e)}
                                      >
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          style={{
                                            margin: "0",
                                            color: "#001323",
                                          }}
                                          id="1"
                                          onClick={(e) => handleTabValue(e)}
                                        >
                                          <b
                                            id="1"
                                            onClick={(e) => handleTabValue(e)}
                                          >
                                            {e.plant_name}
                                          </b>
                                          <Typography
                                            variant="body2"
                                            gutterBottom
                                            id="1"
                                            onClick={(e) => handleTabValue(e)}
                                          >
                                            {e.plant_description
                                              ? e.plant_description
                                              : "-"}
                                          </Typography>
                                        </Typography>
                                        <div className="manageDepartment-fieldIcon">
                                          <img
                                            src={images.editImg}
                                            alt=""
                                            style={{
                                              width: "25x",
                                              cursor: "pointer",
                                            }}
                                            onClick={handleTostMsg}
                                          />
                                          <img
                                            src={images.deleteImg}
                                            alt=""
                                            style={{
                                              width: "25px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handleTostMsg}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="plant-error-div">
                            <ErrorIcon className="plant-error-icon" />
                            <h5 className="mt-2">Plant not found.</h5>
                          </div>
                        )
                      ) : (
                        <>
                          {[...Array(5)].map((_, index) => (
                            <SelectPlantShimmer />
                          ))}
                        </>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Assign Device</b>
                    </Typography>
                    <div className="plantmanagementDepartment-card">
                      <Box className="plantmanagementDepartment-addDevicewrap">
                        {(accessManagementPlant?.is_viewer &&
                          accessManagementPlant?.is_editor) ||
                        (!accessManagementPlant?.is_viewer &&
                          accessManagementPlant?.is_editor) ? (
                          <>
                            <div
                              onClick={handleOPenAssignDeviceModal}
                              className="plantmanagementDepartment-assignDeviceTextWrap"
                            >
                              <Typography
                                variant="caption"
                                display="lock"
                                gutterBottom
                                className="plantmanagemetDepartment-assignDeviceText"
                              >
                                Assign Device
                              </Typography>
                              <img
                                src={images.addIcon}
                                alt=""
                                className="addDeviceImg"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </>
                        ) : (
                          <div
                            onClick={handleTostMsg}
                            className="plantmanagementDepartment-assignDeviceTextWrap"
                          >
                            <Typography
                              variant="caption"
                              display="lock"
                              gutterBottom
                              className="plantmanagemetDepartment-assignDeviceText"
                            >
                              Assign Device
                            </Typography>
                            <img
                              src={images.addIcon}
                              alt=""
                              className="addDeviceImg"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        )}

                        {/* {(accessManagementPlant?.is_viewer &&
                          accessManagementPlant?.is_editor) ||
                        (!accessManagementPlant?.is_viewer &&
                          accessManagementPlant?.is_editor) ? (
                          <img
                            src={images.editImg}
                            alt=""
                            className="addDeviceImg"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                openAddplant ||
                                openAssignDeviceModal ||
                                openEditplant ||
                                openDeleteModal ||
                                openDeleteDeviceModal ||
                                isEmptyDevice
                              ) {
                                return true;
                              }
                              navigate("/deviceManagement");
                            }}
                          />
                        ) : (
                          <img
                            src={images.editImg}
                            alt=""
                            className="addDeviceImg"
                            style={{ cursor: "pointer" }}
                            onClick={handleTostMsg}
                          />
                        )} */}

                        {(accessManagementPlant?.is_viewer &&
                          accessManagementPlant?.is_editor) ||
                        (!accessManagementPlant?.is_viewer &&
                          accessManagementPlant?.is_editor) ? (
                          <img
                            src={images.deleteImg}
                            alt=""
                            className="addDeviceImg"
                            style={{ cursor: "pointer" }}
                            onClick={handleConfirmationDeleteDeviceModal}
                          />
                        ) : (
                          <img
                            src={images.deleteImg}
                            alt=""
                            className="addDeviceImg"
                            style={{ cursor: "pointer" }}
                            onClick={handleTostMsg}
                          />
                        )}
                      </Box>
                      {!isShimmerAssignDevice ? (
                        plantList.length ? (
                          plantList.map((e, i) => {
                            if (e.id == plantId) {
                              return e.device.map((e1) => {
                                return (
                                  <Card className="addDeviceDetailsCard gap">
                                    <Box className="addDeviceCardDetailsWrap">
                                      <Box className="addDeviceDetailsCardetails-wrap">
                                        <Checkbox
                                          {...label}
                                          className="signIncheckBox"
                                          defaultChecked={false}
                                          onClick={() =>
                                            handleSelectDeviceId(e1.id)
                                          }
                                        />
                                        <Box className="addDeviceDetailsCardetails">
                                          <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                          >
                                            <b>{e1.device_name}</b>
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className="addDeviceDetailsCardetails-text"
                                          >
                                            UID - {e1.machine_uid}
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className="addDeviceDetailsCardetails-text"
                                          >
                                            {e1.device_description}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {(accessManagementPlant?.is_viewer &&
                                        accessManagementPlant?.is_editor) ||
                                      (!accessManagementPlant?.is_viewer &&
                                        accessManagementPlant?.is_editor) ? (
                                        <>
                                          <div>
                                            {!e1.is_configure ? (
                                              <img
                                                src={images.alertImage}
                                                alt=""
                                                className="alarmManagement groupIcon"
                                                onMouseEnter={() =>
                                                  handleOpenModal(e1.id)
                                                }
                                                onMouseLeave={() =>
                                                  handleCloseModal(e1.id)
                                                }
                                              />
                                            ) : null}
                                            {deviceLastSeenModal[e1.id] && (
                                              <Card className="clientManagement-updateModal-data plant-management-update-data top">
                                                <Box className="clientMangement-updatedataWrap-data">
                                                  <div>
                                                    <h6
                                                      className="mb-0"
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Configuration Pending..
                                                    </h6>
                                                  </div>
                                                </Box>
                                              </Card>
                                            )}

                                            <button
                                              className="addDeviceConfi-btn"
                                              onClick={() => {
                                                if (
                                                  openAddplant ||
                                                  openAssignDeviceModal ||
                                                  openEditplant ||
                                                  openDeleteModal ||
                                                  openDeleteDeviceModal
                                                ) {
                                                  return true;
                                                }
                                                // navigate(
                                                //   `/deviceManagement/configure/machine/${e1.machine}`
                                                // );
                                                navigate(
                                                  `/plantmanagement/deviceManagement/configure/machine/${e1.machine}`
                                                );
                                              }}
                                            >
                                              Configuration
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <button
                                          className="addDeviceConfi-btn"
                                          onClick={handleTostMsg}
                                        >
                                          Configuration
                                        </button>
                                      )}

                                      {/* <button className='addDeviceConfi-btn' onClick={() => navigate('/deviceManagement/configure/device/:id')}>Configuration</button> */}
                                    </Box>
                                  </Card>
                                );
                              });
                            }
                          })
                        ) : (
                          <div className="configured-error-div">
                            <ErrorIcon className="configured-error-icon" />
                            <h5 className="mt-2">No configuration found.</h5>
                          </div>
                        )
                      ) : (
                        <>
                          {[...Array(7)].map((_, index) => (
                            <SelectAssignDevice />
                          ))}
                        </>
                      )}
                    </div>

                    {(accessManagementPlant?.is_viewer &&
                      accessManagementPlant?.is_editor) ||
                    (!accessManagementPlant?.is_viewer &&
                      accessManagementPlant?.is_editor) ? (
                      <button
                        className="Plantmanagemet submit"
                        onClick={() => {
                          if (
                            openAddplant ||
                            openAssignDeviceModal ||
                            openEditplant ||
                            openDeleteModal ||
                            openDeleteDeviceModal
                          ) {
                            return true;
                          }
                          {
                            is_super_admin
                              ? navigate("/clientmanagement")
                              : navigate("/admindashboard");
                          }
                        }}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="Plantmanagemet submit"
                        onClick={handleTostMsg}
                      >
                        Submit
                      </button>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Container>
        {isLoading && <Loader />}
      </div>
    </>
  );
}

export default PlantManagement;

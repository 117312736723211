import React, { useState, useEffect, useMemo } from "react";
import { BPCLPlant } from "../../CompanyDashboard/services";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
  Box,
  Grid,
  Chip,
  Menu,
} from "@mui/material";
import "./BPCL-soloar-plantDashboard.css";
import MapComponent1 from "../../../components/BPCL/PlantLocation/Map1";
import Datepicker3 from "../../../components/BPCL/Datepicker/Datepicker3";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { images } from "../../../config";
import PieChart from "../../../components/BPCL/Chart/PieChart";
import CombinedChart from "../../../components/BPCL/Chart/LineBarChart";
import AreaChart from "../../../components/BPCL/Chart/AreaChart";
import GuageChart from "../../../components/BPCL/Chart/GuageChart";
import OverViewMachineMqttData from "../../SmartTag/MachineDashboard/mqttDataMachineDashboard/BpclmqttData";
import { SoftTagFormula } from "../../SmartTag/softTag/SoftTagFormula";
import {
  getHikemmREPlantDashboardGenerationCount,
  getHikemmREPlantCapacity,
  getHikemmREPlantDashboardCO2e,
  getHikemmREPlantDashboardCostSaving,
  getHikemmREDashboardTagsData,
  getHikemmREPlantDashboardCo2Count,
  getHikemmREPlantDashboardUtilizationCount,
  getHikemmREDashboardDailyGeneration,
  getCapacityGenerationTrend,
  getHikEMMREPlantDetails,
} from "../services";
import { useSelector } from "react-redux";
import LivePlantCombineChart from "../../../components/BPCL/Chart/LiveLineBarChart";
import SinglePlantCombineChart from "../../../components/BPCL/Chart/SingleLineBarChart";
import { ToastContainer, toast } from "react-toastify";
import Marquee from "react-fast-marquee";
import { cubejsApi } from "../../Cubejs/CubejsApiWrapper";

const SolarBPCLPlantDashboard = () => {
  const [active, setActive] = useState("");
  const [selectTimeRange, setSelectTimeRange] = useState("1min");
  const [plantData, setPlantData] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [SelctedplantLocation, setSelectedPlantLocation] = useState("");
  const [selectedPlantName, setSelectedPlantName] = useState("");
  const [selectedPlantImage, setSelectedPlantImage] = useState("");
  const [selectedPlantId, setSelectedPlantId] = useState([]);
  const [checkBoxPlantId, setCheckBoxPlantId] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [idleTimeout, setIdleTimeout] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSolarFullScreen, setIsFullscreen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showInfo1, setShowInfo1] = useState(false);
  const [showInfo2, setShowInfo2] = useState(false);
  const [showInfo3, setShowInfo3] = useState(false);
  const [dailyGenStartDate, setDailyGenStartDate] = useState(null);
  const [dailyGenEndDate, setDailyGenEndDate] = useState(null);
  const [tagListDataGroupAndList, setTagListDataGroupAndList] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [topics, setTopics] = useState([]);
  const [realTimeData, setRealTimeData] = useState([]);
  const [chartRealTimeData, setChartRealTimeData] = useState([]);
  const [realTimePlantDta, setRealTimePlantData] = useState([]);
  const [chartRealTimePlantDta, setChartRealTimePlantData] = useState([]);
  const { selectTimeZoneProfile } = useSelector((state) => state.userState);
  const [sliderRealTimeTagData, setSliderRealTimeTagData] = useState([]);
  const [cubeData, setCubeData] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [hikemmRePlantDetails, setHikemmRePlantDetails] = useState([]);
  const [isOthersChecked, setIsOthersChecked] = useState(false);
  const [capacity, setCapacity] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const params = useParams();

  console.log("realTimePlantDta", realTimePlantDta);
  const { headerName, headerImageSrc, isFullscreen } = useSelector(
    (state) => state.headerState
  );
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const mergedArray = mqttTagData
    ? [].concat(...Object.values(mqttTagData))
    : [];

  const mqttDataReduce = mergedArray.reduce((map, item) => {
    if (!map[item.device__uid]) {
      map[item.device__uid] = {};
    }
    map[item.device__uid][item.device_tag] = Number(item.tagValue);
    return map;
  }, {});


  const handleDropdown = (event) => {
    setSelectTimeRange(event.target.value);
  };

  const getTagTime = () => {
    return new Date().toLocaleTimeString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  useEffect(() => {
    setCubeData([]);
  }, [selectTimeRange]);
  const executeCubeQuery = async () => {
    try {
      let currentTime;

      // Simplify the time logic, as it's the same for all time ranges
      if (["1min", "5min", "15min", "Hour"].includes(selectTimeRange)) {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      const mwTags = chartRealTimeData.filter((row) => row.tag_unit === "MW");

      // Fetch the data from Cube.js
      const chartGraph = await cubejsApi.load({
        dimensions: [
          "DeviceConfigurationTagconfiguration.latestValue",
          "DeviceConfigurationTagconfiguration.tagName",
          "DeviceConfigurationTagconfiguration.deviceId",
        ],
        filters: [
          {
            member: "DeviceConfigurationTagconfiguration.tagid",
            operator: "equals",
            values: mwTags.length
              ? mwTags.map((row) => row.tag_id.toString()) // Convert tag_id to string
              : [],
          },
        ],
        limit: 4,
      });

      // Extract the pivoted data once
      const chartData = chartGraph.tablePivot() || [];

      console.log("mwTags", mwTags);

      // Process the fetched data
      const processedData = chartData.map((row, i) => {
        // Find the plant that matches the deviceId
        const plant = realTimePlantDta.find((plant) =>
          plant.tags.some(
            (tag) =>
              tag.device === row["DeviceConfigurationTagconfiguration.deviceId"]
          )
        );

        console.log("plant", plant);

        // If a matching plant is found, assign its name
        const plantName = plant ? plant.plant_name : "Unknown"; // Default to "Unknown" if no match

        return {
          plant_name: plantName, // Use the matched plant name
          plantId: plant.plant_id,
          color: (() => {
            // Find the index of the plant in plantData
            const plantIndex = plantData.findIndex(
              (p) => p.id === plant.plant_id
            );
            // Use the index to fetch the color from colorCode
            return plantIndex !== -1 ? colorCode[plantIndex] : "#cccccc"; // Fallback color if no match
          })(),
          tags: [
            {
              tag_name: row["DeviceConfigurationTagconfiguration.tagName"],
              data: row["DeviceConfigurationTagconfiguration.latestValue"],
              tagTime: currentTime, // Set tagTime here based on current time
              // plantName: plantName, // Pass plantName here as well
            },
          ],
        };
      });
      // Update the state with the new data added to the previous data
      setCubeData((prev) => {
        // Create a shallow copy of the previous state
        const updatedData = [...prev];

        // Loop through the new data to check if it exists in the previous state
        processedData.forEach((newData) => {
          const existingPlantIndex = updatedData.findIndex(
            (plant) => plant.plant_name === newData.plant_name
          );

          if (existingPlantIndex !== -1) {
            const existingPlant = updatedData[existingPlantIndex];
            const existingTags = existingPlant.tags;

            // Check if the tag already exists and whether the tagTime matches
            const tagExists = existingTags.some(
              (tag) =>
                tag.tag_name === newData.tags[0].tag_name &&
                tag.data === newData.tags[0].data &&
                tag.tagTime === newData.tags[0].tagTime // Compare the tagTime as well
            );

            if (!tagExists) {
              // Add new tag to the existing tags array if it doesn't exist
              existingTags.push(newData.tags[0]);
            }
          } else {
            // If the plant doesn't exist, add it to the updated data
            updatedData.push(newData);
          }
        });

        return updatedData; // Return the updated state, keeping both old and new data
      });
    } catch (error) {
      console.error("Cube query error:", error);
    }
  };
  console.log("cubeDatarrrr", cubeData, plantData);

  useEffect(() => {
    if (chartRealTimeData.length) {
      console.log("kpkpkpkpkpkpkkpk");
      // Function to execute the cube query

      // Set interval to execute the cube query based on selectTimeRange
      let interval = null;
      switch (selectTimeRange) {
        case "1min":
          interval = setInterval(executeCubeQuery, 60000);
          break;
        case "5min":
          interval = setInterval(executeCubeQuery, 300000);
          break;
        case "15min":
          interval = setInterval(executeCubeQuery, 900000);
          break;
        case "Hour":
          interval = setInterval(executeCubeQuery, 3600000);
          break;
        default:
          clearInterval(interval); // Clear interval if selectTimeRange is not supported
      }

      // Execute the cube query immediately
      executeCubeQuery();

      // Clean up the interval when the component unmounts or when selectTimeRange changes
      return () => {
        clearInterval(interval);
      };
    }
  }, [chartRealTimeData, selectTimeRange]);

  useEffect(() => {
    getPlantByCompany();
  }, [active]);

  const getPlantByCompany = async () => {
    try {
      const param = {
        dashboard: 1,
        company: params.id,
      };

      const resp = await BPCLPlant(param, active);
      if (resp.status == 200) {
        setPlantData(resp.data.payload);
        setAllPlant(resp.data.payload.map((e) => e.plant_location));
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };
  // Initially select all plants
  useEffect(() => {
    if (isAllSelected) {
      const allPlantIDs = plantData.map((plant) => plant.id);
      setSelectedPlantId(["all", ...allPlantIDs]);
      setCheckBoxPlantId(allPlantIDs);
    }
  }, [isAllSelected, plantData]);

  const handlePlantSelected = (plantIds) => {
    console.log("Selected Plant IDs:", plantIds);

    const allPlantIDs = plantData.map((plant) => plant.id);

    // If "All Plants" is selected
    if (plantIds.includes("all")) {
      if (isAllSelected) {
        // Unselect all if "All Plants" is already selected
        setIsAllSelected(false);
        setSelectedPlantId([]); // Deselect all plants
        setCheckBoxPlantId([]); // Deselect checkboxes
        setIsOthersChecked(false); // Ensure "Others" is deselected when "All Plants" is unselected
        setHikemmRePlantDetails([]);
      } else {
        // Select all if "All Plants" is not selected
        setIsAllSelected(true);
        setSelectedPlantId(["all", ...allPlantIDs]);
        setCheckBoxPlantId(allPlantIDs);
        setIsOthersChecked(false); // Ensure "Others" is deselected when "All Plants" is selected
        setHikemmRePlantDetails([]);
      }
    }
    // If "Others" is selected
    else if (plantIds.includes("others")) {
      setIsOthersChecked(!isOthersChecked); // Toggle "Others"
      if (!isOthersChecked) {
        setSelectedPlantId(["others"]);
        setCheckBoxPlantId([]); // Reset other checkboxes
        setIsAllSelected(false);
      } else {
        setSelectedPlantId([]);
        setCheckBoxPlantId([]);
        setIsAllSelected(false);
      }
      setIsAllSelected(false); // Ensure "All Plants" is deselected when "Others" is selected
    }
    // Handle individual plant selection or deselection
    else {
      const updatedSelected = [...selectedPlantId];

      plantIds.forEach((id) => {
        if (updatedSelected.includes(id)) {
          // If the plant is already selected, deselect it
          const index = updatedSelected.indexOf(id);
          if (index > -1) updatedSelected.splice(index, 1);
        } else {
          // If the plant is not selected, select it
          updatedSelected.push(id);
        }
      });

      setSelectedPlantId(updatedSelected);
      setCheckBoxPlantId(updatedSelected); // Update the checkbox state

      // Ensure "Others" is deselected if any plant is selected
      setIsOthersChecked(false);
      setHikemmRePlantDetails([]);
    }
  };

  const handleMenuClick = (event) => {
    // This sets the anchor for the menu, making it open at the button's position
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Closes the menu when clicked outside
  };
  useEffect(() => {
    if (selectedPlantId.length == 1) {
      const selectedPlant = plantData.find(
        (plant) => plant.id === selectedPlantId[0]
      );
      if (selectedPlant) {
        setSelectedPlantLocation(selectedPlant.plant_location);
      }
    } else {
      setSelectedPlantLocation("");
    }
  }, [selectedPlantId]);

  console.log("selectedPlantId", selectedPlantId);

  const selectNextPlant = () => {
    const allPlantIds = plantData.map((plant) => plant.id); // Array of all plant IDs
    const currentIndex = plantData.findIndex(
      (plant) => plant.id === selectedPlantId[0]
    ); // Find the current index

    if (selectedPlantId.includes("all")) {
      // If "All Plants" is currently selected, move to the first plant
      const firstPlantId = plantData[0]?.id;
      setSelectedPlantId([firstPlantId]); // Set to the first plant ID
      setCheckBoxPlantId([firstPlantId]);
      setIsOthersChecked(false);
      setHikemmRePlantDetails([]);
    } else if (currentIndex === plantData.length - 1) {
      // If the current plant is the last in the list, move back to "All Plants"
      setSelectedPlantId(["all", ...allPlantIds]); // Select "All Plants"
      setCheckBoxPlantId(allPlantIds);
      setIsOthersChecked(false);
      setHikemmRePlantDetails([]);
    } else {
      // Otherwise, move to the next plant in the list
      const nextPlant = plantData[currentIndex + 1];
      setSelectedPlantId([nextPlant.id]); // Set to the next plant ID
      setCheckBoxPlantId([nextPlant.id]);
      setIsOthersChecked(false);
      setHikemmRePlantDetails([]);
    }
  };

  const selectPreviousPlant = () => {
    const allPlantIds = plantData.map((plant) => plant.id); // Array of all plant IDs
    const currentIndex = plantData.findIndex(
      (plant) => plant.id === selectedPlantId[0]
    ); // Find the current index

    if (selectedPlantId.includes("all")) {
      // If "All Plants" is currently selected, move to the last plant
      const lastPlantId = plantData[plantData.length - 1]?.id;
      setSelectedPlantId([lastPlantId]); // Set to the last plant ID
      setCheckBoxPlantId([lastPlantId]);
      setIsOthersChecked(false);
      setHikemmRePlantDetails([]);
    } else if (currentIndex === 0) {
      // If the current plant is the first in the list, move back to "All Plants"
      setSelectedPlantId(["all", ...allPlantIds]); // Select "All Plants"
      setCheckBoxPlantId(allPlantIds);
      setIsOthersChecked(false);
      setHikemmRePlantDetails([]);
    } else {
      // Otherwise, move to the previous plant in the list
      const prevPlant = plantData[currentIndex - 1];
      setSelectedPlantId([prevPlant.id]); // Set to the previous plant ID
      setCheckBoxPlantId([prevPlant.id]);
      setIsOthersChecked(false);
      setHikemmRePlantDetails([]);
    }
  };

  const inactivityTimeout = 1000 * 10 * 1;
  let timeoutId;

  const resetIdleTimer = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(selectNextPlant, inactivityTimeout);
  };

  useEffect(() => {
    resetIdleTimer();

    const handleActivity = () => {
      resetIdleTimer();
    };

    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("click", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("touchstart", handleActivity);

    return () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("click", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("touchstart", handleActivity);
      clearTimeout(timeoutId);
    };
  }, [selectedPlantId, plantData, checkBoxPlantId]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    const mapContainer = document.getElementById("map-container");
    if (!isSolarFullScreen) {
      setIsFullscreen(true);
      try {
        if (mapContainer.requestFullscreen) {
          mapContainer.requestFullscreen();
        } else if (mapContainer.webkitRequestFullscreen) {
          mapContainer.webkitRequestFullscreen();
        } else if (mapContainer.msRequestFullscreen) {
          mapContainer.msRequestFullscreen();
        }
      } catch (error) {
        console.error("Fullscreen request failed:", error);
      }
    } else {
      setIsFullscreen(false);
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } catch (error) {
        console.error("Fullscreen exit failed:", error);
      }
    }
  };

  const [totalGeneration, setTotalGeneration] = useState([]);
  const [totalCapacity, setTotalCapacity] = useState([]);
  const [totalCapacityChartData, setTotalCapacityChartData] = useState([]);
  const [co2Emission, setCo2Emission] = useState([]);
  const [co2CountData, setCo2CountData] = useState([]);
  const [co2Percentage, setCo2Percentage] = useState([]);
  const [costSaving, setCostSaving] = useState([]);
  const [utilityCountData, setUtilityCountData] = useState([]);
  const [utilityPercentage, setUtilityPercentage] = useState([]);
  const [generationData, setGenerationData] = useState([]);
  const [dailyGenerationData, setDailyGenerationData] = useState([]);
  const [summationValue, setSummationValue] = useState([]);
  const [updatedValues, setUpdatedValues] = useState([]);
  const [averageValues, setAverageValue] = useState([]);

  const [capacityGenerationTrendData, setCapacityGenerationTrendData] =
    useState([]);

  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };
  const handleRealTimeData = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "MW",
      co2_e_tag_unit: "kt",
      cost_saving_tag_unit: "Lac",
      performance_tag_unit: "PR%",
      utilization_tag_unit: "CUF%",
    };
    try {
      const resp = await getHikemmREDashboardTagsData(params);
      if (resp.status == 200 || resp.status == 201) {
        const tagsData = resp.data.payload;
        setRealTimePlantData(tagsData);
        const tagDataList = tagsData.flatMap((plant) =>
          plant.tags.map((tag) => ({
            dvName: tag.device_tag || "-",
            tagName: tag.tag_name,
            machineUID: tag.device_uid,
            is_soft_tag: tag.is_soft_tag || false,
            soft_tag_id__formula: tag.soft_tag_id__formula || null,
          }))
        );

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it’s a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });
            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.flatMap((plant) =>
          plant.tags.map((tag) => {
            let curValue = 0;
            let prevValue = 0;

            if (tag.is_soft_tag && tag.soft_tag_id__formula) {
              // Evaluate the formula for the soft tag using the provided method
              curValue = evaluateSoftTagValue(
                tag.soft_tag_id__formula,
                mqttTagPreviousvalue
              );
              prevValue = curValue; // Assuming prevValue is the same as curValue initially
            }

            return {
              is_soft_tag: tag.is_soft_tag,
              dvName: tag.device_tag || "-",
              prevValue,
              curValue,
              dateAndTime: getTagDateAndTime(),
              soft_tag_id__formula: tag.soft_tag_id__formula,
            };
          })
        );

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  const handleChartRealTimeData = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "MW",
      co2_e_tag_unit: "kt",
      cost_saving_tag_unit: "Lac",
      performance_tag_unit: "PR%",
      utilization_tag_unit: "CUF%",
    };
    try {
      const resp = await getHikemmREDashboardTagsData(params);
      if (resp.status == 200 || resp.status == 201) {
        const tagsData = resp.data.payload;
        setChartRealTimePlantData(tagsData);
        // const tagDataList = tagsData.flatMap((plant) =>
        //   plant.tags.map((tag) => ({
        //     dvName: tag.device_tag || "-",
        //     tagName: tag.tag_name,
        //     machineUID: tag.device_uid,
        //     is_soft_tag: tag.is_soft_tag || false,
        //     soft_tag_id__formula: tag.soft_tag_id__formula || null,
        //   }))
        // );

        // // Combine default and calculated tags if needed
        // setTagListDataGroupAndList(tagDataList);

        // // Evaluate formula if it’s a soft tag
        // const evaluateSoftTagValue = (formula, tags) => {
        //   try {
        //     const tagPattern = /[A-Za-z0-9_]+/g;
        //     const tagMatches = formula.match(tagPattern) || [];
        //     let evaluatedFormula = formula;

        //     // Replace tags in formula with current values from tags list
        //     tagMatches.forEach((tag) => {
        //       const foundTag = tags.find((t) => t.dvName === tag);
        //       const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
        //       evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
        //     });
        //     return eval(evaluatedFormula);
        //   } catch {
        //     return 0; // Default to 0 on error
        //   }
        // };

        // const tagObj = tagsData.flatMap((plant) =>
        //   plant.tags.map((tag) => {
        //     let curValue = 0;
        //     let prevValue = 0;

        //     if (tag.is_soft_tag && tag.soft_tag_id__formula) {
        //       // Evaluate the formula for the soft tag using the provided method
        //       curValue = evaluateSoftTagValue(
        //         tag.soft_tag_id__formula,
        //         mqttTagPreviousvalue
        //       );
        //       prevValue = curValue; // Assuming prevValue is the same as curValue initially
        //     }

        //     return {
        //       is_soft_tag: tag.is_soft_tag,
        //       dvName: tag.device_tag || "-",
        //       prevValue,
        //       curValue,
        //       dateAndTime: getTagDateAndTime(),
        //       soft_tag_id__formula: tag.soft_tag_id__formula,
        //     };
        //   })
        // );

        // setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (realTimePlantDta.length) {
      const topicArray = realTimePlantDta.flatMap((plant) =>
        plant.tags.map((tag) => "Hikar/DeviceUID/" + tag.device_uid)
      );
      setTopics(topicArray);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    if (realTimePlantDta.length) {
      const onlyTags = realTimePlantDta.flatMap((plant) => plant.tags);
      setRealTimeData(onlyTags);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    if (chartRealTimePlantDta.length) {
      const onlyTags = chartRealTimePlantDta.flatMap((plant) => plant.tags);
      setChartRealTimeData(onlyTags);
    }
  }, [chartRealTimePlantDta]);

  const plantIds = useMemo(() => plantData.map((e) => e.id), [plantData]);

  useEffect(() => {
    handleRealTimeData();
  }, [plantIds, checkBoxPlantId]);

  useEffect(() => {
    handleChartRealTimeData();
  }, [selectTimeRange, plantIds]);

  useEffect(() => {
    const updatedValues = realTimePlantDta.map((plant) => {
      const matchingData = plant.tags?.reduce((acc, tag) => {
        const tagData = mergedArray.find(
          (item) =>
            item.device__uid === tag.device_uid &&
            item.device_tag === tag.device_tag
        );

        let tagValue = mergedArray.length
          ? mergedArray.find(
              (f) =>
                f.device__uid == tag.device_uid &&
                f.device_tag == tag.device_tag
            )?.["tagValue"] || 0.0
          : 0.0;

        if (
          tag.is_soft_tag &&
          tag.soft_tag_id__formula &&
          mqttDataReduce[tag.device_uid]
        ) {
          const formulaResult = SoftTagFormula(
            tag.soft_tag_id__formula,
            mqttDataReduce[tag.device_uid]
          );

          tagValue = formulaResult.softTagNotGetTag
            ? formulaResult.value
            : tagValue;
        }
        const tagValue1 = tagValue;
        const tagName = tag?.tag_unit;
        if (tagName) {
          acc[tagName] = (acc[tagName] || 0) + parseFloat(tagValue1); // Accumulate tag values
        }
        return acc;
      }, {});
      return {
        plant_name: plant.plant_name,
        plant_id: plant.plant_id,
        ...matchingData,
      };
    });
    setUpdatedValues(updatedValues);

    // Summation value of selected plants
    const summation = updatedValues
      .filter((plant) => checkBoxPlantId.includes(plant.plant_id)) // Only include selected plants
      .reduce((acc, plant) => {
        Object.keys(plant).forEach((key) => {
          if (key !== "name" && key !== "plant_id" && key !== "plant_name") {
            acc[key] = (acc[key] || 0) + plant[key];
          }
        });
        return acc;
      }, {});

    setSummationValue(summation);

    // Average value for "PR%" and "CUF%" for selected plants
    const numSelectedPlants = checkBoxPlantId.length;
    const selectedPlants = updatedValues.filter((plant) =>
      checkBoxPlantId.includes(plant.plant_id)
    );

    const averageValues = {
      "PR%":
        numSelectedPlants > 0
          ? selectedPlants.reduce(
              (acc, plant) => acc + (plant["PR%"] || 0),
              0
            ) / numSelectedPlants
          : 0,
      "CUF%":
        numSelectedPlants > 0
          ? selectedPlants.reduce(
              (acc, plant) => acc + (plant["CUF%"] || 0),
              0
            ) / numSelectedPlants
          : 0,
    };

    setAverageValue(averageValues);
  }, [realTimePlantDta, mqttTagData, checkBoxPlantId]);

  const getBorderColor = (displayValue) => {
    if (displayValue > 80) {
      return "#68F195"; // Green
    } else if (displayValue >= 50 && displayValue <= 80) {
      return "#FE884B"; // Orange
    } else {
      return "#F55A48"; // Red
    }
  };

  const filteredPlants = plantData.map((e) => {
    // Find matching plant data
    const plantData12 = realTimePlantDta.find(
      (plantData1) => plantData1.plant_id === e.id
    );

    // Handle cases where plantData12 is not found
    if (!plantData12 || !plantData12.tags) {
      return {
        ...e,
        borderColor: "#F55A48", // Default color if no data is found
      };
    }

    // Flatten mqttTagData into an array
    const mergedArray = mqttTagData
      ? [].concat(...Object.values(mqttTagData))
      : [];

    // Get PR% tags
    const prTags = plantData12.tags.filter((tag) => tag.tag_unit === "PR%");

    // Extract the first valid tagValue
    const prTagValues = prTags.map((tag) => {
      const matchedItem = mergedArray.find(
        (item) =>
          item.device__uid === tag.device_uid &&
          item.device_tag === tag.device_tag
      );
      return matchedItem ? parseFloat(matchedItem.tagValue) : null;
    });

    // Calculate display value (default to 0.0 if no valid tagValue found)
    const displayValue =
      prTagValues.length > 0 && prTagValues[0] !== null ? prTagValues[0] : 0.0;

    // Determine border color based on display value
    const borderColor = getBorderColor(displayValue);

    // Return updated plant data with borderColor
    return {
      ...e,
      borderColor,
    };
  });

  const handleGenerationValue = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "MWh",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardGenerationCount(params);
      if (resp.status === 200) {
        setTotalGeneration(resp.data.total_generation_value);
        setGenerationData(resp.data.data);
      }
    } catch (error) {
      console.log(
        "Error from getHikemmREPlantDashboardGenerationCount...",
        error
      );
    }
  };
  const handleTotalCapacityValue = async () => {
    const params = {
      plant_ids: checkBoxPlantId,
    };
    try {
      const resp = await getHikemmREPlantCapacity(params);
      if (resp.status === 200) {
        setTotalCapacity(resp.data.total_plant_capacity);
        setTotalCapacityChartData(resp.data.data);
      }
    } catch (error) {
      console.log(
        "Error from getHikemmREPlantDashboardGenerationCount...",
        error
      );
    }
  };

  const handleC02EmissionValue = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      co2_e_tag_unit: "kt",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardCO2e(params);
      if (resp.status === 200) {
        setCo2Emission(resp.data.total_co2_e_value);
      }
    } catch (error) {
      console.log(
        "Error from getHikemmREPlantDashboardGenerationCount...",
        error
      );
    }
  };

  const handleCostSaving = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      cost_saving_tag_unit: "Lac",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardCostSaving(params);
      if (resp.status === 200) {
        setCostSaving(resp.data.total_cost_saving_value);
      }
    } catch (error) {
      console.log("Error from getHikemmREPlantDashboardCostSaving...", error);
    }
  };

  const handleCo2Count = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      co2_green_tag_unit: "PR%",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardCo2Count(params);
      if (resp.data.success == true) {
        setCo2CountData(resp.data.data);
        setCo2Percentage(resp.data.total_co2_green_value);
      }
    } catch (error) {
      console.log("Error from getHikemmREPlantDashboardCo2Count...", error);
    }
  };
  const handleUtilityCount = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      utilization_tag_unit: "CUF%",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardUtilizationCount(params);
      if (resp.data.success == true) {
        setUtilityCountData(resp.data.data);
        setUtilityPercentage(resp.data.total_utilization_value);
      }
    } catch (error) {
      console.log("Error from getHikemmREPlantDashboardCo2Count...", error);
    }
  };

  // useEffect(() => {
  //   const today = new Date();
  //   const oneWeekAgo = new Date();
  //   oneWeekAgo.setDate(today.getDate() - 7);

  //   const formattedStartDate =
  //     dailyGenStartDate || oneWeekAgo.toISOString().split("T")[0];
  //   const formattedEndDate =
  //     dailyGenEndDate || today.toISOString().split("T")[0];

  //   // Reset to default dates if either is cleared
  //   if (!dailyGenStartDate) setDailyGenStartDate(formattedStartDate);
  //   if (!dailyGenEndDate) setDailyGenEndDate(formattedEndDate);

  //   // Trigger data fetch whenever dates are set or reset
  //   if (!dailyGenStartDate || !dailyGenEndDate) {
  //     handleDailyGeneration();
  //   }
  // }, [dailyGenStartDate, dailyGenEndDate, plantData]);

  useEffect(() => {
    if (dailyGenStartDate && dailyGenEndDate) {
      handleDailyGeneration();
    }
  }, [dailyGenStartDate, dailyGenEndDate, plantData, checkBoxPlantId]);

  const handleDailyGeneration = async () => {
    if (plantData) {
      const params = {
        plant_ids: checkBoxPlantId,
        tag_unit: "MWh",
        start_date: `${dailyGenStartDate} 00:00:00`,
        end_date: `${dailyGenEndDate} 23:59:59`,
      };
      try {
        const resp = await getHikemmREDashboardDailyGeneration(params);
        if (resp.data.success == true) {
          if (resp.data.success == true) {
            setDailyGenerationData(resp.data.payload);
          }
        }
      } catch (error) {
        setDailyGenerationData([]);
        console.log("Error from getHikemmREDashboardDailyGeneration...", error);
      }
    }
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      return; // Exit early if dates are invalid
    }
    handleGenerationValue();
    handleC02EmissionValue();
    handleCostSaving();
    handleCo2Count();
    handleUtilityCount();
  }, [startDate, endDate]);

  useEffect(() => {
    handleTotalCapacityValue();
  }, [plantData, checkBoxPlantId]);

  useEffect(() => {
    if (dailyGenStartDate && dailyGenEndDate) {
      handleDailyGeneration();
    }
  }, [dailyGenStartDate, dailyGenEndDate, plantData, checkBoxPlantId]);

  const colorCode = {
    0: "#FDBE4A",
    1: "#42899B",
    2: "#CE6A6C",
    3: "#E85AA0",
  };
  const [mwValues, setMwValues] = useState([]);
  const [singleColorIndex, setSingleColorIndex] = useState();
  useEffect(() => {
    // Proceed only if checkBoxPlantId has exactly one element
    if (checkBoxPlantId.length === 1) {
      const matchingIndex = plantData.findIndex(
        (plant) => plant.id === checkBoxPlantId[0] // Compare the plant ID with the single value in checkBoxPlantId
      );

      if (matchingIndex !== -1) {
        setSingleColorIndex(matchingIndex);
      } else {
        console.log("No matching plant found for the given checkBoxPlantId.");
      }
    }
  }, [checkBoxPlantId, plantData]);

  useEffect(() => {
    if (selectedPlantId.length === 1) {
      // Create a copy of the existing mwValues filtered for the selected plant_id
      let updatedMwValues = mwValues.filter(
        (item) => item.plant_id === selectedPlantId[0]
      );

      // Iterate through updatedValues
      updatedValues.forEach((newItem) => {
        // Check if the plant matches the selected plant_id
        if (newItem.plant_id === selectedPlantId[0]) {
          const existingIndex = updatedMwValues.findIndex(
            (item) => item.plant_id === newItem.plant_id
          );

          if (existingIndex !== -1) {
            // If it exists, ensure data array exists and add only if MW is not already in the array
            if (!updatedMwValues[existingIndex].data) {
              updatedMwValues[existingIndex].data = []; // Initialize data array if undefined
            }
            if (
              !updatedMwValues[existingIndex].data.some(
                (dataItem) => dataItem.MW === newItem.MW
              )
            ) {
              updatedMwValues[existingIndex].data.push({
                MW: newItem.MW,
                timestamp: new Date().toISOString(), // Add timestamp
              });
            }
          } else {
            // If it doesn't exist, add a new object with a data array
            updatedMwValues.push({
              plant_name: newItem.plant_name,
              plant_id: newItem.plant_id,
              data: [
                {
                  MW: newItem.MW,
                  timestamp: new Date().toISOString(), // Add timestamp
                },
              ], // Initialize data with the current MW and timestamp
            });
          }
        }
      });

      // Update the state
      setMwValues(updatedMwValues);
    }
  }, [updatedValues, selectedPlantId]);

  // console.log("here", mwValues[0].data)
  const handleCapacityGenerationTrend = async () => {
    const params = {
      plant_ids: selectedPlantId,
      tag_unit: "MWh",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getCapacityGenerationTrend(params);
      if (resp.status === 200) {
        setCapacityGenerationTrendData(resp.data.payload);
      }
    } catch (error) {
      console.log("Error from getCapacityGenerationTrend...", error);
    }
  };

  useEffect(() => {
    if (!startDate && !endDate) {
      return;
    }
    if (selectedPlantId.length === 1) {
      handleCapacityGenerationTrend();
    }
  }, [selectedPlantId, startDate, endDate]);

  const handleReDatails = async () => {
    const resp = await getHikEMMREPlantDetails();
    if (resp.status === 200 || resp.status == 201) {
      setHikemmRePlantDetails(resp.data.payload);
      setCapacity(resp.data);
    }
  };

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setIsOthersChecked(isChecked);

    // Uncheck "All Plants" when "Others" is checked
    if (isChecked) {
      setIsAllSelected(false);

      // Clear selected plant IDs when "Others" is checked
      setSelectedPlantId([]);

      // Call the details function when "Others" is checked
      handleReDatails();

      // Fetch and process real-time data
      await fetchRealTimeData();
      await handleTotalCapacityValue1();
    } else {
      // Restore selected plant IDs or manage state as needed
      // Optionally keep the selected plant IDs or clear other details
      setHikemmRePlantDetails([]); // Clear details when "Others" is unchecked
    }
  };

  const handleTotalCapacityValue1 = async () => {
    const params = {
      plant_ids: [],
    };
    try {
      const resp = await getHikemmREPlantCapacity(params);
      if (resp.status === 200) {
        setTotalCapacity(resp.data.total_plant_capacity);
        setTotalCapacityChartData(resp.data.data);
      }
    } catch (error) {
      console.log(
        "Error from getHikemmREPlantDashboardGenerationCount...",
        error
      );
    }
  };

  // Separate function for fetching real-time data
  const fetchRealTimeData = async () => {
    const params = {
      plant_ids: [],
      generation_tag_unit: "MW",
      co2_e_tag_unit: "kt",
      cost_saving_tag_unit: "Lac",
      performance_tag_unit: "PR%",
      utilization_tag_unit: "CUF%",
    };

    try {
      const resp = await getHikemmREDashboardTagsData(params);
      if (resp.status === 200 || resp.status === 201) {
        const tagsData = resp.data.payload;
        setRealTimePlantData(tagsData);

        const tagDataList = tagsData.flatMap((plant) =>
          plant.tags.map((tag) => ({
            dvName: tag.device_tag || "-",
            tagName: tag.tag_name,
            machineUID: tag.device_uid,
            is_soft_tag: tag.is_soft_tag || false,
            soft_tag_id__formula: tag.soft_tag_id__formula || null,
          }))
        );

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it's a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });

            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.flatMap((plant) =>
          plant.tags.map((tag) => {
            let curValue = 0;
            let prevValue = 0;

            if (tag.is_soft_tag && tag.soft_tag_id__formula) {
              // Evaluate the formula for the soft tag using the provided method
              curValue = evaluateSoftTagValue(
                tag.soft_tag_id__formula,
                mqttTagPreviousvalue
              );
              prevValue = curValue; // Assuming prevValue is the same as curValue initially
            }

            return {
              is_soft_tag: tag.is_soft_tag,
              dvName: tag.device_tag || "-",
              prevValue,
              curValue,
              dateAndTime: getTagDateAndTime(),
              soft_tag_id__formula: tag.soft_tag_id__formula,
            };
          })
        );

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="Bpcl-page-wrapper">
        {realTimeData.length ? (
          <OverViewMachineMqttData
            topics={topics}
            tagListDataGroupAndList={tagListDataGroupAndList}
            setMqttTagPreviousvalue={setMqttTagPreviousvalue}
            mqttTagPreviousvalue={mqttTagPreviousvalue}
            setSliderRealTimeTagData={setSliderRealTimeTagData}
            mqttTagData={mqttTagData}
            setMqttTagData={setMqttTagData}
          />
        ) : null}
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <div style={{ backgroundColor: "white", borderRadius: "8px" }}>
              <Box className="plant-list">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    {/* Total Capacity */}
                    <Box className="plant-item total-capacity">
                      <div>
                        <img src={images.suryaTotalCapacity} />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <div className="title">Total Capacity</div>
                        <div className="value">
                          {parseFloat(totalCapacity).toFixed(2)}{" "}
                          <span
                            style={{
                              color: "#000",
                              fontSize: "12px",
                              fontWeight: "300",
                            }}
                          >
                            MW{" "}
                          </span>{" "}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* Generation */}
                    <Box className="plant-item generation">
                      <div>
                        <img src={images.suryaTotalCapacity} />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {" "}
                        <div className="title">Generation</div>
                        <div className="value">
                          {startDate && endDate
                            ? totalGeneration
                            : isNaN(parseFloat(summationValue.MW))
                            ? 0
                            : parseFloat(summationValue.MW).toFixed(2)}

                          <span
                            style={{
                              color: "#000",
                              fontSize: "12px",
                              fontWeight: "300",
                            }}
                          >
                            {" "}
                            {startDate && endDate ? "MWh" : "MW"}{" "}
                          </span>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: "10px" }}>
                  <Grid item xs={12} md={6}>
                    {/* CO2 Emission */}
                    <Box className="plant-item co2-emission">
                      <div>
                        <img src={images.suryaCo2Emission} />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <div className="title">
                          CO<sub>2</sub>e
                        </div>
                        <div className="value ">
                          {startDate && endDate
                            ? co2Emission
                            : isNaN(parseFloat(summationValue.kt))
                            ? "0.00"
                            : parseFloat(summationValue.kt).toFixed(2)}

                          <span
                            style={{
                              color: "#000",
                              fontSize: "12px",
                              fontWeight: "300",
                            }}
                          >
                            {" "}
                            kt{" "}
                          </span>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* Cost Savings */}
                    <Box className="plant-item cost-savings">
                      <div>
                        <img src={images.suryaTotalSavingIcon} />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <div className="title">Cost Savings</div>
                        <div className="value">
                          {startDate && endDate
                            ? costSaving
                            : isNaN(parseFloat(summationValue.Lac))
                            ? "0.00"
                            : parseFloat(summationValue.Lac).toFixed(2)}

                          <span
                            style={{
                              color: "#000",
                              fontSize: "12px",
                              fontWeight: "300",
                            }}
                          >
                            {" "}
                            Lac{" "}
                          </span>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="generate-report" style={{ marginTop: "5px" }}>
                <div className="report-title">
                  Generation
                  <span
                    className="report-title-span"
                    onMouseEnter={() => setShowInfo(true)} // Show on hover
                    onMouseLeave={() => setShowInfo(false)} // Hide when hover ends
                  >
                    <img src={images.infoIconBlack} style={{ width: "20px" }} />
                  </span>
                </div>
                {showInfo && (
                  <div className="toggle-info">
                    This pie chart illustrates the total power generation along
                    with the contributions from each plants.
                  </div>
                )}

                <div className="report-text">
                  <p className="mb-0">
                    Total Generations <br />
                    <b>
                      {startDate && endDate
                        ? totalGeneration
                        : parseFloat(summationValue.MW).toFixed(2)}{" "}
                      {startDate && endDate ? " MWh" : " MW"}
                    </b>
                  </p>
                </div>
                <PieChart
                  generationData={
                    startDate && endDate
                      ? generationData // When startDate and endDate are defined
                      : updatedValues.map(({ plant_name, plant_id, MW }) => ({
                          plant_name,
                          plant_id,
                          MW,
                        })) // Only send plant_name, plant_id, and MW
                  }
                  colorCode={colorCode}
                  selectedPlantId={selectedPlantId}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
              <Box
                className="capacity-generation-report"
                style={{ marginTop: "5px" }}
              >
                <div className="report-title">
                  Capacity Generation Trend
                  <span
                    className="report-title-span"
                    onMouseEnter={() => setShowInfo1(true)} // Show on hover
                    onMouseLeave={() => setShowInfo1(false)} // Hide when hover ends
                  >
                    <img src={images.infoIconBlack} style={{ width: "20px" }} />
                  </span>
                </div>
                {showInfo1 && (
                  <div className="toggle-info">
                    This trend illustrates the individual capacity of all the
                    plants along with the generation in line graph.
                  </div>
                )}
                <div>
                  {selectedPlantId.length === 1 ? (
                    startDate && endDate ? (
                      // capacityGenerationTrend
                      <SinglePlantCombineChart
                        barChartData={capacityGenerationTrendData.map(
                          (item, index) => ({
                            plant_id: item.plant_id,
                            plant_name: item.plant_name,
                            capacity_data: item.capacity_data,
                          })
                        )}
                        lineChartData={capacityGenerationTrendData.map(
                          (item) => ({
                            plant_id: item.plant_id,
                            plant_name: item.plant_name,
                            generation_value: item.generation_data,
                          })
                        )}
                        colorCode={colorCode[singleColorIndex]}
                      />
                    ) : (
                      // plantCapacity and live data
                      <LivePlantCombineChart
                        barChartData={totalCapacityChartData.filter((plant) =>
                          selectedPlantId.includes(plant.plant_id)
                        )}
                        lineChartData={mwValues}
                        colorCode={colorCode[singleColorIndex]}
                      />
                    )
                  ) : startDate && endDate ? (
                    // plantCapacity and dashboardGenerationCount
                    <CombinedChart
                      barChartData={totalCapacityChartData}
                      lineChartData={generationData}
                      colorCode={colorCode}
                    />
                  ) : (
                    // plantCapacity and live data
                    <CombinedChart
                      barChartData={totalCapacityChartData}
                      lineChartData={updatedValues.map((item) => ({
                        plant_id: item.plant_id,
                        plant_name: item.plant_name,
                        generation_value: item.MW, // Access "CUF%" using bracket notation
                      }))}
                      colorCode={colorCode}
                    />
                  )}
                </div>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              className="map-box"
              style={{ position: "relative", height: "593px" }}
            >
              <Box
                id="map-container"
                className="map-div"
                style={{ position: "relative" }}
              >
                {isOthersChecked ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "80px",
                        left: "10px",
                        padding: "8px",
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: "5px",
                        zIndex: 1,
                      }}
                    >
                      <b>Solar:</b> {capacity?.solar_capacity}{" "}
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        bottom: "30px",
                        left: "10px",
                        padding: "8px",
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: "5px",
                        zIndex: 1,
                      }}
                    >
                      <b>Wind:</b> {capacity?.wind_capacity}{" "}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-18px",
                        left: "10px",
                        padding: "8px",
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: "5px",
                        zIndex: 1,
                      }}
                    >
                      <b>Total Capacity:</b> {capacity?.total_capacity}{" "}
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "0px",
                    padding: "8px",
                    borderRadius: "5px",
                    zIndex: 1,
                  }}
                >
                  <Box className="map-plant">
                    {/* <Select
                      multiple
                      value={selectedPlantId}
                      onChange={(event) =>
                        handlePlantSelected(event.target.value)
                      }
                      renderValue={() => {
                        if (!plantData.length || selectedPlantId.length === 0) {
                          return "Select Plants";
                        } else if (selectedPlantId.includes("others")) {
                          return "Others Selected"; // Custom value for "Others"
                        }

                        return "Select Plants";
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            width: "max-content", // Automatically adjust dropdown width
                          },
                        },
                      }}
                      style={{
                        borderRadius: "4px",
                        width: "100%",
                        backgroundColor: "white",
                        height: "39px",
                      }}
                    >
                      <MenuItem value="all">
                        <Checkbox checked={isAllSelected} />
                        <ListItemText primary="All Plants" />
                      </MenuItem>
                      {plantData.map((plant, index) => (
                        <MenuItem key={index} value={plant.id}>
                          <Checkbox
                            checked={selectedPlantId.includes(plant.id)}
                          />
                          <ListItemText primary={plant.plant_name} />
                        </MenuItem>
                      ))}
                      <MenuItem value="others">
                        <Checkbox
                          checked={isOthersChecked}
                          onChange={handleCheckboxChange}
                        />
                        <ListItemText primary="Others" />
                      </MenuItem>
                    </Select> */}
                    <div>
                      {/* Button to open the "Select Plants" menu */}
                      <Button
                        onClick={handleMenuClick}
                        variant="outlined"
                        className="select-plant-btn"
                      >
                        Select Plants
                      </Button>

                      {/* Menu displaying the list of plants with checkboxes */}
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        {/* "All Plants" checkbox */}
                        <MenuItem value="all">
                          <Checkbox
                            checked={isAllSelected}
                            onChange={() => handlePlantSelected(["all"])}
                          />
                          <ListItemText primary="All Plants" />
                        </MenuItem>

                        {/* Individual plants */}
                        {plantData.map((plant, index) => (
                          <MenuItem key={index} value={plant.id}>
                            <Checkbox
                              checked={selectedPlantId.includes(plant.id)}
                              onChange={
                                () => handlePlantSelected([plant.id]) // Only pass the plant's ID here
                              }
                            />
                            <ListItemText primary={plant.plant_name} />
                          </MenuItem>
                        ))}

                        {/* "Others" checkbox */}
                        <MenuItem value="others">
                          <Checkbox
                            checked={isOthersChecked}
                            onChange={handleCheckboxChange} // Use the handler for toggling "Others"
                          />
                          <ListItemText primary="Others" />
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "-2px",
                    padding: "8px",
                    borderRadius: "5px",
                    zIndex: 1,
                  }}
                >
                  <Datepicker3
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </div>
                {/* Add two button to select next plant */}

                <Box
                  style={{
                    position: "absolute",
                    bottom: isSolarFullScreen ? "25px" : "-19px",
                    right: "90px",
                    zIndex: 1,
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  <button
                    onClick={selectPreviousPlant}
                    style={{
                      border: "none",
                      borderRight: "1px solid #000",
                      backgroundColor: "#fff",
                      width: "40px",
                      height: "40px",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    <ArrowBackIosIcon style={{ width: "15px" }} />
                  </button>
                  <button
                    onClick={selectNextPlant}
                    style={{
                      border: "none",
                      backgroundColor: "#fff",
                      width: "40px",
                      height: "40px",
                      borderRadius: "0px 5px 5px 0px",
                      marginRight: "15px",
                    }}
                  >
                    <ArrowForwardIosIcon style={{ width: "15px" }} />
                  </button>
                </Box>
                {/*Add a button to toggle fullscreen */}
                <button
                  onClick={toggleFullscreen}
                  style={{
                    position: "absolute",
                    bottom: isSolarFullScreen ? "25px" : "-19px",
                    right: "57px",
                    zIndex: 1,
                    backgroundColor: "#fff",
                    border: "none",
                    cursor: "pointer",
                    width: "40px",
                    height: "40px",
                    borderRadius: "5px",
                  }}
                >
                  {isSolarFullScreen ? (
                    <CloseFullscreenIcon style={{ width: "15px" }} />
                  ) : (
                    <OpenInFullIcon style={{ width: "15px" }} />
                  )}
                </button>
                <MapComponent1
                  location={SelctedplantLocation}
                  plantName={selectedPlantName}
                  selectedImage={selectedPlantImage}
                  allPlant={allPlant}
                  plantId={selectedPlantId}
                  companyId={params.id}
                  plantData={filteredPlants}
                  plantAllData={plantData}
                  isSolarFullScreen={isSolarFullScreen}
                  setIsFullscreen={setIsFullscreen}
                  isFullscreen={isFullscreen}
                  hikemmRePlantDetails={hikemmRePlantDetails}
                />
              </Box>
            </Box>
            <Box className="daily-generation">
              <div className="daily-generation-title">
                <b className="daily-title">DAILY GENERATION PATTERN</b>
                <div className="d-flex gap-2">
                  <Select
                    value={selectTimeRange}
                    onChange={(event) => handleDropdown(event)}
                    className="inpute-week"
                    style={{ marginLeft: "107px" }}
                  >
                    <MenuItem value="1min">1 min</MenuItem>
                    <MenuItem value="5min">5 min</MenuItem>
                    <MenuItem value="15min">15 min</MenuItem>
                    <MenuItem value="Hour">Hour</MenuItem>
                  </Select>
                  <span>
                    <Datepicker3
                      startDate={dailyGenStartDate}
                      setStartDate={setDailyGenStartDate}
                      endDate={dailyGenEndDate}
                      setEndDate={setDailyGenEndDate}
                    />
                  </span>
                </div>
              </div>
              <AreaChart
                chartData={dailyGenerationData}
                colorCode={colorCode}
                timeRange={selectTimeRange}
                startDate={dailyGenStartDate}
                endDate={dailyGenEndDate}
                cubeData={cubeData}
                selectedPlantId={selectedPlantId}
                checkBoxPlantId={checkBoxPlantId}
                isOthersChecked={isOthersChecked}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <div
              style={{
                backgroundColor: "white",
                paddingTop: "10px",
                borderRadius: "8px",
              }}
            >
              <Box className="insight-box">
                <div className="report-title" style={{ display: "block" }}>
                  Insights (
                  <span style={{ textTransform: "lowercase" }}>powered by</span>
                  <span className="powered-by">AI</span>)
                </div>
                <div className="insight-text">
                  Insights will get generated based on accumulation of data.
                </div>
              </Box>
              <Box
                className="performance-ratio"
                style={{ marginTop: "25px", paddingBottom: "15px" }}
              >
                <div className="report-title">
                  Performance Ratio
                  <span
                    className="report-title-span"
                    onMouseEnter={() => setShowInfo2(true)} // Show on hover
                    onMouseLeave={() => setShowInfo2(false)} // Hide when hover ends
                  >
                    <img
                      src={images.infoIconBlack}
                      style={{ width: "20px" }}
                      alt=""
                    />
                  </span>
                </div>
                {showInfo2 && (
                  <div className="toggle-info-popup">
                    This pie chart illustrates the total PR along with the
                    contribution from each plants.
                  </div>
                )}
                <GuageChart
                  chartData={
                    startDate && endDate
                      ? co2CountData // Use co2CountData when dates are provided
                      : updatedValues.map((item) => ({
                          plant_id: item.plant_id,
                          plant_name: item.plant_name,
                          co2_green_value: item["PR%"], // Access "CUF%" using bracket notation
                        }))
                  }
                  percentage={
                    startDate && endDate
                      ? checkBoxPlantId.length === 1 // Check if a single plant is selected
                        ? co2CountData.find(
                            (plant) => plant.plant_id === checkBoxPlantId[0]
                          )?.co2_green_value // Find the selected plant's co2_green_value
                        : co2Percentage // Use co2Percentage if no specific plant is selected
                      : averageValues["PR%"] // Default to average PR% if no dates are selected
                  }
                  colorCode={colorCode}
                  chart="performance"
                  selectedPlantId={selectedPlantId}
                />
                <div className="performance-plant-list">
                  <Marquee pauseOnHover={true}>
                    {startDate && endDate
                      ? co2CountData.map((item, index) => {
                          const isChecked = checkBoxPlantId.includes(
                            item.plant_id
                          ); // Check if plant is in checkBoxPlantId
                          const backgroundColor = isChecked
                            ? colorCode[index] // Use the regular color
                            : "#918c8c"; // Use greyed-out color for unchecked plants
                          return (
                            <div
                              key={index}
                              className="performance-plant-item"
                              style={{ backgroundColor }}
                            >
                              <div className="plant-name">
                                {item.plant_name}
                              </div>
                              <div className="plant-value">
                                {item.co2_green_value}%
                              </div>
                            </div>
                          );
                        })
                      : updatedValues.map((item, index) => {
                          const isChecked = checkBoxPlantId.includes(
                            item.plant_id
                          ); // Check if plant is in checkBoxPlantId
                          const backgroundColor = isChecked
                            ? colorCode[index] // Use the regular color
                            : "#918c8c"; // Use greyed-out color for unchecked plants
                          return (
                            <div
                              key={index}
                              className="performance-plant-item"
                              style={{ backgroundColor }}
                            >
                              <div className="plant-name">
                                {item.plant_name}
                              </div>
                              <div className="plant-value">{item["PR%"]}%</div>
                            </div>
                          );
                        })}
                  </Marquee>
                </div>
              </Box>
              <Box
                className="capacity-utilization"
                style={{ marginTop: "10px", paddingBottom: "20px" }}
              >
                <div className="report-title">
                  Capacity Utilization Factor
                  <span
                    className="report-title-span"
                    onMouseEnter={() => setShowInfo3(true)} // Show on hover
                    onMouseLeave={() => setShowInfo3(false)} // Hide when hover ends
                  >
                    <img src={images.infoIconBlack} style={{ width: "20px" }} />
                  </span>
                </div>
                {showInfo3 && (
                  <div className="toggle-info-popup">
                    This pie chart illustrates the total CUF along with the
                    contribution from each plants.
                  </div>
                )}
                <GuageChart
                  chartData={
                    startDate && endDate
                      ? utilityCountData // Use utilityCountData when dates are provided
                      : updatedValues.map((item) => ({
                          plant_id: item.plant_id,
                          plant_name: item.plant_name,
                          utilization_value: item["CUF%"], // Access "CUF%" using bracket notation
                        }))
                  }
                  percentage={
                    startDate && endDate
                      ? checkBoxPlantId.length === 1 // Check if a single plant is selected
                        ? utilityCountData.find(
                            (plant) => plant.plant_id === checkBoxPlantId[0]
                          )?.utilization_value // Find the selected plant's co2_green_value
                        : utilityPercentage // Use co2Percentage if no specific plant is selected
                      : averageValues["CUF%"] // Default to average PR% if no dates are selected
                  }
                  colorCode={colorCode}
                  chart="utilization"
                  selectedPlantId={selectedPlantId}
                />
                {/*flex div with scrolling and the  div take the background color of the data*/}
                <div className="performance-plant-list">
                  <Marquee pauseOnHover={true}>
                    {startDate && endDate
                      ? utilityCountData.map((item, index) => {
                          const isChecked = checkBoxPlantId.includes(
                            item.plant_id
                          ); // Check if plant is in checkBoxPlantId
                          const backgroundColor = isChecked
                            ? colorCode[index] // Use the regular color
                            : "#918c8c"; // Use greyed-out color for unchecked plants
                          return (
                            <div
                              key={index}
                              className="performance-plant-item"
                              style={{ backgroundColor }}
                            >
                              <div className="plant-name">
                                {item.plant_name}
                              </div>
                              <div className="plant-value">
                                {item.utilization_value}%
                              </div>
                            </div>
                          );
                        })
                      : updatedValues.map((item, index) => {
                          const isChecked = checkBoxPlantId.includes(
                            item.plant_id
                          ); // Check if plant is in checkBoxPlantId
                          const backgroundColor = isChecked
                            ? colorCode[index] // Use the regular color
                            : "#918c8c"; // Use greyed-out color for unchecked plants
                          return (
                            <div
                              key={index}
                              className="performance-plant-item"
                              style={{ backgroundColor }}
                            >
                              <div className="plant-name">
                                {item.plant_name}
                              </div>
                              <div className="plant-value">
                                {item["CUF%"]}%{" "}
                              </div>
                            </div>
                          );
                        })}
                  </Marquee>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SolarBPCLPlantDashboard;

import * as React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";

// import FooterText from '../../components/FooterText/FooterText';
import "../../CompanyDashboard/Dashboard1.css";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { images } from "../../../config";
import { getCompany } from "../../CompanyDashboard/services";
import { setHeaderDetails } from "../../Header/headerSlice";
import { setUserDetails } from "../../Login/slice/userSlice";
import { companyDashboardCountData } from "../../SuperAdminDashboard/service";
import ShimmerEffectPlaceholder from "../../../components/Shimmer/DashboardShimmer/CompanyListShimmer";
import { ShimmerText } from "react-shimmer-effects";
import "./Smattag_Dashboard.css";
import { toast } from "react-toastify";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const SmartTag_Dashboard = () => {
  const [companyData, setCompanyData] = useState([]);
  const [companyDataCount, setCompanyDataCount] = useState([]);
  const [isShimmerCompanyList, setisShimmerCompanyList] = useState(false);
  const [isShimmerHeader, setisShimmerHeader] = useState(false);


  const {
    is_admin,
    is_super_admin,
    mobileNoRe,
    is_created_by_super_admin_user_company,
    company_access,
  } = useSelector((state) => state.userState);

  useEffect(() => {
    // if user mobileNo not verified then redirect to userprofile dahboard
    if (
      is_super_admin == false &&
      is_created_by_super_admin_user_company == false &&
      mobileNoRe
    ) {
      if (companyData.length) {
      if (companyData[0].is_location) {
      
          navigate(`/location-plantdashboard/company/${companyData[0].id}`);
          dispatch(
            setHeaderDetails({ headerName: companyData[0].company_name })
          );
        }
       else if (companyData.length) {
        navigate(`/Smarttag-companyDashboard/company/${companyData[0].id}`);
        dispatch(setHeaderDetails({ headerName: companyData[0].company_name }));
      }
    }
  }
    if (mobileNoRe == null) {
      navigate("/userprofile");
    }
  }, [companyData]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    companyDetail();
    dispatch(
      setHeaderDetails({
        headerName: is_super_admin ? "Company Dashboard" : "",
      })
    );
    dispatch(
      setUserDetails({
        is_redirect: false,
      })
    );
  }, []);

  const companyDetail = async () => {
    setisShimmerCompanyList(true);

    const params = {
      dashboard: 3,
    };
    try {
      const resp = await getCompany(params);
      if (resp.status == 200 || resp.status == 201  ) {
        setCompanyData(resp.data.payload);
        setTimeout(() => {
          setisShimmerCompanyList(false);
        }, 1000);
      }
    } catch (error) {
      // Handle the error here
      setisShimmerCompanyList(false);

      console.error("An error occurred:", error);
      // You can also perform any necessary error handling or show an error message to the user
    }
  };
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  const handlePlantDashboard = async (row) => {
    const hasAccess = company_access.find(
      (company) => company.company_name === row.company_name
    );
    if (is_super_admin !== true || is_admin !== true) {
      if (hasAccess) {
        if (row.is_location) {
          navigate(`/location-plantdashboard/company/${row.id}`);
        } else {
          navigate(`/Smarttag-companyDashboard/company/${row.id}`);
        }

        // dispatch(setHeaderDetails({ headerName: row.company_name }))
      } else {
        handleTostMsg();
      }
    } else {
      if (row.is_location) {
        navigate(`/location-plantdashboard/company/${row.id}`);
      } else {
        navigate(`/Smarttag-companyDashboard/company/${row.id}`);
      }
    }
  };

  useEffect(() => {
    handleCompanyDataCount();
  }, []);

  const handleCompanyDataCount = async () => {
    try {
      setisShimmerHeader(true);
      const params = {
        dashboard: 3,
      };
      const resp = await companyDashboardCountData(params);
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerHeader(false);
        }, 1000);

        setCompanyDataCount(resp.data.payload);
      }
    } catch (error) {
      setisShimmerHeader(false);
      console.log("Error from...handleCompanyDataCount", error);
    }
  };

  const TextShimmer = () => {
    return (
      <>
        <ShimmerText
          line={1}
          gap={10}
          className="Header-TextShimmer sup-header"
        />
      </>
    );
  };
  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Card className="dashboardCard">
            <Grid container>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.energyMeterImg}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">SmarTag</p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerHeader
                          ? companyDataCount.device_count
                          : TextShimmer()}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.clientManagementPlantS}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">COMPANY</p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerHeader
                          ? companyDataCount.company_count
                          : TextShimmer()}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.activeImg}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">ACTIVE</p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerHeader
                          ? companyDataCount.active_company
                          : TextShimmer()}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.inActiveImg}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">IN ACTIVE</p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerHeader
                          ? companyDataCount.inactive_company
                          : TextShimmer()}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>

          <Grid container spacing={4} mt={"0px"}>
            {!isShimmerCompanyList ? (
              companyData.map((row, i) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <Card
                      className="dashboardCard-plant"
                      onClick={() => handlePlantDashboard(row)}
                    >
                      <Stack direction="column">
                        <Typography
                          gutterBottom
                          className="dashboardCard-plantTitle"
                        >
                          {row.company_name ? row.company_name : "-"}
                        </Typography>
                        <Typography
                          gutterBottom
                          className="dashboardCard-plantsubTitle"
                        >
                          {row.company_description
                            ? row.company_description
                            : "-"}
                        </Typography>

                        <Box className="dashboardCard-companyContent">
                          <Typography
                            gutterBottom
                            className="dashboardCard-plantText"
                          >
                            Plant:
                          </Typography>
                          <Stack direction="column">
                            <Typography
                              variant="body1"
                              className="dashboardCard-plantNumber1"
                            >
                              {row.active_plant_count}
                            </Typography>
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Active
                            </Typography>
                          </Stack>
                          <Box>
                            <Stack direction="column">
                              <Typography
                                variant="body1"
                                className="dashboardCard-plantNumber2"
                              >
                                {row.inactive_plant_count}
                              </Typography>
                              <Typography
                                variant="caption"
                                className="dashboardCard-plantSmalltext"
                              >
                                Inactive
                              </Typography>
                            </Stack>
                          </Box>
                          <div
                            style={{
                              borderRight: "2px solid #C9CFE7",
                              height: "30px",
                            }}
                          ></div>
                          <Box>
                            <Stack
                              direction="column"
                              spacing={1}
                              style={{ marginTop: "5px" }}
                            >
                              <img
                                src={images.serviceCallImg}
                                alt=""
                                className="dashboardCard-companyImg"
                              />
                              <Typography
                                variant="caption"
                                className="dashboardCard-plantSmalltext"
                              >
                                Service call
                              </Typography>
                            </Stack>
                          </Box>
                          <div
                            style={{
                              borderRight: "2px solid #C9CFE7",
                              height: "30px",
                            }}
                          ></div>

                          <Box paddingRight={4}>
                            <Stack
                              direction="column"
                              spacing={1}
                              style={{ marginTop: "5px" }}
                            >
                              {row.subscribe_device_license_count == 0 ? (
                                <img
                                  src={images.noSub}
                                  alt=""
                                  className="dashboardCard-companyImg"
                                />
                              ) : (
                                <img
                                  src={images.alertImage}
                                  alt=""
                                  className="dashboardCard-companyImg"
                                />
                              )}

                              <Typography
                                variant="caption"
                                className="dashboardCard-plantSmalltext"
                              >
                                Subscription
                              </Typography>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <ShimmerEffectPlaceholder />
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default SmartTag_Dashboard;

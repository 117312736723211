import React, { useEffect, useState } from "react";
import Mqqtdata from "../../../ProcessDashboard/Mqqtdata";
import { timeStampCheckStatus } from "../../../../components/TimeStampCheckStatus/timeStampCheckStatus";
import { toast } from "react-toastify";

const MqttDataMachineDashboard = ({
  topics,
  tagData,
  setSliderRealTimeTagData,
  setMqttTagPreviousvalue,
  mqttTagPreviousvalue,
  mqttTagData,
  setMqttTagData
}) => {

  const [isNotified, setIsNotified] = useState(false);
  const [MqttTime, setMqttTime] = useState("");

  useEffect(() => {
    const mergedArray = mqttTagData
      ? [].concat(...Object.values(mqttTagData))
      : [];
    if (tagData.length) {
      const filteredArray = mergedArray.filter((obj) => {
        const device = obj.device__uid;
        const deviceTag = obj.device_tag;
        return tagData.some(
          (item) =>
            item.tag?.device?.machine_uid == device &&
            item.tag?.device_tag == deviceTag
        );
      });
      console.log("filteredArray", filteredArray);
      setSliderRealTimeTagData(filteredArray);

      setMqttTagPreviousvalue((prev) => {
        const prevValue = [...prev].map((row) => {
          const findTag = filteredArray.find(
            (item) => item.device_tag == row.dvName
          );
          if (findTag) {
            const checkTimeData = findTag.tagDateAndTime === row.dateAndTime;
            if (checkTimeData) {
              return row;
            }
            return {
              dvName: findTag.device_tag,
              prevValue: row.curValue,
              curValue: findTag.tagValue,
              dateAndTime: findTag.tagDateAndTime,
            };
          }
          return row;
        });
        return prevValue;
      });
    }
  }, [mqttTagData]);

  const convertTimeStampFormat = mqttTagData
    ? Object.keys(mqttTagData).map((row) => {
        const findTimeStamp = mqttTagData[row].find(
          (f) => f.device_tag == "TIMESTAMP"
        );
        return {
          ...findTimeStamp,
          TIMESTAMP: findTimeStamp?.TIMESTAMP || "",
        };
      })
    : [];

  useEffect(() => {
    const getData = timeStampCheckStatus(convertTimeStampFormat);

    if (!isNotified && Array.isArray(getData) && getData.length > 0) {
      const { device_tag, tagDateAndTimeFormat, tagValue } = getData[0];
      setMqttTime(tagValue);
      const notifyError = (message) =>
        toast.error(message, {
          theme: "colored",
          position: toast.POSITION.TOP_RIGHT,
          toastClassName: "custom-toast",
        });

      if (device_tag && tagDateAndTimeFormat && tagValue) {
        notifyError(
          <div>
            <p>Device Tag: {device_tag}</p>
            <p>Current Time: {tagDateAndTimeFormat}</p>
            <p>Mqtt Time: {tagValue}</p>
          </div>
        );
        setIsNotified(true);
      }
    }
  }, [mqttTagData, isNotified]);

  return (
    <>
      <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} overViewTagData={tagData}/>
    </>
  );
};

export default MqttDataMachineDashboard;

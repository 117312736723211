import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Card,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import TagListComponent from "./TagListComponent/TagListComponent";
import GraphComponent from "./AnalyticsGraphComponent/GraphComponent";
import ReportsGraphComponent from "./ReportsGraphComponent/ReportsGraphComponent";
import { useParams, useNavigate } from "react-router-dom";
import {
  getDeviceByType,
  getDeviceDashboard,
  getDeviceUnits,
  getTagID,
} from "./service";
import { getPiechartCOC, getTagList } from "../../../../SmartTag/services";
import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { setVmsReportData } from "./Slice/VmsReportSlice";
import COCDatePicker from "../../../../../components/COCDatepicker/COCDatePicker";
import POPDatePicker from "../../../../../components/POPDatePicker/POPDatePicker";
import {
  getReportAPIAnalyticsData,
  getReportAPIComp,
} from "./getReportAPIComp";
import { getReportLineChartData } from "./getReportLineChartData";
import {
  getReportTableData,
  getStorePreviousSelectedData,
  storeSelectedData,
} from "../service";
import { getDeviceType } from "../../../../ProcessDashboard/Source/services";
import { ToastContainer, toast } from "react-toastify";
import { SelectRangeArray } from "./SelectRangeArray";
import COCVmsDatePicker from "../../../VMSComponent/COCVmsDatePicker";
import POPVmsDatePicker from "../../../VMSComponent/POPVmsDatePicker";
import { getConsumptionDeviceData } from "../../../../ProcessDashboard/Consumption/service";
import { getDeviceByDashboard } from "../../../../SmartTag/services";
import { images } from "../../../../../config";

const VmsReports = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [activeTabClass, setActiveTabClass] = useState("Consumer");
  const [deviceData, setDeviceCOCData] = useState([]);
  const [tagUnitData, setTagUnitData] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [cocGraphdata, setCocGraphData] = useState([]);
  const [cocLineChartdata, setCocLineChartData] = useState([]);
  const [averageCOCLineArray, setAverageCOCLineArray] = useState([]);
  const [averagePOPLineArray, setAveragePOPLineArray] = useState([]);
  const [popBarGraphData1, setPopBarGraphData1] = useState([]);
  const [popBarGraphData2, setPopBarGraphData2] = useState([]);
  const [popLineChartdata, setPopLineChartData] = useState([]);
  const [pop2LineChartdata, setPop2LineChartData] = useState([]);
  const [reportsTableData, setReportsTableData] = useState([]);
  const [selectTagIDReport1, setSelectTagIDReport1] = useState("none");
  const [selectTagIDReport2, setSelectTagIDReport2] = useState("none");
  const [reportsIntervalTime1, setReportIntervalTime1] = useState("1");
  const [reportLineChartData1, setReportLineChartData1] = useState([]);
  const [reportLineChartData2, setReportLineChartData2] = useState([]);
  const [deviceDataDetail, setDeviceData] = useState("");
  const [rangeModal, setRangeModal] = useState(false);
  const [rangeModalPOP, setRangeModalPOP] = useState(false);
  const [isShimmerCOC, setShimmerCOC] = useState(false);
  const [isShimmerPOP, setShimmerPOP] = useState(false);
  const [isShimmerLineChartCOC, setShimmerLineChartCOC] = useState(false);
  const [isShimmerLineChartPOP, setShimmerLineChartPOP] = useState(false);
  const [isShimmerLineChartReports, setShimmerLineChartReports] =
    useState(false);
  const [unitsData, setUnitsData] = useState([]);
  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);
  const [selectedPreviousData, setSelectedPreviousData] = useState([]);
  const [VmsCOCSelectMachine, setVmsCOCSelectMachine] = useState([]);
  const [vmsCOCSelectUnit, setVmsCOCSelectUnit] = useState([]);
  const [vmsCOCSelectTag, setVmsCOCSelectTag] = useState([]);
  const [vmsPOPSelectMachine, setVmsPOPSelectMachine] = useState([]);
  const [vmsPOPSelectUnit, setVmsPOPSelectUnit] = useState([]);
  const [vmsPOPSelectTag, setVmsPOPSelectTag] = useState([]);
  const [VmsReportTime, setVmsReportTime] = useState(false);
  const [VmsReportPOPTime, setVmsReportPOPTime] = useState(false);
  const [LineCOCIntervalTime, setLineCOCIntervalTime] = useState("1");
  const [LinePOPIntervalTime, setLinePOPIntervalTime] = useState("1");
  const [selectTimeReportRange, setSelectTimeReportRange] = useState(15);
  const [disableOtherUnits, setDisableOtherUnits] = useState(false);
  const [piechartData, setPiechartData] = useState([]);
  const [pieChartSum, setPieChartSum] = useState("");
  const [aggCOC, setAggCOC] = useState(15);
  const [aggPOP, setAggPOP] = useState(15);
  const [piechartDataPOP, setPiechartDataPOP] = useState([]);
  const [piechartDataPOP1, setPiechartDataPOP1] = useState([]);
  const [disableOtherUnitsPOP, setDisableOtherUnitsPOP] = useState(false);

  console.log("unitsData", unitsData);

  const [rangeCOCValue, setRangeCOCValue] = useState("Set Range");
  const [rangePOPValue, setRangePOPValue] = useState("Set Range");

  const [rangeCOC, setRangeCOC] = useState("");
  const [rangePOP, setRangePOP] = useState("");
  const navigate = useNavigate();

  const param = useParams();
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  console.log("reportsIntervalTime1", reportsIntervalTime1);
  const {
    VmsReportCOCSelectmachine,
    VmsReportCOCSelectUnit,
    // VmsReportCOCSelectTag,
    // VmsReportCOCStartDate,
    selectTimeRangeCOC,
    // VmsReportCOCEndDate,
    // VmsReportPOPStartDate1,
    // VmsReportPOPEndDate1,
    // VmsReportPOPStartDate2,
    // VmsReportPOPEndDate2,
    // VmsReportTime,
    // VmsReportPOPTime,
    VmsReportPOPSelectmachine,
    VmsReportPOPSelectUnit,
    VmsReportPOPSelectTag,
    selectTimeRangePOP,
    // reportsIntervalTime,
    // reportIntervalTimePop,
    // VmsReportReportsSelectmachine,
    // VmsReportReportsSelectUnit,
    // VmsReportReportsSelectTag,
    // VmsReportReportsStartDate,
    // VmsReportReportsEndDate,
    // VmsReportReportsTime,
    vmsReportCOCRange,
    vmsReportPOPRange,
  } = useSelector((state) => state.VmsReportSlice);
  const dispatch = useDispatch();
  const [VmsReportReportsSelectmachine, setVmsReportSelectMachine] = useState(
    []
  );
  const [VmsReportReportsSelectUnit, setVmsReportReportsSelectUnit] = useState(
    []
  );
  const [VmsReportReportsSelectTag, setVmsReportReportsSelectTag] = useState(
    []
  );
  const [VmsReportSwitch, setVmsReportSwitch] = useState(false);
  const [VmsReportReportsStartDate, setVmsReportReportsStartDate] = useState(
    VmsReportSwitch ? dayjs().subtract(24, "hour") : null
  );
  const [VmsReportReportsEndDate, setVmsReportReportsEndDate] = useState(
    VmsReportSwitch ? dayjs() : null
  );
  const [VmsReportReportsTime, setVmsReportReportsTime] = useState(false);
  const [VmsReportTimeConsumer, setVmsReportTimeConsumer] = useState(false);
  const [VmsReportTimePeriod, setVmsReportTimePeriod] = useState(false);

  const [VmsReportCOCStartDate, setVmsReportCOCStartDate] = useState(
    VmsReportTimeConsumer ? dayjs().subtract(24, "hour") : null
  );
  const [VmsReportCOCEndDate, setVmsReportCOCEndDate] = useState(
    VmsReportTimeConsumer ? dayjs() : null
  );
  const [VmsReportPOPStartDate1, setVmsReportPOPStartDate1] = useState(
    VmsReportTimePeriod ? dayjs().subtract(24, "hour") : null
  );
  const [VmsReportPOPEndDate1, setVmsReportPOPEndDate1] = useState(
    VmsReportTimePeriod ? dayjs() : null
  );
  const [VmsReportPOPStartDate2, setVmsReportPOPStartDate2] = useState(
    VmsReportTimePeriod ? dayjs().subtract(24, "hour") : null
  );
  const [VmsReportPOPEndDate2, setVmsReportPOPEndDate2] = useState(
    VmsReportTimePeriod ? dayjs() : null
  );

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const { user_access } = useSelector((state) => state.userState);

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Analytics"
        )
      : null
    : null;

  const ReportAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Reports"
        )
      : null
    : null;

  // COC Date
  const startDateReCOC = VmsReportCOCStartDate
    ? dayjs(VmsReportCOCStartDate)
    : null;
  const endDateReCOC = VmsReportCOCEndDate ? dayjs(VmsReportCOCEndDate) : null;

  const startDatePOP = VmsReportPOPStartDate1
    ? dayjs(VmsReportPOPStartDate1)
    : null;
  const endDatePOP = VmsReportPOPEndDate1 ? dayjs(VmsReportPOPEndDate1) : null;

  const startDatePOP2 = VmsReportPOPStartDate2
    ? dayjs(VmsReportPOPStartDate2)
    : null;
  const endDatePOP2 = VmsReportPOPEndDate2 ? dayjs(VmsReportPOPEndDate2) : null;

  // Report Date

  const startDateReReports = VmsReportReportsStartDate
    ? dayjs(VmsReportReportsStartDate)
    : null;
  const endDateReReports = VmsReportReportsEndDate
    ? dayjs(VmsReportReportsEndDate)
    : null;

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const startDateFormatCOC = VmsReportCOCStartDate
    ? dayjs(VmsReportCOCStartDate).format(dateFormat)
    : "";
  const endDateFormatCOC = VmsReportCOCEndDate
    ? dayjs(VmsReportCOCEndDate).format(dateFormat)
    : "";

  const startTimeFormatCOC = VmsReportCOCStartDate
    ? dayjs(VmsReportCOCStartDate).format(timeFormat)
    : "";

  const endTimeFormatCOC = VmsReportCOCEndDate
    ? dayjs(VmsReportCOCEndDate).format(timeFormat)
    : "";

  // POP Format Date
  const startDateFormatPOP = VmsReportPOPStartDate1
    ? dayjs(VmsReportPOPStartDate1).format(dateFormat)
    : "";
  const endDateFormatPOP = VmsReportPOPEndDate1
    ? dayjs(VmsReportPOPEndDate1).format(dateFormat)
    : "";

  const startDateFormatPOP2 = VmsReportPOPStartDate2
    ? dayjs(VmsReportPOPStartDate2).format(dateFormat)
    : "";
  const endDateFormatPOP2 = VmsReportPOPEndDate2
    ? dayjs(VmsReportPOPEndDate2).format(dateFormat)
    : "";

  const startTimeFormatPOP = VmsReportPOPStartDate1
    ? dayjs(VmsReportPOPStartDate1).format(timeFormat)
    : "";

  const endTimeFormatPOP = VmsReportPOPEndDate1
    ? dayjs(VmsReportPOPEndDate1).format(timeFormat)
    : "";

  const startTimeFormatPOP2 = VmsReportPOPStartDate2
    ? dayjs(VmsReportPOPStartDate2).format(timeFormat)
    : "";

  const endTimeFormatPOP2 = VmsReportPOPEndDate2
    ? dayjs(VmsReportPOPEndDate2).format(timeFormat)
    : "";

  // Report Format Date

  const startDateFormatReports = VmsReportReportsStartDate
    ? dayjs(VmsReportReportsStartDate).format(dateFormat)
    : "";
  const endDateFormatReports = VmsReportReportsEndDate
    ? dayjs(VmsReportReportsEndDate).format(dateFormat)
    : "";

  const startTimeFormatReports = VmsReportReportsStartDate
    ? dayjs(VmsReportReportsStartDate).format(timeFormat)
    : "";

  const endTimeFormatReports = VmsReportReportsEndDate
    ? dayjs(VmsReportReportsEndDate).format(timeFormat)
    : "";

    console.log("VmsReportReportsSelectTag",VmsReportReportsSelectTag)
  // get device ID

  const getDeviceId = async () => {
    const params = {
      device: param.deviceId,
    };
    try {
      const resp = await getConsumptionDeviceData(params);
      if (resp.status === 200) {
        setDeviceData(resp.data.payload);
      }
    } catch (error) {
      console.error("An error occurred while fetching device data:", error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  useEffect(() => {
    getDeviceId();
  }, []);

  // get Device Type
  const getDeviceByPlant = async () => {
    const paramReq = {
      dashboard: 2,
      plant: param.plantId,
    };
    try {
      const resp = await getDeviceDashboard(paramReq);
      if (resp.status == 200) {
        console.log("resprespresp", resp);
        if (resp.data.payload.device.length) {
          setDeviceCOCData(resp.data.payload.device);
          const uniqueTagUnits = {};
          const filteredData = resp.data.payload.tag.filter((obj) => {
            if (!obj.tag_unit) return true; // Keep objects with no tag_unit property
            if (!uniqueTagUnits[obj.tag_unit]) {
              uniqueTagUnits[obj.tag_unit] = true;
              return true;
            }
            return false;
          });
          setTagUnitData(filteredData);
        }
      }
    } catch (error) {
      console.log("Error from GetSmartTagMachine", error);
    }
  };

  const handlegetUnits = async (id) => {
    const params = {
      device: id,
    };
    try {
      const resp = await getDeviceUnits(params);
      if (resp.status == 200 || resp.status == 201) {
        setUnitsData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    getDeviceByPlant();
  }, []);

  const getDeviceByTagID = async (deviceId, tagUnit) => {
    setisShimmerdevicelist(true);
    try {
      const paramReq = {
        device_id: deviceId,
        tag_unit: tagUnit,
      };
      const resp = await getTagList(paramReq);
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);

        setTagListData(resp.data.payload);
      }
    } catch (e) {
      setisShimmerdevicelist(false);

      console.log("error from getDeviceByTagID", e);
    }
  };

  useEffect(() => {
    const findPlantByCOCSelectmachine = VmsCOCSelectMachine.length
      ? VmsCOCSelectMachine.filter((f) => f.deviceId == param.deviceId).map(
          (row) => row.machineId
        )
      : [];
    const findPlantByCOCSelectTagUnit = vmsCOCSelectUnit.length
      ? vmsCOCSelectUnit.filter((f) => f.deviceId == param.deviceId).length
        ? vmsCOCSelectUnit
            .filter((f) => f.deviceId == param.deviceId)
            ?.map((row) => row.tagUnit) || ""
        : ""
      : "";

    const findPlantByPOPSelectmachine = vmsPOPSelectMachine.length
      ? vmsPOPSelectMachine
          .filter((f) => f.deviceId == param.deviceId)
          .map((row) => row.machineId)
      : [];
    const findPlantByPOPSelectTagUnit = vmsPOPSelectUnit.length
      ? vmsPOPSelectUnit.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectUnit
            .filter((f) => f.deviceId == param.deviceId)
            ?.map((row) => row.tagUnit) || ""
        : ""
      : "";

    const findPlantByReportsSelectmachine = VmsReportReportsSelectmachine.length
      ? VmsReportReportsSelectmachine.filter(
          (f) => f.deviceId == param.deviceId
        ).map((row) => row.machineId)
      : [];

    const findPlantByReportsSelectTagUnit = VmsReportReportsSelectUnit.length
      ? VmsReportReportsSelectUnit.filter((f) => f.deviceId == param.deviceId)
          .length
        ? VmsReportReportsSelectUnit.filter(
            (f) => f.deviceId == param.deviceId
          )?.map((row) => row.tagUnit) || ""
        : ""
      : "";

    if (
      findPlantByCOCSelectmachine.length &&
      findPlantByCOCSelectTagUnit &&
      activeTabClass == "Consumer" &&
      activeClass == "Analytics"
    ) {
      getDeviceByTagID(
        findPlantByCOCSelectmachine,
        findPlantByCOCSelectTagUnit
      );
    }

    if (
      findPlantByPOPSelectmachine.length &&
      findPlantByPOPSelectTagUnit &&
      activeTabClass == "Period" &&
      activeClass == "Analytics"
    ) {
      getDeviceByTagID(
        findPlantByPOPSelectmachine,
        findPlantByPOPSelectTagUnit
      );
    }

    if (
      findPlantByReportsSelectmachine.length &&
      findPlantByReportsSelectTagUnit &&
      activeClass == "Reports"
    ) {
      getDeviceByTagID(
        findPlantByReportsSelectmachine,
        findPlantByReportsSelectTagUnit
      );
    }

    if (
      findPlantByCOCSelectmachine &&
      activeTabClass == "Consumer" &&
      activeClass == "Analytics"
    ) {
      handlegetUnits(findPlantByCOCSelectmachine);
    } else {
      setUnitsData([]);
    }

    if (
      findPlantByPOPSelectmachine &&
      activeTabClass == "Period" &&
      activeClass == "Analytics"
    ) {
      handlegetUnits(findPlantByPOPSelectmachine);
    } else {
      setUnitsData([]);
    }

    if (findPlantByReportsSelectmachine && activeClass == "Reports") {
      handlegetUnits(findPlantByReportsSelectmachine);
    } else {
      setUnitsData([]);
    }
  }, [
    VmsCOCSelectMachine,
    vmsCOCSelectUnit,
    vmsPOPSelectMachine,
    vmsPOPSelectUnit,
    VmsReportReportsSelectmachine,
    VmsReportReportsSelectUnit,
    activeClass,
    activeTabClass,
  ]);

  // COC Handele Redux

  console.log("VmsCOCSelectMachine", VmsCOCSelectMachine);
  const handleSelectCOCMachine = (row) => {
    console.log("row", row);
    const existingIndex = VmsCOCSelectMachine.findIndex(
      (color) =>
        color.machineId === row.device && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsCOCSelectMachine];
      updateMachineId.splice(existingIndex, 1);
      setVmsCOCSelectMachine(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportCOCSelectmachine: updateMachineId,
      //   })
      // );

      const findSelectedTagUnit = vmsCOCSelectUnit.length
        ? vmsCOCSelectUnit.filter((f) => f.deviceId != param.deviceId)
        : [];
      setVmsCOCSelectUnit(findSelectedTagUnit);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportCOCSelectUnit: findSelectedTagUnit,
      //   })
      // );

      const findSelectedTeg = vmsCOCSelectTag.length
        ? vmsCOCSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      setVmsCOCSelectTag(findSelectedTeg);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportCOCSelectTag: findSelectedTeg,
      //   })
      // );
      setTagListData([]);
    } else {
      const newMachine = {
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
      };
      // Add the object to the state array if it doesn't exist
      setVmsCOCSelectMachine((prevState) => [...prevState, newMachine]);
    }
  };

  const handleSelectCOCTagUnit = (row) => {
    const requiredUnits = ["kvah", "kvarh", "kwh","agg"];
    const unitLowerCase = row.tag_unit.toLowerCase();

    const existingIndex = vmsCOCSelectUnit.findIndex(
      (color) =>
        color.tagUnit.toLowerCase() === unitLowerCase &&
        color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...vmsCOCSelectUnit];
      updateMachineId.splice(existingIndex, 1);
      setVmsCOCSelectUnit(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportCOCSelectUnit: updateMachineId,
      //   })
      // );
      const hasRequiredUnitSelected = updateMachineId.some((unit) =>
        requiredUnits.includes(unit.tagUnit.toLowerCase())
      );
      setDisableOtherUnits(hasRequiredUnitSelected);

      const findSelectedTeg = vmsCOCSelectTag.length
        ? vmsCOCSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      setVmsCOCSelectTag(findSelectedTeg);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportCOCSelectTag: findSelectedTeg,
      //   })
      // );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      const newUnit = {
        tagUnit: row.tag_unit,
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
      };
      // Add the object to the state array if it doesn't exist
      // setVmsCOCSelectUnit((prevState) => [...prevState, newUnit]);
      setVmsCOCSelectUnit((prevState) => {
        const updatedUnits = [...prevState, newUnit];

        // Check if a required unit is selected
        const hasRequiredUnitSelected = updatedUnits.some((unit) =>
          requiredUnits.includes(unit.tagUnit.toLowerCase())
        );

        // Disable other units if a required unit is selected
        setDisableOtherUnits(hasRequiredUnitSelected);

        // Turn off all other switches when a required unit is selected
        if (hasRequiredUnitSelected) {
          return updatedUnits.filter((unit) =>
            requiredUnits.includes(unit.tagUnit.toLowerCase())
          ); // Keep only the required units
        }

        return updatedUnits;
      });
    }
  };

  const handleSelectCOCTag = (row, color) => {
    const existingIndex = vmsCOCSelectTag.findIndex(
      (color) => color.tagId === row.id && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...vmsCOCSelectTag];
      updateMachineId.splice(existingIndex, 1);
      setVmsCOCSelectTag(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     vmsCOCSelectTag: updateMachineId,
      //   })
      // );
    } else {
      // Add the object to the state array if it doesn't exist
      const newTag = {
        tagId: row.id,
        tagUnit: row.tag_unit,
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
        color: color,
      };
      // Add the object to the state array if it doesn't exist
      setVmsCOCSelectTag((prevState) => [...prevState, newTag]);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportCOCSelectTag: [
      //       ...VmsReportCOCSelectTag,
      //       {
      //         tagId: row.id,
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //         color: color,
      //       },
      //     ],
      //   })
      // );
    }
  };

  // POP Handele Redux

  const handleSelectPOPMachine = (row) => {
    const existingIndex = vmsPOPSelectMachine.findIndex(
      (color) =>
        color.machineId === row.device && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...vmsPOPSelectMachine];
      updateMachineId.splice(existingIndex, 1);
      setVmsPOPSelectMachine(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectmachine: updateMachineId,
      //   })
      // );

      const findSelectedTagUnit = vmsPOPSelectUnit.length
        ? vmsPOPSelectUnit.filter((f) => f.deviceId != param.deviceId)
        : [];

      setVmsPOPSelectUnit(findSelectedTagUnit);

      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectUnit: findSelectedTagUnit,
      //   })
      // );

      const findSelectedTeg = vmsPOPSelectTag.length
        ? vmsPOPSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];

      setVmsPOPSelectTag(findSelectedTeg);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectTag: findSelectedTeg,
      //   })
      // );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      const newDevice = {
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
      };
      // Add the object to the state array if it doesn't exist
      setVmsPOPSelectMachine((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectmachine: [
      //       ...VmsReportPOPSelectmachine,
      //       {
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handleSelectPOPTagUnit = (row) => {
    const requiredUnits = ["kvah", "kvarh", "kwh","agg"];
    const unitLowerCase = row.tag_unit.toLowerCase();

    const existingIndex = vmsPOPSelectUnit.findIndex(
      (color) =>
        color.tagUnit === unitLowerCase && color.plantId === param.plantId
    );

    if (existingIndex !== -1) {
      // Create a copy of the array and remove the item at the existing index
      const updateMachineId = [...vmsPOPSelectUnit];
      updateMachineId.splice(existingIndex, 1);
      setVmsPOPSelectUnit(updateMachineId);

      const hasRequiredUnitSelected = updateMachineId.some((unit) =>
        requiredUnits.includes(unit.tagUnit.toLowerCase())
      );
      setDisableOtherUnitsPOP(hasRequiredUnitSelected);
      // Dispatch the updated array to the state
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectUnit: updateMachineId,
      //   })
      // );

      // Filter out the items from VmsReportPOPSelectTag that do not match the deviceId
      const findSelectedTag = vmsPOPSelectTag.filter(
        (f) => f.deviceId !== param.deviceId
      );
      setVmsPOPSelectTag(findSelectedTag);

      // Clear the tag list data
      setTagListData([]);
    } else {
      // Add the new item to the state array
      const newUnit = {
        tagUnit: row.tag_unit,
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
      };
      // Add the object to the state array if it doesn't exist
      // setVmsPOPSelectUnit((prevState) => [...prevState, newUnit]);
      setVmsPOPSelectUnit((prevState) => {
        const updatedUnits = [...prevState, newUnit];

        // Check if a required unit is selected
        const hasRequiredUnitSelected = updatedUnits.some((unit) =>
          requiredUnits.includes(unit.tagUnit.toLowerCase())
        );

        // Disable other units if a required unit is selected
        setDisableOtherUnitsPOP(hasRequiredUnitSelected);

        // Turn off all other switches when a required unit is selected
        if (hasRequiredUnitSelected) {
          return updatedUnits.filter((unit) =>
            requiredUnits.includes(unit.tagUnit.toLowerCase())
          ); // Keep only the required units
        }

        return updatedUnits;
      });
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectUnit: [
      //       ...VmsReportPOPSelectUnit,
      //       {
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
    // Clear the selected tags and tag list
    setVmsPOPSelectTag([]);
    // dispatch(
    //   setVmsReportData({
    //     VmsReportPOPSelectTag: [],
    //   })
    // );
    setTagListData([]);
  };

  const handleSelectPOPTag = (row, color) => {
    const existingIndex = vmsPOPSelectTag.findIndex(
      (color) => color.tagId === row.id && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...vmsPOPSelectTag];
      updateMachineId.splice(existingIndex, 1);
      setVmsPOPSelectTag(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectTag: updateMachineId,
      //   })
      // );
    } else {
      // Add the object to the state array if it doesn't exist
      const oldDataTagSelected = [...vmsPOPSelectTag];

      const filterOldData = oldDataTagSelected.filter(
        (f) => f.deviceId != param.deviceId
      );
      const newTag = {
        tagId: row.id,
        tagUnit: row.tag_unit,
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
        color: color,
      };
      setVmsPOPSelectTag([...filterOldData, newTag]);

      // Update the Redux state
      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectTag: [...filterOldData, newTag],
      //   })
      // );

      // dispatch(
      //   setVmsReportData({
      //     VmsReportPOPSelectTag: [
      //       ...filterOldData,
      //       {
      //         tagId: row.id,
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //         color: color,
      //       },
      //     ],
      //   })
      // );
    }
  };

  // Reports handle Redux

  const handleSelectReportsMachine = (row) => {
    const existingIndex = VmsReportReportsSelectmachine.findIndex(
      (color) =>
        color.machineId === row.device && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportReportsSelectmachine];
      updateMachineId.splice(existingIndex, 1);
      setVmsReportSelectMachine(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectmachine: updateMachineId,
      //   })
      // );

      const finfSelectedTagUnit = VmsReportReportsSelectUnit.length
        ? VmsReportReportsSelectUnit.filter((f) => f.deviceId != param.deviceId)
        : [];
      setVmsReportReportsSelectUnit(finfSelectedTagUnit);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectUnit: finfSelectedTagUnit,
      //   })
      // );

      const findSelectedTeg = VmsReportReportsSelectTag.length
        ? VmsReportReportsSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      setVmsReportReportsSelectTag(findSelectedTeg);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectTag: findSelectedTeg,
      //   })
      // );
      setTagListData([]);
    } else {
      // Add the object to the state array if it doesn't exist
      const newDevice = {
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
      };
      // Add the object to the state array if it doesn't exist
      setVmsReportSelectMachine((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectmachine: [
      //       ...VmsReportReportsSelectmachine,
      //       {
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handleSelectReportsTagUnit = (row) => {
    const existingIndex = VmsReportReportsSelectUnit.findIndex(
      (color) =>
        color.tagUnit === row.tag_unit && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportReportsSelectUnit];
      updateMachineId.splice(existingIndex, 1);
      setVmsReportReportsSelectUnit(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectUnit: updateMachineId,
      //   })
      // );

      const findSelectedTeg = VmsReportReportsSelectTag.length
        ? VmsReportReportsSelectTag.filter((f) => f.deviceId != param.deviceId)
        : [];
      setVmsReportReportsSelectTag(findSelectedTeg);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectTag: findSelectedTeg,
      //   })
      // );
      setTagListData([]);
    } else {
      const newDevice = {
        tagUnit: row.tag_unit,
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
      };
      // Add the object to the state array if it doesn't exist
      setVmsReportReportsSelectUnit((prevState) => [...prevState, newDevice]);

      // Add the object to the state array if it doesn't exist
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectUnit: [
      //       ...VmsReportReportsSelectUnit,
      //       {
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handleSelectReportsTag = (row, color) => {
    const existingIndex = VmsReportReportsSelectTag.findIndex(
      (color) => color.tagId === row.id && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...VmsReportReportsSelectTag];
      updateMachineId.splice(existingIndex, 1);
      setVmsReportReportsSelectTag(updateMachineId);
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectTag: updateMachineId,
      //   })
      // );
    } else {
      const newDevice = {
        tagId: row.id,
        tagName: row.tag_name,
        tagUnit: row.tag_unit,
        machineId: row.device,
        dvName: row.device__device_name,
        plantId: param.plantId,
        deviceId: param.deviceId,
        color: color,
      };
      // Add the object to the state array if it doesn't exist
      setVmsReportReportsSelectTag((prevState) => [...prevState, newDevice]);

      // Add the object to the state array if it doesn't exist
      // dispatch(
      //   setVmsReportData({
      //     VmsReportReportsSelectTag: [
      //       ...VmsReportReportsSelectTag,
      //       {
      //         tagId: row.id,
      //         tagName: row.tag_name,
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         dvName: row.device__device_name,
      //         plantId: param.plantId,
      //         deviceId: param.deviceId,
      //         color: color,
      //       },
      //     ],
      //   })
      // );
    }

    setSelectTagIDReport1("none");
    setSelectTagIDReport2("none");
  };

  // COC handel date
  const handleStartDate = (newValue) => {
    const durationInDays = endDateReCOC
      ? endDateReCOC.diff(newValue, "days")
        ? endDateReCOC.diff(newValue, "days") > 0
          ? endDateReCOC.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setVmsReportCOCStartDate(null);
      // dispatch(setVmsReportData({ VmsReportCOCStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReCOC && newValue.isBefore(endDateReCOC)) {
      setVmsReportCOCStartDate(newValue);
      // dispatch(setVmsReportData({ VmsReportCOCStartDate: newValue }));
    } else if (!endDateReCOC) {
      setVmsReportCOCStartDate(newValue);
      // dispatch(setVmsReportData({ VmsReportCOCStartDate: newValue }));
    } else if (endDateReCOC && newValue.isSame(endDateReCOC, "day")) {
      // Check for time validation if showTime is true
      if (
        VmsReportTime &&
        (newValue.hour() > endDateReCOC.hour() ||
          (newValue.hour() === endDateReCOC.hour() &&
            newValue.minute() >= endDateReCOC.minute()))
      ) {
        setVmsReportCOCStartDate(null);
        // dispatch(setVmsReportData({ VmsReportCOCStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        setVmsReportCOCStartDate(newValue);
        // dispatch(setVmsReportData({ VmsReportCOCStartDate: newValue }));
      }
    } else {
      setVmsReportCOCStartDate(null);
      // dispatch(setVmsReportData({ VmsReportCOCStartDate: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setVmsReportData({ VmsReportCOCStartDate: newValue }));
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReCOC
      ? startDateReCOC.diff(newValue, "days")
        ? startDateReCOC.diff(newValue, "days") > 0
          ? startDateReCOC.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setVmsReportCOCEndDate(null);
      // dispatch(setVmsReportData({ VmsReportCOCEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReCOC && newValue.isAfter(startDateReCOC)) {
      setVmsReportCOCEndDate(newValue);
      // dispatch(setVmsReportData({ VmsReportCOCEndDate: newValue }));
    } else if (!startDateReCOC) {
      setVmsReportCOCEndDate(newValue);
      // dispatch(setVmsReportData({ VmsReportCOCEndDate: newValue }));
    } else if (startDateReCOC && newValue.isSame(startDateReCOC, "day")) {
      // Check for time validation if showTime is true
      if (
        VmsReportTime &&
        (newValue.hour() < startDateReCOC.hour() ||
          (newValue.hour() === startDateReCOC.hour() &&
            newValue.minute() <= startDateReCOC.minute()))
      ) {
        setVmsReportCOCEndDate(null);
        // dispatch(setVmsReportData({ VmsReportCOCEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        setVmsReportCOCEndDate(newValue);
        // dispatch(setVmsReportData({ VmsReportCOCEndDate: newValue }));
      }
    } else {
      setVmsReportCOCEndDate(null);
      // dispatch(setVmsReportData({ VmsReportCOCEndDate: null }));
      notifyError("Start date must be less than end date");
    }
    // dispatch(setVmsReportData({ VmsReportCOCEndDate: newValue }));
  };

  const handleToggleTime = () => {
    setVmsReportTime(!VmsReportTime);
    // dispatch(setVmsReportData({ VmsReportTime: !VmsReportTime }));
  };

  // POP handle date
  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOP
      ? endDatePOP.diff(newValue, "days")
        ? endDatePOP.diff(newValue, "days") > 0
          ? endDatePOP.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setVmsReportPOPStartDate1(null);
      // dispatch(setVmsReportData({ VmsReportPOPStartDate1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP && newValue.isBefore(endDatePOP)) {
      setVmsReportPOPStartDate1(newValue);
      // dispatch(setVmsReportData({ VmsReportPOPStartDate1: newValue }));
    } else if (!endDatePOP) {
      setVmsReportPOPStartDate1(newValue);
      // dispatch(setVmsReportData({ VmsReportPOPStartDate1: newValue }));
    } else if (endDatePOP && newValue.isSame(endDatePOP, "day")) {
      // Check for time validation if showTime is true
      if (
        VmsReportPOPTime &&
        (newValue.hour() > endDatePOP.hour() ||
          (newValue.hour() === endDatePOP.hour() &&
            newValue.minute() >= endDatePOP.minute()))
      ) {
        setVmsReportPOPStartDate1(null);
        // dispatch(setVmsReportData({ VmsReportPOPStartDate1: null }));
        notifyError("Start time must be before end time");
      } else {
        setVmsReportPOPStartDate1(newValue);
        // dispatch(setVmsReportData({ VmsReportPOPStartDate1: newValue }));
      }
    } else {
      setVmsReportPOPStartDate1(null);
      // dispatch(setVmsReportData({ VmsReportPOPStartDate1: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setVmsReportData({ VmsReportPOPStartDate1: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDatePOP
      ? startDatePOP.diff(newValue, "days")
        ? startDatePOP.diff(newValue, "days") > 0
          ? startDatePOP.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setVmsReportPOPEndDate1(null);
      // dispatch(setVmsReportData({ VmsReportPOPEndDate1: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP && newValue.isAfter(startDatePOP)) {
      setVmsReportPOPEndDate1(newValue);
      // dispatch(setVmsReportData({ VmsReportPOPEndDate1: newValue }));
    } else if (!startDatePOP) {
      setVmsReportPOPEndDate1(newValue);
      // dispatch(setVmsReportData({ VmsReportPOPEndDate1: newValue }));
    } else if (startDatePOP && newValue.isSame(startDatePOP, "day")) {
      // Check for time validation if showTime is true
      if (
        VmsReportPOPTime &&
        (newValue.hour() < startDatePOP.hour() ||
          (newValue.hour() === startDatePOP.hour() &&
            newValue.minute() <= startDatePOP.minute()))
      ) {
        setVmsReportPOPEndDate1(null);
        // dispatch(setVmsReportData({ VmsReportPOPEndDate1: null }));
        notifyError("End time must be after start time");
      } else {
        setVmsReportPOPEndDate1(newValue);
        // dispatch(setVmsReportData({ VmsReportPOPEndDate1: newValue }));
      }
    } else {
      setVmsReportPOPEndDate1(null);
      // dispatch(setVmsReportData({ VmsReportPOPEndDate1: null }));
      notifyError("Start date must be less than end date");
    }
    // dispatch(setVmsReportData({ VmsReportPOPEndDate1: newValue }));
  };

  const handlePOPToggleTime = () => {
    setVmsReportPOPTime(!VmsReportPOPTime);
    // dispatch(setVmsReportData({ VmsReportPOPTime: !VmsReportPOPTime }));
  };

  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP2
      ? endDatePOP2.diff(newValue, "days")
        ? endDatePOP2.diff(newValue, "days") > 0
          ? endDatePOP2.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setVmsReportPOPStartDate2(null);
      // dispatch(setVmsReportData({ VmsReportPOPStartDate2: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP2 && newValue.isBefore(endDatePOP2)) {
      setVmsReportPOPStartDate2(newValue);
      // dispatch(setVmsReportData({ VmsReportPOPStartDate2: newValue }));
    } else if (!endDatePOP2) {
      setVmsReportPOPStartDate2(newValue);
      // dispatch(setVmsReportData({ VmsReportPOPStartDate2: newValue }));
    } else if (endDatePOP2 && newValue.isSame(endDatePOP2, "day")) {
      // Check for time validation if showTime is true
      if (
        VmsReportPOPTime &&
        (newValue.hour() > endDatePOP2.hour() ||
          (newValue.hour() === endDatePOP2.hour() &&
            newValue.minute() >= endDatePOP2.minute()))
      ) {
        setVmsReportPOPStartDate2(null);
        // dispatch(setVmsReportData({ VmsReportPOPStartDate2: null }));
        notifyError("Start time must be before end time");
      } else {
        setVmsReportPOPStartDate2(newValue);
        // dispatch(setVmsReportData({ VmsReportPOPStartDate2: newValue }));
      }
    } else {
      setVmsReportPOPStartDate2(null);
      // dispatch(setVmsReportData({ VmsReportPOPStartDate2: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setVmsReportData({ VmsReportPOPStartDate2: newValue }));
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP2
      ? startDatePOP2.diff(newValue, "days")
        ? startDatePOP2.diff(newValue, "days") > 0
          ? startDatePOP2.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setVmsReportPOPEndDate2(null);
      // dispatch(setVmsReportData({ VmsReportPOPEndDate2: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP2 && newValue.isAfter(startDatePOP2)) {
      setVmsReportPOPEndDate2(newValue);
      // dispatch(setVmsReportData({ VmsReportPOPEndDate2: newValue }));
    } else if (!startDatePOP2) {
      setVmsReportPOPEndDate2(newValue);
      // dispatch(setVmsReportData({ VmsReportPOPEndDate2: newValue }));
    } else if (startDatePOP2 && newValue.isSame(startDatePOP2, "day")) {
      // Check for time validation if showTime is true
      if (
        VmsReportPOPTime &&
        (newValue.hour() < startDatePOP2.hour() ||
          (newValue.hour() === startDatePOP2.hour() &&
            newValue.minute() <= startDatePOP2.minute()))
      ) {
        setVmsReportPOPEndDate2(null);
        // dispatch(setVmsReportData({ VmsReportPOPEndDate2: null }));
        notifyError("End time must be after start time");
      } else {
        setVmsReportPOPEndDate2(newValue);
        // dispatch(setVmsReportData({ VmsReportPOPEndDate2: newValue }));
      }
    } else {
      setVmsReportPOPEndDate2(null);
      // dispatch(setVmsReportData({ VmsReportPOPEndDate2: null }));
      notifyError("Start date must be less than end date");
    }
  };

  // Report handle date
  const handleStartDateReports = (newValue) => {
    const durationInDays = endDateReReports
      ? endDateReReports.diff(newValue, "days")
        ? endDateReReports.diff(newValue, "days") > 0
          ? endDateReReports.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setVmsReportReportsStartDate(null);
      // dispatch(setVmsReportData({ VmsReportReportsStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReReports && newValue.isBefore(endDateReReports)) {
      setVmsReportReportsStartDate(newValue);
      // dispatch(setVmsReportData({ VmsReportReportsStartDate: newValue }));
    } else if (!endDateReReports) {
      setVmsReportReportsStartDate(newValue);
      // dispatch(setVmsReportData({ VmsReportReportsStartDate: newValue }));
    } else if (endDateReReports && newValue.isSame(endDateReReports, "day")) {
      // Check for time validation if showTime is true
      if (
        VmsReportReportsTime &&
        (newValue.hour() > endDateReReports.hour() ||
          (newValue.hour() === endDateReReports.hour() &&
            newValue.minute() >= endDateReReports.minute()))
      ) {
        setVmsReportReportsStartDate(null);
        // dispatch(setVmsReportData({ VmsReportReportsStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        setVmsReportReportsStartDate(newValue);
        // dispatch(setVmsReportData({ VmsReportReportsStartDate: newValue }));
      }
    } else {
      setVmsReportReportsStartDate(null);
      // dispatch(setVmsReportData({ VmsReportReportsStartDate: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setVmsReportData({ VmsReportReportsStartDate: newValue }));
  };

  const handleEndDateReports = (newValue) => {
    const durationInDays = startDateReReports
      ? startDateReReports.diff(newValue, "days")
        ? startDateReReports.diff(newValue, "days") > 0
          ? startDateReReports.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setVmsReportReportsEndDate(null);
      // dispatch(setVmsReportData({ VmsReportReportsEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReReports && newValue.isAfter(startDateReReports)) {
      setVmsReportReportsEndDate(newValue);
      // dispatch(setVmsReportData({ VmsReportReportsEndDate: newValue }));
    } else if (!startDateReReports) {
      setVmsReportReportsEndDate(newValue);
      // dispatch(setVmsReportData({ VmsReportReportsEndDate: newValue }));
    } else if (
      startDateReReports &&
      newValue.isSame(startDateReReports, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        VmsReportReportsTime &&
        (newValue.hour() < startDateReReports.hour() ||
          (newValue.hour() === startDateReReports.hour() &&
            newValue.minute() <= startDateReReports.minute()))
      ) {
        setVmsReportReportsEndDate(null);
        // dispatch(setVmsReportData({ VmsReportReportsEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        setVmsReportReportsEndDate(newValue);
        // dispatch(setVmsReportData({ VmsReportReportsEndDate: newValue }));
      }
    } else {
      setVmsReportReportsEndDate(null);
      // dispatch(setVmsReportData({ VmsReportReportsEndDate: null }));
      notifyError("Start date must be less than end date");
    }
    // dispatch(setVmsReportData({ VmsReportReportsEndDate: newValue }));
  };

  const handleToggleTimeReports = () => {
    setVmsReportReportsTime(!VmsReportReportsTime);
    // dispatch(setVmsReportData({ VmsReportReportsTime: !VmsReportReportsTime }));
  };

  const handleSubmitRange = () => {
    setRangeCOC(rangeCOCValue);
    // dispatch(setVmsReportData({ vmsReportCOCRange: rangeCOCValue }));
    setRangeModal(false);
  };
  const handleSubmitRangePOP = () => {
    setRangePOP(rangePOPValue);
    // dispatch(setVmsReportData({ vmsReportPOPRange: rangePOPValue }));
    setRangeModalPOP(false);
  };

  useEffect(() => {
    const findSelectedTag = vmsCOCSelectTag.length
      ? vmsCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsCOCSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];

    if (
      findSelectedTag.length &&
      startDateFormatCOC &&
      endDateFormatCOC &&
      rangeCOC !== "none" &&
      activeTabClass == "Consumer"
    ) {
      handleStoreSeletedData();
    } else {
      setCocGraphData([]);
    }
  }, [
    VmsReportTimeConsumer,
    vmsCOCSelectTag,
    startDateFormatCOC,
    endDateFormatCOC,
    rangeCOC,
    activeTabClass,
    VmsReportTime,
    startTimeFormatCOC,
    endTimeFormatCOC,
    VmsReportCOCStartDate,
    LineCOCIntervalTime,
  ]);

  useEffect(() => {
    const findSelectedTag = vmsCOCSelectTag.length
      ? vmsCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsCOCSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];

    if (
      findSelectedTag.length &&
      startDateFormatCOC &&
      endDateFormatCOC &&
      aggCOC &&
      activeTabClass == "Consumer"
    ) {
      handleStoreSeletedData();
    } else {
      setCocGraphData([]);
    }
  }, [
    VmsReportTimeConsumer,
    vmsCOCSelectTag,
    startDateFormatCOC,
    endDateFormatCOC,
    aggCOC,
    activeTabClass,
    VmsReportTime,
    startTimeFormatCOC,
    endTimeFormatCOC,
    VmsReportCOCStartDate,
    LineCOCIntervalTime,
  ]);

  useEffect(() => {
    const findSelectedTag = VmsReportReportsSelectTag.length
      ? VmsReportReportsSelectTag.filter((f) => f.deviceId == param.deviceId)
          .length
        ? VmsReportReportsSelectTag.filter(
            (f) => f.deviceId == param.deviceId
          ).map((row) => row.tagId)
        : []
      : [];

    if (
      findSelectedTag.length &&
      VmsReportReportsStartDate &&
      VmsReportReportsEndDate &&
      activeClass == "Reports"
    ) {
      handleStoreReportSeletedData();
    } else {
      setReportLineChartData1([]);
      setReportLineChartData2([]);
    }
  }, [
    VmsReportSwitch,
    VmsReportReportsSelectTag,
    VmsReportReportsSelectmachine,
    VmsReportReportsSelectUnit,
    VmsReportReportsStartDate,
    VmsReportReportsEndDate,
    selectTimeReportRange,
    activeClass,
    VmsReportReportsTime,
    VmsReportReportsStartDate,
    VmsReportReportsEndDate,
    reportsIntervalTime1,
    selectTagIDReport1,
    selectTagIDReport2
  ]);
  console.log("vmsCOCSelectTag", vmsCOCSelectTag);

  const handleStoreSeletedData = async () => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      dashboard: 2,
      module_display_name: activeTabClass,
      device: VmsCOCSelectMachine.length
        ? VmsCOCSelectMachine.filter((f) => f.deviceId == param.deviceId).length
          ? VmsCOCSelectMachine.filter((f) => f.deviceId == param.deviceId).map(
              (row) => ({
                device_id: row.machineId, // Extract id as tag_id
                color: "null", // Extract color
              })
            )
          : []
        : [],
      tag_unit: vmsCOCSelectUnit
        .filter((f) => f.deviceId == param.deviceId)
        ?.map((row) => row.tagUnit),
      tag: vmsCOCSelectTag.length
        ? vmsCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
          ? vmsCOCSelectTag
              .filter((f) => f.deviceId == param.deviceId)
              .map((row) => ({
                tag_id: row.tagId, // Extract id as tag_id
                color: row.color, // Extract color
              }))
          : []
        : [],

      interval: LineCOCIntervalTime,
      is_store: VmsReportTimeConsumer,
      recipe_name: [],
    };
    if (disableOtherUnits) {
      params["aggrigation"] = Number(aggCOC);
    } else {
      params["set_range"] = rangeCOC;
    }
    try {
      const resp = await storeSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleStoreReportSeletedData = async () => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      dashboard: 2,
      module_display_name: activeClass,
      report_device: VmsReportReportsSelectmachine.length
        ? VmsReportReportsSelectmachine.filter(
            (f) => f.deviceId == param.deviceId
          ).length
          ? VmsReportReportsSelectmachine.filter(
              (f) => f.deviceId == param.deviceId
            ).map((row) => row.machineId)
          : []
        : [],
      tag_unit: VmsReportReportsSelectUnit.filter(
        (f) => f.deviceId == param.deviceId
      )?.map((row) => row.tagUnit),
      tag: VmsReportReportsSelectTag.length
        ? VmsReportReportsSelectTag.filter((f) => f.deviceId == param.deviceId)
            .length
          ? VmsReportReportsSelectTag.filter(
              (f) => f.deviceId == param.deviceId
            ).map((row) => ({
              tag_id: row.tagId,
              color: row.color,
            }))
          : []
        : [],

      interval: reportsIntervalTime1,
      is_store: VmsReportSwitch,
      left_tag_unit: selectTagIDReport1,
      right_tag_unit: selectTagIDReport2,
      recipe_name: [],
    };
    if (hasRequiredUnits) {
      params["aggrigation"] = Number(aggCOC);
    }
    try {
      const resp = await storeSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP &&
      endDateFormatPOP &&
      activeTabClass == "Period"
    ) {
      handleStoreSeletedDataPOP();
    } else {
      setPopBarGraphData1([]);
    }
  }, [
    VmsReportTimePeriod,
    vmsPOPSelectTag,
    startDateFormatPOP,
    endDateFormatPOP,
    rangePOP,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP,
    endTimeFormatPOP,
    LinePOPIntervalTime,
    aggPOP,
  ]);

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP2 &&
      endDateFormatPOP2 &&
      rangePOP != "none" &&
      activeTabClass == "Period"
    ) {
      handleStoreSeletedDataPOP();
    } else {
      setPopBarGraphData2([]);
    }
  }, [
    VmsReportTimePeriod && vmsPOPSelectTag,
    startDateFormatPOP2,
    endDateFormatPOP2,
    rangePOP,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP2,
    endTimeFormatPOP2,
    aggPOP,
  ]);

  const handleStoreSeletedDataPOP = async () => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      dashboard: 2,
      module_display_name: activeTabClass,
      device: vmsPOPSelectMachine.length
        ? vmsPOPSelectMachine.filter((f) => f.deviceId == param.deviceId).length
          ? vmsPOPSelectMachine
              .filter((f) => f.deviceId == param.deviceId)
              .map((row) => ({
                device_id: row.machineId, // Extract id as tag_id
                color: "null", // Extract color
              }))
          : []
        : [],
      tag_unit: vmsPOPSelectUnit
        .filter((f) => f.deviceId == param.deviceId)
        ?.map((row) => row.tagUnit),

      tag: vmsPOPSelectTag.length
        ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
          ? vmsPOPSelectTag
              .filter((f) => f.deviceId == param.deviceId)
              .map((row) => ({
                tag_id: row.tagId, // Extract id as tag_id
                color: row.color, // Extract color
              }))
          : []
        : [],
      interval: LinePOPIntervalTime,
      is_store: VmsReportTimePeriod,
      recipe_name: [],
    };
    if (disableOtherUnitsPOP) {
      params["aggrigation"] = Number(aggPOP);
    } else {
      params["set_range"] = rangePOP;
    }
    try {
      const resp = await storeSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (!VmsReportTimeConsumer) {
      setVmsCOCSelectMachine([]);
      setVmsCOCSelectTag([]);
      setVmsCOCSelectUnit([]);
      setTagListData([]);
      setRangeCOC("");
      setVmsReportCOCStartDate(null);
      setVmsReportCOCEndDate(null);
      setVmsReportTime(false);
      setLineCOCIntervalTime("1");
      setAggCOC(15);
    }

    if (!VmsReportTimePeriod) {
      setVmsPOPSelectMachine([]);
      setVmsPOPSelectTag([]);
      setVmsPOPSelectUnit([]);
      setTagListData([]);
      setRangePOP("");
      setVmsReportPOPStartDate1(null);
      setVmsReportPOPEndDate1(null);
      setVmsReportPOPStartDate2(null);
      setVmsReportPOPEndDate2(null);
      setVmsReportPOPTime(false);
      setLinePOPIntervalTime("1");
      setAggPOP(15);
      setPiechartDataPOP([]);
      setPiechartDataPOP1([]);
      // setVmsReportPOPStartDate(null)
    }
  }, [VmsReportTimeConsumer, VmsReportTimePeriod, activeTabClass]);

  useEffect(() => {
    if (activeTabClass == "Consumer") {
      handleGetStorePreviousSelectedData();
    } else if (!VmsReportTimeConsumer) {
      setSelectedPreviousData([]);
      setTagListData([]);
      setVmsCOCSelectMachine([]);
      setVmsCOCSelectTag([]);
      setVmsCOCSelectUnit([]);
      setTagListData([]);
      setRangeCOC("");
      setVmsReportCOCStartDate(null);
      setVmsReportCOCEndDate(null);
      setVmsReportTime(false);
      setLineCOCIntervalTime("1");
      setCocLineChartData([]);
      setAggCOC(15);
      setPiechartData([]);
      setPieChartSum("");
    }
  }, [activeTabClass]);

  useEffect(() => {
    if (activeTabClass == "Period") {
      handleGetStorePreviousSelectedData();
    } else if (!VmsReportTimePeriod) {
      setSelectedPreviousData([]);
      setVmsPOPSelectMachine([]);
      setVmsPOPSelectTag([]);
      setVmsPOPSelectUnit([]);
      setTagListData([]);
      setRangePOP("");
      setVmsReportPOPStartDate1(null);
      setVmsReportPOPEndDate1(null);
      setVmsReportPOPStartDate2(null);
      setVmsReportPOPEndDate2(null);
      setVmsReportPOPTime(false);
      setLinePOPIntervalTime("1");
      setPopLineChartData([]);
      setPiechartDataPOP([]);
      setPiechartDataPOP1([]);
      setAggPOP(15);
    }
  }, [activeTabClass]);

  useEffect(() => {
    if (activeClass == "Reports") {
      handleGetStorePreviousReportSelectedData();
    } else if (!VmsReportSwitch) {
      setSelectedPreviousData([]);
      setTagListData([]);
      setVmsReportSelectMachine([]);
      setVmsReportReportsSelectTag([]);
      setVmsReportReportsSelectUnit([]);
      setVmsReportReportsEndDate(null);
      setVmsReportReportsStartDate(null);
      setVmsReportReportsTime(false);
      setReportIntervalTime1("1");
      setSelectTagIDReport1("none");
      setSelectTagIDReport2("none");
      setSelectTimeReportRange(15);

    }
  }, [activeClass]);

  useEffect(()=>{
    if(!VmsReportSwitch){
      setSelectedPreviousData([]);
      setTagListData([]);
      setVmsReportSelectMachine([]);
      setVmsReportReportsSelectTag([]);
      setVmsReportReportsSelectUnit([]);
      setVmsReportReportsEndDate(null);
      setVmsReportReportsStartDate(null);
      setVmsReportReportsTime(false);
      setReportIntervalTime1("1");
      setSelectTagIDReport1("none");
      setSelectTagIDReport2("none");
      setSelectTimeReportRange(15);
    }
  },[VmsReportSwitch])

  const handleGetStorePreviousReportSelectedData = async () => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      dashboard: 2,
      module_display_name: activeClass,
      report_device: [],
      report_tag_unit: [],
      tag: [],
      recipe_name: [],
      tag_unit: [],
    };
    try {
      const resp = await getStorePreviousSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        setSelectedPreviousData(resp.data.payload);

        const cocDeviceData = resp.data.payload?.device?.map((row) => {
          return {
            machineId: row.id,
            dvName: row.device_name,
            plantId: param.plantId,
            deviceId: param.deviceId,
          };
        });
        setVmsReportSelectMachine(cocDeviceData);

        // Tag Unit Data
        const cocTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
          return {
            tagUnit: row,
            machineId: "",
            dvName: "",
            plantId: param.plantId,
            deviceId: param.deviceId,
          };
        });
        setVmsReportReportsSelectUnit(cocTagUnitData);

        // Tag Data
        const cocTagDataData = resp.data.payload?.tag?.map((row) => {
          return {
            tagId: row.id,
            tagUnit: row.tag_unit,
            tagName: row.tag_name,
            machineId: row.device_id,
            dvName: row.device_name,
            plantId: param.plantId,
            deviceId: param.deviceId,
            color: row.color,
          };
        });
        setVmsReportReportsSelectTag(cocTagDataData);

        const IntervalData = resp.data.payload.interval;
        setReportIntervalTime1(IntervalData);

        const switchData = resp.data.payload?.is_store;
        setVmsReportSwitch(switchData);

        const agg = resp.data.payload?.aggrigation;
        setSelectTimeReportRange(agg);

        setSelectTagIDReport1(resp.data.payload.left_tag_unit || []);
        setSelectTagIDReport2(resp.data.payload.right_tag_unit || []);
        setVmsReportSwitch(resp.data.payload?.is_store || false);
      }
    } catch (error) {
      setSelectedPreviousData([]);
      console.log("handleGetStorePreviousSelectedData Error:", error);
    }
  };

  const handleGetStorePreviousSelectedData = async () => {
    const requiredUnits = ["kvah", "kvarh", "kwh","agg"]; // List of required units
    const params = {
      company: param.companyId,
      plant: param.plantId,
      dashboard: 2,
      module_display_name: activeTabClass,
      device: [],
      tag_unit: [],
      tag: [],
      recipe_name: [],
    };
    try {
      const resp = await getStorePreviousSelectedData(params);
      if (resp.status == 200 || resp.status == 201) {
        setSelectedPreviousData(resp.data.payload);

        // Store data for COC
        if (activeTabClass == "Consumer") {
          // Machine data
          const cocDeviceData = resp.data.payload?.device?.map((row) => {
            return {
              machineId: row.id,
              dvName: row.device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            };
          });
          setVmsCOCSelectMachine(cocDeviceData);

          // Tag Unit Data
          const cocTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
            return {
              tagUnit: row,
              machineId: "",
              dvName: "",
              plantId: param.plantId,
              deviceId: param.deviceId,
            };
          });
          setVmsCOCSelectUnit(cocTagUnitData);

          // Tag Data
          const cocTagDataData = resp.data.payload?.tag?.map((row) => {
            return {
              tagId: row.id,
              tagUnit: row.tag_unit,
              machineId: row.device_id,
              dvName: row.device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
              color: row.color,
            };
          });
          setVmsCOCSelectTag(cocTagDataData);

          // Check if any of the required units are selected
          const hasRequiredUnit = resp.data.payload?.tag_unit?.some((unit) =>
            requiredUnits.includes(unit.toLowerCase())
          );

          // Disable other units if one of the required units is found
          setDisableOtherUnits(hasRequiredUnit);

          const cocRange = resp.data.payload.set_range;
          setRangeCOC(cocRange);

          const IntervalData = resp.data.payload.interval;
          setLineCOCIntervalTime(IntervalData);

          const switchData = resp.data.payload?.is_store;
          setVmsReportTimeConsumer(switchData);

          const agg = resp.data.payload?.aggrigation;
          setAggCOC(agg);
        } else if (activeTabClass == "Period") {
          // Similar logic for Period tab
          const POPDeviceData = resp.data.payload?.device?.map((row) => {
            return {
              machineId: row.id,
              dvName: row.device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            };
          });
          setVmsPOPSelectMachine(POPDeviceData);

          const POPTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
            return {
              tagUnit: row,
              machineId: "",
              dvName: "",
              plantId: param.plantId,
              deviceId: param.deviceId,
            };
          });
          setVmsPOPSelectUnit(POPTagUnitData);

          const POPTagDataData = resp.data.payload?.tag?.map((row) => {
            return {
              tagId: row.id,
              tagUnit: row.tag_unit,
              machineId: row.device_id,
              dvName: row.device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
              color: row.color,
            };
          });
          setVmsPOPSelectTag(POPTagDataData);

          // Check if any required unit is selected in the Period tab
          const hasRequiredUnit = resp.data.payload?.tag_unit?.some((unit) =>
            requiredUnits.includes(unit.toLowerCase())
          );

          setDisableOtherUnitsPOP(hasRequiredUnit);

          const POPRange = resp.data.payload.set_range;
          setRangePOP(POPRange);

          const IntervalData = resp.data.payload.interval;
          setLinePOPIntervalTime(IntervalData);

          const switchData = resp.data.payload?.is_store;
          setVmsReportTimePeriod(switchData);

          const agg = resp.data.payload?.aggrigation;
          setAggPOP(agg);
        }
      }
    } catch (error) {
      setSelectedPreviousData([]);
      console.log("handleGetStorePreviousSelectedData Error:", error);
    }
  };

  useEffect(() => {
    if (VmsReportTimeConsumer) {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;

      setVmsReportCOCStartDate(startDate);
      setVmsReportCOCEndDate(endDate);
    } else {
      // When the switch is off, clear the start and end dates
      setVmsReportCOCStartDate(null);
      setVmsReportCOCEndDate(null);
    }
  }, [VmsReportTimeConsumer]);

  useEffect(() => {
    if (VmsReportTimePeriod) {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;
      setVmsReportPOPStartDate1(startDate);
      setVmsReportPOPEndDate1(endDate);
      setVmsReportPOPStartDate2(startDate);
      setVmsReportPOPEndDate2(endDate);
      // setVmsReportCOCStartDate(startDate);
      // setVmsReportCOCEndDate(endDate);
    } else {
      // When the switch is off, clear the start and end dates
      setVmsReportPOPStartDate1(null);
      setVmsReportPOPEndDate1(null);
      setVmsReportPOPStartDate2(null);
      setVmsReportPOPEndDate2(null);
    }
  }, [VmsReportTimePeriod]);

  useEffect(() => {
    if (VmsReportSwitch) {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;

      setVmsReportReportsStartDate(startDate);
      setVmsReportReportsEndDate(endDate);
    } else {
      // When the switch is off, clear the start and end dates
      setVmsReportReportsStartDate(null);
      setVmsReportReportsEndDate(null);
    }
  }, [VmsReportSwitch]);
  // getReportParatoChat API
  const getReportParatoChatAPI = async (
    tagId,
    startDate,
    endDate,
    rangeCOC,
    setState,
    timeActive,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      range_selected: rangeCOC,
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportAPIComp(paramReq, setState, setShimmerCOC);
    } catch (error) {
      console.log("errror", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = vmsCOCSelectTag.length
      ? vmsCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsCOCSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];

    if (
      findSelectedTag.length &&
      startDateFormatCOC &&
      endDateFormatCOC &&
      rangeCOC !== "none" &&
      activeTabClass == "Consumer"
    ) {
      getReportParatoChatAPI(
        findSelectedTag,
        startDateFormatCOC,
        endDateFormatCOC,
        rangeCOC,
        setCocGraphData,
        VmsReportTime,
        startTimeFormatCOC,
        endTimeFormatCOC
      );
    } else {
      setCocGraphData([]);
    }
  }, [
    vmsCOCSelectTag,
    startDateFormatCOC,
    endDateFormatCOC,
    rangeCOC,
    activeTabClass,
    VmsReportTime,
    startTimeFormatCOC,
    endTimeFormatCOC,
  ]);

  const getReportAnalyticsDataAPI = async (
    tagId,
    startDate,
    endDate,
    rangeCOC,
    setState,
    smartTagCOCTime,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
      aggregate: Number(aggCOC),
    };

    if (smartTagCOCTime) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    }
    console.log("paramReq", paramReq);
    try {
      getReportAPIAnalyticsData(paramReq, setState, setShimmerCOC);
    } catch (error) {
      console.log("errror", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = vmsCOCSelectTag.length
      ? vmsCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsCOCSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];

    if (
      disableOtherUnits &&
      findSelectedTag.length &&
      startDateFormatCOC &&
      endDateFormatCOC &&
      aggCOC &&
      activeTabClass == "Consumer"
    ) {
      getReportAnalyticsDataAPI(
        findSelectedTag,
        startDateFormatCOC,
        endDateFormatCOC,
        rangeCOC,
        setCocGraphData,
        VmsReportTime,
        startTimeFormatCOC,
        endTimeFormatCOC,
        aggCOC
      );
    } else {
      setCocGraphData([]);
    }
  }, [
    disableOtherUnits && vmsCOCSelectTag,
    startDateFormatCOC,
    endDateFormatCOC,
    rangeCOC,
    activeTabClass,
    VmsReportTime,
    startTimeFormatCOC,
    endTimeFormatCOC,
    aggCOC,
  ]);

  const getPiechartCOCData = async (
    tagId,
    startDate,
    endDate,
    rangeCOC,
    setState,
    startTime,
    endTime
  ) => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
      aggregate: Number(aggCOC),
    };

    if (VmsReportTime) {
      param["start_date"] = `${startDate} ${startTime}`;
      param["end_date"] = `${endDate} ${endTime}`;
    }

    try {
      const resp = await getPiechartCOC(params);
      if (resp.status == 200 || resp.status == 201) {
        setPiechartData(resp.data.payload);
        setPieChartSum(resp.data);
      }
    } catch (error) {
      setPiechartData([]);
      setPieChartSum([]);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = vmsCOCSelectTag.length
      ? vmsCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsCOCSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];

    if (
      disableOtherUnits &&
      findSelectedTag.length &&
      startDateFormatCOC &&
      endDateFormatCOC &&
      activeTabClass == "Consumer"
    ) {
      getPiechartCOCData(
        findSelectedTag,
        startDateFormatCOC,
        endDateFormatCOC,
        rangeCOC,
        setCocGraphData,
        VmsReportTime,
        startTimeFormatCOC,
        endTimeFormatCOC
      );
    }
  }, [
    disableOtherUnits && vmsCOCSelectTag,
    startDateFormatCOC,
    endDateFormatCOC,
    rangeCOC,
    activeTabClass,
    VmsReportTime,
    startTimeFormatCOC,
    endTimeFormatCOC,
    aggCOC,
  ]);

  const getReportAnalyticsDataAPIPOP = async (
    tagId,
    startDate,
    endDate,
    rangeCOC,
    setState,
    smartTagCOCTime,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
      aggregate: Number(aggPOP),
    };

    if (VmsReportPOPTime) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    }
    console.log("paramReq", paramReq);
    try {
      getReportAPIAnalyticsData(paramReq, setState, setShimmerCOC);
    } catch (error) {
      console.log("errror", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      disableOtherUnitsPOP &&
      findSelectedTag.length &&
      startDateFormatPOP &&
      endDateFormatPOP &&
      aggPOP &&
      activeTabClass == "Period"
    ) {
      getReportAnalyticsDataAPIPOP(
        findSelectedTag,
        startDateFormatPOP,
        endDateFormatPOP,
        rangePOP,
        setPopBarGraphData1,
        VmsReportPOPTime,
        startTimeFormatPOP,
        endTimeFormatPOP
      );
    } else {
      setPopBarGraphData1([]);
    }
  }, [
    disableOtherUnitsPOP,
    vmsPOPSelectTag,
    startDateFormatPOP,
    endDateFormatPOP,
    rangePOP,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP,
    endTimeFormatPOP,
    aggPOP,
  ]);

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP2 &&
      endDateFormatPOP2 &&
      aggPOP &&
      activeTabClass == "Period"
    ) {
      getReportAnalyticsDataAPIPOP(
        findSelectedTag,
        startDateFormatPOP2,
        endDateFormatPOP2,
        rangePOP,
        setPopBarGraphData2,
        VmsReportPOPTime,
        startTimeFormatPOP2,
        endTimeFormatPOP2
      );
    } else {
      setPopBarGraphData2([]);
    }
  }, [
    vmsPOPSelectTag,
    startDateFormatPOP2,
    endDateFormatPOP2,
    rangePOP,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP2,
    endTimeFormatPOP2,
    aggPOP,
  ]);

  const getPiechartPOPData = async (
    tagId,
    startDate,
    endDate,
    rangeCOC,
    setState,
    smartTagCOCTime,
    startTime,
    endTime
  ) => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
      aggregate: Number(aggPOP),
    };
    if (VmsReportPOPTime) {
      params["start_date"] = `${startDate} ${startTime}`;
      params["end_date"] = `${endDate} ${endTime}`;
    }
    try {
      const resp = await getPiechartCOC(params);
      if (resp.status == 200 || resp.status == 201) {
        setPiechartDataPOP(resp.data.payload);
      }
    } catch (error) {
      setPiechartDataPOP([]);

      console.log("handlesubmit", error);
    }
  };

  const getPiechartPOPData1 = async (
    tagId,
    startDate,
    endDate,
    rangeCOC,
    setState,
    smartTagCOCTime,
    startTime,
    endTime
  ) => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
      aggregate: Number(aggPOP),
    };
    if (VmsReportPOPTime) {
      params["start_date"] = `${startDate} ${startTime}`;
      params["end_date"] = `${endDate} ${endTime}`;
    }
    try {
      const resp = await getPiechartCOC(params);
      if (resp.status == 200 || resp.status == 201) {
        setPiechartDataPOP1(resp.data.payload);
      }
    } catch (error) {
      setPiechartDataPOP1([]);

      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      disableOtherUnitsPOP &&
      findSelectedTag.length &&
      startDateFormatPOP &&
      endDateFormatPOP &&
      aggPOP &&
      activeTabClass == "Period"
    ) {
      getPiechartPOPData(
        findSelectedTag,
        startDateFormatPOP,
        endDateFormatPOP,
        rangePOP,
        setPopBarGraphData1,
        VmsReportPOPTime,
        startTimeFormatPOP,
        endTimeFormatPOP
      );
    } else {
      setPopBarGraphData1([]);
    }
  }, [
    disableOtherUnitsPOP,
    vmsPOPSelectTag,
    startDateFormatPOP,
    endDateFormatPOP,
    rangePOP,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP,
    endTimeFormatPOP,
    aggPOP,
  ]);

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      disableOtherUnitsPOP &&
      findSelectedTag.length &&
      startDateFormatPOP2 &&
      endDateFormatPOP2 &&
      aggPOP &&
      activeTabClass == "Period"
    ) {
      getPiechartPOPData1(
        findSelectedTag,
        startDateFormatPOP2,
        endDateFormatPOP2,
        rangePOP,
        setPopBarGraphData2,
        VmsReportPOPTime,
        startTimeFormatPOP2,
        endTimeFormatPOP2
      );
    } else {
      setPopBarGraphData2([]);
    }
  }, [
    disableOtherUnitsPOP && vmsPOPSelectTag,
    startDateFormatPOP2,
    endDateFormatPOP2,
    rangePOP,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP2,
    endTimeFormatPOP2,
    aggPOP,
  ]);

  const getReportParatoChatAPIPOP = async (
    tagId,
    startDate,
    endDate,
    rangePOP,
    setState,
    timeActive,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      range_selected: rangePOP,
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportAPIComp(paramReq, setState, setShimmerPOP);
    } catch (error) {
      console.log("errror", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP &&
      endDateFormatPOP &&
      rangePOP != "none" &&
      activeTabClass == "Period"
    ) {
      getReportParatoChatAPIPOP(
        findSelectedTag,
        startDateFormatPOP,
        endDateFormatPOP,
        rangePOP,
        setPopBarGraphData1,
        VmsReportPOPTime,
        startTimeFormatPOP,
        endTimeFormatPOP
      );
    } else {
      setPopBarGraphData1([]);
    }
  }, [
    vmsPOPSelectTag,
    startDateFormatPOP,
    endDateFormatPOP,
    rangePOP,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP,
    endTimeFormatPOP,
  ]);

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP2 &&
      endDateFormatPOP2 &&
      rangePOP != "none" &&
      activeTabClass == "Period"
    ) {
      getReportParatoChatAPIPOP(
        findSelectedTag,
        startDateFormatPOP2,
        endDateFormatPOP2,
        rangePOP,
        setPopBarGraphData2,
        VmsReportPOPTime,
        startTimeFormatPOP2,
        endTimeFormatPOP2
      );
    } else {
      setPopBarGraphData2([]);
    }
  }, [
    vmsPOPSelectTag,
    startDateFormatPOP2,
    endDateFormatPOP2,
    rangePOP,
    activeTabClass,
    VmsReportPOPTime,
    startTimeFormatPOP2,
    endTimeFormatPOP2,
  ]);

  // getReportParatoChat API
  const getReportLineChartAPI = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    intervalTime,
    timeActive
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      interval: Number(intervalTime),
    };

    // if (activeClass == "Reports") {
    //   paramReq["interval"] = Number(intervalTime);
    // } else {
    //   paramReq["interval"] = 1;
    // }

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmerLineChartCOC);
    } catch (error) {
      console.log("errror", error);
    }
  };

  // COC Line Chart
  useEffect(() => {
    const findSelectedTag = vmsCOCSelectTag.length
      ? vmsCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsCOCSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatCOC &&
      endDateFormatCOC &&
      activeTabClass == "Consumer"
    ) {
      getReportLineChartAPI(
        findSelectedTag,
        startDateFormatCOC,
        endDateFormatCOC,
        startTimeFormatCOC,
        endTimeFormatCOC,
        setCocLineChartData,
        LineCOCIntervalTime,
        VmsReportTime
      );
    } else {
      setCocLineChartData([]);
    }
  }, [
    vmsCOCSelectTag,
    startDateFormatCOC,
    endDateFormatCOC,
    startTimeFormatCOC,
    endTimeFormatCOC,
    VmsReportTime,
    activeTabClass,
    LineCOCIntervalTime,
  ]);

  // useEffect(()=>{
  //   const findSelectedTag = VmsReportCOCSelectTag.length
  //   ? VmsReportCOCSelectTag.filter((f) => f.deviceId == param.deviceId).length
  //     ? VmsReportCOCSelectTag.filter((f) => f.deviceId == param.deviceId).map(
  //         (row) => row.tagId
  //       )
  //     : []
  //   : [];

  //   if(!findSelectedTag.length){
  //     setCocLineChartData([]);
  //   }
  // },[VmsReportCOCSelectTag, cocLineChartdata])

  const getReportLineChartAPIPOP = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    timeActive,
    intervalTime,
    activeClass
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      interval: Number(intervalTime),
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmerLineChartPOP);
    } catch (error) {
      console.log("errror", error);
    }
  };

  // POP Line Chart
  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP &&
      endDateFormatPOP &&
      activeTabClass == "Period"
    ) {
      getReportLineChartAPIPOP(
        findSelectedTag,
        startDateFormatPOP,
        endDateFormatPOP,
        startTimeFormatPOP,
        endTimeFormatPOP,
        setPopLineChartData,
        VmsReportPOPTime,
        LinePOPIntervalTime
      );
    } else {
      setPopLineChartData([]);
    }
  }, [
    vmsPOPSelectTag,
    startDateFormatPOP,
    endDateFormatPOP,
    startTimeFormatPOP,
    endTimeFormatPOP,
    VmsReportPOPTime,
    activeTabClass,
    LinePOPIntervalTime,
  ]);

  useEffect(() => {
    const findSelectedTag = vmsPOPSelectTag.length
      ? vmsPOPSelectTag.filter((f) => f.deviceId == param.deviceId).length
        ? vmsPOPSelectTag
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.tagId)
        : []
      : [];
    if (
      findSelectedTag.length &&
      startDateFormatPOP2 &&
      endDateFormatPOP2 &&
      activeTabClass == "Period"
    ) {
      getReportLineChartAPIPOP(
        findSelectedTag,
        startDateFormatPOP2,
        endDateFormatPOP2,
        startTimeFormatPOP2,
        endTimeFormatPOP2,
        setPop2LineChartData,
        VmsReportPOPTime,
        LinePOPIntervalTime
      );
    } else {
      setPop2LineChartData([]);
    }
  }, [
    vmsPOPSelectTag,
    startDateFormatPOP2,
    endDateFormatPOP2,
    startTimeFormatPOP2,
    endTimeFormatPOP2,
    VmsReportPOPTime,
    activeTabClass,
    LinePOPIntervalTime,
  ]);

  const getReportLineChartAPIReports = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    timeActive,
    intervalTime,
    activeClass
  ) => {
    const paramReq = {
      company: param.companyId,
      plant: param.plantId,
      tag_id: tagId,
      interval: Number(intervalTime),
    };

    if (timeActive) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }

    if (hasRequiredUnits) {
      paramReq["aggregate"] = Number(selectTimeReportRange);
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmerLineChartReports);
    } catch (error) {
      console.log("errror", error);
    }
  };
  // Report Line Chart
  useEffect(() => {
    if (
      selectTagIDReport1 != "none" &&
      startDateFormatReports &&
      endDateFormatReports &&
      activeClass == "Reports"
    ) {
      getReportLineChartAPIReports(
        [selectTagIDReport1],
        startDateFormatReports,
        endDateFormatReports,
        startTimeFormatReports,
        endTimeFormatReports,
        setReportLineChartData1,
        VmsReportReportsTime,
        reportsIntervalTime1,
        activeClass
      );
    } else {
      setReportLineChartData1([]);
    }
  }, [
    selectTagIDReport1,
    startDateFormatReports,
    endDateFormatReports,
    startTimeFormatReports,
    endTimeFormatReports,
    VmsReportReportsTime,
    reportsIntervalTime1,
    activeClass,
    selectTimeReportRange,
  ]);

  useEffect(() => {
    if (
      selectTagIDReport2 != "none" &&
      startDateFormatReports &&
      endDateFormatReports &&
      activeClass == "Reports"
    ) {
      getReportLineChartAPIReports(
        [selectTagIDReport2],
        startDateFormatReports,
        endDateFormatReports,
        startTimeFormatReports,
        endTimeFormatReports,
        setReportLineChartData2,
        VmsReportReportsTime,
        reportsIntervalTime1,
        activeClass
      );
    } else {
      setReportLineChartData2([]);
    }
  }, [
    selectTagIDReport2,
    startDateFormatReports,
    endDateFormatReports,
    startTimeFormatReports,
    endTimeFormatReports,
    VmsReportReportsTime,
    activeClass,
    reportsIntervalTime1,
    selectTimeReportRange,
  ]);

  // calculate average in COC LineChart
  useMemo(() => {
    if (cocLineChartdata.length == 1) {
      const chartDataTagValue = cocLineChartdata[0].data
        ? cocLineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      console.log("averagesArray", averagesArray);
      setAverageCOCLineArray(averagesArray);
    }
  }, [cocLineChartdata]);

  // calculate average in COC LineChart

  function averageTagValues(arr) {
    // Find the maximum length of the data arrays
    const maxLength = arr.reduce(
      (max, obj) => Math.max(max, obj.data?.length),
      0
    );
    const averages = [];

    // Loop through each index up to the maximum length
    for (let i = 0; i < maxLength; i++) {
      let sum = 0;
      let count = 0;

      // Loop through each object in the array
      for (let j = 0; j < arr.length; j++) {
        const tagValue = arr[j].data[i] ? arr[j].data[i].tag_value : 0;
        sum += tagValue;
        count++;
      }

      // Calculate the average and push it to the averages array
      const average = parseFloat((sum / count).toFixed(2));
      averages.push(average);
    }

    return averages;
  }

  useMemo(() => {
    if (cocLineChartdata.length > 1) {
      const result = averageTagValues(cocLineChartdata);
      setAverageCOCLineArray(result);
    }
  }, [cocLineChartdata]);

  useEffect(() => {
    if (popLineChartdata.length && !pop2LineChartdata.length) {
      const chartDataTagValue = popLineChartdata[0].data
        ? popLineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      console.log("averagesArray", averagesArray);
      setAveragePOPLineArray(averagesArray);
    }

    if (!popLineChartdata.length && pop2LineChartdata.length) {
      const chartDataTagValue = pop2LineChartdata[0].data
        ? pop2LineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      console.log("averagesArray", averagesArray);
      setAveragePOPLineArray(averagesArray);
    }

    if (popLineChartdata.length && pop2LineChartdata.length) {
      const result = averageTagValues(
        popLineChartdata.concat(pop2LineChartdata)
      );
      setAveragePOPLineArray(result);
    }

    if (!popLineChartdata.length && !pop2LineChartdata.length) {
      setAveragePOPLineArray([]);
    }
  }, [popLineChartdata, pop2LineChartdata]);

  // Report Table Data
  const getReportTableDataApi = async (
    tagID,
    startDate,
    endDate,
    startTimeFormatReports,
    endTimeFormatReports,
    VmsReportReportsTime
  ) => {
    const paramReq = {
      tag_id: tagID,
    };
    if (VmsReportReportsTime) {
      paramReq["start_date"] = `${startDate} ${startTimeFormatReports}`;
      paramReq["end_date"] = `${endDate} ${endTimeFormatReports}`;
    } else {
      paramReq["start_date"] = `${startDate} 00:00:00`;
      paramReq["end_date"] = `${endDate} 23:59:59`;
    }

    try {
      const resp = await getReportTableData(paramReq);
      if (resp.status == 200 && resp.data.payload.length) {
        setReportsTableData(resp.data.payload);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching report table data:", error);
    }
  };

  useEffect(() => {
    const findSelectedTag = VmsReportReportsSelectTag.length
      ? VmsReportReportsSelectTag.filter((f) => f.deviceId == param.deviceId)
          .length
        ? VmsReportReportsSelectTag.filter(
            (f) => f.deviceId == param.deviceId
          ).map((row) => row.tagId)
        : []
      : [];

    console.log("findSelectedTag", findSelectedTag);
    if (
      findSelectedTag.length &&
      startDateFormatReports &&
      endDateFormatReports
    ) {
      getReportTableDataApi(
        findSelectedTag,
        startDateFormatReports,
        endDateFormatReports,
        startTimeFormatReports,
        endTimeFormatReports,
        VmsReportReportsTime
      );
    } else {
      setReportsTableData([]);
    }
  }, [
    VmsReportReportsSelectTag,
    startDateFormatReports,
    endDateFormatReports,
    startTimeFormatReports,
    endTimeFormatReports,
    VmsReportReportsTime,
  ]);

  const handleTabChange = (tab) => {
    setActiveTabClass(tab);
    setTagListData([]);
  };

  const handleMainTabChange = (tab) => {
    setActiveClass(tab);
    setTagListData([]);
  };
  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  const requiredUnits = ["kVAH", "kVARH", "kWH","agg"];
  // const hasRequiredUnits = requiredUnits.some(unit =>
  //   VmsReportReportsSelectUnit.some(item => item.tagUnit === unit)
  // );

  const hasRequiredUnits = VmsReportReportsSelectUnit.some((unit) =>
    requiredUnits.some((requiredUnit) =>
      unit.tagUnit.toLowerCase().includes(requiredUnit.toLowerCase())
    )
  );

  return (
    <>
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          <div className="page-back-btn">
            <ArrowBackIcon
              onClick={() =>
                navigate(
                  `/submachine/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
                )
              }
            />
            <span
              onClick={() =>
                navigate(
                  `/submachine/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
                )
              }
            >
              {deviceDataDetail ? deviceDataDetail.device_name : ""} - Reports{" "}
            </span>
          </div>
          <div className="d-flex">
            <button
              className={`${
                activeClass == "Analytics" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => handleMainTabChange("Analytics")}
            >
              Analytics
            </button>
            <button
              className={`${
                activeClass == "Reports" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => handleMainTabChange("Reports")}
            >
              Reports
            </button>
          </div>
        </div>
        {activeClass == "Analytics" ? (
          <>
            <div className="analysis-tabs-btn pt-3">
              <div className="analytics-tabs-btn">
                <div className="d-flex">
                  <button
                    className={`${
                      activeTabClass == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    // onClick={() =>
                    //     dispatch(
                    //         setOverviewData({ activeClassConsumer: "Consumer" })
                    //     )
                    // }
                    onClick={() => handleTabChange("Consumer")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      activeTabClass == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    // onClick={() =>
                    //     dispatch(
                    //         setOverviewData({ activeClassConsumer: "Period" })
                    //     )
                    // }
                    onClick={() => handleTabChange("Period")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
              </div>
              {activeTabClass == "Consumer" ? (
                <>
                  <div
                    className="analytics-aggregation analytics-datepicker "
                    style={{ justifyContent: "flex-end" }}
                  >
                    {disableOtherUnits ? (
                      <>
                        <h6 className="AGG-dropdown">AGG.</h6>
                        <Box
                          sx={{ minWidth: 200 }}
                          style={{ float: "right", marginRight: "13px" }}
                        >
                          <select
                            className="form-input-class agg-input"
                            style={{
                              padding: "16px 8px",
                              borderRadius: "4px",
                            }}
                            id="selectedClass"
                            value={aggCOC}
                            onChange={(e) => setAggCOC(e.target.value)}
                            //  dispatch(
                            //    setOverviewData({
                            //      selectTimeRange: e.target.value,
                            //    })
                            //  )

                            required
                          >
                            <option value={15} selected disabled hidden>
                              15 Min
                            </option>
                            <option value={15}>15 Min</option>
                            <option value={30}>30 Min</option>
                            <option value={60}>Hourly</option>
                            <option value={1}>Daily</option>
                            <option value={7}>Weekly</option>
                          </select>
                        </Box>
                      </>
                    ) : (analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (!analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (analyticsAccess?.is_viewer &&
                        !analyticsAccess?.is_editor) ? (
                      <button
                        className="setvms-COCrange-popup"
                        onClick={() => {
                          setRangeCOCValue(rangeCOC);
                          setRangeModal(true);
                        }}
                      >
                        {rangeCOC ? rangeCOC : "Set Range"}
                      </button>
                    ) : (
                      <button
                        className="setvms-COCrange-popup"
                        onClick={handleTostMsg}
                      >
                        {rangeCOC ? rangeCOC : "Set Range"}
                      </button>
                    )}

                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch color="primary" checked={VmsReportTime} />
                          }
                          label="Time"
                          labelPlacement="top"
                          onChange={handleToggleTime}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch color="primary" checked={VmsReportTime} />
                          }
                          label="Time"
                          labelPlacement="top"
                          onChange={handleTostMsg}
                        />
                      </FormGroup>
                    )}

                    <COCVmsDatePicker
                      time={VmsReportTime}
                      startDateReFormat={startDateReCOC}
                      endDateReFormat={endDateReCOC}
                      access={analyticsAccess}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      handleTostMsg={handleTostMsg}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="analytics-aggregation analytics-datepicker analyticsAgg-datePicker"
                    // style={{ justifyContent: "flex-end" }}
                  >
                    {disableOtherUnitsPOP ? (
                      <>
                        <h6 className="AGG-dropdown">AGG.</h6>
                        <Box
                          sx={{ minWidth: 200 }}
                          style={{ float: "right", marginRight: "13px" }}
                        >
                          <select
                            className="form-input-class agg-input"
                            style={{
                              padding: "16px 8px",
                              borderRadius: "4px",
                            }}
                            id="selectedClass"
                            value={aggPOP}
                            onChange={(e) => setAggPOP(e.target.value)}
                            //  dispatch(
                            //    setOverviewData({
                            //      selectTimeRange: e.target.value,
                            //    })
                            //  )

                            required
                          >
                            <option value={15} selected disabled hidden>
                              15 Min
                            </option>
                            <option value={15}>15 Min</option>
                            <option value={30}>30 Min</option>
                            <option value={60}>Hourly</option>
                            <option value={1}>Daily</option>
                            <option value={7}>Weekly</option>
                          </select>
                        </Box>
                      </>
                    ) : (
                      <div
                        className="abc1"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {(analyticsAccess?.is_viewer &&
                          analyticsAccess?.is_editor) ||
                        (!analyticsAccess?.is_viewer &&
                          analyticsAccess?.is_editor) ||
                        (analyticsAccess?.is_viewer &&
                          !analyticsAccess?.is_editor) ? (
                          <button
                            className="setvms-COCrange-popup"
                            onClick={() => {
                              setRangePOPValue(rangePOP);
                              setRangeModalPOP(true);
                            }}
                          >
                            {rangePOP ? rangePOP : "Set Range"}
                          </button>
                        ) : (
                          <button
                            className="setvms-COCrange-popup"
                            onClick={handleTostMsg}
                          >
                            {rangePOP ? rangePOP : "Set Range"}
                          </button>
                        )}
                      </div>
                    )}

                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <div className="timeToggle">
                        <h6 style={{ marginBottom: "0px" }}>Time</h6>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Switch
                                color="primary"
                                checked={VmsReportPOPTime}
                              />
                            }
                            // label="Time"
                            labelPlacement="top"
                            onChange={handlePOPToggleTime}
                            style={{ margin: "0px" }}
                          />
                        </FormGroup>
                      </div>
                    ) : (
                      <div className="timeToggle">
                        <h6 style={{ marginBottom: "0px" }}>Time</h6>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Switch
                                color="primary"
                                checked={VmsReportPOPTime}
                              />
                            }
                            // label="Time"
                            labelPlacement="top"
                            onChange={handleTostMsg}
                            style={{ margin: "0px" }}
                          />
                        </FormGroup>
                      </div>
                    )}

                    <POPVmsDatePicker
                      time={VmsReportPOPTime}
                      startDateReFormat={startDatePOP}
                      endDateReFormat={endDatePOP}
                      startDate1ReFormat={startDatePOP2}
                      endDate1ReFormat={endDatePOP2}
                      handleStartDate={handlePOPStartDate}
                      handleEndDate={handlePOPEndDate}
                      handlePOP1StartDate={handlePOP1StartDate}
                      handlePOP1EndDate={handlePOP1EndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </div>
                </>
              )}
              {rangeModal && (
                <Card
                  className="addDeviceModal-card"
                  style={{ width: "440px" }}
                >
                  <Box className="addDeviceModal-titleWrap">
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="addDeviceModal-title"
                    >
                      Set Range
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{
                        width: "30px",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      className="closeImg"
                      onClick={() => setRangeModal(false)}
                    />
                  </Box>

                  <Box className="addDeviceModal-innerWrap">
                    <Stack spacing={2} pt="10px" className="pt-4">
                      <TextField
                        id="filled-basic"
                        name="group_name"
                        label="Enter range"
                        variant="filled"
                        className="customeTextField deviceManagement"
                        type="number" // Set the type to "number" to allow decimal input
                        onChange={(e) => setRangeCOCValue(e.target.value)}
                        // value={vmsReportCOCRange}
                        value={rangeCOCValue ? rangeCOCValue : null}
                      />
                    </Stack>
                    <Box className="accountSecurity-cardButtons deviceManagement">
                      <button
                        className="addDeviceBtn"
                        onClick={handleSubmitRange}
                      >
                        Submit
                      </button>
                    </Box>
                  </Box>
                </Card>
              )}
              {rangeModalPOP && (
                <Card
                  className="addDeviceModal-card"
                  style={{ width: "440px" }}
                >
                  <Box className="addDeviceModal-titleWrap">
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="addDeviceModal-title"
                    >
                      Set Range
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{
                        width: "30px",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      className="closeImg"
                      onClick={() => setRangeModalPOP(false)}
                    />
                  </Box>

                  <Box className="addDeviceModal-innerWrap">
                    <Stack spacing={2} pt="10px" className="pt-4">
                      <TextField
                        id="filled-basic"
                        name="group_name"
                        label="Enter range"
                        variant="filled"
                        className="customeTextField deviceManagement"
                        type="number" // Set the type to "number" to allow decimal input
                        // onChange={(e) =>
                        //   dispatch(
                        //     setVmsReportData({
                        //       vmsReportPOPRange: e.target.value,
                        //     })
                        //   )
                        // }
                        // value={vmsReportPOPRange}
                        onChange={(e) => setRangePOPValue(e.target.value)}
                        value={rangePOPValue ? rangePOPValue : null}
                      />
                    </Stack>
                    <Box className="accountSecurity-cardButtons deviceManagement">
                      <button
                        className="addDeviceBtn"
                        onClick={handleSubmitRangePOP}
                      >
                        Submit
                      </button>
                    </Box>
                  </Box>
                </Card>
              )}
            </div>

            <div className="pt-2 consumption-grid-card">
              {activeTabClass == "Consumer" ? (
                <TagListComponent
                  access={analyticsAccess}
                  handleTostMsg={handleTostMsg}
                  time={VmsReportTime}
                  selectTimeRangeCOC={selectTimeRangeCOC}
                  startDateFormatCOC={startDateFormatCOC}
                  endDateFormatCOC={endDateFormatCOC}
                  startTimeFormatCOC={startTimeFormatCOC}
                  endTimeFormatCOC={endTimeFormatCOC}
                  handleSelectMachine={handleSelectCOCMachine}
                  handleSelectTagUnit={handleSelectCOCTagUnit}
                  handleSelectTag={handleSelectCOCTag}
                  deviceData={deviceData}
                  deviceUnitData={unitsData}
                  isShimmerdevicelist={isShimmerdevicelist}
                  activeTabClass={activeTabClass}
                  activeClass={activeClass}
                  tagListData={tagListData}
                  VmsReportSelectmachine={
                    VmsCOCSelectMachine.length
                      ? VmsCOCSelectMachine.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsReportSelectTagUnit={
                    vmsCOCSelectUnit.length
                      ? vmsCOCSelectUnit.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsReportSelectTag={
                    vmsCOCSelectTag.length
                      ? vmsCOCSelectTag.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsSelectSwitch={VmsReportTimeConsumer}
                  handleToggleTime={() =>
                    setVmsReportTimeConsumer(!VmsReportTimeConsumer)
                  }
                  disableOtherUnits={disableOtherUnits}
                  rangeValue={rangeCOCValue}
                  hasRequiredUnits={hasRequiredUnits}
                  agg={Number(aggCOC)}
                  // handleToggleTime={()=>dispatch(setVmsReportData({VmsReportTimeConsumer : !VmsReportTimeConsumer}))}
                />
              ) : (
                <TagListComponent
                  access={analyticsAccess}
                  time={VmsReportPOPTime}
                  selectTimeRangeCOC={selectTimeRangeCOC}
                  startDateFormatCOC={startDateFormatPOP}
                  endDateFormatCOC={endDateFormatPOP}
                  startTimeFormatCOC={startTimeFormatPOP}
                  endTimeFormatCOC={endTimeFormatPOP}
                  startDateFormatPOP2={startDateFormatPOP2}
                  endDateFormatPOP2={endDateFormatPOP2}
                  startTimeFormatPOP2={startTimeFormatPOP2}
                  endTimeFormatPOP2={endTimeFormatPOP2}
                  deviceData={deviceData}
                  deviceUnitData={unitsData}
                  activeTabClass={activeTabClass}
                  activeClass={activeClass}
                  tagListData={tagListData}
                  isShimmerdevicelist={isShimmerdevicelist}
                  handleSelectMachine={handleSelectPOPMachine}
                  handleSelectTagUnit={handleSelectPOPTagUnit}
                  handleSelectTag={handleSelectPOPTag}
                  VmsReportSelectmachine={
                    vmsPOPSelectMachine.length
                      ? vmsPOPSelectMachine.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsReportSelectTagUnit={
                    vmsPOPSelectUnit.length
                      ? vmsPOPSelectUnit.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsReportSelectTag={
                    vmsPOPSelectTag.length
                      ? vmsPOPSelectTag.filter(
                          (f) => f.deviceId == param.deviceId
                        )
                      : []
                  }
                  VmsSelectSwitch={VmsReportTimePeriod}
                  handleToggleTime={() =>
                    setVmsReportTimePeriod(!VmsReportTimePeriod)
                  }
                  disableOtherUnits={disableOtherUnitsPOP}
                  rangeValue={rangePOPValue}
                  hasRequiredUnits={hasRequiredUnits}
                  agg={Number(aggPOP)}
                />
              )}

              <GraphComponent
                activeTabClass={activeTabClass}
                Graphdata={cocGraphdata}
                LineGraphData={cocLineChartdata}
                tagID={
                  vmsCOCSelectTag.length
                    ? vmsCOCSelectTag.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                tagSelectPOP={
                  vmsPOPSelectTag.length
                    ? vmsPOPSelectTag.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsReportSelectmachine={
                  vmsPOPSelectMachine.length
                    ? vmsPOPSelectMachine.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsReportSelectmachine1={
                  VmsCOCSelectMachine.length
                    ? VmsCOCSelectMachine.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                averageArray={averageCOCLineArray}
                averagePOP={averagePOPLineArray}
                BarGraphData1={popBarGraphData1}
                BarGraphData2={popBarGraphData2}
                LineChartdata={popLineChartdata}
                LineChartdata2={pop2LineChartdata}
                isShimmerCOC={isShimmerCOC}
                isShimmerPOP={isShimmerPOP}
                isShimmerLineChartCOC={isShimmerLineChartCOC}
                isShimmerLineChartPOP={isShimmerLineChartPOP}
                LineCOCIntervalTime={LineCOCIntervalTime}
                LinePOPIntervalTime={LinePOPIntervalTime}
                setLineCOCIntervalTime={setLineCOCIntervalTime}
                setLinePOPIntervalTime={setLinePOPIntervalTime}
                VmsReportTimeConsumer={VmsReportTimeConsumer}
                VmsReportTimePeriod={VmsReportTimePeriod}
                disableOtherUnits={disableOtherUnits}
                piechartData={piechartData}
                pieChartSum={pieChartSum}
                disableOtherUnitsPOP={disableOtherUnitsPOP}
                piechartDataPOP={piechartDataPOP}
                piechartDataPOP1={piechartDataPOP1}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end pt-3">
              {hasRequiredUnits ? (
                <>
                  <h6 className="AGG-dropdown">AGG.</h6>
                  <Box
                    sx={{ minWidth: 200 }}
                    style={{ float: "right", marginRight: "13px" }}
                  >
                    <select
                      className="form-input-class agg-input"
                      style={{
                        padding: "16px 8px",
                        borderRadius: "4px",
                      }}
                      id="selectedClass"
                      value={selectTimeReportRange}
                      onChange={(e) => setSelectTimeReportRange(e.target.value)}
                      // dispatch(
                      //   setOverviewData({
                      //     selectTimeRange: e.target.value,
                      //   })
                      // )

                      required
                    >
                      <option value={15} selected disabled hidden>
                        15 Min
                      </option>
                      <option value={15}>15 Min</option>
                      <option value={30}>30 Min</option>
                      <option value={60}>Hourly</option>
                      <option value={1}>Daily</option>
                      <option value={7}>Weekly</option>
                    </select>
                  </Box>
                </>
              ) : (
                ""
              )}
              <div
                className="analytics-aggregation analytics-datepicker analyticsAgg-datePicker"
                style={{ justifyContent: "flex-end" }}
              >
                <h6 className="AGG-dropdown">Interval</h6>
                <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                  {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                  (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                  (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                    <select
                      className="form-input-class"
                      style={{
                        padding: "14px 8px",
                        borderRadius: "4px",
                        marginRight: "20px",
                      }}
                      id="selectedClass"
                      value={reportsIntervalTime1}
                      onChange={(e) => setReportIntervalTime1(e.target.value)}
                      required
                    >
                      <option value="1" selected disabled hidden>
                        1 min
                      </option>
                      <option value="1">1 Min</option>
                      <option value="3">3 Min</option>
                      <option value="5">5 Min</option>
                      <option value="15">15 Min</option>
                      <option value="60">Hourly</option>
                      <option value="1440">Daily</option>
                    </select>
                  ) : (
                    <select
                      className="form-input-class"
                      style={{
                        padding: "14px 8px",
                        borderRadius: "4px",
                        marginRight: "20px",
                      }}
                      id="selectedClass"
                      value={reportsIntervalTime1}
                      onChange={handleTostMsg}
                      required
                    >
                      <option value="1" selected disabled hidden>
                        1 min
                      </option>
                      <option value="1">1 Min</option>
                      <option value="3">3 Min</option>
                      <option value="5">5 Min</option>
                      <option value="15">15 Min</option>
                    </select>
                  )}
                </Box>
                {(ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                (!ReportAccess?.is_viewer && ReportAccess?.is_editor) ||
                (ReportAccess?.is_viewer && !ReportAccess?.is_editor) ? (
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={
                        <Switch
                          color="primary"
                          checked={VmsReportReportsTime}
                        />
                      }
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTimeReports}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={
                        <Switch
                          color="primary"
                          checked={VmsReportReportsTime}
                        />
                      }
                      label="Time"
                      labelPlacement="top"
                      onChange={handleTostMsg}
                    />
                  </FormGroup>
                )}

                {/* vmsReport Report date */}
                <COCVmsDatePicker
                  time={VmsReportReportsTime}
                  startDateReFormat={startDateReReports}
                  endDateReFormat={endDateReReports}
                  handleStartDate={handleStartDateReports}
                  handleEndDate={handleEndDateReports}
                  access={ReportAccess}
                  handleTostMsg={handleTostMsg}
                />
              </div>
            </div>
            <div className="pt-2 consumption-grid-card">
              <TagListComponent
                access={ReportAccess}
                handleTostMsg={handleTostMsg}
                reportsIntervalTime1={reportsIntervalTime1}
                time={VmsReportReportsTime}
                startDateFormatCOC={startDateFormatReports}
                endDateFormatCOC={endDateFormatReports}
                startTimeFormatCOC={startTimeFormatReports}
                endTimeFormatCOC={endTimeFormatReports}
                deviceData={deviceData}
                deviceUnitData={unitsData}
                tagListData={tagListData}
                isShimmerdevicelist={isShimmerdevicelist}
                activeTabClass={activeTabClass}
                activeClass={activeClass}
                handleSelectMachine={handleSelectReportsMachine}
                handleSelectTagUnit={handleSelectReportsTagUnit}
                handleSelectTag={handleSelectReportsTag}
                VmsReportSelectmachine={
                  VmsReportReportsSelectmachine.length
                    ? VmsReportReportsSelectmachine.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsReportSelectTagUnit={
                  VmsReportReportsSelectUnit.length
                    ? VmsReportReportsSelectUnit.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsReportSelectTag={
                  VmsReportReportsSelectTag.length
                    ? VmsReportReportsSelectTag.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                VmsSelectSwitch={VmsReportSwitch}
                handleToggleTime={() => setVmsReportSwitch(!VmsReportSwitch)}
                hasRequiredUnits={hasRequiredUnits}
                selectTimeReportRange={selectTimeReportRange}
                agg={Number(selectTimeReportRange)}
              />
              <ReportsGraphComponent
                tableData={reportsTableData}
                selectedMachine={
                  VmsReportReportsSelectTag
                    ? VmsReportReportsSelectTag.filter(
                        (f) => f.deviceId == param.deviceId
                      )
                    : []
                }
                selectTagID1={selectTagIDReport1}
                selectTagID2={selectTagIDReport2}
                setSelectTag1={setSelectTagIDReport1}
                setSelectTag2={setSelectTagIDReport2}
                LineChartData1={reportLineChartData1}
                LineChartData2={reportLineChartData2}
                isShimmerLineChartReports={isShimmerLineChartReports}
                selectAggReport={selectTimeReportRange} // aggreagate
                selectIntervalReport={reportsIntervalTime1} // interval
                hasRequiredUnits={hasRequiredUnits}
                selectTag={VmsReportReportsSelectTag}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default VmsReports;

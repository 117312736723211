import {
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../../components/BPCL/BPCLWeathers/BPCLWeathers.css";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { WeatherBarChart } from "../../Graph/BPCL/Weather-bar-chart";
import HalfCircleGauge from "../../Graph/BPCL/Speedometer";
import { MultiAxisLineChart } from "../../Graph/BPCL/MultiAxis-line-chart";
import { cubejsApi } from "../../../container/Cubejs/CubejsApiWrapper";
import { useSelector } from "react-redux";
import Datepicker from "../Datepicker/Datepicker";
import { images } from "../../../config";

const BPCLWeathers = (props) => {
  console.log(
    "sliderRealTimeTagDatasliderRealTimeTagData",
    props.sliderRealTimeTagData
  );

  const [dateRange, setDateRange] = React.useState("Today");
  const [selectTimeRange, setSelectTimeRange] = useState("live");
  const [combineChartData, setCombineChartData] = useState([[], []]);
  const { selectTimeZoneProfile } = useSelector((state) => state.userState);

  console.log("combineChartData", combineChartData);

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };
  const chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
      ],
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "MWh",
        data: [3000, 4000, 5000, 6000, 7000, 8000, 9000],
        color: "#9C27B0",
      },
    ],
  };

  const barChartOptions = {
    chart: { type: "column" },
    title: { text: null },

    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: true,
    },
    xAxis: {
      categories: [
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
      ],
    },
    yAxis: { title: { text: "MWh" }, max: 10000 },
    series: [
      {
        name: "Data",
        data: [6000, 9000, 7500, 9500, 7000, 6000, 8500, 8000],
        color: "#a16eff",
      },
    ],
  };

  const lineChartOptions = {
    chart: { type: "line" },
    title: { text: null },
    credits: {
      enabled: false,
    },

    xAxis: { categories: ["1 Min", "2 Min", "3 Min", "4 Min", "5 Min"] },
    yAxis: { title: { text: "W/m²" }, max: 10000 },
    series: [
      {
        name: "Ambient Temp",
        data: [2000, 3000, 2500, 9000, 4000, 5000, 1200, 1560],
        color: "#a16eff",
      },
      {
        name: "Module Temp",
        data: [1000, 2000, 1500, 8000, 3500, 2500, 9000, 4000],
        color: "#e056fd",
      },
    ],
  };

  const RealTimeData = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === props.realTimeData[0].device_uid &&
          f.device_tag === props.realTimeData[0].device_tag
      )
    : "";

  const RealTimeData1 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === props.realTimeData[1].device_uid &&
          f.device_tag === props.realTimeData[1].device_tag
      )
    : "";
  const [chartLineData, setChartLineData] = useState([]);
  const [realTimevalue, setRealTimevalue] = useState([]);
  const [chartLineData1, setChartLineData1] = useState([]);
  const [realTimevalue1, setRealTimevalue1] = useState([]);

  const chartRef = useRef(null);
  const handelCubeChartLineData = (value) => {
    setChartLineData((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData((prev) => [...prev, value]);

    setRealTimevalue([value]);
  };

  // Line-2 Chart data
  const handelCubeChartLineData1 = (value) => {
    setChartLineData1((prev) => {
      // Create a shallow copy of the previous state
      const indexCopy = [...prev];
      //==========================new add code==============================
      if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
        indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
      }

      return [...indexCopy, value];
    });
    // setChartLineData1((prev) => [...prev, value]);
    setRealTimevalue1([value]);
    // setMinMax1([minmax]);
  };

  const getTagTime = () => {
    return new Date().toLocaleTimeString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  const executeCubeQuery = async () => {
    try {
      var currentTime;

      if (selectTimeRange == "1min") {
        console.log(
          "selectTimeRange.........................",
          selectTimeRange
        );
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "5min") {
        console.log(
          "selectTimeRange.........................",
          selectTimeRange
        );
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "15min") {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      if (selectTimeRange == "30min") {
        currentTime = new Date().toLocaleTimeString("en-US", {
          timeZone: selectTimeZoneProfile,
          hour12: false,
        });
      }

      const chartGraph = await cubejsApi.load({
        dimensions: [
          "DeviceConfigurationTagconfiguration.latestValue",
          "DeviceConfigurationTagconfiguration.tagName",
          "DeviceConfigurationTagconfiguration.deviceId",
        ],
        filters: [
          {
            member: "DeviceConfigurationTagconfiguration.tagName",
            operator: "equals",
            values: props.realTimeData.length
              ? props.realTimeData.map((row) => row.tag_name)
              : [],
          },
        ],
        limit: 4,
      });

      const findPlantviseDeviceId = props.realTimeData.map((e) => e);

      const sortingSelectViseDeiveId = findPlantviseDeviceId.map((row) => {
        return chartGraph
          .tablePivot()
          ?.find(
            (id) =>
              id["DeviceConfigurationTagconfiguration.deviceId"] ==
                row.device &&
              id["DeviceConfigurationTagconfiguration.tagName"] == row.tag_name
          );
      });

      setCombineChartData((prev) => {
        const zeroIndx = [...prev];

        let innerArrayCopy = [...zeroIndx[0]];
        let innerArrayCopy1 = [...zeroIndx[1]];

        if (props.realTimeData[0]) {
          if (
            innerArrayCopy[innerArrayCopy.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy.push({
              tagValue:
                sortingSelectViseDeiveId?.[0]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[0]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        if (props.realTimeData[1]) {
          if (
            innerArrayCopy1[innerArrayCopy1.length - 1]?.tagTime != getTagTime()
          ) {
            innerArrayCopy1.push({
              tagValue:
                sortingSelectViseDeiveId?.[1]?.[
                  "DeviceConfigurationTagconfiguration.latestValue"
                ],
              tagTime: getTagTime(),
            });
            handelCubeChartLineData1(
              {
                tagValue:
                  sortingSelectViseDeiveId?.[1]?.[
                    "DeviceConfigurationTagconfiguration.latestValue"
                  ],
                tagTime: getTagTime(),
              },
              null
            );
          }
        }

        //==========================new add code==============================
        if (innerArrayCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        if (innerArrayCopy1.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          innerArrayCopy1.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }

        zeroIndx[0] = innerArrayCopy;
        zeroIndx[1] = innerArrayCopy1;

        return zeroIndx;
      });

      console.log("chartGraphchartGraphchartGraph", sortingSelectViseDeiveId);
    } catch (error) {
      // Handle any errors that occur during the cube query
      console.error("Cube query error:", error);
    }
  };

  useEffect(() => {
    if (selectTimeRange != "live" && props.realTimeData.length) {
      console.log("kpkpkpkpkpkpkkpk");
      // Function to execute the cube query

      // Set interval to execute the cube query based on selectTimeRange
      let interval = null;
      switch (selectTimeRange) {
        case "1min":
          interval = setInterval(executeCubeQuery, 60000);
          break;
        case "5min":
          interval = setInterval(executeCubeQuery, 300000);
          break;
        case "15min":
          interval = setInterval(executeCubeQuery, 900000);
          break;
        default:
          clearInterval(interval); // Clear interval if selectTimeRange is not supported
      }

      // Execute the cube query immediately
      executeCubeQuery();

      // Clean up the interval when the component unmounts or when selectTimeRange changes
      return () => {
        clearInterval(interval);
      };
    }
  }, [props.realTimeData, selectTimeRange]);

  const handleDropdown = (event) => {
    setSelectTimeRange(event.target.value);
  };

 

  return (
    <div>
      <Box className="pt-2">
        <Card className="availability-box">
          <Typography
            variant="h6"
            className="typography_Growth"
            style={{ fontWeight: "600" }}
          >
            Weather
          </Typography>

          <Grid container spacing={2} className="custom-grid mt-3">
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  {" "}
                  <h5 className="weather_BPCL">GTI</h5>
                </div>
                <div className="weather-card-data">
                  {" "}
                  {props.realWeatherData[0] &&
                  Object.keys(props.realWeatherData[0]).length > 0 ? (
                    <HalfCircleGauge
                      realTimeData={props.realWeatherData[0]}
                      sliderRealTimeTagData={props.mergedArray}
                      containerName={"guage_1"}
                      color={"red-green"}
                    />
                  ) : (
                    <img
                      src={images.noDataFoundImg}
                      className="notFoundDataImg"
                      alt="No Data Found Img"
                      style={{ transform: "translate(-16px, -8px)" }}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  <h5 className="weather_BPCL">Wind Speed</h5>
                </div>
                <div className="weather-card-data">
                  {props.realWeatherData[1] &&
                  Object.keys(props.realWeatherData[1]).length > 0 ? (
                    <HalfCircleGauge
                      realTimeData={props.realWeatherData[1]}
                      sliderRealTimeTagData={props.mergedArray}
                      containerName={"guage_2"}
                      color={"green-red"}
                    />
                  ) : (
                    <img
                      src={images.noDataFoundImg}
                      className="notFoundDataImg"
                      alt="No Data Found Img"
                      style={{ transform: "translate(-16px, -8px)" }}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  {" "}
                  <h5 className="weather_BPCL">Ambient Temp.</h5>
                </div>
                <div className="weather-card-data">
                  {props.realWeatherData[2] &&
                  Object.keys(props.realWeatherData[2]).length > 0 ? (
                    <HalfCircleGauge
                      realTimeData={props.realWeatherData[2]}
                      sliderRealTimeTagData={props.mergedArray}
                      containerName={"guage_3"}
                      color={"green-red"}
                    />
                  ) : (
                    <img
                      src={images.noDataFoundImg}
                      className="notFoundDataImg"
                      alt="No Data Found Img"
                      style={{ transform: "translate(-16px, -8px)" }}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="custom-grid mt-3">
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  {" "}
                  <h5 className="weather_BPCL">GHI</h5>
                </div>
                <div className="weather-card-data">
                  {" "}
                  {props.realWeatherData[3] &&
                  Object.keys(props.realWeatherData[3]).length > 0 ? (
                    <HalfCircleGauge
                      realTimeData={props.realWeatherData[3]}
                      sliderRealTimeTagData={props.mergedArray}
                      containerName={"guage_4"}
                      color={"red-green"}
                    />
                  ) : (
                    <img
                      src={images.noDataFoundImg}
                      className="notFoundDataImg"
                      alt="No Data Found Img"
                      style={{ transform: "translate(-16px, -8px)" }}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  <h5 className="weather_BPCL">Wind Direction</h5>
                </div>
                <div className="weather-card-data">
                  {props.realWeatherData[4] &&
                  Object.keys(props.realWeatherData[4]).length > 0 ? (
                    <HalfCircleGauge
                      realTimeData={props.realWeatherData[4]}
                      sliderRealTimeTagData={props.mergedArray}
                      containerName={"guage_5"}
                      color={"red-green"}
                    />
                  ) : (
                    <img
                      src={images.noDataFoundImg}
                      className="notFoundDataImg"
                      alt="No Data Found Img"
                      style={{ transform: "translate(-16px, -8px)" }}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  <h5 className="weather_BPCL">Module Temp.</h5>
                </div>
                <div className="weather-card-data">
                  {props.realWeatherData[5] &&
                  Object.keys(props.realWeatherData[5]).length > 0 ? (
                    <HalfCircleGauge
                      realTimeData={props.realWeatherData[5]}
                      sliderRealTimeTagData={props.mergedArray}
                      containerName={"guage_6"}
                      color={"green-red"}
                    />
                  ) : (
                    <img
                      src={images.noDataFoundImg}
                      className="notFoundDataImg"
                      alt="No Data Found Img"
                      style={{ transform: "translate(-16px, -8px)" }}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>

          <Box className="pt-3">
            <div className="BPCL_weather_chart mb-3">
              <Grid item xs={12} md={3} lg={3}>
                <Select
                  variant="outlined"
                  fullWidth
                  value={props.weatherValue}
                  onChange={props.handleDateWeatherValue}
                  className="inpute-week"
                >
                  <MenuItem value={2}>GHI</MenuItem>
                  <MenuItem value={1}>GTI</MenuItem>

                  <MenuItem value={3}>Wind Speed</MenuItem>
                  <MenuItem value={4}>Amb.Temp.</MenuItem>
                  <MenuItem value={5}>Module Temp.</MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                lg={9}
                className="d-flex align-items-center gap-2"
              >
                <Datepicker
                  startDate={props.weatherStartDate}
                  endDate={props.weatherEndDate}
                  setStartDate={props.setWeatherStartDate}
                  setEndDate={props.setWeatherEndDate}
                />
                <Select
                  value={props.weatherTime}
                  onChange={props.handleDateWeatherTime}
                  className="inpute-week"
                  // style={{ marginLeft: "107px" }}
                >
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>Week</MenuItem>
                  <MenuItem value={3}>Month</MenuItem>
                  <MenuItem value={4}>Quarter</MenuItem>
                  <MenuItem value={5}>Year</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Select
                  value={selectTimeRange}
                  onChange={(event) => handleDropdown(event)}
                  className="inpute-week"
                  style={{ marginLeft: "107px" }}
                >
                  <MenuItem value="live">Live</MenuItem>
                  <MenuItem value="1min">1 min</MenuItem>
                  <MenuItem value="5min">5 min</MenuItem>
                  <MenuItem value="15min">15 min</MenuItem>
                </Select>
              </Grid>
            </div>

            <Grid container spacing={2}>
              {/* Second row: Charts */}
              <Grid
                item
                xs={12}
                md={6}
                lg={7}
                style={{ borderRight: "1px solid #f5efef" }}
              >
                <Box p={2}>
                  <WeatherBarChart
                    weatherGraphData={
                      props.weatherStartDate && props.weatherEndDate
                        ? props.weatherGraphDateWiseData
                        : props.weatherGraphData
                    }
                    startDate={ props.weatherStartDate}
                    endDate={props.weatherEndDate}
                    weatherTime={props.weatherTime}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Box p={2}>
                  <MultiAxisLineChart
                    sliderRealTimeTagData={props.mergedArray}
                    realTimeData={props.realTimeData}
                    RealTimeData={RealTimeData}
                    RealTimeData1={RealTimeData1}
                    chartLineData={chartLineData}
                    combineChartData={combineChartData}
                    selectTimeRange={selectTimeRange}
                    setCombineChartData={setCombineChartData}
                  />
                </Box>
              </Grid>

              {/* Third row: Peak Values */}
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Grid container spacing={3}>
                <Grid item md={2.4} sm={12} className="grid-vertical-item">
                  <h5 style={{ fontWeight: "bold" }}>GTI</h5>
                  <div className="pt-1 d-flex gap-2">
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        Peak
                      </h3>
                      <div className="d-flex flex-availbility  ">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.gti?.peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.gti?.peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.gti?.tag_unit
                            : props.weatherGraphData[0]?.gti?.tag_unit}
                        </span>
                      </div>
                    </button>
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        All Time Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.gti
                                ?.all_time_peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.gti
                                ?.all_time_peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.gti?.tag_unit
                            : props.weatherGraphData[0]?.gti?.tag_unit}
                        </span>
                      </div>
                    </button>
                  </div>
                </Grid>
                <Grid item md={2.4} sm={12} className="grid-vertical-item">
                  <h5 style={{ fontWeight: "bold" }}>GHI</h5>
                  <div className="pt-1 d-flex gap-2">
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.ghi?.peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.ghi?.peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.ghi?.tag_unit
                            : props.weatherGraphData[0]?.ghi?.tag_unit}
                        </span>
                      </div>
                    </button>
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        All Time Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.ghi
                                ?.all_time_peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.ghi
                                ?.all_time_peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.ghi?.tag_unit
                            : props.weatherGraphData[0]?.ghi?.tag_unit}
                        </span>
                      </div>
                    </button>
                  </div>
                </Grid>
                <Grid item md={2.4} sm={12} className="grid-vertical-item">
                  <h5 style={{ fontWeight: "bold" }}>Wind Speed</h5>
                  <div className="pt-1 d-flex gap-2">
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.wind_speed
                                ?.peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.wind_speed?.peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.wind_speed
                                ?.tag_unit
                            : props.weatherGraphData[0]?.wind_speed?.tag_unit}
                        </span>
                      </div>
                    </button>
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        All Time Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.wind_speed
                                ?.all_time_peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.wind_speed
                                ?.all_time_peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]?.wind_speed
                                ?.tag_unit
                            : props.weatherGraphData[0]?.wind_speed?.tag_unit}
                        </span>
                      </div>
                    </button>
                  </div>
                </Grid>
                <Grid item md={2.4} sm={12} className="grid-vertical-item">
                  <h5 style={{ fontWeight: "bold" }}>Ambient Temperature</h5>
                  <div className="pt-1 d-flex gap-2">
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]
                                ?.ambient_temperature?.peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.ambient_temperature
                                ?.peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]
                                ?.ambient_temperature?.tag_unit
                            : props.weatherGraphData[0]?.ambient_temperature
                                ?.tag_unit}
                        </span>
                      </div>
                    </button>
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        All Time Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]
                                ?.ambient_temperature?.all_time_peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.ambient_temperature
                                ?.all_time_peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]
                                ?.ambient_temperature?.tag_unit
                            : props.weatherGraphData[0]?.ambient_temperature
                                ?.tag_unit}
                        </span>
                      </div>
                    </button>
                  </div>
                </Grid>
                <Grid item md={2.4} sm={12}>
                  <h5 style={{ fontWeight: "bold" }}>Module Temperature</h5>
                  <div className="pt-1 d-flex gap-2">
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]
                                ?.module_temperature?.peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.module_temperature
                                ?.peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]
                                ?.module_temperature?.tag_unit
                            : props.weatherGraphData[0]?.module_temperature
                                ?.tag_unit}
                        </span>
                      </div>
                    </button>
                    <button className="BPCLavailability1">
                      <h3 className="bpclPlant" style={{ color: "black" }}>
                        All Time Peak
                      </h3>
                      <div className="d-flex flex-availbility">
                        <h3 className="plant_time" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]
                                ?.module_temperature?.all_time_peak_value
                            : props.weatherGraphData[0]
                            ? props.weatherGraphData[0]?.module_temperature
                                ?.all_time_peak_value
                            : 0}
                        </h3>
                        <span className="plant_hrs" style={{ color: "black" }}>
                          {props.weatherStartDate && props.weatherEndDate
                            ? props.weatherGraphDateWiseData[0]
                                ?.module_temperature?.tag_unit
                            : props.weatherGraphData[0]?.module_temperature
                                ?.tag_unit}
                        </span>
                      </div>
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default BPCLWeathers;

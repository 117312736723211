import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";

HC_more(Highcharts);
exporting(Highcharts);

const LivePlantCombineChart = ({ barChartData, lineChartData, colorCode }) => {
  const [chartData, setChartData] = useState({
    categories: [], // Start with an empty array for timestamps
    barData: [], // Initialize as empty for bar chart data
    lineData: [], // Initialize as empty for line chart data
  });



  const chartRef = useRef(null);

  useEffect(() => {
    if (lineChartData && lineChartData.length > 0) {
      const plantData = lineChartData[0];

      // Check if lineChartData starts from 1, then reset the chart
      if (plantData.data.length === 1) {
        setChartData({
          categories: [],
          barData: [],
          lineData: [],
        });
      }

      // Extract timestamps from the incoming data
      const newTimestamps = plantData.data.map((item) =>
        new Date(item.timestamp).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );

      // Extract line chart values (MW)
      const newLineValues = plantData.data.map((item) => item.MW || 0);

      

      setChartData((prevData) => {
        // Filter unique timestamps only
        const uniqueTimestamps = newTimestamps.filter(
          (timestamp) => !prevData.categories.includes(timestamp)
        );

        // If no new timestamps, do not update the state
        if (uniqueTimestamps.length === 0) {
          return prevData;
        }

        return {
          categories: [...prevData.categories, ...uniqueTimestamps], // Append unique timestamps
          barData: [
            ...prevData.barData,
            ...uniqueTimestamps.map(() => barChartData[0]?.plant_capacity || 0),
          ],
          lineData: [
            ...prevData.lineData,
            ...newLineValues.slice(-uniqueTimestamps.length),
          ],
        };
      });
    }
  }, [lineChartData, barChartData]);

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      // Scroll to the most recent data points
      chart.xAxis[0].setExtremes(
        Math.max(0, chartData.categories.length - 10),
        chartData.categories.length - 1
      );
      chart.redraw();
    }
  }, [chartData]);

  // Dynamically calculate the min, max, and tick interval for the second Y-axis
  const calculateYAxisRange = (data) => {
    if (data.length === 0) return { min: 0, max: 100, tickInterval: 10 };

    const min = Math.min(...data);
    const max = Math.max(...data);
    const range = max - min;

    // Set a dynamic tick interval
    const tickInterval = Math.ceil(range / 5);

    return {
      min: Math.floor(min / tickInterval) * tickInterval, // Round down to the nearest tick
      max: Math.ceil(max / tickInterval) * tickInterval, // Round up to the nearest tick
      tickInterval: tickInterval,
    };
  };

  const yAxisRange = calculateYAxisRange(chartData.lineData);

  const options = {
    chart: {
      type: "column",
      height: 300,
    },
    scrollbar: {
      enabled: true,
    },
    title: {
      text: null, // No title
    },
    xAxis: {
      categories: chartData.categories,
      title: {
        text: null,
      },
    },
    yAxis: [
      {
        title: { text: "MW (Capacity)" },
        gridLineDashStyle: "Dash",
        gridLineWidth: 1,
        gridLineColor: "#e0e0e0",
        lineWidth: 1,
        lineColor: "#e0e0e0",
        opposite: false,

        labels: {
          enabled: true,
          style: {
            fontSize: "10px",
            color: "#333",
          },
        },
      },
      {
        title: {
          text: "MWh (Generation)",
        },
        gridLineDashStyle: "Dash",
        gridLineWidth: 1,
        gridLineColor: "#e0e0e0",
        lineWidth: 1,
        lineColor: "#e0e0e0",
        opposite: true,

        labels: {
          enabled: true, // Ensure labels are displayed
          style: {
            fontSize: "10px",
            color: "#333",
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        if (!this.points || !this.points.length) {
          return `<b>${this.x}</b><br/>No data available`;
        }
      
        let tooltipContent = `<b>${this.x}</b><br/>`;
        this.points.forEach((point) => {
          if (point && point.series.name !== "MWh") { // Exclude "MWh" series
            tooltipContent += `
              <span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${this.points[1]?.y.toFixed(2)}</b><br/>
            `;
          }
        });
        return tooltipContent;
      },
    },
    
    credits: {
      enabled: false, // Remove the Highcharts logo
    },
    legend: {
      enabled: false, // Remove the legend
    },
    scrollbar: {
      enabled: true,
    },
    series: [
      {
        name: "MW",
        type: "column",
        data: chartData.barData,
        color: colorCode,
        yAxis: 0, // Bind this series to the first y-axis
      },
      {
        name: "MWh",
        type: "line",
        data: chartData.lineData,
        color: "#45748b",
        marker: {
          enabled: true,
        },
        lineWidth: 2, // Ensure line visibility
        yAxis: 1, // Bind this series to the second y-axis
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default LivePlantCombineChart;

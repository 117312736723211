import {
  Box,
  Card,
  ClickAwayListener,
  Container,
  Grid,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPlant } from "../../../CompanyDashboard/services";
import { images } from "../../../../config";
import MapComponent from "../../../../components/BPCL/PlantLocation/Map";
import { companyDashboardCountData } from "../../../SuperAdminDashboard/service";
import SmarTagMap from "../../../../components/BPCL/PlantLocation/SmarTagMap";
import { ShimmerText } from "react-shimmer-effects";
import Textshimmer from "../../../../components/Shimmer/TextShimmer/TextShimmer";
import { smartTagPlantDashboardCount } from "../../services";
import SearchIcon from "@material-ui/icons/Search";

const LocationPlantDashboard = () => {
  const [plantData, setPlantData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [plantDashboardCount1, setPlantDashboardCount1] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [plantId, setPlantId] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [isShimmerHeader, setisShimmerHeader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Filter plantData based on search query
  const filteredPlants = plantData.filter((row) =>
    row.plant_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const params = useParams();

  useEffect(() => {
    getPlantByCompany();
  }, []);

  const getPlantByCompany = async () => {
    try {
      const param = {
        dashboard: 3,
        company: params.companyid,
      };
      const resp = await getPlant(param);
      if (resp.status == 200 || resp.status == 201) {
        setPlantData(resp.data.payload);
        setAllPlant(resp.data.payload.map((e) => e.plant_location));
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };

  useEffect(() => {
    handlePlantDashboardCount();
  }, []);

  const handlePlantDashboardCount = async () => {
    try {
      setisShimmerHeader(true);
      const param = {
        company_name: params.companyid,
        dashboard: 3,
      };
      const resp = await smartTagPlantDashboardCount(param);
      if (resp.data.success == true) {
        setTimeout(() => {
          setisShimmerHeader(false);
        }, 1000);

        setPlantDashboardCount1(resp.data.payload);
      }
    } catch (error) {
      setisShimmerHeader(false);
      console.log("handleSubmitError", error);
    }
  };
  const TextShimmer = () => {
    return (
      <>
        <ShimmerText
          line={1}
          gap={10}
          className="Header-TextShimmer sup-header"
        />
      </>
    );
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClickImg = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBoxClick = (location, name, img, id) => {
    setSelectedLocation(location);
    setSelectedPlant(name);
    setSelectedImage(img);
    setPlantId(id);
  };

  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Card className="dashboardCard">
            <Grid container>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.energyMeterImg}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">SmarTag</p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerHeader
                          ? plantDashboardCount1.device_count
                          : TextShimmer()}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.clientManagementPlantS}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">PLANTS</p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerHeader
                          ? plantDashboardCount1.plant_count
                          : TextShimmer()}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.activeImg}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">ACTIVE</p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerHeader
                          ? plantDashboardCount1.active_plant_count
                          : TextShimmer()}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.inActiveImg}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">IN ACTIVE</p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {!isShimmerHeader
                          ? plantDashboardCount1.inactive_plant_count
                          : TextShimmer()}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
          <div className="pt-4">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={8} xl={8}>
                <TextField
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "white" }}
                  // className="search-textfield"
                  placeholder="Search Plant"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />
                <div style={{ height: "630px", overflow: "auto" }}>
                  <div className="pt-3">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {filteredPlants.length
                          ? filteredPlants.map((row) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  className="mt-2"
                                >
                                  <Card
                                    className="dashboardCard-plant"
                                    onClick={() =>
                                      handleBoxClick(
                                        row.plant_location,
                                        row.plant_name,
                                        row.is_solar_energy,
                                        row.id
                                      )
                                    }
                                  >
                                    <Stack direction="column">
                                      <Typography
                                        gutterBottom
                                        className="dashboardlocation-plantTitle"
                                      >
                                        {row.plant_name ? row.plant_name : "-"}
                                      </Typography>
                                      <Typography
                                        gutterBottom
                                        className="dashboardlocation-plantsubTitle"
                                      >
                                        {row.plant_description
                                          ? row.plant_description
                                          : "-"}
                                      </Typography>

                                      <Box className="dashboardCard-companyContent">
                                        <Typography
                                          gutterBottom
                                          className="dashboardCard-plantText"
                                        >
                                          Device :
                                        </Typography>
                                        <Stack direction="column">
                                          <Typography
                                            variant="body1"
                                            className="dashboardCard-plantNumber1"
                                          >
                                            {
                                              row.device.filter(
                                                (value) => value.is_sub == true
                                              ).length
                                            }
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            className="dashboardCard-plantSmalltext"
                                          >
                                            Active
                                          </Typography>
                                        </Stack>
                                        <Box>
                                          <Stack direction="column">
                                            <Typography
                                              variant="body1"
                                              className="dashboardCard-plantNumber2"
                                            >
                                              {
                                                row.device.filter(
                                                  (value) =>
                                                    value.is_sub == false
                                                ).length
                                              }
                                            </Typography>
                                            <Typography
                                              variant="caption"
                                              className="dashboardCard-plantSmalltext"
                                            >
                                              Inactive
                                            </Typography>
                                          </Stack>
                                        </Box>
                                        <div
                                          style={{
                                            borderRight: "2px solid #C9CFE7",
                                            height: "30px",
                                          }}
                                        ></div>
                                        <Box>
                                          <Stack
                                            direction="column"
                                            spacing={1}
                                            style={{ marginTop: "5px" }}
                                          >
                                            <img
                                              src={images.serviceCallImg}
                                              alt=""
                                              className="dashboardCard-companyImg"
                                            />
                                            <Typography
                                              variant="caption"
                                              className="dashboardCard-plantSmalltext"
                                            >
                                              Service call
                                            </Typography>
                                          </Stack>
                                        </Box>
                                        <div
                                          style={{
                                            borderRight: "2px solid #C9CFE7",
                                            height: "30px",
                                          }}
                                        ></div>

                                        <Box>
                                          <Stack
                                            direction="column"
                                            spacing={1}
                                            style={{ marginTop: "5px" }}
                                          >
                                            {row.license_status_count == 0 ? (
                                              <img
                                                src={images.noSub}
                                                alt=""
                                                className="dashboardCard-companyImg"
                                              />
                                            ) : (
                                              <img
                                                src={images.alertImage}
                                                alt=""
                                                className="dashboardCard-companyImg"
                                              />
                                            )}
                                            <Typography
                                              variant="caption"
                                              className="dashboardCard-plantSmalltext"
                                            >
                                              Subscription
                                            </Typography>
                                          </Stack>
                                        </Box>
                                        <div
                                          style={{
                                            borderRight: "2px solid #C9CFE7",
                                            height: "30px",
                                          }}
                                        ></div>

                                        <Box paddingRight={4}>
                                          <Stack
                                            direction="column"
                                            spacing={1}
                                            style={{ marginTop: "5px" }}
                                          >
                                            {row.alarm_count > 0 ? (
                                              <img
                                                src={
                                                  images.notificationactiveImg
                                                }
                                                alt=""
                                                className="dashboardCard-companyImg"
                                              />
                                            ) : (
                                              <img
                                                src={images.alertImg}
                                                alt=""
                                                style={{
                                                  width: "25px",
                                                  margin: "0 auto",
                                                }}
                                                // className="dashboardCard-companyImg"
                                              />
                                            )}
                                            <Typography
                                              variant="caption"
                                              className="dashboardCard-plantSmalltext"
                                            >
                                              Notification
                                            </Typography>
                                          </Stack>
                                        </Box>
                                      </Box>
                                    </Stack>
                                  </Card>
                                </Grid>
                              );
                            })
                          : []}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <SmarTagMap
                  location={selectedLocation}
                  plantName={selectedPlant}
                  selectedImage={selectedImage}
                  allPlant={allPlant}
                  plantId={plantId}
                  companyId={params.companyid}
                  plantAllData={plantData}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>

      {/* <div className="p-4">
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box className="location-box">
              <div className="mt-2 location-div">
                {plantData.length > 0 &&
                  plantData.map((row, index) => (
                    <Box
                      key={index}
                      className="location-plants-data mt-2"
                      onClick={() =>
                        handleBoxClick(
                          row.plant_location,
                          row.plant_name,
                          row.is_solar_energy,
                          row.id
                        )
                      }
                    >
                      <Stack direction="column">
                        <Typography
                          gutterBottom
                          className="dashboardlocation-plantTitle"
                        >
                          {row.plant_name ? row.plant_name : "-"}
                        </Typography>
                        <Typography
                          gutterBottom
                          className="dashboardlocation-plantsubTitle"
                        >
                          {row.plant_description ? row.plant_description : "-"}
                        </Typography>

                        <Box className="dashboardCard-companyContent">
                          <Typography
                            gutterBottom
                            className="dashboardCard-plantText"
                          >
                            Device :
                          </Typography>
                          <Stack direction="column">
                            <Typography
                              variant="body1"
                              className="dashboardCard-plantNumber1"
                            >
                              {
                                row.device.filter(
                                  (value) =>
                                    value.is_sub == true &&
                                    value.device_type_name !== "Other"
                                ).length
                              }
                            </Typography>
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Active
                            </Typography>
                          </Stack>
                          <Box>
                            <Stack direction="column">
                              <Typography
                                variant="body1"
                                className="dashboardCard-plantNumber2"
                              >
                                {
                                  row.device.filter(
                                    (value) =>
                                      value.is_sub == false &&
                                      value.device_type_name !== "Other"
                                  ).length
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className="dashboardCard-plantSmalltext"
                              >
                                Inactive
                              </Typography>
                            </Stack>
                          </Box>
                          <div
                            style={{
                              borderRight: "2px solid #C9CFE7",
                              height: "30px",
                            }}
                          ></div>
                          <Box>
                            <Stack
                              direction="column"
                              spacing={1}
                              style={{ marginTop: "5px" }}
                            >
                              <img
                                src={images.serviceCallImg}
                                alt=""
                                className="dashboardCard-companyImg"
                              />
                              <Typography
                                variant="caption"
                                className="dashboardCard-plantSmalltext"
                              >
                                Service call
                              </Typography>
                            </Stack>
                          </Box>
                          <div
                            style={{
                              borderRight: "2px solid #C9CFE7",
                              height: "30px",
                            }}
                          ></div>

                          <Box>
                            <Stack
                              direction="column"
                              spacing={1}
                              style={{ marginTop: "5px" }}
                            >
                              {row.license_status_count == 0 ? (
                                <img
                                  src={images.noSub}
                                  alt=""
                                  className="dashboardCard-companyImg"
                                />
                              ) : (
                                <img
                                  src={images.alertImage}
                                  alt=""
                                  className="dashboardCard-companyImg"
                                />
                              )}
                              <Typography
                                variant="caption"
                                className="dashboardCard-plantSmalltext"
                              >
                                Subscription
                              </Typography>
                            </Stack>
                          </Box>
                          <div
                            style={{
                              borderRight: "2px solid #C9CFE7",
                              height: "30px",
                            }}
                          ></div>

                          <Box paddingRight={4}>
                            <Stack
                              direction="column"
                              spacing={1}
                              style={{ marginTop: "5px" }}
                            >
                              {row.alarm_count > 0 ? (
                                <img
                                  src={images.notificationactiveImg}
                                  alt=""
                                  className="dashboardCard-companyImg"
                                />
                              ) : (
                                <img
                                  src={images.alertImg}
                                  alt=""
                                  style={{ width: "25px", margin: "0 auto" }}
                                  // className="dashboardCard-companyImg"
                                />
                              )}
                              <Typography
                                variant="caption"
                                className="dashboardCard-plantSmalltext"
                              >
                                Notification
                              </Typography>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  ))}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box className="map-box" style={{ position: "relative" }}>
              <Box className="map-div" style={{ position: "relative" }}>
                
              <SmarTagMap
                  location={selectedLocation}
                  plantName={selectedPlant}
                  selectedImage={selectedImage}
                  allPlant={allPlant}
                  plantId={plantId}
                  companyId={params.id}
              
                  plantAllData={plantData}
                />
              </Box>

              <Box className="analyze-div d-flex justify-content-between">
                <div className="gen-div">
                  <div className="dashboardCard-item-location">
                    <div className="dashboard-card-img">
                      <img
                        src={images.energyMeterImg}
                        alt=""
                        className="dashboardCard-img"
                      />
                    </div>
                    <div className="dashboardCard-text">
                      <div>
                        <p className="dashboardCard-title">SmarTag</p>
                      </div>
                      <div>
                        <h6 className="dashboardCard-number">
                          {companyDataCount.device_count}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="generation-divider"></div>
                <div className="gen-div">
                  <div className="dashboardCard-item-location">
                    <div className="dashboard-card-img">
                      <img
                        src={images.clientManagementPlantS}
                        alt=""
                        className="dashboardCard-img"
                      />
                    </div>
                    <div className="dashboardCard-text">
                      <div>
                        <p className="dashboardCard-title">COMPANY</p>
                      </div>
                      <div>
                        <h6 className="dashboardCard-number">
                          {companyDataCount.company_count}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="generation-divider"></div>
                <div className="gen-div">
                  <div className="dashboardCard-item-location">
                    <div className="dashboard-card-img">
                      <img
                        src={images.activeImg}
                        alt=""
                        className="dashboardCard-img"
                      />
                    </div>
                    <div className="dashboardCard-text">
                      <div>
                        <p className="dashboardCard-title">ACTIVE</p>
                      </div>
                      <div>
                        <h6 className="dashboardCard-number">
                          {companyDataCount.active_company}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="generation-divider"></div>
                <div className="gen-div">
                  <div className="dashboardCard-item-location">
                    <div className="dashboard-card-img">
                      <img
                        src={images.inActiveImg}
                        alt=""
                        className="dashboardCard-img"
                      />
                    </div>
                    <div className="dashboardCard-text">
                      <div>
                        <p className="dashboardCard-title">IN ACTIVE</p>
                      </div>
                      <div>
                        <h6 className="dashboardCard-number">
                          {companyDataCount.inactive_company}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div> */}
    </>
  );
};

export default LocationPlantDashboard;

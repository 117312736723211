import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";

HC_more(Highcharts);
exporting(Highcharts);

const PieChart = ({
  generationData,
  colorCode,
  selectedPlantId,
  startDate,
  endDate,
}) => {
  // Utility function to adjust color opacity
  const adjustColorOpacity = (color, isSelected) => {
    if (isSelected) return color; // Keep normal color for selected plants
    const opacity = 0.2; // Adjust opacity for greyed-out effect
    return Highcharts.color(color).setOpacity(opacity).get();
  };

  // Transform generationData into the format required for Highcharts
  const chartData = generationData.map((item, index) => {
    const isSelected = selectedPlantId.includes(item.plant_id); // Check if plant_id is in selectedPlantId

    const color = isSelected
      ? colorCode[index] // Use the regular color
      : "#918c8c"; // Use greyed-out color for unchecked plants
    return {
      name: item.plant_name,
      y: item.generation_value
        ? parseFloat(Math.abs(item.generation_value).toFixed(2))
        : parseFloat(Math.abs(item.MW).toFixed(2)), // Use MW or generation_value rounded to 2 decimals
      color: color,
      plant_id: item.plant_id, // Include plant_id in the chart data
    };
  });

  const options = {
    chart: {
      type: "pie",
      height: 300,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>",
    },
    accessibility: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          distance: 20, // Increased distance to give labels more space
          formatter: function () {
            // Use the plant_id to conditionally show labels
            if (selectedPlantId.includes(this.point.plant_id)) {
              return `
                                <span style="font-size: 16px; font-weight: bold; margin-right: 10px;">${this.percentage.toFixed(
                                  2
                                )}%</span><br>
                                <span style="font-size: 10px; font-weight: 400; text-align: right;">${
                                  this.y
                                }${
                startDate && endDate ? " MWh" : " MW"
              }</span><br>
                                <span style="font-size: 10px; font-weight: 400; text-align: right;">${
                                  this.point.name
                                }</span>
                            `;
            }
            return null; // Hide label for non-selected plant IDs
          },
          connectorWidth: 1, // Thin connector line

          style: {
            color: "black",
            textOutline: "none",
          },
          overflow: "allow", // Allow labels to overflow if needed
        },
        softConnector: true, // Use soft connectors to avoid straight lines between pie slices
      },
    },

    series: [
      {
        name: "Share",
        colorByPoint: true,
        data: chartData,
      },
    ],
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PieChart;

// import React from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";

// const PieChart = () => {
//   const options = {
//     chart: {
//       type: "pie",
//       height: "300px",
//     },
//     title: {
//       text: null, // No title above the chart
//     },
//     tooltip: {
//       pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
//     },
//     accessibility: {
//       enabled: false, // Disable extra accessibility features
//     },
//     credits: {
//       enabled: false, // Remove the Highcharts logo
//     },
//     plotOptions: {
//       pie: {
//         allowPointSelect: true,
//         cursor: "pointer",
//         dataLabels: {
//           enabled: true,
//           distance: 15, // Place labels outside the pie chart
//           connectorWidth: 0, // Remove connector lines
//           format: "<b>{point.percentage}%</b><br>{point.y}MW<br>{point.name}",
//           style: {
//             fontSize: "12px",
//             color: "#333", // Ensure labels are visible outside
//             textOutline: "none", // Remove text outline
//           },
//           overflow: "justify", // Ensure proper label alignment
//         },
//         borderWidth: 0, // Remove space between segments
//         size: "70%", // Ensure full size pie chart
//       },
//     },
//     series: [
//       {
//         name: "Share",
//         colorByPoint: true,
//         data: [
//           { name: "KOCHI Refinery", y: 125, color: "#f3abab" },
//           { name: "KOCHI Refinery", y: 187.5, color: "#fca7a7" },
//           { name: "BINA Refinery", y: 250, color: "#5999a0" },
//           { name: "BINA Refinery", y: 375, color: "#45748b" },
//           { name: "KOCHI Refinery", y: 312.5, color: "#a5d1d4" },
//         ],
//       },
//     ],
//   };

//   return <HighchartsReact highcharts={Highcharts} options={options} />;
// };

// export default PieChart;
